import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'gatsby-plugin-intl';
import Avatar from '../avatar';
import styles from './order-placed-banner.module.scss';

const OrderPlacedBanner = ({
  userName,
  orderResult,
  orderMessage,
  isInit,
  isSuccess,
  showDisclaimer,
  requestToPayOrder,
  payerUserName,
  payerUserLastName,
  bankStatementDescriptor,
}) => {
  const intl = useIntl();

  return (
    <div className={styles.banner}>
      <div className={styles.avatarWrapper}>
        <Avatar />
      </div>
      <div className={styles.textWrapper}>
        <p className={styles.text}>
          {`${intl.formatMessage({ id: 'hello' })}, `}
          <span>{userName}</span>!
        </p>

        <p className={styles.text}>
          {requestToPayOrder ? (
            <>
              <div>{`Your order request has been forwarded to ${payerUserName} ${payerUserLastName}.`}</div>
              <div>
                You will receive a confirmation email soon this order is completed successfully
              </div>
            </>
          ) : (
            <>
              Your order has been &nbsp;
              <span className={styles[orderResult.toLocaleLowerCase()]}>{orderResult || ''}</span>!
            </>
          )}
        </p>
        {orderMessage && <p className={clsx(styles.text, styles.result)}>{orderMessage}</p>}
      </div>

      {isInit && isSuccess && (
        <div className={clsx(styles.init)}>
          <span>
            Please check your email and click on the “Complete Verification“ link to activate your
            product. If you don’t see this email in your inbox, check your junk mail folder.
          </span>
        </div>
      )}
      {showDisclaimer && (
        <div className={clsx(styles.disclaimer)}>
          <span>{`Please note that this charge will appear in your bank statement as ${bankStatementDescriptor}`}</span>
        </div>
      )}
    </div>
  );
};

OrderPlacedBanner.propTypes = {
  userName: PropTypes.string.isRequired,
  payerUserName: PropTypes.string.isRequired,
  payerUserLastName: PropTypes.string.isRequired,
  orderResult: PropTypes.string.isRequired,
  orderMessage: PropTypes.string.isRequired,
  isInit: PropTypes.string.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  showDisclaimer: PropTypes.bool.isRequired,
  requestToPayOrder: PropTypes.bool.isRequired,
  bankStatementDescriptor: PropTypes.string.isRequired,
};

export default OrderPlacedBanner;
