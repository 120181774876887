import { isClient, isUserLogged } from '../../helpers/utils';

export const TOKEN_EXPIRED = 'AXIOS_TOKEN_EXPIRED';

export const tokenExpiredAction = () => ({ type: TOKEN_EXPIRED });

export function getTokens() {
  if (isClient && isUserLogged()) {
    return {
      refreshToken: localStorage.getItem('RT'),
      accessToken: localStorage.getItem('AT'),
      redirectionToken: localStorage.getItem('RED_TOKEN'),
    };
  }
  return {};
}

export function setTokens(data) {
  if (isClient) {
    localStorage.setItem('RT', data.refreshToken);
    localStorage.setItem('AT', data.accessToken);
    localStorage.setItem('RED_TOKEN', data.redirectionToken);
  }
}

export function patchRequestHeadersInterceptor(config = {}) {
  const { url, headers = {} } = config;
  if (url === '/auth/refresh-tokens') {
    headers.Authorization = `Bearer ${getTokens().refreshToken}`;
  } else if (url.includes('/auth/signin-cp-final')) {
    headers.Authorization = `Bearer ${getTokens().redirectionToken}`;
  } else {
    headers.Authorization = `Bearer ${getTokens().accessToken}`;
  }
  return { ...config, headers };
}
