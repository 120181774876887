import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { addToCart } from '../../store/actions';
import { selectUpsellingAddonsWithSubscriptions } from '../../store/selectors/entities';
import Addon from '../addon';
import styles from './upselling-addons.module.scss';
import { scrollToMiddle } from '../../helpers/utils';

const ID = 'UPSELLING_ADDONS';

const UpsellingAddons = ({ variant }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const upsellingAddons = useSelector(selectUpsellingAddonsWithSubscriptions);
  const isPreview = variant === 'preview';

  if (!upsellingAddons?.length) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {/* eslint-disable-next-line */}
      <h2
        className={clsx(styles.titleButton, isPreview ? styles.preview : '')}
        onClick={() => scrollToMiddle(ID)}
      >
        {intl.formatMessage({ id: 'upsellingAddons.title' })}
      </h2>

      <div className={styles.content}>
        {/* eslint-disable-next-line */}
        <div className={styles.previewContainer} onClick={() => scrollToMiddle(ID)}>
          {isPreview &&
            upsellingAddons?.map((item) => (
              <div key={JSON.stringify(item)} className={styles.previewItem}>
                <img src={item.imageUrl} alt="bg" />
              </div>
            ))}
        </div>

        {!isPreview &&
          upsellingAddons?.map((item) => (
            <div key={`${item.id}${Math.random()}`} className={styles.addonWrapper} id={ID}>
              <Addon
                key={item.id}
                id={item.sku}
                name={item.product.name}
                intervalSize={item.intervalSize}
                price={item.price}
                recurringPrice={item.recurringPrice}
                expiresAt={item.expiresAt}
                isExpired={item.isExpired}
                isRenew={item.isRenew}
                currentSubscription={item.currentSubscription}
                imageUrl={item.imageUrl}
                onClick={() => dispatch(addToCart('product', item))}
                description={item.bullets}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

UpsellingAddons.defaultProps = { variant: '' };

UpsellingAddons.propTypes = {
  variant: PropTypes.string,
};

export default UpsellingAddons;
