import { isProduction, isAddonInstance } from '../helpers/instanceHandler';

export const SHOP_ERROR_TYPES = {
  RENEW_NO_BILLED_SUBSCRIPTIONS: 'RENEW_NO_BILLED_SUBSCRIPTIONS',
  RENEW_UPCOMMING_INVOICE_NOT_FOUND: 'RENEW_UPCOMMING_INVOICE_NOT_FOUND',
  RENEW_SUBSCRIPTION_TOO_EARLY: 'RENEW_SUBSCRIPTION_TOO_EARLY',
  RENEW_NO_SUBSCRIPTIONS_IN_PAYMENT_SYSTEM: 'RENEW_NO_SUBSCRIPTIONS_IN_PAYMENT_SYSTEM',
  UPGRADE_NO_SUBSCRIPTIONS_IN_PAYMENT_SYSTEM: 'UPGRADE_NO_SUBSCRIPTIONS_IN_PAYMENT_SYSTEM',
  UPGRADE_NOT_EXIST: 'UPGRADE_NOT_EXIST',
  INCORRECT_PLAN_LIST: 'INCORRECT_PLAN_LIST',
  PLAN_UNSUPPORTABLE_PAYMENT_METHOD: 'PLAN_UNSUPPORTABLE_PAYMENT_METHOD',
  PLAN_NOT_FOUND: 'PLAN_NOT_FO',
  PURCHASE_RULE_NOT_PASSED: 'PURCHASE_RULE_NOT_PASSED',
  COUPON_DOES_NOT_SUIT: 'COUPON_DOES_NOT_SUIT',
  COUPON_FOR_BITCOIN_ONLY: 'COUPON_FOR_BITCOIN_ONLY',
  COUPON_FOR_ELITE_ONLY: 'COUPON_FOR_ELITE_ONLY',
  COUPON_UNSUPPORTED_COUNTRY: 'COUPON_UNSUPPORTED_COUNTRY',
  COUPON_UNSUPPORTED_PAYMENT_METHOD: 'COUPON_UNSUPPORTED_PAYMENT_METHOD',
  COUPON_UNSUPPORTED_CURRENCY: 'COUPON_UNSUPPORTED_CURRENCY',
  COUPON_FOR_SINGLE_PURCHASE_ONLY: 'COUPON_FOR_SINGLE_PURCHASE_ONLY',
  UNPROCESSABLE_ERROR: 'UNPROCESSABLE_ERROR',
};

const loaderPrefix = 'loader_button';

export const INITIATORS = {
  SHOP: 'shop',
  ADDON_SHOP: 'addon_shop',
};

export const ButtonLoaderTypes = {
  PURCHASE: `purchase_${loaderPrefix}`,
};

const paypalGetawaysProduction = {
  paypalCommon: 'paypal-live',
  paypalCard: 'paypal-live',
};

const paypalGetawaysStaging = {
  paypalCommon: 'demo-sandbox-gateway',
  paypalCard: 'paypal',
};

export const REDIRECTION_TYPES = {
  SHOP: 'shop',
  PORTAL: 'portal',
  ALTERNATIVE_PAYOUT: 'alternativePayout',
  WOOCOMMERCE: 'woocommerce',
};

export const TOKEN_TYPES = {
  PORTAL: 'tempToken',
  SHOP: 'tempTok',
  WOOCOMMERCE: 'Token',
};

export const SOCKET_EVENTS = {
  ORDER_UPDATED: 'ORDER_UPDATED',
};

export const SocketActionTypes = {
  ORDER_UPDATED: 'SOCKET_ORDER_UPDATED',
};

export const INSTANCE_TYPES = {
  MHA_LOGIN: 'mha_login',
  TRAVEL_LOGIN: 'travel_login',
  SHOP: 'shop',
  CUSTOMER_PORTAL: 'customer_portal',
  IBI_SHOP: 'ibi_shop',
  KEY_REPORT: 'key_report',
  BE_PAY: 'be_pay',
};

export const INITIATOR = isAddonInstance ? INITIATORS.ADDON_SHOP : INITIATORS.SHOP;
export const PAYPAL_GETAWAYS = isProduction ? paypalGetawaysProduction : paypalGetawaysStaging;
