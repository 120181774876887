import React from 'react';

import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3';

import SEO from '../components/seo';
import LocaleTabs from '../components/locale-tabs';
import LogoImage from '../components/images/logoImage';

import styles from '../styles/pages/region.module.scss';
import { pageLinks } from '../helpers/navigation';
import { getInstanceName } from '../helpers/utils';

const RegionsPage = () => {
  const intl = useIntl();
  const siteName = getInstanceName();
  const welcomeTxt = `Welcome to ${siteName}.`;
  const pleaseChooseTxt = intl.formatMessage({ id: 'regions_page.please_choose' });
  const closeTxt = intl.formatMessage({ id: 'regions_page.close' });
  const title = intl.formatMessage({ id: 'pageTitle.regions' });

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <>
          <SEO title={`${title} - ${siteName}`} />
          <div className="container">
            <div className={styles.regions}>
              <div className={styles.header}>
                <div className={styles.logoWrapper}>
                  <LogoImage />
                </div>
                <div className={styles.closeLink}>
                  <Link to={modal ? closeTo : pageLinks.home} className={styles.link}>
                    <span>{closeTxt}</span>
                    <i className="icon-close" />
                  </Link>
                </div>
              </div>
              <h2 className={styles.title}>{welcomeTxt}</h2>
              <p className={styles.subtitle}>{pleaseChooseTxt}</p>
              <div className={styles.tabsWrapper}>
                <LocaleTabs />
              </div>
            </div>
          </div>
        </>
      )}
    </ModalRoutingContext.Consumer>
  );
};

export default RegionsPage;
