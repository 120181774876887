import apiAction from '../apiActionCreator';

const type = 'RENEW_SUBSCRIPTION';

const config = {
  url: '/orders/renewal',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
