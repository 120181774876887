import clsx from 'clsx';

import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

import Button from '../button';

import styles from './add-cart-item.module.scss';

const AddCartItem = ({ onAdd, title }) => {
  const intl = useIntl();
  const addItemTxt = intl.formatMessage({ id: 'add_item' });

  return (
    <Button className={styles.button} onClick={onAdd}>
      <div className={styles.content}>
        <i className={clsx('icon-plus-expand', styles.icon)} />
        <span>{title || addItemTxt}</span>
      </div>
    </Button>
  );
};

AddCartItem.defaultProps = { onAdd: () => {}, title: '' };

AddCartItem.propTypes = {
  onAdd: PropTypes.func,
  title: PropTypes.string,
};

export default AddCartItem;
