import apiAction from '../apiActionCreator';

const type = 'ADD_PAYMENT_CARD_AND_SET_DEFAULT';

const config = {
  url: '/payments/method/create-and-set-default',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
