import apiAction from '../apiActionCreator';

const type = 'GET_USER_EXPECTED_SUBSCRIPTIONS';

const config = {
  url: '/orders/purchased/list',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
