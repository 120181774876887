import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './src/store/createStore';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating state in `wrapRootElement` handler ensures:
  //  - there is fresh state for each SSR page
  //  - it will be called only once in browser, when React mounts

  store.runSaga();
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  );
};
