import clsx from 'clsx';
import React from 'react';
import { Link, useIntl } from 'gatsby-plugin-intl';
import { pageLinks } from '../../helpers/navigation';
import { filterMemberShipPages } from '../../helpers/pages';
import styles from './membershipNav.module.scss';

const links = [
  {
    id: 0,
    icon: 'icon-compass',
    title: 'membership.travel',
    url: pageLinks.membershipTravel,
    className: styles.colorPrimary,
  },
  {
    id: 1,
    icon: 'icon-book',
    title: 'membership.learn',
    url: pageLinks.membershipLearn,
    className: styles.colorYellow,
  },
  {
    id: 2,
    icon: 'icon-boat',
    title: 'membership.lifestyle',
    url: pageLinks.membershipLifestyle,
    className: styles.colorCyan,
  },
  {
    id: 3,
    icon: 'icon-dish',
    title: 'membership.concierge',
    url: pageLinks.membershipConcierge,
    className: styles.colorRed,
  },
];

const MembershipNav = () => {
  const intl = useIntl();
  const navs = links.filter(filterMemberShipPages);

  if (navs.length === 1) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className="container">
        <div className={styles.row}>
          {navs.map((x) => (
            <Link
              activeClassName={styles.active}
              className={clsx(styles.link, x.className)}
              to={x.url}
              key={x.id}
            >
              <i className={x.icon} />
              {intl.formatMessage({ id: x.title })}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MembershipNav;
