import React from 'react';
import { useSelector } from 'react-redux';
import { selectLanguages } from '../../store/selectors/global';

import LanguageSelect from './language-select';

const LanguageSelectContainer = (props) => {
  const languages = useSelector(selectLanguages);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <LanguageSelect languages={languages} {...props} />;
};

export default LanguageSelectContainer;
