import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './input-radio.module.scss';

const InputRadio = ({ className, checked, ...props }) => {
  const icon = checked ? 'icon-radio-button-checked' : 'icon-radio-button-unchecked';
  return (
    <span className={clsx(styles.wrapper, className)}>
      <i className={clsx(icon, styles.icon)} />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input type="radio" checked={checked} className={styles.input} {...props} />
    </span>
  );
};

InputRadio.defaultProps = { className: '', checked: false };

InputRadio.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
};

export default InputRadio;
