import { all, put, select, take, takeLatest, call, cancel, fork } from 'redux-saga/effects';
import * as AppConstants from '../store/constants';
import { createNavigateTo, pageLinks } from '../helpers/navigation';
import {
  addValidAddonToCard,
  makeOrderWithSavedCart,
  onValidateAddon,
  setLoader,
  setModalInfo,
} from '../store/actions';
import {
  hasLiveSubscriptionChecker,
  isClient,
  isLiveAddonsChecker,
  isUserLogged,
} from '../helpers/utils';
import { selectEntityUserSubscriptions } from '../store/selectors/entities';
import checkSorUser from '../services/api/actions/checkSorUser';
import calculatePrice from '../services/api/actions/calculatePrice';
import { selectCart } from '../store/selectors';
// eslint-disable-next-line require-yield
function* toggleModalWorker({ payload }) {
  if (payload.type === null) {
    document.body.className = document.body.className.replace('modal-is-active', '');
  } else if (isClient) {
    window.scrollTo({
      top: 100,
      left: 0,
      behavior: 'smooth',
    });
    document.body.className = document.body.className.replace('', ' modal-is-active');
  }
}

function* onSuccessAddonValidation(addon) {
  yield put(setLoader(false));
  switch (addon.successActionType) {
    case 'ADD_TO_CART':
      yield put(addValidAddonToCard('product', { ...addon, level: '' }));
      break;
    case 'PURCHASE':
      yield put(makeOrderWithSavedCart());
      break;
    case 'MOVE_TO_CHECKOUT':
      createNavigateTo(pageLinks.checkout)();
      break;
    default:
      break;
  }
}

function* validateAddonWorker({ payload }) {
  yield put(setLoader(true));
  const addon = payload[0];

  // if (!addon) {
  //   return;
  // }

  if (payload?.length === 1) {
    const shoppingCart = yield select(selectCart);

    const addonAlreadyAdded = shoppingCart.products.some(
      (plan) => plan.product.name === addon.product.name
    );

    if (addonAlreadyAdded || !isUserLogged()) {
      yield call(onSuccessAddonValidation, addon);
      return;
    }
  }

  const subscriptions = yield select(selectEntityUserSubscriptions);
  yield put(onValidateAddon(payload));

  const isLiveAddons = isLiveAddonsChecker(addon.skus);
  const hasLive = hasLiveSubscriptionChecker(subscriptions);

  if (isLiveAddons) {
    if (!hasLive) {
      yield put(
        setModalInfo({
          type: 'doNotHaveLiveSubscription',
          addonName: addon.product.name,
        })
      );
      yield put(setLoader(false));
      return;
    }
  }

  while (true) {
    const response = yield take(calculatePrice.type.success);
    if (isLiveAddons) {
      yield put(checkSorUser.action());
      const { error } = yield take([checkSorUser.type.success, checkSorUser.type.error]);

      if (error) {
        yield put(
          setModalInfo({
            type: 'neverLoggedToTravel',
          })
        );
        yield put(setLoader(false));
        return;
      }
    }

    if (response) {
      yield call(onSuccessAddonValidation, addon);
      yield put({ type: AppConstants.STOP_ADDON_VALIDATION });
    }
  }
}

function* cancelTaskWorker(task) {
  yield cancel(task);
}

function* addonWatcher(action) {
  const task = yield fork(validateAddonWorker, action);
  yield takeLatest(AppConstants.STOP_ADDON_VALIDATION, () => cancelTaskWorker(task));
}

export default function* addonsSaga() {
  yield all([
    yield takeLatest(AppConstants.VALIDATE_ADDON, addonWatcher),
    yield takeLatest(AppConstants.SET_ADDONS_MODAL_INFO, toggleModalWorker),
  ]);
}
