import apiAction from '../apiActionCreator';

const type = 'PAY_CANCELLED_ORDER';

const config = {
  url: '/orders/purchase/retry',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
