import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { RichText } from 'prismic-reactjs';

import styles from './accordion-item.module.scss';

const AccordionItem = ({ content, open, onItemClick }) => {
  const onClick = () => {
    onItemClick(content.title);
  };

  const iconName = open ? 'icon-minus-collapse' : 'icon-plus-expand';

  return (
    <div className={clsx(styles.item, { [styles.open]: open })}>
      <p className={styles.title} onClick={onClick} role="presentation">
        {content.title}
        <i className={clsx(iconName, styles.icon)} />
      </p>
      <div className={styles.descriptionWrapper}>
        {open && <p className={styles.description}>{RichText.render(content.description)}</p>}
      </div>
    </div>
  );
};

AccordionItem.propTypes = {
  open: PropTypes.bool.isRequired,
  onItemClick: PropTypes.func.isRequired,
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.arrayOf(
      PropTypes.shape({
        spans: PropTypes.arrayOf(PropTypes.string),
        type: PropTypes.string,
        text: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
};

export default AccordionItem;
