import { countries } from 'countries-list';

export const isValidCountryCode = (code) =>
  // eslint-disable-next-line
  typeof code === 'string' && countries.hasOwnProperty(code.toUpperCase());

export const getCountryData = (code) => {
  if (!isValidCountryCode(code)) {
    return null;
  }
  return countries[code.toUpperCase()];
};

export const getAllCountryCodes = () => Object.keys(countries).map((code) => code);
