import apiAction from '../apiActionCreator';

const type = 'GET_USER_SOCIAL_NETWORKS';

const config = {
  url: '/user/exigo-social-links',
  actionType: type,
  sendFormat: 'json',
  method: 'GET',
};

export default apiAction(config);
