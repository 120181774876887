import React from 'react';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

import { createNavigateTo, pageLinks } from '../../helpers/navigation';

import Button from '../button';

import styles from './join-cta.module.scss';

const query = graphql`
  query {
    ctaImage: file(relativePath: { eq: "people-invite-friends.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 360, maxHeight: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ctaBackgroundLeft: file(relativePath: { eq: "confeti-left.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 270) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ctaBackgroundRight: file(relativePath: { eq: "confeti-right.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 270) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const JoinCta = () => {
  const intl = useIntl();
  const data = useStaticQuery(query);

  return (
    <div className={styles.ctaBanner}>
      <BackgroundImage
        Tag="div"
        className={styles.ctaBackground}
        fluid={[
          data.ctaBackgroundRight.childImageSharp.fluid,
          data.ctaBackgroundLeft.childImageSharp.fluid,
        ]}
        backgroundColor="transparent"
      >
        <div className={styles.ctaContent}>
          <div className={styles.contentWrapper}>
            <div className={styles.invitedPeople}>
              <div className={styles.invitedPeopleImageWrapper}>
                <Img fluid={data.ctaImage.childImageSharp.fluid} />
              </div>
            </div>
            <h2 className={styles.ctaHeader}>
              {intl.formatMessage({ id: 'join_other_customers' })}
            </h2>
            <p className={styles.ctaText}>
              {intl.formatMessage({ id: 'we_ve_got_the_right_plan_for_you' })}
            </p>
            <div className={styles.ctaAction}>
              <Button
                color="primary"
                className={styles.ctaBtn}
                onClick={createNavigateTo(pageLinks.pricing)}
              >
                {intl.formatMessage({ id: 'get_started' })}
              </Button>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </div>
  );
};

export default JoinCta;
