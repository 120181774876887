const DEPRECATED_PAGE_LIST = [
  '/membership/travel/',
  '/membership/concierge/',
  '/membership/lifestyle/',
  '/membership/learn/',
  '/membership-old/',
  '/blog/',
  // TODO: remove it when promote is available
  '/promote/',
  '/sign-up-2/',
  '/sign-up-3/',
  '/sign-up-4/',
  '/pre-checkout/',
  '/promote-sign-up/',
  '/become-ibp/',
];

// if (process.env.GATSBY_INSTANCE_NAME !== 'onlineLine') {
//   DEPRECATED_PAGE_LIST = [
//     ...DEPRECATED_PAGE_LIST,
//     '/promote/',
//     '/sign-up-2/',
//     '/sign-up-3/',
//     '/sign-up-4/',
//   ];
// }

module.exports = DEPRECATED_PAGE_LIST;
