import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { RichText } from 'prismic-reactjs';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import BannerRefferal from '../components/banner-refferal';
import PricingPlanCard from '../components/pricing-plan-card';
import Accordion from '../components/accordion';
import JoinCta from '../components/join-cta';
import withLocation from '../hoc/withLocation';
import { selectProducts } from '../store/selectors';
import { logEvent, getInstanceName } from '../helpers/utils';
import styles from '../styles/pages/pricing.module.scss';
import SEO from '../components/seo';
import { pageLinks } from '../helpers/navigation';
import { isAddonInstance } from '../helpers/instanceHandler';
import PreviousOrderModal from '../components/previous-order-modal';

export const query = graphql`
  query {
    images: allImage {
      edges {
        node {
          id
          name
          image {
            childImageSharp {
              fluid(quality: 90, maxWidth: 360, maxHeight: 430) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    defaultProductLogo: file(relativePath: { eq: "plan/default-plan.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 360, maxHeight: 430) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    prismic {
      allFrequentlyaskedquestions {
        edges {
          node {
            question
            answer
          }
        }
      }
    }
  }
`;

const sortFn = (a, b) => a.priority - b.priority;

const questionsType = 'allFrequentlyaskedquestions';

const createImages = (list, defaultImage) =>
  list.reduce(
    (acc, val) => {
      acc[val.node.name] = val?.node?.image?.childImageSharp?.fluid;
      return acc;
    },
    {
      default: defaultImage,
    }
  );

const stateSelector = createStructuredSelector({ products: selectProducts });

// eslint-disable-next-line react/prop-types
const PricingPage = ({ webAlias, PlacementType: placementType, Token: refferalToken, country }) => {
  const intl = useIntl();
  const data = useStaticQuery(query);
  const siteName = getInstanceName();
  const { products } = useSelector(stateSelector);

  useEffect(() => logEvent('visited_pricing'), []);

  const defaultImage = data.defaultProductLogo.childImageSharp.fluid;

  const accordionQuestions = data.prismic[questionsType].edges.map((doc) => ({
    title: RichText.asText(doc.node.question),
    description: doc.node.answer,
  }));

  const images = createImages(data.images.edges, defaultImage);

  const pageUrl = isAddonInstance ? pageLinks.annualPackages : pageLinks.pricing;

  return (
    <>
      <SEO title={`${intl.formatMessage({ id: 'pageTitle.pricing' })} - ${siteName}`} />
      <Layout>
        <PreviousOrderModal />
        <div className="refferalWrapper">
          <BannerRefferal
            webAlias={webAlias}
            placementType={placementType}
            refferalToken={refferalToken}
            country={country}
          />
        </div>

        <div className={styles.pricing}>
          <div className={styles.plans}>
            <h2 className={styles.plansHeader}>
              {intl.formatMessage({ id: 'pricing_page.flexible_plans_with_no_surprise' })}
            </h2>
            <h3 className={styles.plansSubheader}>
              {intl.formatMessage({ id: 'pricing_page.simple_plans' })}
            </h3>
          </div>

          <div className={styles.cards}>
            {products?.length > 0 &&
              products
                .sort(sortFn)
                .map(({ name, price, bgColor, color, urlParam, minimumAnnualPrice }) => {
                  const minPrice = isAddonInstance
                    ? `$${minimumAnnualPrice}/year`
                    : `$${price}/month`;
                  return (
                    <PricingPlanCard
                      key={urlParam}
                      name={name}
                      showPrice={price > 0}
                      price={minPrice}
                      planUrl={`${pageUrl}/${urlParam}`}
                      imageData={images[`${urlParam}_preview_image`] || images.default}
                      productColors={{ bgColor, color }}
                    />
                  );
                })}
          </div>
          <div className={styles.help}>
            <h2 className={styles.helpHeader}>
              {intl.formatMessage({ id: 'help.how_we_can_help_you' })}
            </h2>
            <Accordion items={accordionQuestions} />
          </div>

          <div className={styles.cta}>
            <JoinCta />
          </div>
        </div>
      </Layout>
    </>
  );
};

PricingPage.propTypes = {
  webAlias: PropTypes.string,
  PlacementType: PropTypes.string,
  Token: PropTypes.string,
};

PricingPage.defaultProps = {
  webAlias: '',
  PlacementType: '',
  Token: '',
};

export default withLocation(PricingPage);
