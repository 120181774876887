import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';

import Button from '../button';
import { SocialIconsLibrary } from './constants';

import styles from './banner-refferal.module.scss';

const BannerRefferal = ({
  showBanner,
  customerId,
  firstName,
  lastName,
  email,
  closeBanner,
  social,
}) =>
  showBanner && (
    <div className={styles.bannerContainer}>
      <div className={styles.bannerWrapper}>
        <section>
          <h4 className={styles.bannerTitle}>
            You are referred by{' '}
            <span className={styles.userName}>{`${firstName} ${lastName}`}</span>
          </h4>

          <div className={styles.userInfoSection}>
            <div className={styles.userId}>
              <span className={styles.userInfoLabel}>ID:</span>
              <span className={styles.userInfoText}>{customerId}</span>
            </div>

            {email && (
              <div className={styles.userEmail}>
                <span className={styles.userInfoLabel}>Email:</span>
                <span className={styles.userInfoText}>
                  <a href={email} target="_blank" rel="noopener noreferrer">
                    {email}
                  </a>
                </span>
              </div>
            )}
            {social.length > 0 && (
              <div className={styles.userSocial}>
                <div>
                  <span className={styles.userInfoLabel}>Social:</span>
                </div>
                <div className={styles.socialIcons}>
                  {social.map(({ name, url }) => {
                    if (SocialIconsLibrary[name.toLowerCase()]) {
                      return (
                        <span key={name} className={styles.userInfoIcon}>
                          <a href={url} title={name} target="_blank" rel="noopener noreferrer">
                            {SocialIconsLibrary[name.toLowerCase()]}
                          </a>
                        </span>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            )}
          </div>
        </section>

        <Button className={styles.bannerBtn} onClick={closeBanner}>
          {useIntl().formatMessage({ id: 'button.gotIt' })}
        </Button>
      </div>
    </div>
  );

BannerRefferal.defaultProps = {
  showBanner: false,
  customerId: undefined,
  firstName: '',
  lastName: '',
  email: '',
  social: [],
  closeBanner: () => {},
};

BannerRefferal.propTypes = {
  showBanner: PropTypes.bool,
  customerId: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  closeBanner: PropTypes.func,
  social: PropTypes.arrayOf(PropTypes.object),
};

export default BannerRefferal;
