import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal';
import Button from '../button';
import styles from './modals.module.scss';
import BillingPaymentBlockCard from '../billing-payment-block-card';
import Input from '../input';

const UpdateCvvModal = ({ onConfirm, hideModal, card, fullName, onSkipClick }) => {
  const { brand, method, last4, isDefault, id, expMonth, expYear } = card;
  const [value, setValue] = React.useState('');

  const onConfirmClick = () => {
    onConfirm({ id, cvv: value, expMonth, expYear });
  };

  const valid = value.length > 2;

  return (
    <Modal
      onCloseClick={hideModal}
      className={styles.modal}
      title={`Dear ${fullName}`}
      idOverlay="renewModal"
      modalHeaderContainerStyles={styles.modalHeader}
      isShown
    >
      <div className={styles.modalWrapper}>
        <p className={styles.modalText}>Please update/verify CVV</p>

        <BillingPaymentBlockCard
          brand={brand || method}
          last4={last4}
          isDefault={isDefault}
          wrapperClassName={styles.addressInList}
        />

        <Input
          label="CVV"
          id="cvv"
          placeholder="Enter cvv"
          onChange={(e) => setValue(e.target.value)}
          value={value}
          fullwidth
          required
        />

        <div className={styles.btnContainer}>
          <Button variant="outlined" size="large" type="button" onClick={onSkipClick}>
            Change payment method
          </Button>

          <Button
            className={styles.modalButton}
            onClick={onConfirmClick}
            disabled={!valid}
            size="large"
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

UpdateCvvModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  onSkipClick: PropTypes.func.isRequired,
  fullName: PropTypes.string.isRequired,
  card: PropTypes.shape({
    brand: PropTypes.string.isRequired,
    method: PropTypes.string.isRequired,
    last4: PropTypes.string.isRequired,
    isDefault: PropTypes.bool.isRequired,
    id: PropTypes.bool.isRequired,
    expMonth: PropTypes.bool.isRequired,
    expYear: PropTypes.bool.isRequired,
  }).isRequired,
};

export default UpdateCvvModal;
