import { BULLET_SPACE, isClient, shouldAddNewPage } from '../../helpers/utils';
import { font, fontStyle, id, postScriptName } from '../../../config/jsPDF';
import 'jspdf-autotable';
import {
  firstPageText,
  leftListA,
  pageTwoParagraphOne,
  pageTwoParagraphThree,
  pageTwoParagraphTwo,
  paragraphOne,
  paragraphTwo,
  rightListA,
  rightListB,
  string,
} from './pdf-config';
import { drawSideLines } from '../../helpers/pdfjs';

const HEIGHT = 600;
const WIDTH = 440;
const BULLETS_LENGTH = {
  SECOND_PAGE: 25,
  THIRD_PAGE: 80,
  FORTH_PAGE: 160,
  FIFTH_PAGE: 230,
};

export default (action, billingAddress, cart, paymentCompanyName, agreementPlans) => {
  if (isClient) {
    // eslint-disable-next-line global-require
    const { jsPDF } = require('jspdf');
    // eslint-disable-next-line global-require
    const moment = require('moment');
    const today = moment().format('MMM DD, YYYY');

    const cartData = {
      ...cart,
      products: agreementPlans,
    };

    const tableData = cartData.products.map((item) => {
      const recuringBillingDate = moment(today)
        .add(item.intervalSize, item.intervalUnit)
        .format('DD/MM/YYYY');

      const recuringPrice = item?.recurringPrice ? `$${item.recurringPrice}` : '-';
      return [
        item.name,
        // item.description,
        '1',
        // window.location.hostname,
        today,
        `$${item.price || item.setupPrice}`,
        recuringBillingDate,
        recuringPrice,
      ];
    });

    const tableGap = tableData?.length / 1.5;

    // eslint-disable-next-line new-cap
    const pdf = new jsPDF({ format: [HEIGHT, WIDTH], unit: 'px' });
    const setFontBold = () => pdf.setFont(undefined, 'bold');
    const setFontNormal = () => pdf.setFont(undefined, 'normal');

    // const setFontColorPurple = () => pdf.setTextColor(93, 1, 162);
    const setFontColorBlack = () => pdf.setTextColor(0, 0, 0);
    const setFontColorRed = () => pdf.setTextColor(255, 0, 0);
    // const countryCode = userDetails && userDetails.country;

    const fullName = `${billingAddress?.firstName} ${billingAddress?.lastName}`;

    pdf.addFileToVFS(postScriptName, font);
    pdf.addFont(postScriptName, id, fontStyle);
    pdf.setFont(id);

    let yPoint = 25;
    let yPointSecondColumn = 25;
    // const yStepVerySmall = 2;
    // const yStepSmall = 4;
    const yStepMiddle = 6;
    const yStepMiddlePlus = 8;
    const yStep = 12;
    const startPointY = 32 + 15;
    const startPointList = startPointY + 10;
    const startPointListInsdeList = startPointList + 10;
    const smallIndent = startPointY + 3;
    // const indent = startPointY + 5;

    const secondColumn = WIDTH / 2;
    const secondColumnList = secondColumn + 15;
    const startSecondColumn = secondColumn + 30;

    const addThirdPage = shouldAddNewPage(cartData, BULLET_SPACE.pageThree);
    const addForthPage = shouldAddNewPage(cartData, BULLET_SPACE.pageFour);
    const addFifthPage = shouldAddNewPage(cartData, BULLET_SPACE.pageFive);
    const addSixthPage = shouldAddNewPage(cartData, BULLET_SPACE.pageSix);

    const secondPageBullets = [];
    const thirdPageBullets = [];
    const forthPageBullets = [];
    const fifthPageBullets = [];
    const sixthPageBullets = [];
    const getLength = (productList) =>
      productList.reduce((accum, currentProduct) => accum + currentProduct.bullets.length, 0);

    const drawBullets = (productList) =>
      productList.forEach((product) => {
        if (product?.bullets?.length) {
          setFontBold();
          pdf.text(`${product.name}:`, smallIndent, yPoint, {
            align: 'left',
          });
          yPoint += yStep;
          setFontNormal();

          product.bullets.forEach((bullet) => {
            setFontNormal();
            pdf.text(`- ${bullet.replace('\n', '')}`, smallIndent, yPoint, {
              align: 'left',
            });
            yPoint += yStepMiddlePlus;
          });
          yPoint += yStepMiddlePlus;
        }
      });

    const separateBulletsBetweenPages = () => {
      cartData.products.forEach((product) => {
        if (product?.bullets?.length) {
          const currentItemBulletsLength = [...product?.bullets].length;
          const getBulletsLength = (bullets) => currentItemBulletsLength + getLength([...bullets]);
          const bulletLengthForSecondPage =
            currentItemBulletsLength + getLength(secondPageBullets) <
            BULLETS_LENGTH.SECOND_PAGE - tableGap;

          const thirdPage =
            getBulletsLength([...secondPageBullets, ...thirdPageBullets]) <
            BULLETS_LENGTH.THIRD_PAGE - tableGap;

          const forthPage =
            getBulletsLength([...secondPageBullets, ...thirdPageBullets, ...forthPageBullets]) <
            BULLETS_LENGTH.FORTH_PAGE - tableGap;

          const fifthPage =
            getBulletsLength([
              ...secondPageBullets,
              ...thirdPageBullets,
              ...forthPageBullets,
              ...fifthPageBullets,
            ]) <
            BULLETS_LENGTH.FIFTH_PAGE - tableGap;

          switch (true) {
            case bulletLengthForSecondPage:
              secondPageBullets.push(product);
              break;
            case thirdPage:
              thirdPageBullets.push(product);
              break;
            case forthPage:
              forthPageBullets.push(product);
              break;
            case fifthPage:
              fifthPageBullets.push(product);
              break;
            default:
              sixthPageBullets.push(product);
          }
        }
      });
    };

    if (addThirdPage) {
      separateBulletsBetweenPages();
    }

    const printListText = (list, x, isRightColumn, char) => {
      if (char) {
        pdf.text(char, x - 6, isRightColumn ? yPointSecondColumn : yPoint, { align: 'left' });
      }

      return list?.map((text) => {
        if (typeof text === 'object') {
          setFontBold();
          pdf.text(text.title, x, isRightColumn ? yPointSecondColumn : yPoint, { align: 'left' });
          setFontNormal();
          pdf.text(text.text, x + text.x, isRightColumn ? yPointSecondColumn : yPoint, {
            align: 'left',
          });
        } else {
          pdf.text(text, x, isRightColumn ? yPointSecondColumn : yPoint, { align: 'left' });
        }

        if (isRightColumn) {
          yPointSecondColumn += yStepMiddlePlus;
        } else {
          yPoint += yStepMiddlePlus;
        }

        return null;
      });
    };

    /** first page: */

    drawSideLines(pdf);
    setFontBold();
    pdf.setFontSize(8);
    pdf.text('- 1 -', WIDTH / 2, 15);
    pdf.text(firstPageText.refundAndCancellation, 73, yPoint, { align: 'left' });
    yPoint += yStepMiddlePlus;

    pdf.setFontSize(8);
    setFontColorRed();
    setFontNormal();
    pdf.text(firstPageText.readTheEntire, 80, yPoint, { align: 'left' });
    yPoint += yStepMiddlePlus;
    pdf.setFillColor(0, 0, 0);
    pdf.rect(startPointY, yPoint, 170, 1, 'F');
    yPoint += yStep;

    pdf.setFontSize(8);
    setFontColorBlack();
    paragraphOne.map((text) => {
      pdf.text(text, startPointY, yPoint, { align: 'left' });
      yPoint += yStepMiddlePlus;
      return null;
    });

    yPoint += yStepMiddle;
    paragraphTwo.map((text) => {
      pdf.text(text, startPointY, yPoint, { align: 'left' });
      yPoint += yStepMiddlePlus;
      return null;
    });

    yPoint += yStepMiddlePlus;
    pdf.text(firstPageText.theseTermsAre, startPointY, yPoint, { align: 'left' });
    setFontBold();
    pdf.text(firstPageText.standardTerms, 160, yPoint, { align: 'left' });
    yPoint += yStepMiddlePlus;

    setFontNormal();
    pdf.text(firstPageText.thatApplyToAll, startPointY, yPoint, { align: 'left' });
    yPoint += yStepMiddlePlus;
    setFontBold();
    pdf.text(firstPageText.regionSecificTerms, startPointY, yPoint, { align: 'left' });
    setFontNormal();
    pdf.text(firstPageText.thatAre, 128, yPoint, { align: 'left' });
    yPoint += yStepMiddlePlus;
    pdf.text(firstPageText.whoHaveLegal, startPointY, yPoint, { align: 'left' });
    yPoint += yStep;

    pdf.text(firstPageText.asFarAsPermitted, startPointY, yPoint, { align: 'left' });
    setFontBold();
    pdf.text(firstPageText.standardTerms, 160, yPoint, { align: 'left' });
    setFontNormal();
    yPoint += yStepMiddlePlus;
    pdf.text(firstPageText.shallSupersede, startPointY, yPoint, { align: 'left' });
    setFontBold();
    pdf.text(firstPageText.regionSecificTerms, 95, yPoint, { align: 'left' });
    yPoint += yStep;

    pdf.text('A.', startPointList, yPoint, { align: 'left' });
    pdf.text(`${firstPageText.standardTerms}:`, startPointY + 20, yPoint, { align: 'left' });
    yPoint += yStep;

    leftListA.map((data) => {
      printListText(data.list, startPointListInsdeList, false, data.char);
      yPoint += yStepMiddle;
      return null;
    });

    rightListA.map((data) => {
      printListText(data.list, startSecondColumn, true, data.char);
      yPointSecondColumn += yStepMiddle;
      return null;
    });

    setFontBold();
    pdf.text('B.', secondColumnList, yPointSecondColumn, { align: 'left' });
    pdf.text(`${firstPageText.regionSecificTerms}:`, secondColumnList + 10, yPointSecondColumn, {
      align: 'left',
    });
    yPointSecondColumn += yStep;
    setFontNormal();

    rightListB.map((data) => {
      printListText(data.list, startSecondColumn, true, data.char);
      yPointSecondColumn += yStepMiddle;
      return null;
    });

    /** second page: */

    pdf.addPage();
    yPoint = 35;
    drawSideLines(pdf);

    pdf.setFillColor(93, 1, 162);
    pdf.rect(550, 750, 10, 200, 'F');

    setFontBold();
    pdf.setFontSize(8);
    pdf.text('- 2 -', WIDTH / 2, 15);
    pdf.setFontSize(12);
    pdf.text(string.customerSigneTransaction, startPointY, yPoint, { align: 'left' });
    yPoint += yStep * 1.5;
    setFontNormal();

    pdf.setFontSize(10);
    pdf.text(`Dated: ${today}`, WIDTH - 100, yPoint, { align: 'left' });
    yPoint += yStep;

    pdf.setFillColor(93, 1, 162);
    pdf.rect(startPointY, yPoint, 360, 1, 'F');
    yPoint += yStep;

    pdf.text(
      `I, ${fullName}, hereby confirm and authorize the company to charge my credit card to purchase the`,
      smallIndent,
      yPoint,
      {
        align: 'left',
      }
    );
    yPoint += yStepMiddlePlus;

    pdf.text(`products as summarized below.`, smallIndent, yPoint, { align: 'left' });
    yPoint += yStepMiddlePlus;

    pdf.text(
      `I understand and accept that this charge will appear in my bank statement as`,
      smallIndent,
      yPoint,
      { align: 'left' }
    );
    yPoint += yStepMiddlePlus;

    pdf.text(`__________________________________`, smallIndent, yPoint, { align: 'left' });
    yPoint += yStep + 4;

    setFontBold();
    pdf.setFontSize(10);
    pdf.text(string.purchase, smallIndent, yPoint, { align: 'left' });
    yPoint += yStep;
    setFontNormal();
    pdf.text('Invoice Number: ________________________', smallIndent, yPoint, { align: 'left' });
    yPoint += yStepMiddle;

    const tableStyle = {
      fontSize: 7,
      fillColor: '#fff',
      textColor: '#000',
      lineColor: '#fff',
      lineWidth: 0.1,
    };

    const headTableStyle = {
      fontSize: 8,
      fillColor: '#5f0e9d',
      textColor: '#fff',
      halign: 'center',
    };

    pdf.autoTable({
      startY: yPoint,
      margin: {
        left: smallIndent,
      },
      tableWidth: 350,
      headStyles: { ...tableStyle, ...headTableStyle },
      bodyStyles: { ...tableStyle },
      columnStyles: {
        0: { halign: 'center' },
        1: { halign: 'center' },
        2: { halign: 'center' },
        3: { halign: 'center' },
        4: { halign: 'center' },
        5: { halign: 'center' },
        // 6: { halign: 'center' },
        // 7: { halign: 'center' },
      },
      head: [
        [
          'Product/\n Service',
          // 'Description',
          'Quantity',
          // 'Vendor \n website \n URL',
          'Date of \n Purchase',
          'Amount',
          'Recurring \n Billing Date',
          'Recurring \n Amount',
        ],
      ],
      body: tableData,
    });

    yPoint += yStep * (tableData.length + 4);

    if (!addThirdPage) {
      drawBullets(cartData.products);
    }

    if (addThirdPage) {
      yPoint += yStepMiddle;
      drawBullets(secondPageBullets);

      /** third page: */
      pdf.addPage();
      yPoint = 35;
      drawSideLines(pdf);
      pdf.text('- 3 -', WIDTH / 2, 15);
      drawBullets(thirdPageBullets);
    }

    if (addForthPage) {
      /** forth page: */
      pdf.addPage();
      yPoint = 35;
      drawSideLines(pdf);
      pdf.text('- 4 -', WIDTH / 2, 15);
      drawBullets(forthPageBullets);
    }

    if (addFifthPage) {
      /** fifth page: */
      pdf.addPage();
      yPoint = 35;
      drawSideLines(pdf);
      pdf.text('- 5 -', WIDTH / 2, 15);
      drawBullets(fifthPageBullets);
    }

    if (addSixthPage) {
      /** sixth page: */
      pdf.addPage();
      yPoint = 35;
      drawSideLines(pdf);
      pdf.text('- 6 -', WIDTH / 2, 15);
      drawBullets(sixthPageBullets);
    }

    pageTwoParagraphOne.map((text) => {
      pdf.text(text, smallIndent, yPoint, {
        align: 'left',
      });
      yPoint += yStepMiddlePlus;
      return null;
    });
    yPoint += yStepMiddlePlus;

    pageTwoParagraphTwo.map((text) => {
      pdf.text(text, smallIndent, yPoint, {
        align: 'left',
      });
      yPoint += yStepMiddlePlus;

      return null;
    });
    yPoint += yStepMiddlePlus;

    pageTwoParagraphThree.map((text) => {
      pdf.text(text, smallIndent, yPoint, {
        align: 'left',
      });
      yPoint += yStepMiddlePlus;
      return null;
    });
    yPoint += yStep;

    yPoint = 550;
    pdf.setFillColor(0, 0, 0);
    pdf.rect(startPointY, yPoint, 150, 0.5, 'F');
    yPoint += 8;
    pdf.text(string.signature, smallIndent, yPoint, {
      align: 'left',
    });
    yPoint += yStep;

    yPoint += 30;
    if (action === 'save') {
      pdf.save(`agreement-${today}.pdf`);
    }
    if (action === 'getBlob') {
      const blob = pdf.output('datauristring');
      return blob;
    }

    if (action === 'show') {
      pdf.output('dataurlnewwindow');
    }
  }

  return null;
};
