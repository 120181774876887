import { put, takeLatest } from 'redux-saga/effects';
import uniqBy from 'lodash/uniqBy';
import setNotification from '../helpers/notifications';
import addPaymentCardAndSetDefault from '../services/api/actions/addPaymentCardAndSetDefault';
import renewSubscription from '../services/api/actions/renewSubscription';
import upgradeSubscription from '../services/api/actions/upgradeSubscription';
import makeInitialOrder from '../services/api/actions/makeInitialOrder';
import recoverEmail from '../services/api/actions/recoverEmail';
import passwordReset from '../services/api/actions/passwordReset';
import forgotUserName from '../services/api/actions/forgotUserName';
import getToken from '../services/api/actions/getToken';
import getRedirectionToken from '../services/api/actions/getRedirectionToken';
import signUp from '../services/api/actions/signUp';
import getTokenFinal from '../services/api/actions/getTokenFinal';
import deleteBillAddress from '../services/api/actions/deleteBillAddress';
import updateBillAddress from '../services/api/actions/updateBillAddress';
import addPaymentCard from '../services/api/actions/addPaymentCard';
import addBillAddress from '../services/api/actions/addBillAddress';
import ordersPurchase from '../services/api/actions/ordersPurchase';
import getCouponById from '../services/api/actions/getCouponById';
import createPayment from '../services/api/actions/createPayment';
import removeCard from '../services/api/actions/removePaymentMethod';
import { clearCouponData, preselectCoupon, setLoader, setModalInfo } from '../store/actions';
import changeEmail from '../services/api/actions/changeEmail';
import decryptBackOfficeToken from '../services/api/actions/decryptBackOfficeToken';
import validateCardAndAdd from '../services/api/actions/validateCardAndAdd';
import updatePaymentCard from '../services/api/actions/updatePaymentCard';
import calculatePrice from '../services/api/actions/calculatePrice';
import { isAddonInstance } from '../helpers/instanceHandler';
import addAgreement from '../services/api/actions/addAgreement';
import ordersPurchaseForFriend from '../services/api/actions/ordersPurchaseForFriend';

function* onAxiosError(action) {
  yield put(setLoader(false));
  const messageResponse = action.error.response && action.error.response.data.message;
  let text;
  try {
    if (Array.isArray(messageResponse)) {
      const getValue = (val) =>
        val
          .map((item) => Object.values(item.constraints))
          .reduce((acc, value) => `${acc} ${value} `, '');

      text =
        typeof messageResponse[0] === 'string' ? messageResponse[0] : getValue(messageResponse);
    }

    if (typeof messageResponse === 'string') {
      text = messageResponse;
    }

    if (
      action.error.response &&
      action.error.response.data.statusCode === 401 &&
      !action.error.response.data.message
    ) {
      return;
    }

    if (action.type === addBillAddress.type.error || action.type === updateBillAddress.type.error) {
      const inValid = text.includes('is invalid');
      const notExist = text.includes('not faund');
      if (inValid || notExist) {
        return;
      }
    }
  } catch (e) {
    text = null;
  }

  // TEMP SOLUTION
  if (text && text.includes('coupon')) {
    yield put(preselectCoupon(null));
    yield put(clearCouponData(null));
  }

  setNotification('error', {
    message: text,
    title: 'Error',
  });
}

const errorConstants = [
  addPaymentCardAndSetDefault.type.error,
  renewSubscription.type.error,
  upgradeSubscription.type.error,
  makeInitialOrder.type.error,
  recoverEmail.type.error,
  passwordReset.type.error,
  forgotUserName.type.error,
  getToken.type.error,
  getRedirectionToken.type.error,
  signUp.type.error,
  signUp.type.error,
  getTokenFinal.type.error,
  deleteBillAddress.type.error,
  updateBillAddress.type.error,
  addBillAddress.type.error,
  removeCard.type.error,
  addPaymentCard.type.error,
  ordersPurchase.type.error,
  changeEmail.type.error,
  getCouponById.type.error,
  createPayment.type.error,
  decryptBackOfficeToken.type.error,
  validateCardAndAdd.type.error,
  updatePaymentCard.type.error,
  addAgreement.type.error,
  ordersPurchaseForFriend.type.error,
];

function* priceCalculationWorker(action) {
  try {
    const {
      error: {
        response: { data },
      },
    } = action;
    const errorMessage = data?.response?.message;
    const rules = data?.response?.rulesList;

    if (isAddonInstance && !!rules?.length) {
      const planRules = uniqBy(rules.flat(Infinity), (i) => i.plan).reduce(
        (acc, val, index, array) =>
          `${acc}${index !== 1 && index <= array.length - 1 ? '' : ', '} ${val.plan}`,
        ''
      );

      yield put(
        setModalInfo({
          type: 'learnModal',
          action: 'buy',
          planInfo: planRules,
          image: '',
        })
      );
    } else {
      setNotification('error', {
        message: errorMessage,
        title: 'Error',
      });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('priceCalculationWorker: ', e);
  } finally {
    yield put(setLoader(false));
  }
}

export default function* authSaga() {
  yield takeLatest([errorConstants], onAxiosError);
  yield takeLatest(calculatePrice.type.error, priceCalculationWorker);
}
