import clsx from 'clsx';

import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ArrowBack from '../components/arrowBack';

import styles from '../styles/pages/forgot-success.module.scss';
import { getInstanceName } from '../helpers/utils';
import { pageLinks } from '../helpers/navigation';

const ForgotPasswordSuccessPage = () => {
  const intl = useIntl();

  const backTxt = intl.formatMessage({ id: 'forgot.backToLogin' });
  const heading = intl.formatMessage({ id: 'forgot.yourPasswordResetEmail' });
  const copies = intl.formatMessage({ id: 'forgot.weHaveSentAPasswordResetEmail' });

  const siteName = getInstanceName();
  const title = intl.formatMessage({ id: 'pageTitle.forgotPasswordSuccess' });

  return (
    <>
      <SEO title={`${title} - ${siteName}`} />
      <Layout fluid pageWrapperClassName="page_wrapper">
        <div className="container">
          <ArrowBack text={backTxt} navigationTo={pageLinks.signIn} />
          <div className={styles.wrapper}>
            <i className={clsx('icon-mail-confirmed', styles.icon_lg)} />
            <h2 className={styles.title}>{heading}</h2>
            <p className={styles.copies}>{copies}</p>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ForgotPasswordSuccessPage;
