import clsx from 'clsx';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'gatsby-plugin-intl';

import Input from '../input';

import styles from './inputPassword.module.scss';

const InputPassword = (props) => {
  const intl = useIntl();

  const {
    id,
    label,
    onChange,
    value,
    placeholder,
    wrapperClassName,
    labelClassName,
    inputClassName,
    errorMessage,
    forgotPassword,
    onForgotPasswordClick,
    onBlur,
    required,
    onfocus,
  } = props;

  const forgotPasswordText = intl.formatMessage({ id: 'forgotPassword' });

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    // if (onfocus) onfocus({ target: { value, id } });
    setShowPassword(!showPassword);
  };

  const forgotButton = (
    <button
      type="button"
      tabIndex="-1"
      onClick={onForgotPasswordClick}
      className={clsx(styles.forgotPassword)}
    >
      {forgotPasswordText}
    </button>
  );

  const showPasswordButton = (
    <button type="button" onClick={toggleShowPassword} className={clsx(styles.showPass)}>
      <i className="icon-eye" />
    </button>
  );

  return (
    <div className={clsx(styles.wrapper)}>
      <Input
        id={id}
        label={label}
        type={showPassword ? 'text' : 'password'}
        rightSideLink={forgotPassword ? forgotButton : null}
        onChange={onChange}
        onBlur={onBlur}
        onfocus={onfocus}
        value={value}
        placeholder={placeholder}
        wrapperClassName={wrapperClassName}
        labelClassName={labelClassName}
        errorMessage={errorMessage}
        inputClassName={clsx(
          styles.inputWithIcon,
          inputClassName,
          errorMessage ? styles.errorInput : null
        )}
        fullwidth
        required={required}
        icon={value.length ? showPasswordButton : null}
      />
    </div>
  );
};

InputPassword.defaultProps = {
  label: null,
  placeholder: '',
  wrapperClassName: null,
  labelClassName: null,
  inputClassName: null,
  forgotPassword: false,
  onForgotPasswordClick: null,
  required: false,
  errorMessage: '',
  onBlur: null,
  onfocus: null,
};

InputPassword.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onfocus: PropTypes.func,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  wrapperClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  forgotPassword: PropTypes.bool,
  onForgotPasswordClick: PropTypes.func,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default InputPassword;
