import React from 'react';
import PropTypes from 'prop-types';

import styles from './banner-btc-discount-checkout.module.scss';

const strings = {
  payWith: 'Pay with E-Wallet or BTC',
  toGetDiscount: 'to get 5% Discount!',
  congrats: 'Congratulations!',
  discount: '5% discount will be applied on your purchase',
};

const BannerBtcDiscountCheckout = ({ isCongrats }) =>
  isCongrats ? (
    <div className={styles.containerCongrats}>
      <div className={styles.congrats}>{`${strings.congrats}`}</div>
      <div className={styles.description}>{`${strings.discount}`}</div>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.text}>{`${strings.payWith}`}</div>
      <div className={styles.text}>{`${strings.toGetDiscount}`}</div>
    </div>
  );

BannerBtcDiscountCheckout.defaultProps = {
  isCongrats: false,
};

BannerBtcDiscountCheckout.propTypes = {
  isCongrats: PropTypes.bool,
};

export default BannerBtcDiscountCheckout;
