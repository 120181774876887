import { all, call, put, select, takeLatest, delay } from 'redux-saga/effects';
import decryptBackOfficeToken from '../services/api/actions/decryptBackOfficeToken';
import { isClient, isEqualArrayStrings, updatePlanPrice } from '../helpers/utils';

import { checkRedirectionUrl, getSearchParam } from '../helpers/url';
import {
  addToCart,
  clearShoppingCart,
  clearStore,
  clearUserDataOnNewEnrollmentInfo,
  recalculatePrice,
  setLoader,
  setPayForFriendMode,
} from '../store/actions';
import { setTokens } from '../services/api/tokenHandler';
import getTokenFinal from '../services/api/actions/getTokenFinal';
import getOrderById from '../services/api/actions/getOrderById';

// eslint-disable-next-line import/no-cycle
import userDataSaga from './userDataSaga';
import { selectCart } from '../store/selectors';
import { selectAllPlans } from '../store/selectors/entities';
import signUp from '../services/api/actions/signUp';
import getUserDetails from '../services/api/actions/getUserDetails';
import { createNavigateTo, pageLinks } from '../helpers/navigation';
import getToken from '../services/api/actions/getToken';
import { INITIATOR, INSTANCE_TYPES } from '../constants/types';
import { selectPayForFriendMode } from '../store/selectors/global';
import { isTrivaInstance } from '../helpers/instanceHandler';

const INSTANCES_WITHOUT_REDIRECTION = [
  'rapidchangers',
  'brbeunleash',
  'beunleash',
  'triva',
  'addon',
];
const SKIP_REDIRECTIONS = INSTANCES_WITHOUT_REDIRECTION.includes(process.env.GATSBY_INSTANCE_NAME);
const isDevelopmentMode = process.env.NODE_ENV !== `production`;

let SSOTimesCalled = 0;

function* addItemToCard(item) {
  if (!item) return;
  yield put(addToCart('product', item, item.action, { noLastAddedModal: true }));
}

function* requestedOrderWorker(requestedOrderId) {
  const [{ payload }] = yield all([
    yield put(getOrderById.withQuery(`/${requestedOrderId}`).action()),
  ]);
  if (payload) {
    yield put(setPayForFriendMode(true));
    yield put(clearShoppingCart());
    const allPlans = yield select(selectAllPlans);
    const orderData = payload.data;

    const comboData = orderData.items.filter((item) => item?.isCombo);
    const rest = orderData.items.filter((item) => !item.isCombo);

    const comboPlan = {
      rebillyPlanIds: comboData.map((item) => item?.rebillyPlanId),
      amount: comboData.reduce((acc, val) => acc + val.amount || 0, 0),
      action: comboData.some((item) => item.action === 'INITIAL')
        ? 'INITIAL'
        : comboData?.length && comboData[0].action,
    };

    const restPlans = rest.map(({ rebillyPlanId, action, amount }) => ({
      rebillyPlanIds: [rebillyPlanId],
      amount,
      action,
    }));

    const products = [comboPlan, ...restPlans].map((item) => {
      const plan = allPlans.find((planItem) =>
        isEqualArrayStrings(planItem.rebillyPlanIds, item.rebillyPlanIds)
      );
      if (plan) {
        return {
          ...plan,
          name: plan?.level,
          isRenew: item.action === 'RENEW',
          isUpgrade: item.action === 'UPGRADE',
          upgradePrice: item.action === 'UPGRADE' ? item.amount || 0 : 0,
          levelId: plan && plan.name && plan.name.toLowerCase(),
          sku: plan?.skus[0],
          price: updatePlanPrice(plan),
        };
      }
      return undefined;
    });

    yield all([...products.map((item) => call(addItemToCard, item))]);
    yield put(recalculatePrice());
  }
}

function* addToCartDispatchWorker(type, item, action, config) {
  yield put(addToCart(type, item, action, config));
}

function* fillShoppingCart(paymentAction) {
  try {
    yield delay(0);

    yield put(clearShoppingCart());
    const urlRebillyPlanIds = getSearchParam('plans', window.location.href)?.split(',');
    const topUpAmount = isClient && getSearchParam('topUpAmount', window.location.href);

    const plans = yield select(selectAllPlans);
    let cartPlans = [];

    if (urlRebillyPlanIds) {
      const foundPlan = plans.find((item) =>
        isEqualArrayStrings(urlRebillyPlanIds, item.rebillyPlanIds)
      );
      cartPlans.push(foundPlan);
    }

    if (topUpAmount) {
      const foundPlan = plans.find((item) => item.rebillyPlanIds.includes('topup'));
      cartPlans.push(foundPlan);
    }

    if (isTrivaInstance) {
      cartPlans = urlRebillyPlanIds.map((planId) =>
        plans.find((item) => item.rebillyPlanIds[0] === planId)
      );
    }

    const cartData = cartPlans.map((item) => ({
      ...item,
      isRenew: paymentAction === 'renew',
      levelId: item && item.name && item.name.toLowerCase(),
      sku: item?.skus[0],
      name: item?.level,
      planId: item?.rebillyPlanIds[0],
      ...(topUpAmount ? { topUpAmount: Number(topUpAmount) } : {}),
    }));

    yield all(
      cartData.map((item) =>
        call(addToCartDispatchWorker, 'product', item, paymentAction, { noLastAddedModal: true })
      )
    );
  } catch (e) {
    // eslint-disable-next-line
    console.error('FILLING_SHOPPING_CART_ERROR', e);
  }
}

// ? Called on start App and
function* singleSignOnSaga(redirectionToken) {
  const tempToken = isClient && getSearchParam('tempTok', window.location.href);
  const trivaToken = isClient && getSearchParam('trivaTok', window.location.href);
  const action = isClient && getSearchParam('action', window.location.href);
  const beBackOfficeToken = isClient && getSearchParam('Token', window.location.href);
  const requestToken = isClient && getSearchParam('requestToken', window.location.href);

  const isEnrollment = isClient && window.location.href.includes('enrollment');

  if (beBackOfficeToken && !isEnrollment && !SSOTimesCalled) {
    yield put(
      decryptBackOfficeToken
        .withQuery(`/${encodeURIComponent(beBackOfficeToken)}?instanceType=${INSTANCE_TYPES.SHOP}`)
        .action()
    );

    yield put(clearShoppingCart());
    SSOTimesCalled = 1;
    return;
  }

  if (isEnrollment && isClient) {
    yield put(clearShoppingCart());
    yield put(clearUserDataOnNewEnrollmentInfo());
    localStorage.removeItem('AT');
    localStorage.removeItem('UN');
    return;
  }

  const token = redirectionToken || tempToken || requestToken || trivaToken;

  if (action) {
    yield call(fillShoppingCart, action);
  }

  if (token) {
    yield put(clearStore());
    const payForFriendMode = yield select(selectPayForFriendMode);
    if (payForFriendMode && !requestToken) {
      yield put(setPayForFriendMode(false));
    }
    yield call(setTokens, { accessToken: null, refreshToken: null, redirectionToken: token });
    yield put(
      getTokenFinal
        .withQuery(
          `?initiator=${INITIATOR}&instanceType=${INSTANCE_TYPES.SHOP}&devMode=${isDevelopmentMode}`
        )
        .action()
    );
  }

  if (!action && !token) {
    yield call(userDataSaga);
  }
}

// ? Called on login, decryptToken, redirect from CP.
function* checkTokenWorker(action) {
  const { payload } = action;
  const { redirectionToken, wrongDomain, redirectionUrl } = payload.data;

  if (!SKIP_REDIRECTIONS && !isDevelopmentMode && wrongDomain) {
    const redirectionLink = checkRedirectionUrl(redirectionUrl);
    window.location.href = `https://${redirectionLink}/?tempTok=${redirectionToken}`;
    return;
  }

  yield put(setLoader(false));
  yield call(singleSignOnSaga, redirectionToken);
}

function* finalizeSignInWorker(action) {
  const { payload } = action;

  yield put(setLoader(false));

  const requestedOrderId = isClient && getSearchParam('requestedOrderId', window.location.href);
  const trivaToken = isClient && getSearchParam('trivaTok', window.location.href);

  const { userId, username, accessToken, refreshToken, wrongDomain, redirectionUrl } = payload.data;
  const redirectionLink = checkRedirectionUrl(redirectionUrl);

  const { productCount } = yield select(selectCart);

  if (!SKIP_REDIRECTIONS && !isDevelopmentMode && wrongDomain) {
    const redToken = isClient && window.localStorage.getItem('RED_TOKEN');
    const beBackOfficeToken = isClient && getSearchParam('Token', window.location.href);

    if (beBackOfficeToken) {
      window.location.href = window.location.href.replace(window.location.origin, redirectionLink);
      return;
    }

    const redirectionToken =
      redToken || action.payload.config.headers.Authorization.replace('Bearer ', '');

    window.location.href = `${redirectionLink}/?tempTok=${redirectionToken}`;
    return;
  }

  if (isClient) {
    localStorage.setItem('UID', userId);
    localStorage.setItem('UN', username);
  }

  yield call(setTokens, { accessToken, refreshToken });

  if (action.type === signUp.type.success) {
    const { data } = action.meta.previousAction.payload.request;
    yield put({ type: getUserDetails.type.success, payload: { data: { data } } });

    // ?FOR testing and brazil
    if (data.phone.includes('117788')) {
      createNavigateTo(pageLinks.registrationSuccess)();
    } else {
      createNavigateTo(pageLinks.checkout)();
    }
    yield call(userDataSaga);
    return;
  }

  if (requestedOrderId) {
    yield call(requestedOrderWorker, requestedOrderId);
    createNavigateTo(pageLinks.checkout)();
    yield call(userDataSaga);
    return;
  }

  const isPricing = isClient && window.location.pathname.includes('pricing');
  const isAnnualPackagesPage = isClient && window.location.pathname.includes('annual-packages');
  const isCart = isClient && window.location.pathname.includes('shopping-cart');

  if (
    isTrivaInstance &&
    trivaToken &&
    productCount &&
    !isPricing &&
    !isCart &&
    !isAnnualPackagesPage
  ) {
    createNavigateTo(pageLinks.checkout)();
  }

  if (!isTrivaInstance && !productCount && !isPricing && !isAnnualPackagesPage) {
    createNavigateTo(pageLinks.pricing)();
  }

  yield call(userDataSaga);
}

// eslint-disable-next-line require-yield
function* errorTokenWorker() {
  const token = isClient && localStorage.getItem('AT');
  if (token) {
    localStorage.removeItem('AT');
    localStorage.removeItem('UN');
  }
}

function* tokenSaga() {
  yield all([
    singleSignOnSaga(),
    yield takeLatest(
      [getToken.type.success, decryptBackOfficeToken.type.success],
      checkTokenWorker
    ),
    yield takeLatest([getTokenFinal.type.success, signUp.type.success], finalizeSignInWorker),
    yield takeLatest([getTokenFinal.type.error], errorTokenWorker),
  ]);
}

export default tokenSaga;
