import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Flag from 'react-world-flags';
import Select from 'react-select';

import Input from '../input';
import { isValidCountryCode, getCountryData } from '../../helpers/countries';

import styles from './input-phone.module.scss';

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: 54,
    minHeight: 54,
    borderWidth: 1,
    borderColor: '#96929a !important',
    borderRadius: 3,
    boxShadow: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
  }),

  indicatorSeparator: () => ({}),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: 15,
    color: '#11031c',
    padding: 0,
    outline: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#5e5e5e',
  }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    height: 50,
    cursor: 'pointer',
    transition: '.15s ease-in-out',
    color: '#11031c',
    backgroundColor: state.isSelected ? '#dedede' : '#fff',

    '&:hover': { backgroundColor: '#f7f7f7', color: '#11031c' },
  }),

  singleValue: (provided) => ({
    ...provided,
    color: '#11031c',
  }),
};

const InputPhone = ({
  country,
  id,
  label,
  placeholder,
  onChange,
  value,
  name,
  pattern,
  required,
  rightSideLink,
  errorMessage,
  onBlur,
  disabled,
  setFieldValue,
  countryCode,
}) => {
  let phoneCode = '';
  let options = [];

  if (isValidCountryCode(country)) {
    const countryCodeNumber = getCountryData(country).phone;

    if (countryCodeNumber.includes(',')) {
      const codes = countryCodeNumber.split(',');

      options = codes.map((code) => ({
        value: `+${code}`,
        label: (
          <div key={code} className={styles.flagSelect}>
            <Flag code={country} width="32" height="16" />
            <span>{`+${code}`}</span>
          </div>
        ),
      }));

      if (!countryCode) {
        setFieldValue('countryCode', options[0].value);
      }
    } else {
      phoneCode = `+${countryCodeNumber}`;
    }
  }

  const onChangeSelect = (code) => {
    setFieldValue('countryCode', code.value);
  };

  const defaultValue =
    countryCode && options.length && options.find((item) => item.value === countryCode);

  const selectInput = (
    <div className={styles.phoneSelectContainer}>
      <div className={styles.wrapper}>
        {label && (
          <label className="basicLabel" htmlFor={id}>
            {label}
            {required && (
              <span aria-hidden className="asterisk">
                *
              </span>
            )}
          </label>
        )}
        <Select
          id={id}
          options={options}
          styles={customStyles}
          placeholder={`${phoneCode}`}
          value={defaultValue || ''}
          onChange={onChangeSelect}
          isSearchable={false}
          cacheOptions
          maxMenuHeight={300}
        />
      </div>

      <Input
        fullwidth
        id={id}
        wrapperClassName={styles.phoneNumberInput}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        name={name}
        type="tel"
        pattern={pattern}
        required={required}
        rightSideLink={rightSideLink}
        errorMessage={errorMessage}
        onBlur={onBlur}
        disabled={disabled}
      />
    </div>
  );

  const inputChildren = (
    <div className={clsx(styles.languageInput, { [styles.errorInput]: errorMessage })}>
      <div className={styles.indexImageStatic}>
        {country ? (
          <Flag code={country} height="16" fallback={<div className={styles.indexImageStatic} />} />
        ) : (
          <div className={styles.indexImageStatic} />
        )}
      </div>
      <div>{phoneCode}</div>
    </div>
  );

  const input = (
    <Input
      fullwidth
      id={id}
      label={label}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      name={name}
      type="tel"
      pattern={pattern}
      required={required}
      rightSideLink={rightSideLink}
      errorMessage={errorMessage}
      onBlur={onBlur}
      disabled={disabled}
    >
      {inputChildren}
    </Input>
  );

  return <div>{options.length ? selectInput : input}</div>;
};

InputPhone.defaultProps = {
  id: 'phone',
  label: 'Phone',
  placeholder: 'phone',
  onChange: () => {},
  onBlur: () => {},
  value: '',
  name: '',
  rightSideLink: null,
  required: false,
  errorMessage: '',
  pattern: null,
  setFieldValue: () => {},
  countryCode: '',
  disabled: false,
  country: '',
};

InputPhone.propTypes = {
  country: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  pattern: PropTypes.string,
  rightSideLink: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  countryCode: PropTypes.string,
  setFieldValue: PropTypes.func,
};

export default InputPhone;
