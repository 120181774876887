import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import { useIntl } from 'gatsby-plugin-intl';
import { Link } from 'gatsby';

import styles from './pricing-plan-card.module.scss';

const PricingPlanCard = ({
  name,
  price,
  planUrl,
  imageData,
  productColors,
  popularChoice,
  showPrice,
}) => {
  const intl = useIntl();
  const renderPopularChoiceTag = () => (
    <div className={styles.popularTag}>
      <span className={styles.tagText}>{intl.formatMessage({ id: 'popular_choice' })}</span>
      <span className={styles.iconWrapper}>
        <i className="icon-heart" />
      </span>
    </div>
  );
  return (
    <div className={styles.card}>
      {popularChoice && renderPopularChoiceTag()}
      <BackgroundImage
        Tag="div"
        className={styles.cardBackground}
        fluid={imageData}
        backgroundColor="transparent"
        preserveStackingContext
      >
        <div className={styles.cardOverlay} />
        <div className={styles.cardContent}>
          <div className={styles.cardBody}>
            <p className={clsx(styles.title, 'dont-translate')}>{name}</p>

            {showPrice > 0 && (
              <p className={styles.text}>
                {intl.formatMessage({ id: 'starting_from' })}
                &nbsp;
                <span className={styles.price}>{price}</span>
              </p>
            )}

            <p className={styles.text}>{intl.formatMessage({ id: 'click_to_see_more' })}</p>
          </div>
          <Link
            to={planUrl}
            className={clsx(styles.cardActions, styles.link)}
            style={{
              backgroundColor: productColors.bgColor || '#5d01a2',
              color: productColors.color || '#fff',
            }}
          >
            <span>{intl.formatMessage({ id: 'become_a_member' })}</span>
            <i className="icon-arrow-right" />
          </Link>
        </div>
      </BackgroundImage>
    </div>
  );
};

PricingPlanCard.defaultProps = {
  productColors: { bgColor: '#5d01a2', color: '#fff' },
  popularChoice: false,
};

PricingPlanCard.propTypes = {
  popularChoice: PropTypes.bool,
  showPrice: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  planUrl: PropTypes.string.isRequired,
  productColors: PropTypes.shape({
    bgColor: PropTypes.string,
    color: PropTypes.string,
  }),
  imageData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default PricingPlanCard;
