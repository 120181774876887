import apiAction from '../apiActionCreator';

const type = 'DECRYPT_BE_BACK_OFFICE_TOKEN';

const config = {
  url: '/auth/sso',
  actionType: type,
  sendFormat: 'json',
  method: 'GET',
};

export default apiAction(config);
