import React from 'react';

import Instagram from '../../icons/svg/instagram.svg';
import Youtube from '../../icons/svg/youtube.svg';
import GooglePlus from '../../icons/svg/google-plus.svg';
import Facebook from '../../icons/svg/facebook.svg';
import Linkedin from '../../icons/svg/linkedin.svg';
import Pinterest from '../../icons/svg/pinterest.svg';
import Twitter from '../../icons/svg/twitter.svg';
import MySpace from '../../icons/svg/myspace.svg';
import Blog from '../../icons/svg/blog.svg';

export const SocialIconsLibrary = {
  instagram: <Instagram />,
  googleplus: <GooglePlus />,
  facebook: <Facebook />,
  twitter: <Twitter />,
  linkedin: <Linkedin />,
  pinterest: <Pinterest />,
  myspace: <MySpace />,
  youtube: <Youtube />,
  blog: <Blog />,
};
