import apiAction from '../apiActionCreator';

const type = 'UPDATE_PAYMENT_CARD';

const config = {
  url: '/payments/card/update',
  actionType: type,
  sendFormat: 'json',
  method: 'PUT',
};

export default apiAction(config);
