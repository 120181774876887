import apiAction from '../apiActionCreator';

const type = 'CHANGE_PROFILE_INFO';

const config = {
  url: '/user/profile/edit',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
