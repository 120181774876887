import { createSelector } from 'reselect';
import isNil from 'lodash/isNil';
import min from 'lodash/min';

import PaymentConstants from '../../constants/payment';

import {
  selectAllPlans,
  selectCalculatedPrice,
  selectEntityBillingDetails,
  selectEntityPaymentMethods,
  selectEntityUserDetails,
  selectOrderResultData,
  selectProductsWithSubscriptions,
} from './entities';

import { selectChosenPaymentMethod, selectShoppingCart, selectPayForFriendMode } from './global';
import { isAddonInstance } from '../../helpers/instanceHandler';

import {
  doNotShowRenewalPriceAddons,
  getPlanColors,
  isEqualArrayStrings,
  isUserLogged,
  updatePlanPrice,
} from '../../helpers/utils';
import { CUSTOM_PRODUCT_PRIORITY } from '../../constants/products';

export const selectProducts = createSelector(selectProductsWithSubscriptions, (products) => {
  if (isAddonInstance) {
    const productsWithOnlyAnnualPlans = products?.map((prod) => {
      const plans = prod?.plans?.filter((plan) => plan.intervalSize > 360);

      const minimumAnnualPrice = min(plans)?.recurringPrice;
      return {
        ...prod,
        minimumAnnualPrice,
        plans,
      };
    });
    return productsWithOnlyAnnualPlans;
  }

  return products.filter((i) =>
    ['learn', 'nitro', 'elite', 'digi', isUserLogged() && 'tickets'].includes(i.urlParam)
  );
});

const plansUpdater = (plan) => {
  const { mainBg, text } = getPlanColors({ bgColor: plan.bgColor, color: plan.color });
  return {
    ...plan,
    price: plan.setupPrice,
    bgColor: mainBg,
    color: text,
  };
};

export const selectPlans = (productUrl, showAnnualPlans = false) =>
  createSelector([selectProducts], (products) => {
    if (products) {
      const productWithPlans = products.find(
        (item) => item?.urlParam?.toLowerCase() === productUrl?.toLowerCase()
      );

      if (productWithPlans?.plans?.length) {
        return productWithPlans.plans
          .filter((p) => (showAnnualPlans ? p.isAnnual : !p.isAnnual))
          .map((item) => ({ ...plansUpdater(item) }));
      }
    }
    return null;
  });

export const selectRequestedProducts = createSelector(
  [selectOrderResultData, selectAllPlans, selectPayForFriendMode],
  (orderData, allPlans, modeEnabled) => {
    if (!modeEnabled) {
      return;
    }

    if (orderData && allPlans) {
      const comboData = orderData.items.filter((item) => item?.isCombo);
      const rest = orderData.items.filter((item) => !item.isCombo);

      const comboPlan = {
        rebillyPlanIds: comboData.map((item) => item.rebillyPlanId),
        amount: comboData.reduce((acc, val) => acc + val || 0),
        action: comboData.some((item) => item.action === 'INITIAL')
          ? 'INITIAL'
          : comboData?.length && comboData[0].action,
      };

      const restPlans = rest.map(({ rebillyPlanId, action, amount }) => ({
        rebillyPlanIds: [rebillyPlanId],
        amount,
        action,
      }));

      const products = [comboPlan, ...restPlans]
        .map((item) => {
          const plan = allPlans.find((planItem) =>
            isEqualArrayStrings(planItem.rebillyPlanIds, item.rebillyPlanIds)
          );
          if (plan) {
            return {
              ...plan,
              name: plan?.level,
              isRenew: item.action === 'RENEW',
              isUpgrade: item.action === 'UPGRADE',
              upgradePrice: (item.action === 'UPGRADE' && item.amount) || 0,
              levelId: plan && plan.name && plan.name.toLowerCase(),
              sku: plan?.skus[0],
              price: updatePlanPrice(plan),
            };
          }
          return undefined;
        })
        .filter((i) => !!i);

      // eslint-disable-next-line
      return products;
    }
  }
);

export const selectCart = createSelector(
  [selectShoppingCart, selectCalculatedPrice, selectAllPlans],
  (cart, calculatedPrice, allPlans) => {
    const productsInCart = Object.values(cart.products);

    const plans = allPlans;

    let products = productsInCart?.map((plan) => {
      let numberPlanIds = [plan.id];
      let includedPlanData = null;

      let planFromProduct = plans?.find((item) =>
        isEqualArrayStrings(item.rebillyPlanIds, plan.rebillyPlanIds)
      );

      if (plan.isCombo) {
        // eslint-disable-next-line consistent-return,array-callback-return
        numberPlanIds = plan.rebillyPlanIds.map((id) => {
          const foundPlan = allPlans.find((p) => isEqualArrayStrings(p.rebillyPlanIds, [id]));
          if (foundPlan) {
            return foundPlan.id;
          }
        });

        // eslint-disable-next-line consistent-return,array-callback-return
        includedPlanData = plan.rebillyPlanIds.map((id) => {
          const foundPlan = allPlans.find((p) => isEqualArrayStrings(p.rebillyPlanIds, [id]));
          if (foundPlan) {
            return { id: foundPlan.id, rebillyPlanId: foundPlan.rebillyPlanIds[0] };
          }
        });
      }

      const hideRecurringPrice = doNotShowRenewalPriceAddons.includes(plan.sku);

      if (!isAddonInstance) {
        if (!planFromProduct && plan.isAddon) {
          planFromProduct = plan;
        }

        if (!planFromProduct) {
          return null;
        }
      }

      const btcDiscount = planFromProduct?.price * 0.05;

      const result = {
        ...plan,
        numberPlanIds,
        btcDiscount,
        includedPlanData,
      };

      if (planFromProduct) {
        result.recurringPrice = hideRecurringPrice ? null : planFromProduct?.recurringPrice;
        result.price = planFromProduct?.setupPrice;
        result.setupPrice = planFromProduct?.setupPrice;
        result.gatewayMethods = planFromProduct.gatewayMethods;
      }

      return result;
    });

    products = products?.filter((item) => !!item);

    if (products.length) {
      products = products.map((item) => {
        const price = updatePlanPrice(item);
        const btcDiscount = price * 0.05;
        const isEntryPurchase = !item.isRenew && !item.isUpgrade;

        return {
          ...item,
          name: `${item.product.name} ${item.name}`,
          price,
          btcDiscount: isEntryPurchase ? btcDiscount : 0,
          btcPrice: isEntryPurchase ? price - btcDiscount : price,
          productName: item.product.name,
          level: item.levelId || item.name.toLowerCase(),
        };
      });
    }

    const subTotal = products.map((item) => item.price).reduce((acc, idx) => acc + idx, 0);

    const btcDiscountTotal = products
      .map((item) => item.btcDiscount)
      .reduce((acc, idx) => acc + idx, 0);

    const btcPriceTotal = products.map((item) => item.btcPrice).reduce((acc, idx) => acc + idx, 0);
    const subTotalPrice = !isNil(calculatedPrice) ? calculatedPrice.totalAmount || 0 : subTotal;
    const totalDiscount = !isNil(calculatedPrice) && calculatedPrice.totalDiscount;
    const withDiscount = calculatedPrice?.totalAmount && subTotal > calculatedPrice.totalAmount;

    // const showInitialDiscount = products.some((plan) => !plan.isRenew && !plan.isUpgrade);

    return {
      paymentAction: cart.paymentAction,
      subTotal: subTotalPrice,
      shoppingCartProductPrice: subTotal,
      totalDiscount,
      products,
      withDiscount,
      productCount: products.length,
      processingFee: calculatedPrice ? calculatedPrice.processingFee : 0,
      vatAmount: calculatedPrice && calculatedPrice.vatAmount ? calculatedPrice.vatAmount : 0,
      btcDiscountTotal,
      btcPriceTotal,
      showInitialDiscount: false,
    };
  }
);

export const selectBillingAddresses = createSelector(selectEntityUserDetails, (userDetails) => {
  if (userDetails && userDetails.billingAddresses && userDetails.billingAddresses.length > 0) {
    return userDetails.billingAddresses.map((item) => ({
      ...item,
      firstName: item?.firstName || userDetails?.firstName,
      lastName: item?.lastName || userDetails?.lastName,
    }));
  }
  return [];
});

export const selectPrimaryBillingAddress = createSelector(
  selectEntityUserDetails,
  (userDetails) => {
    const billingAddresses = userDetails?.billingAddresses;

    if (billingAddresses?.length) {
      const primaryAddress = billingAddresses.find((item) => !!item.primary);
      const exigoAddress = billingAddresses.find((item) => !!item.exigo);
      return primaryAddress || exigoAddress;
    }

    return null;
  }
);

export const selectAddressCountry = createSelector(
  [selectEntityUserDetails, selectPrimaryBillingAddress],
  (userDetails, address) => address?.country || userDetails?.country || null
);

export const selectPaymentsWithDefault = createSelector(
  [selectEntityPaymentMethods, selectEntityBillingDetails],
  (methods, details) => {
    if (methods && details && details.defaultPaymentInstrument) {
      const {
        paymentCardId,
        payPalAccountId,
        bankAccountId,

        // TODO: Ask Maksym about paymentInstrumentId is it the same as ^^
      } = details.defaultPaymentInstrument;

      const cards = methods.cards.map((item) => ({
        ...item,
        isDefault: item.id === paymentCardId,
      }));

      const paypal = methods.paypal.map((item) => ({
        ...item,
        isDefault: item.id === payPalAccountId,
      }));

      const bankAccounts = methods.bankAccounts.map((item) => ({
        ...item,
        brand: (item.brand || item.method || '').toUpperCase(),
        isDefault: item.id === bankAccountId,
      }));

      return {
        ...methods,
        cards,
        paypal,
        bankAccounts,
      };
    }
    return {
      cards: [],
      paypal: [],
      bankAccounts: [],
    };
  }
);

export const selectSetPaymentMethod = createSelector(
  [selectChosenPaymentMethod, selectPaymentsWithDefault],
  (handleSelected, list) => {
    if (handleSelected) {
      return handleSelected;
    }
    if (list) {
      return (
        Object.values(list)
          .reduce((acc, val) => [...acc, ...val], [])
          .find((item) => !!item.isDefault) || {}
      );
    }
    return null;
  }
);

export const showAutoRenewalMessage = createSelector(selectCart, (cart) => {
  if (cart.productCount) {
    return cart.products.some((item) => item.isRenew && !item.autopay);
  }
  return false;
});

// ? Showed pre authorize modal
export const selectPreAuthorizeModalCondition = createSelector(
  [selectSetPaymentMethod, selectEntityBillingDetails],
  (selectedMethod, billDetailsInfo) => {
    if (selectedMethod && billDetailsInfo) {
      const defaultPaymentInstrument = billDetailsInfo?.defaultPaymentInstrument?.method;
      if (
        ['e-wallet', 'coinpayments'].includes(selectedMethod?.method) &&
        !defaultPaymentInstrument
      ) {
        return true;
      }
    }
    return false;
  }
);

// ? Showed CVV modal
export const selectPaymentCardForCvvConfirm = createSelector(
  [selectSetPaymentMethod, selectPaymentsWithDefault],
  (selectedMethod, allMethods) => {
    if (allMethods) {
      let card = null;
      const defaultCard = allMethods.cards.find((item) => item.isDefault);

      if (selectedMethod && selectedMethod.method === 'payment-card') {
        card = selectedMethod;
      }

      if (!selectedMethod && defaultCard) {
        card = defaultCard;
      }

      if (card) {
        return card;
      }

      return null;
    }

    return null;
  }
);

const selectDefaultPaymentMethod = createSelector(selectPaymentsWithDefault, (methods) => {
  if (methods) {
    return Object.values(methods).find((method) => method.isDefault);
  }
  return null;
});

// ? Showed 10% coupon discount
export const selectCoinPaymentDiscount = createSelector(
  [selectSetPaymentMethod, selectDefaultPaymentMethod, selectCalculatedPrice],
  (selectedMethod, defaultMethod, calculatedPrice) => {
    //! NO methods
    // const noMethodsSelected = !defaultMethod && !selectedMethod?.method;
    // if (noMethodsSelected) {
    //   return {
    //     message: 'Add BITCOIN method for 10% discount ',
    //     links: null,
    //   };
    // }

    // TODO: move to links

    if (selectedMethod?.method === PaymentConstants.SHOP_METHODS.coinPayments) {
      return {
        messages: null,
        links: {
          buy: {
            label: 'Buy BTC Here',
            url: `https://changelly.com/buy?from=usd&to=btc&amount=${
              calculatedPrice?.totalAmount || 0
            }&ref_id=7og15wixrcg4zqp1`,
          },
          guide: {
            label: 'Follow this Easy Guide',
            url: 'btc-guide',
          },
        },
      };
    }

    return false;
  }
);

export const selectProductsNavigationData = createSelector(selectProducts, (products) => {
  try {
    return products
      .reduce((acc, { name, urlParam }) => {
        if (urlParam) {
          acc.push({ name, urlParam });
          return acc;
        }
        return acc;
      }, [])
      .sort((a, b) => CUSTOM_PRODUCT_PRIORITY[a.urlParam] - CUSTOM_PRODUCT_PRIORITY[b.urlParam]);
  } catch (err) {
    return [];
  }
});

export const selectRequestedOrderData = createSelector(
  [selectOrderResultData, selectPayForFriendMode],
  (orderData, modeEnabled) => {
    if (!modeEnabled) {
      return null;
    }

    if (orderData) {
      const itemsForPriceCalculate = orderData.items.map(
        ({ action, isCombo, rebillyPlanId, amount }) => ({
          action,
          isCombo,
          planId: rebillyPlanId,
          ...(rebillyPlanId === 'topup' && amount ? { topUpAmount: amount } : {}),
        })
      );

      // eslint-disable-next-line
      return {
        ...orderData,
        itemsForPriceCalculate,
        orderId: orderData.id,
      };
    }

    return null;
  }
);
