import { createSelector } from 'reselect';

const selectedProduct = (state) => state.global.selectedProduct;
const preselectedAddon = (state) => state.global.preselectedAddon;
const shoppingCart = (state) => state.global.shoppingCart;
const isLoading = (state) => state.global.isLoading;
const submitCount = (state) => state.global.submitCount;
const languages = (state) => state.global.languages;
const selectedLanguage = (state) => state.global.selectedLanguage;
const registrationValues = (state) => state.global.registrationValues;
const lastAddedAddon = (state) => state.global.lastAddedAddon;
const selectedPaymentMethod = (state) => state.global.selectedPaymentMethod;
const appliedCoupons = (state) => state.global.appliedCoupons;
const preselectedCoupon = (state) => state.global.preselectedCoupon;
const addonsModalInfo = (state) => state.global.addonsModalInfo;
const modal = (state) => state.global.modal;
const availablePaymentMethods = (state) => state.global.availablePaymentMethods;
const shouldUpdateAgreement = (state) => state.global.shouldUpdateAgreement;

export const selectModalError = (state) => state.global.modalError;
export const selectPhoneVerificationButtonState = (state) =>
  state.global.allowToSkipPhoneVerification;

export const selectCouponId = (state) => state.global.selectedCoupon;
export const selectUserCountry = (state) => state.global.userCountry;
export const selectIsAnnually = (state) => state.global.isAnnually;
export const selectEmailBannerFlag = (state) => state.global.isWrongEmail;
export const selectButtonLoaderType = (state) => state.global.buttonLoaderType;
export const selectPayForFriendMode = (state) => state.global.payForFriendMode;

export const selectPreselectedAddon = createSelector(preselectedAddon, (state) => state || []);

export const selectChosenProduct = createSelector(
  selectedProduct,
  (global) => global.selectedProduct
);

export const selectShoppingCart = createSelector(shoppingCart, (state) => state);

export const selectShoppingCartProductCount = createSelector(
  shoppingCart,
  (cart) => Object.keys(cart.products).length
);

export const selectLoader = createSelector(isLoading, (state) => state);
export const selectSubmitCount = createSelector(submitCount, (state) => state);
export const selectLanguages = createSelector(languages, (state) => state);
export const selectChosenLanguage = createSelector(selectedLanguage, (state) => state);
export const selectRegisterValues = createSelector(registrationValues, (state) => state);
export const selectLastAddedAddon = createSelector(lastAddedAddon, (state) => state);
export const selectChosenPaymentMethod = createSelector(selectedPaymentMethod, (state) => state);
export const selectAppliedCoupons = createSelector(appliedCoupons, (state) => state);
export const selectPreselectedCoupon = createSelector(preselectedCoupon, (state) => state);
export const selectAddonsModalInfo = createSelector(addonsModalInfo, (state) => state);
export const selectModal = createSelector(modal, (state) => state);
export const selectAvailablePaymentMethods = createSelector(
  availablePaymentMethods,
  (state) => state
);
export const selectShouldUpdateAgreement = createSelector(shouldUpdateAgreement, (state) => state);
