import apiAction from '../apiActionCreator';

const type = 'GET_SHOP_META_DATA';

const config = {
  url: '/shop/metadata',
  actionType: type,
  sendFormat: 'json',
  method: 'GET',
};

export default apiAction(config);
