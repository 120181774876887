import React from 'react';
// import Modal from '../modal';
// import { isClient } from '../../helpers/utils';
// import { isLocalStorageAvailable } from '../../services/storage';

// const strings = {
//   modalMessage:
//     'We noticed “cookies” disabled on your safari browser, please enable cookies or use another browser such as google chrome to continue in order to get the best user experience',
// };

const BlockedCookiesModal = () => <div />;
// let isRealLocalStorage;
// const [isVisible, setIsVisible] = useState(true);

// if (isClient) {
//   isRealLocalStorage = isLocalStorageAvailable();
//   if (!isRealLocalStorage) {
//     document.body.style.position = 'fixed';
//   }
// }
//
// useEffect(() => {
//   if (isClient && !isVisible && !isRealLocalStorage) {
//     document.body.style.position = '';
//   }
// });
//
// const ModalContent = () => <div>{strings.modalMessage}</div>;
//
// const onModalHide = () => {
//   setIsVisible(false);
// };

// return (
//   <Modal
//     modalType="enrollModal"
//     isShown={isVisible}
//     title="Warning"
//     onCloseClick={onModalHide}
//     isSticky
//   >
//     <ModalContent />
//   </Modal>
// );

export default BlockedCookiesModal;
