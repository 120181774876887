import getUserDetails from '../../services/api/actions/getUserDetails';
import getUserRefferal from '../../services/api/actions/getUserRefferal';
import getUserSubscriptions from '../../services/api/actions/getUserSubscriptions';
import getUserPaymentMethods from '../../services/api/actions/getUserPaymentMethods';
import getBillingDetails from '../../services/api/actions/getBillingDetails';
import getCoupons from '../../services/api/actions/getCoupons';
import ordersPurchase from '../../services/api/actions/ordersPurchase';
import getWallets from '../../services/api/actions/getWallets';
import getCouponById from '../../services/api/actions/getCouponById';
import signUp from '../../services/api/actions/signUp';
import calculatePrice from '../../services/api/actions/calculatePrice';
import * as AppConstants from '../constants';
import getTokenFinal from '../../services/api/actions/getTokenFinal';
import getUserElectronicSign from '../../services/api/actions/getUserElectronicSign';
import getMetaData from '../../services/api/actions/getMetaData';
import refreshToken from '../../services/api/actions/refreshToken';
import { SocketActionTypes } from '../../constants/types';
import getOrderById from '../../services/api/actions/getOrderById';
import getUserRefferalSocialNetworks from '../../services/api/actions/getUserRefferalSocialNetworks';
import validateReferral from '../../services/api/actions/validateReferral';

const products = require('../products.json');
const combo = require('../combo.json');

const initialState = {
  products: [...products.data, ...combo.data],
  userDetails: null,
  userRefferal: null,
  userSubscriptions: null,
  expectedSubscriptions: null,
  paymentMethods: null,
  billingDetails: null,
  coupons: null,
  wallets: null,
  calculatedPrice: null,
  electronicSignment: null,
  metaInfo: null,
  upsellingAddons: null,
  auditTrial: { id: '', step: 0 },
  orderResultData: null,
  coordinator: null,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case getUserDetails.type.success:
      return { ...state, userDetails: payload.data.data };

    case getUserRefferal.type.success:
      return {
        ...state,
        userRefferal: {
          ...state.userRefferal,
          ...payload.data,
          showBanner: true,
          showBannerHeader: false,
          error: false,
        },
      };

    case getUserRefferal.type.error:
      return {
        ...state,
        userRefferal: { ...state.userRefferal, error: true },
      };
    case getUserRefferalSocialNetworks.type.success:
      return {
        ...state,
        userRefferal: {
          ...state.userRefferal,
          social: payload.data.data
            .map((item) =>
              item.socialNetworkDescription && item.url
                ? { name: item.socialNetworkDescription, url: item.url }
                : null
            )
            .filter((i) => !!i),
        },
      };

    case getUserSubscriptions.type.success:
      return { ...state, userSubscriptions: payload.data.data };
    case getUserPaymentMethods.type.success:
      return { ...state, paymentMethods: payload.data };
    case getBillingDetails.type.success:
      return { ...state, billingDetails: payload.data };
    case getCoupons.type.success: {
      const couponList = payload.data.data?.filter((coupon) => coupon?.title !== 'INVISIBLE') || [];
      return { ...state, coupons: couponList };
    }
    case getWallets.type.success:
      return { ...state, wallets: payload.data.data };
    case getCouponById.type.success:
      return {
        ...state,
        coupons: [
          ...(payload.data.data.title === 'FREEQUEST' &&
          state.coupons.some((item) => item.couponCode === 'FREEQUEST')
            ? []
            : [payload.data.data]),
          ...state.coupons,
        ],
      };
    case calculatePrice.type.success:
      return { ...state, calculatedPrice: payload.data.data };

    case getUserElectronicSign.type.success:
      return { ...state, electronicSignment: payload.data.data };

    case AppConstants.SET_PRODUCTS:
      return { ...state, products: payload };

    // eslint-disable-next-line no-fallthrough
    case AppConstants.ADD_TO_CART:
    case AppConstants.REMOVE_FROM_CART:
    case AppConstants.CLEAR_SHOPPING_CART: {
      return { ...state, calculatedPrice: null };
    }

    case AppConstants.SAVE_REFERRAL_INFO:
      if (!payload) {
        return { ...state, userRefferal: null };
      }
      return {
        ...state,
        userRefferal: {
          ...state.userRefferal,
          ...payload,
        },
      };

    case AppConstants.SET_BANNER_REFFERAL:
      return {
        ...state,
        userRefferal: {
          ...state.userRefferal,
          showBanner: payload,
          showBannerHeader: !payload,
        },
      };

    case ordersPurchase.type.success:
      return {
        ...state,
        userRefferal: null,
        calculatedPrice: null,
      };

    case getTokenFinal.type.success:
      return {
        ...state,
        userRefferal: null,
      };

    case AppConstants.SET_EXPECTED_SUBSCRIPTIONS:
      return {
        ...state,
        expectedSubscriptions: payload,
      };

    case AppConstants.CLEAR_USER_DATA_ON_NEW_ENROLLMENT_INFO:
    case signUp.type.start:
      return {
        ...initialState,
        userRefferal: state.userRefferal,
      };

    case getMetaData.type.success:
      return {
        ...state,
        userRefferal: state.userRefferal,
        metaInfo: payload.data.data,
      };

    case AppConstants.UPDATE_UPSELLING_ADDONS:
      return {
        ...state,
        upsellingAddons: payload,
      };

    case AppConstants.SET_AUDIT_TRIAL:
      return {
        ...state,
        auditTrial: payload,
      };

    case SocketActionTypes.ORDER_UPDATED:
      return {
        ...state,
        orderResultData: { ...(state.orderResultData || {}), ...payload },
      };

    case getOrderById.type.success:
      return {
        ...state,
        orderResultData: payload.data,
      };

    case AppConstants.SET_PAY_FOR_FRIEND_MODE: {
      return {
        ...state,
        orderResultData: payload ? state.orderResultData : null,
        calculatedPrice: payload ? state.calculatedPrice : null,
      };
    }

    case validateReferral.type.success:
      return {
        ...state,
        coordinator: payload.data,
      };

    case AppConstants.LOG_OUT_USER:
    case AppConstants.CLEAR_ENTITIES:
    case refreshToken.type.error:
    case getTokenFinal.type.error:
      return initialState;
    default:
      return state;
  }
}
