const CUSTOM_INSTANCE_TEXT = {
  rapidchangers: {
    title: 'Transform your life through continuous learning',
    subtitle:
      'Learners around the world are starting new careers, progressing in their fields, and enriching their lives.',
    button: 'Start yours',
  },

  default: {
    title: 'On living a life that is driven by freedom',
    subtitle: 'to learn more',
    button: 'Get Started',
  },
};

const customText =
  CUSTOM_INSTANCE_TEXT[process.env.GATSBY_INSTANCE_NAME] || CUSTOM_INSTANCE_TEXT.default;

export { customText };
