import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AccordionItem from '../accordion-item';

import styles from './accordion.module.scss';

const Accordion = ({ items }) => {
  const [itemOpen, setItemOpen] = useState(null);

  const onOpen = (itemId) => {
    setItemOpen((open) => (itemId === open ? null : itemId));
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <div className={styles.accordion}>
      {items.map((item) => (
        <AccordionItem
          key={item.title}
          open={item.title === itemOpen}
          content={item}
          onItemClick={onOpen}
        />
      ))}
    </div>
  );
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.arrayOf(
        PropTypes.shape({
          spans: PropTypes.arrayOf(PropTypes.string),
          type: PropTypes.string,
          text: PropTypes.string,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default Accordion;
