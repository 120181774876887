import clsx from 'clsx';
import React, { useEffect } from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { RichText } from 'prismic-reactjs';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import Banner from '../components/banner';
import Button from '../components/button';
import AboutUsCards from '../components/about-us-card';
import BannerRefferal from '../components/banner-refferal';

import { createNavigateTo, pageLinks } from '../helpers/navigation';

import styles from '../styles/pages/about-us.module.scss';
import SEO from '../components/seo';
import { getInstanceName } from '../helpers/instanceHandler';
import { logEvent } from '../helpers/utils';

const query = graphql`
  query {
    heroDesktop: file(relativePath: { eq: "about-us/hero-about-us.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    teamDesktop: file(relativePath: { eq: "about-us/our-team-desktop.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    prismic {
      allAboutuspages {
        edges {
          node {
            befreedom {
              name
              team
            }
            betoinspireprod {
              name
              team
            }
            beunleash {
              name
              team
            }
            br {
              name
              team
            }
            brbeunleash {
              name
              team
            }
            fr {
              name
              team
            }
            gb {
              name
              team
            }
            onlineline {
              name
              team
            }
            euonlineline {
              name
              team
            }
            onlineera {
              name
              team
            }
            euonlineera {
              name
              team
            }
          }
        }
      }
    }
  }
`;

const AboutUs = () => {
  const intl = useIntl();
  const data = useStaticQuery(query);

  useEffect(() => logEvent('visited_about_us'), []);

  let team = '';
  let teamName = '';

  if (data && data.prismic) {
    const instance = (process.env.GATSBY_INSTANCE_NAME || 'gb').toLowerCase();

    const instData =
      data.prismic.allAboutuspages.edges[0].node[instance] ||
      data.prismic.allAboutuspages.edges[0].node.onlineline;

    const teamText = instData[0].team;
    const teamNameText = instData[0].name;
    team = RichText.render(teamText);
    teamName = RichText.render(teamNameText);
  }

  const heroTitleTxt = intl.formatMessage({ id: 'about_us_page.empowering_and_enabling' });
  const heroSubTitleTxt = intl.formatMessage({ id: 'about_us_page.new_skills' });
  const heroButtonTxt = intl.formatMessage({ id: 'about_us_page.get_started' });
  const coreValuesTxt = intl.formatMessage({ id: 'about_us_page.core_values' });
  const titleTxt = intl.formatMessage({ id: 'about_us_page.our_team' });

  const siteName = getInstanceName();
  const title = intl.formatMessage({ id: 'pageTitle.aboutUs' });

  const { heroDesktop, teamDesktop } = data;

  const teamImg = teamDesktop?.childImageSharp?.fluid;

  return (
    <>
      <SEO title={`${title} - ${siteName}`} />
      <Layout fluid>
        <Banner isHero imageData={heroDesktop.childImageSharp.fluid}>
          <div className="refferalWrapperAbsolute refferalWrapperAbsoluteMemberShip">
            <BannerRefferal />
          </div>
          <h1 className={clsx('common_title', styles.heroTitle)}>{heroTitleTxt}</h1>
          <h3 className={clsx(styles.heroSubTitle)}>{heroSubTitleTxt}</h3>
          <Button
            color="primary"
            className={styles.heroButton}
            onClick={createNavigateTo(pageLinks.pricing)}
          >
            {heroButtonTxt}
          </Button>
        </Banner>

        <div className={styles.cards}>
          <AboutUsCards />
        </div>

        {teamImg && <Img fluid={teamImg} />}

        <div className={styles.team}>
          <div className={styles.teamContent}>
            <h4 className={styles.title}>{titleTxt}</h4>
            {team && <p className={styles.text}>{team}</p>}
            {teamName && <p className={clsx(styles.text, styles.boldText)}>{teamName}</p>}
          </div>
          <div className={styles.valuesWrapper}>
            <h4 className={styles.title}>{coreValuesTxt}</h4>
            <div className={styles.values}>
              <div>
                <i className={clsx('icon-honor', styles.badge)} />
                <p>{intl.formatMessage({ id: 'about_us_page.honor' })}</p>
              </div>
              <div>
                <i className={clsx('icon-loyalty', styles.badge)} />
                <p>{intl.formatMessage({ id: 'about_us_page.loyalty' })}</p>
              </div>
              <div>
                <i className={clsx('icon-edification', styles.badge)} />
                <p>{intl.formatMessage({ id: 'about_us_page.edification' })}</p>
              </div>
              <div>
                <i className={clsx('icon-unity', styles.badge)} />
                <p>{intl.formatMessage({ id: 'about_us_page.unity' })}</p>
              </div>

              {process.env.GATSBY_INSTANCE_TYPE !== 'betoinspireprod' && (
                <div>
                  <i className={clsx('icon-integrity', styles.badge)} />
                  <p>{intl.formatMessage({ id: 'about_us_page.integrity' })}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AboutUs;
