import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import { createStructuredSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { selectCart } from '../../store/selectors';
import { selectLastAddedAddon } from '../../store/selectors/global';
import { createNavigateTo, pageLinks } from '../../helpers/navigation';
import { checkIfPathProtected, isClient } from '../../helpers/utils';
import Button from '../button';
import styles from './cart-item-popup.module.scss';
import { isAddonInstance } from '../../helpers/instanceHandler';

const stateSelector = createStructuredSelector({
  cartData: selectCart,
  lastAddedAddon: selectLastAddedAddon,
});

const CartItemPopup = ({ level, productName, isAddonsPage, ...rest }) => {
  const intl = useIntl();

  const {
    cartData: { subTotal, productCount },
    lastAddedAddon,
  } = useSelector(stateSelector);

  const name = lastAddedAddon?.product?.name || productName;

  const { bgColor, color, icon } = lastAddedAddon || rest;

  useEffect(() => {
    if (isClient) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [name]);

  if (!lastAddedAddon && isAddonsPage) {
    return <div />;
  }

  const addedToCartTxt = intl.formatMessage({ id: 'cart_item_popup.added_to_cart' });
  const cartStr = intl.formatMessage({ id: 'cart_item_popup.cart' });
  const toCheckoutStr = intl.formatMessage({ id: 'cart_item_popup.proceed_to_checkout' });

  return (
    <div
      className={clsx(
        styles.itemWrapper,
        !isAddonInstance && isAddonsPage && styles.modalWrapper,
        rest.isAddon && styles.addonItemWrapper
      )}
    >
      <div className={styles.item}>
        <div className={styles.info}>
          <div className={clsx(styles.image)} style={{ backgroundColor: bgColor, color }}>
            <i className={`icon-${icon}`} />
            <i className={clsx('icon-check-small', styles.checkedIcon)} />
          </div>

          <div className={styles.description}>
            <div className={styles.nameWrapper}>
              <p className={styles.nameHeader}>{addedToCartTxt}</p>
              <p className={styles.name}>{`${name} ${level || ''}`}</p>
            </div>
            <div className={styles.subtotal}>
              <p className={styles.subtotalHeader}>{`${cartStr} (${productCount} item):`}</p>
              <span className={styles.price}>{`${subTotal}$`}</span>
            </div>
          </div>
        </div>

        <div className={styles.actions}>
          <div>
            <Button
              className={styles.cartButton}
              onClick={createNavigateTo(pageLinks.shoppingCart)}
            >
              {cartStr}
            </Button>
          </div>
          <Button
            color="primary"
            className={styles.checkoutButton}
            onClick={createNavigateTo(checkIfPathProtected(pageLinks.checkout))}
          >
            <i className="icon-cart" />
            {`${toCheckoutStr} (${productCount} item)`}
          </Button>
        </div>
      </div>
    </div>
  );
};

CartItemPopup.defaultProps = {
  isAddonsPage: false,
  level: '',
  color: '',
  bgColor: '',
  icon: '',
  productName: '',
};

CartItemPopup.propTypes = {
  level: PropTypes.string,
  productName: PropTypes.string,
  icon: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  isAddonsPage: PropTypes.bool,
};

export default CartItemPopup;
