import React from 'react';
import PropTypes from 'prop-types';
import styles from './annual-sub-info.module.scss';

const string = {
  info: 'subscription only available via CryptoCurrency or E-wallet payment. Do not have Crypto funds? no worries, you can purchase from',
  here: 'here',
  link: 'https://coinmarketcap.com/how-to-buy-bitcoin/',
};

const AnnualSubInfo = ({ annualPlanNames }) => (
  <div className={styles.container}>
    {`${annualPlanNames} ${string.info} `}
    <a href={string.link} rel="noreferrer" target="_blank">
      {string.here}
    </a>
  </div>
);

export default AnnualSubInfo;

AnnualSubInfo.defaultProps = {
  annualPlanNames: '',
};

AnnualSubInfo.propTypes = {
  annualPlanNames: PropTypes.string,
};
