import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './sign-pdf-modal.module.scss';
import Modal from '../modal';
import Button from '../button';
import addAgreement from '../../services/api/actions/addAgreement';
import {
  BULLET_SPACE,
  isClient,
  shouldAddNewPage,
  SIGNING_AUDIT_TRAIL_TYPE,
} from '../../helpers/utils';
import { setLoader } from '../../store/actions';
import checkMobile from '../../hooks/checkMobile';
import LoaderAgreement from '../loader-agreement';
import { cartDataPropTypes } from '../../shared/propTypes';

const disabledElements = [
  // 'header',
  // 'panToolButton',
  'rectangleToolButton',
  'rubberStampToolGroupButton',
  'textToolGroupButton',
  'stampToolGroupButton',
  'fileAttachmentToolGroupButton',
  'calloutToolGroupButton',
  'underlineToolButton',
  'textToolGroupButton',
  'annotationNoteConnectorLine',
  'searchButton',
  'menuButton',
  'toolsButton',
  'commentsButton',
  'outlinesPanelButton',
  'outlinesPanel',
  'layersPanelButton',
  'layersPanel',
  'bookmarksPanelButton',
  'bookmarksPanel',
  'searchPanel',
  'annotationCommentButton',
  'toggleNotesButton',
  'freeTextToolButton',
  'selectToolButton',
  'viewControlsOverlay',
  'viewControlsButton',
  'leftPanelButton',
  'contextMenuPopup',
  'textPopup',
  'imageSignaturePanelButton',
  'toolsOverlay',
  'signatureToolGroupButton',
  'moreButton',
  'linkButton',
  'annotationStyleEditButton',
];

// eslint-disable-next-line consistent-return
const SignPdfModal = ({
  show,
  toggle,
  onSubmit,
  showPdf,
  cartData,
  billingAddresses,
  setAuditTrialStatus,
  externalId,
  planIds,
  requestOrderId,
  // eslint-disable-next-line consistent-return
}) => {
  if (!isClient) {
    return <div />;
  }

  if (isClient) {
    // eslint-disable-next-line global-require
    const WebViewer = require('@pdftron/webviewer').default;
    const dispatch = useDispatch();
    const [isLoaded, setIsLoaded] = useState(false);
    const [attempt, setAttempt] = useState(0);
    const viewer = useRef(null);
    const submitAndPay = 'Submit and pay';
    const [isBtnDisabled, toggleBtnDisabled] = useState(true);
    const [isSendingPdf, setIsSendingPdf] = useState(false);
    const [inst, setInstance] = useState(null);
    const [annotManager, setAnnotatManager] = useState(null);
    const [docviewer, setDocviewer] = useState(null);
    const [signImage, setSignImage] = useState('');
    const [iframeDocument, setIframeDocument] = useState(null);
    const pdfBlob = showPdf();

    const isMobile = checkMobile();
    const signatureToDelete = 'data:,';
    const signatureSubject = 'Signature';

    const createImage = (annot) => {
      const canvas = document.createElement('canvas');
      canvas.height = annot.getHeight();
      canvas.width = annot.getWidth();
      const ctx = canvas.getContext('2d');
      const data = annot
        .getPaths()
        .map((path) => path.map((point) => [point.getX(), point.getY()]));
      let miny = Infinity;
      let minx = Infinity;
      let maxx = -Infinity;
      let maxy = -Infinity;
      data.forEach((dat) => {
        dat.forEach((point) => {
          minx = Math.min(minx, point[0]);
          miny = Math.min(miny, point[1]);
          maxx = Math.max(maxx, point[0]);
          maxy = Math.max(maxy, point[1]);
        });
      });
      const rangeX = maxx - minx;
      const rangeY = maxy - miny;
      const range = Math.max(rangeX, rangeY);
      const scale = Math.min(annot.getWidth(), annot.getHeight());

      data.forEach((dat) => {
        ctx.beginPath();
        dat.forEach((point) => {
          let x = point[0];
          let y = point[1];
          x = ((x - minx) / range) * scale;
          y = ((y - miny) / range) * scale;
          ctx.lineTo(x, y);
        });
        ctx.lineWidth = annot.StrokeThickness;
        ctx.strokeStyle = annot.StrokeColor;
        ctx.stroke();
      });
      return ctx.canvas.toDataURL();
    };

    const licenseKey = process.env.GATSBY_PDF_TRON_CLIENT_KEY;

    const annotationsList = annotManager?.getAnnotationsList() || [];
    const signX = 63;
    const signY = 670;

    const billingAddress = billingAddresses?.find((address) => address.primary);

    const onClose = () => {
      toggle(false);
      document.body.className = document.body.className.replaceAll('modal-is-active', '');
      if (annotationsList?.length) {
        // eslint-disable-next-line consistent-return,array-callback-return
        const annotationsToDelete = annotationsList.map((a) => {
          if (a?.Subject === signatureSubject) {
            return a;
          }
        });
        annotManager.deleteAnnotations(annotationsToDelete);
      }
    };

    const onCloseHandler = () => {
      setAuditTrialStatus(SIGNING_AUDIT_TRAIL_TYPE.CANCELED.step);
      onClose();
    };

    useEffect(() => {
      if (annotationsList && !isSendingPdf) {
        if (annotationsList.some((ann) => ann.Subject === signatureSubject)) {
          if (annotationsList.every((ann) => ann.ImageData !== signatureToDelete)) {
            toggleBtnDisabled(false);
          }
        } else {
          toggleBtnDisabled(true);
        }
      }
    });

    const sendDoc = async (data, auditTrialId) => {
      if (!isClient) {
        return null;
      }
      toggleBtnDisabled(true);
      dispatch(setLoader(true));
      let sign;
      const arr = new Uint8Array(data);
      const blob = new Blob([arr], { type: 'application/pdf' });
      try {
        sign = await fetch(signImage).then((res) => res.blob().then((myBlob) => myBlob));

        // eslint-disable-next-line
        console.warn('sign', sign);

        // eslint-disable-next-line no-empty
      } catch (e) {}
      // const blob = new Blob([data], { type: 'application/pdf' });
      const formData = new FormData();
      formData.append('file', blob, 'electronic-sign.pdf');
      formData.append('type', 'signature');
      formData.append('signingLocation', `${window.location.hostname}${window.location.pathname}`);
      formData.append(
        'numbersOfSignatures',
        `${
          annotManager?.getAnnotationsList().filter((annot) => annot.Subject === signatureSubject)
            .length || 1
        }`
      );
      formData.append('numbersOfPages', `${inst?.getPageCount() || 1}`);

      if (requestOrderId) {
        formData.append('requestOrderId', requestOrderId);
      }

      if (sign) {
        formData.append('signature', sign, 'signature.png');
      }

      try {
        planIds.forEach((code) => formData.append(`plan`, code));
      } catch (e) {
        throw new Error(e);
      }

      formData.append('firstName', billingAddress.firstName);
      formData.append('lastName', billingAddress.lastName);
      formData.append('externalId', externalId?.length ? externalId : auditTrialId);
      if (isClient) {
        formData.append('siteUrl', window.location.host);
      }
      const result = await dispatch(addAgreement.action(formData));
      if (result.type === addAgreement.type.error) {
        throw new Error(result.error);
      }
      return result;
    };

    useEffect(() => {
      if (inst && iframeDocument) {
        const btnElement = iframeDocument.querySelector('[data-element="alertButton"]');
        if (btnElement) {
          btnElement.style.backgroundColor = '#5d01a2';
          btnElement.style.color = 'white';
          btnElement.style.fontWeight = 'bold';
          btnElement.style.fontSize = isMobile ? '15px' : '18px';
          btnElement.style.width = '220px';
        }
      }
    }, [inst, iframeDocument]);

    const scrollToSignHereElement = () => {
      const docContainer1 = inst.iframeWindow.document.querySelector(
        '[data-element="documentContainer"]'
      );
      docContainer1.scrollTop = 20000;
    };

    useEffect(() => {
      if (iframeDocument) {
        const signatureModal = iframeDocument.querySelector('[data-element="signatureModal"]');
        const inkSignaturePanelButton = iframeDocument.querySelector(
          '[data-element="inkSignaturePanelButton"]'
        );
        if (signatureModal && inkSignaturePanelButton) {
          inkSignaturePanelButton.click();
        }
      }
    });

    useEffect(() => {
      if (inst) {
        const { docViewer, Annotations } = inst;
        const { WidgetFlags } = Annotations;

        const iframeDoc = inst.iframeWindow.document;
        setIframeDocument(iframeDoc);

        const createSignHereText = () => {
          Annotations.SignatureWidgetAnnotation.prototype.createSignHereElement = () => {
            const div = document.createElement('div');
            div.style.width = '100%';
            div.style.height = '100%';
            div.style.marginTop = '10%';
            div.style.marginLeft = '30%';
            div.style.color = 'red';
            div.style.cursor = 'pointer';
            div.innerHTML = 'SIGN HERE';
            return div;
          };
        };

        inst.disableElements(['ribbons']);
        inst.setHeaderItems((header) => {
          const title = 'Scroll to sign here place';
          const label = 'Please click here, to scroll';
          const pdfHeader = header.getHeader('default');
          const toolbarGroupDefault = pdfHeader.headers.default;
          const isScrollBtnAdded = Object.values(toolbarGroupDefault).find(
            (h) => h.title === title
          );
          const newActionButton = {
            type: 'actionButton',
            title,
            label,
            onClick: scrollToSignHereElement,
            dataElement: 'alertButton',
            // hidden: [ 'mobile' ],
            width: 200,
          };

          if (!isScrollBtnAdded) {
            // eslint-disable-next-line no-unused-expressions
            toolbarGroupDefault?.splice(7, 0, newActionButton);

            // header.push(newActionButton);
            // header.push({
            //   dataElement: 'signatureToolGroupButton',
            //   img: 'icon-tool-signature',
            //   showColor: 'never',
            //   title: 'annotation.signature',
            //   toolGroup: 'signatureTools',
            //   type: 'toolGroupButton',
            // });
            // header.push(toolsSignature);
          }
        });
        inst.setHeaderItems((header) => {
          // select first (default in dropdown/ribbon) toolbar, clean toolbar
          header.getHeader('toolbarGroup-Annotate');
          header.update([]);
          header.getHeader('toolbarGroup-Insert');
          header.update([]);
        });
        inst.loadDocument(pdfBlob, { filename: 'agreement.pdf' });
        // eslint-disable-next-line no-shadow
        const annotManager = docViewer.getAnnotationManager();
        annotManager.setCurrentUser(''); // will also set the value to the AnnotationCreateSignature modal
        setAnnotatManager(annotManager);
        setDocviewer(docViewer);

        annotManager.on('annotationChanged', (annotations, action) => {
          if (action === 'delete') {
            if (!annotationsList.some((ann) => ann.Subject === signatureSubject)) {
              toggleBtnDisabled(true);
            }
            // annotationsList.forEach(annot => {
            //   if (annot.Subject === "Signature") {
            //     annotManager.deleteAnnotations([annot]);
            //   }
            // });
          }

          const selectedAnnots = annotManager.getSelectedAnnotations();
          if (selectedAnnots || selectedAnnots.length > 0) {
            selectedAnnots.forEach((annot) => {
              // eslint-disable-next-line no-param-reassign
              annot.X = signX;
              // eslint-disable-next-line no-param-reassign
              annot.Y = signY;
              annotManager.redrawAnnotation(annot);
            });
          }
          annotations.forEach((annot) => {
            const signature = annot.Subject === signatureSubject;
            const emptySignature = annot.ImageData === signatureToDelete;
            if (signature && !emptySignature) {
              if (annot.ToolName !== 'AnnotationCreateFreeHand') {
                setSignImage(annot.ImageData);
              } else {
                setSignImage(createImage(annot));
              }
              toggleBtnDisabled(false);
              setAuditTrialStatus(SIGNING_AUDIT_TRAIL_TYPE.DOCUMENT_SIGNED.step);
            }

            if (emptySignature) {
              annotManager.deleteAnnotations(annotations);
              createSignHereText();
            }
          });
        });

        const changePositionOfSignAnnotation = () => {
          const annots = annotManager.getAnnotationsList();
          annots.forEach((annot) => {
            const signature = annot.Subject === signatureSubject;
            if (signature) {
              // eslint-disable-next-line no-param-reassign
              annot.X = signX;
              // eslint-disable-next-line no-param-reassign
              annot.Y = signY;
              // eslint-disable-next-line no-param-reassign
              annot.NoResize = true; // NoResize = true to disable resizing of the signature
            }
          });
        };

        annotManager.on('annotationsDrawn', async () => {
          changePositionOfSignAnnotation();
        });

        annotManager.on('fieldChanged', async () => {
          const doc = docViewer.getDocument();
          const xfdfString = await annotManager.exportAnnotations();
          const options = { xfdfString };
          await doc.getFileData(options);
        });

        docViewer.on('documentLoaded', () => {
          setIsLoaded(false);
          const { FitMode } = inst;
          inst.setFitMode(FitMode.FitWidth);
        });

        docViewer.on('annotationsLoaded', () => {
          const annots = annotManager.getAnnotationsList();
          annotManager.deleteAnnotations(annots);

          createSignHereText();

          // set flags for required
          const flags = new WidgetFlags();
          flags.set('Required', true);

          // create a form field
          const field = new Annotations.Forms.Field('SHOP_ANNOTATION_FIELD', {
            type: 'Sig',
            flags,
          });

          // create a widget annotation
          const widgetAnnot = new Annotations.SignatureWidgetAnnotation(field, {
            appearance: '_DEFAULT',
            appearances: {
              _DEFAULT: {
                Normal: {
                  data: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC',
                  offset: {
                    x: 100,
                    y: 100,
                  },
                },
              },
            },
          });

          const pageCalculation = [
            {
              pageLength: BULLET_SPACE.pageThree,
              pageNumberToAdd: 3,
            },
            {
              pageLength: BULLET_SPACE.pageFour,
              pageNumberToAdd: 4,
            },
            {
              pageLength: BULLET_SPACE.pageFive,
              pageNumberToAdd: 5,
            },
            {
              pageLength: BULLET_SPACE.pageSix,
              pageNumberToAdd: 6,
            },
          ];

          const signHerePage = pageCalculation.reduce((accum, currentItem) => {
            if (shouldAddNewPage(cartData, currentItem.pageLength)) {
              return currentItem.pageNumberToAdd;
            }

            return accum;
          }, 2);

          // set position and size of sign here
          widgetAnnot.PageNumber = signHerePage;
          widgetAnnot.X = signX;
          widgetAnnot.Y = signY;
          widgetAnnot.Width = 195;
          widgetAnnot.Height = 60;

          // add the form field and widget annotation
          annotManager.addAnnotation(widgetAnnot);
          annotManager.drawAnnotationsFromList([widgetAnnot]);
          annotManager.getFieldManager().addField(field);

          docViewer.one('pageComplete', () => {
            setAuditTrialStatus(SIGNING_AUDIT_TRAIL_TYPE.DOCUMENT_VIEWED.step);
          });
        });
      }
    }, [inst, billingAddress && billingAddress.id]);

    // useEffect(() => {
    //   if (inst) setInstanceData(inst);
    // }, [billingAddress && billingAddress.id]);

    useEffect(() => {
      setIsLoaded(true);
      if (!inst && isClient) {
        WebViewer(
          {
            licenseKey,
            disabledElements,
            path: '/public',
          },
          viewer.current
        ).then((instance) => setInstance(instance));
      }
      return () => setInstance(null);
    }, []);

    useEffect(() => {
      if (inst && isClient) inst.loadDocument(pdfBlob, { filename: 'agreement.pdf' });
    }, [attempt]);

    const completeSigning = async (isWithSending = true) => {
      if (isWithSending) {
        const auditTrialId = await setAuditTrialStatus(
          SIGNING_AUDIT_TRAIL_TYPE.SIGNING_COMPLETED.step
        );

        setIsSendingPdf(true);
        toggleBtnDisabled(true);
        const xfdfString = await annotManager.exportAnnotations();
        const doc = docviewer.getDocument();
        doc
          .getFileData({ xfdfString })
          .then((data) => sendDoc(data, auditTrialId))
          .then(() => onSubmit())
          .then(() => onClose())
          .catch(() => onClose());
      } else {
        onSubmit();
      }
    };
    return (
      <Modal
        isShown={show}
        modalType="bigModal"
        onCloseClick={onCloseHandler}
        idOverlay="registerModal"
        className="sign-pdf-modal"
        modalHeaderContainerStyles={styles.modalHeaderContainer}
      >
        <div className={styles.content}>
          {isLoaded && (
            <LoaderAgreement
              setAttempt={setAttempt}
              attempt={attempt}
              completeSigning={() => completeSigning(false)}
              show={show}
            />
          )}
          <div className={styles.webviewer} ref={viewer} />
          <div className={styles.btnContainer}>
            <Button fullWidth size="large" className={styles.submitButton} onClick={onCloseHandler}>
              Cancel
            </Button>
            <Button
              fullWidth
              size="large"
              disabled={isBtnDisabled}
              className={styles.submitButton}
              onClick={completeSigning}
            >
              {submitAndPay}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
};

export default SignPdfModal;

SignPdfModal.defaultProps = {
  requestOrderId: null,
  userDetails: {
    signedAgreement: null,
  },
};

SignPdfModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showPdf: PropTypes.func.isRequired,
  setAuditTrialStatus: PropTypes.func.isRequired,
  externalId: PropTypes.string.isRequired,
  requestOrderId: PropTypes.number,
  userDetails: PropTypes.shape({
    signedAgreement: null,
  }),
  billingAddresses: PropTypes.arrayOf({}).isRequired,
  planIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  cartData: cartDataPropTypes.isRequired,
};
