import clsx from 'clsx';

import React, { useState } from 'react';
import ReactDatePicker from 'react-date-picker';
import DateMobilePicker from 'react-mobile-datepicker';
import moment from 'moment';
import PropTypes, { oneOfType } from 'prop-types';
import styles from './date-picker.module.scss';
import checkMobile from '../../hooks/checkMobile';

const DatePicker = (props) => {
  const {
    onChange,
    value,
    labelClassName,
    id,
    label,
    rightSideLink,
    required,
    errorMessage,
    minDate,
    maxDate,
  } = props;

  const isMobile = checkMobile();
  const [mobileValue, setMobileValue] = useState(new Date());
  const [mobileIsOpen, setMobileIsOpen] = useState(false);

  const handleCancel = () => {
    setMobileIsOpen(false);
  };

  const handleSelect = (date) => {
    setMobileValue(date);
    setMobileIsOpen(false);
    onChange(date);
  };

  const dateConfig = {
    year: {
      format: 'YYYY',
      caption: 'Year',
      step: 1,
    },
    month: {
      format: 'MM',
      caption: 'Mon',
      step: 1,
    },
    date: {
      format: 'DD',
      caption: 'Day',
      step: 1,
    },
  };

  return (
    <div className={clsx(styles.wrapper)}>
      <div className={clsx(styles.labelRow)}>
        {label ? (
          <label className={clsx('basicLabel', labelClassName)} htmlFor={id}>
            {label}
            {required && (
              <span aria-hidden className="asterisk">
                *
              </span>
            )}
          </label>
        ) : null}
        {rightSideLink}
      </div>
      <div className={clsx(styles.pickerWrapper)}>
        {isMobile ? (
          <div className={clsx(styles.mobilePickerWrapper)}>
            {mobileIsOpen && (
              <DateMobilePicker
                isPopup={false}
                dateConfig={dateConfig}
                value={mobileValue}
                onSelect={handleSelect}
                onCancel={handleCancel}
                min={new Date(1921, 1, 1)}
                max={new Date()}
                confirmText="OK"
                cancelText="Cancel"
              />
            )}
            <span>
              <input
                className={clsx(styles.inputMobile, { [styles.errorInput]: errorMessage })}
                type="string"
                value={value ? moment(value).format('DD/MM/yyyy') : ''}
                onFocus={() => setMobileIsOpen(true)}
                placeholder="dd/mm/yyyy"
              />
              <i className={clsx('icon-calendar', styles.icon)} />
            </span>
          </div>
        ) : (
          <ReactDatePicker
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            format="dd/MM/yyyy"
            id={id}
            value={value}
            onChange={onChange}
            minDate={minDate}
            maxDate={maxDate}
            next2Label={null}
            prev2Label={null}
            clearIcon={null}
            required={required}
            className={clsx(styles.input, { [styles.errorInput]: errorMessage })}
            calendarClassName={styles.calendar}
            titleClassName={styles.calendarTitle}
            calendarIcon={<i className={clsx('icon-calendar', styles.icon)} />}
          />
        )}
        {errorMessage ? <div className={styles.errorMessage}>{errorMessage}</div> : null}
      </div>
    </div>
  );
};

DatePicker.defaultProps = {
  label: null,
  labelClassName: null,
  rightSideLink: null,
  required: null,
  errorMessage: '',
  minDate: '',
  maxDate: '',
};

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  value: oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  onChange: PropTypes.func.isRequired,
  rightSideLink: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  minDate: oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxDate: oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

export default DatePicker;
