import React, { useState } from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styles from './banner-quest-coupon.module.scss';

const strings = {
  renew: 'Renew your LEARN subscription and get',
  free: 'FREE Quest coupon',
  toRenew: 'to renew your LIVE subscription for free!',
};

const query = graphql`
  query {
    close: file(relativePath: { eq: "payment/close-banner.png" }) {
      childImageSharp {
        fixed(width: 15) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    left: file(relativePath: { eq: "pricing/banner-coupon-left.png" }) {
      childImageSharp {
        fixed(width: 315, height: 105) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const BannerQuestCoupon = () => {
  const data = useStaticQuery(query);
  const [showBanner, setShowBanner] = useState(true);
  const closeImg = data.close && data.close.childImageSharp.fixed;
  const left = data.left && data.left.childImageSharp.fixed;

  const onClose = () => {
    setShowBanner(false);
  };

  return showBanner ? (
    <div className={styles.bannerWrapper}>
      <div className={styles.imageLeft}>
        <Img fixed={left} />
      </div>
      <div className={styles.bannerContainer}>
        <div className={styles.text}>
          {strings.renew}
          <span className={styles.textFree}>{strings.free}</span>
        </div>
        <div className={styles.text}>{strings.toRenew}</div>
      </div>
      <div className={styles.imageRight}>
        <Img fixed={left} />
      </div>
      {closeImg && (
        <div
          className={styles.close}
          onClick={onClose}
          onKeyDown={onClose}
          role="button"
          tabIndex={0}
        >
          <Img fixed={closeImg} />
        </div>
      )}
    </div>
  ) : null;
};

export default BannerQuestCoupon;
