export const string = {
  customerSigneTransaction: 'CUSTOMER SIGNED TRANSACTION AUTHORIZATION & REFUND TERMS FORM',
  customer: 'CUSTOMER SIGNED TRANSACTION AUTHORIZATION &',
  terms: 'TERMS ACCEPTANCE FORM',
  purchase: 'PURCHASE SUMMARY:',
  consentToPay:
    'I consent to monthly, quarterly or yearly recurring billing as applicable with specific package.',
  consentReadPolicy:
    'I consent that I have read and accepted the below Terms and Refund and Cancellation Policy:',
  policyRefundAndCancellation: '• REFUND AND CANCELLATION POLICY:',
  policyTerms: '• TERMS OF USE:',
  myRight1:
    'I also understand that I am waiving my right to dispute this charge with my bank after receiving the service',
  myRight2:
    'delivery details in my email, except as per applicable Refund and Cancellation Policy.',
  merchantCanClaim:
    'I consent that the merchant can claim damages and compensation if I file for a fraudulent chargeback.',
  signature: 'ELECTRONIC SIGNATURE OF CUSTOMER',
  invoiceNumber: 'Invoice Number: ',
};

export const firstPageText = {
  refundEmail: 'refund@livesupportnow.com',
  ordersEmail: 'orders@livesupportnow.com',
  refundAndCancellation: 'REFUND AND CANCELLATION POLICY',
  readTheEntire: '(read the entire document before signing)',
  thanksForShopping: 'Thanks  for  shopping  on  our  website.  We appreciate that you like to',
  buyTheServices: 'buy the services and products that  we  provide.  We also want to make',
  sureYouHave: 'sure you have a rewarding experience while exploring, evaluating, and',
  purchasingOurService: 'purchasing our services and products.',

  asWithAnyShoppingExp: 'As with any shopping experience, there are  Terms of  Use and Privacy',
  policyThatApply: 'Policy that apply to transactions on our website. We will be as brief as',
  ourAttorneys: 'our attorneys will allow. The main thing to remember is that by placing',
  anOrderOr: 'an  order  or making a purchase on our website, you agree to the terms',
  setForth: 'set forth below and our  Terms of  Use  and  Privacy  Policy  which are',
  availableOnOur: 'available  on  our  website  for  your  review  and  acceptance  before',
  makingAPurchase: 'making a purchase.',

  theseTermsAre: 'These  terms  are  divided  into two sections; a.',
  standardTerms: 'STANDARD TERMS',
  thatApplyToAll: 'that apply  to  all  customers  irrespective of  their  legal  residence.  b.',
  regionSecificTerms: 'REGION-SPECIFIC TERMS',
  thatAre: 'that  are  applicable to the customers',
  whoHaveLegal: 'who have legal residence in that specific Region.',

  asFarAsPermitted: 'As far as permitted by the applicable laws, the',
  shallSupersede: 'shall supersede the',

  listTitleRefund: 'Refund:',
  ifYouDid: 'If you did not like the product or service:',
  purchasedFromOur: 'purchased from our website, you could claim a refund',
  asPerTheApplicable: 'as per the applicable terms. We offer 7 calendar days no',
  questionAskedRefund: 'question asked refund irrespective of whether',
  applicableLawMandates: 'applicable law mandates us to provide a refund or not.',
  thatMeansACustomer: 'That means a customer can ask for the purchase fee to',
  beRefundedWithin: 'be refunded within 7 calendar days starting from the',
  dateOfPurchase: 'date of purchase without providing any justification or',
  answeringAnyQuestions: 'answering any questions from our team. If you want to',
  requestForARefund: 'request for a refund, please contact us on',

  listTitleMonthToMonth: 'Month-to-Month Subscriptions:',
  pleaseNoteThatWe: 'Please note that we',
  doNotOfferRefunds: 'do not offer refunds of payments made on a monthly',
  subscriptionPlanToAvoid: 'subscription plan. To avoid being charged a',
  subscriptionFeeFor: 'subscription fee for the next billing, you must cancel',
  yourSubscriptionBefore: 'your subscription before the monthly renewal date.',
  additionallyYourSubs: 'Additionally, your subscription will continue a month-',
  toMonthBasis: 'to-month basis unless and until you cancel, or the',
  subsIsSuspended: 'subscription is suspended or discontinued by us. If you',
  cancelYourSubs: 'cancel your subscription, the cancellation will be',
  effectiveAtTheEnd: 'effective at the end of the current monthly period; you',
  willContinueToHave: 'will continue to have access to your subscription for the',
  remainderOfThat: 'remainder of that period, but you will not receive a',
  refund: 'refund.',

  listTitleEWalletAndGift: 'E-Wallet and Gift-Wallet:',
  pleaseNoteThatAny: 'Please note that any',
  paymentMadeFrom: 'payment made from E-Wallet or Gift-Wallet towards',
  purchaseOrSubs: 'purchase or subscription renewal of our services shall',
  notToBeRefundable: 'not be refundable even if the refund is sought within',
  theInitial: 'the initial 7 calendar days.',

  listTitleDelivery: 'Delivery:',
  afterYourCon: 'After your confirmed purchase on our',
  websiteYouWill: 'website, you will receive a purchase confirmation, login',
  detailsAndAn: 'details and an executed copy of this Refund Policy from',
  toYourRegistered: 'to your registered email address.',
  theLoginDetails: 'The login details will also be displayed on the website',
  asAPopupNotification: 'as a popup notification. Please ensure the save the login',
  detailsInASafe: 'details in a safe place. If you do not receive the login',
  detailsOrCannot: 'details or cannot log in, or your subscription is not',
  activeWithin7: 'active within 7 calendar days of confirmed purchase,',
  pleaseContactOur: 'please contact our Customer Support Team as provided',
  onOurWebsite: 'on our website. Your failure to contact us within the 7',
  calendarDaysAsProvided: 'calendar days as provided above shall be deemed as',
  confirmedAndSatisfactory: 'confirmed and satisfactory delivery of the product and',
  servicesAsPurchased: 'services as purchased by you.',

  listTitleSubscriptionActive: 'Subscription Active:',
  kindlyNoteThat: 'Kindly note that your',
  subscriptionDayShall: 'subscription day shall begin from the moment your',
  subscriptionIsActive: 'subscription is active.',

  listTitleClaimsAndDispute: 'Claims and Dispute:',
  ifWeInformYou: 'If we inform you that we have',
  receivedAClaim: 'received a claim, chargeback or other dispute',
  concerningOneOf: 'concerning one of your transactions, then you shall',
  promptlyDeliver: 'promptly deliver any helpful information we request to',
  confirmThatYou: 'confirm that you fulfilled the transaction per the',
  agreementWithUs: 'agreement with us (including but not limited to Terms',
  ofUseIfYouFail: 'of Use). If you fail to comply with the prior sentence or',
  wasUnableToCompile: 'was unable to comply with the agreement or these',
  policiesOrIf: 'policies, or if the claim chargeback or dispute is not',
  causedByOur: 'caused by (i) our failure to make your order available as',
  itWasReceived: 'it was received by us or (ii) credit card fraud, then you',
  shallPromptlyReimburse: 'shall promptly reimburse us for the amount of the',
  customerPurchase: 'customer purchase (including the purchase price and',
  anyTaxesAndAll: 'any taxes) and all associated credit card association,',
  bankOrOther: 'bank or other payment processing, re-presentment and',
  penaltyFeesAssociated: 'penalty fees associated with the original purchase and',
  anyChargebackOrRefund: 'any chargeback or refund, litigation and arbitration fees',
  inEachCaseTo: 'in each case to the extent paid or payable by us.',

  listTitleDigitalNature: 'Digital Nature of Services:',
  pleaseKeepInMind: 'Please keep in mind that',
  mostOfOurServices: 'most of our services are digital (e-services). Therefore,',
  thereIsNoPhysical: 'there is no physical delivery or return policy in place at',
  theMoment: 'the moment.',

  thisRefundAnd: 'This Refund and Cancellation Policy forms an integral',
  partOfOur: 'part of our Terms of Use.',

  listTitleAdditionalCharge: 'Additional Charge:',
  weWillNotBe: 'We will not be responsible for any',
  additionalChargeLevied: 'additional charge levied by the Banks, Payment',
  processorOrAThird: 'Processor or a third party while processing a',
  cancellationOrRefund: 'cancellation or refund request. Such charges shall be the',
  responsibilityOfThe: 'responsibility of the Customers. Therefore, we WON’T',
  refundSuchCharges: 'refund such charges.',

  listTitleKycAndAmlMandate: 'KYC and AML Mandate:',
  allTheCustomersAnd: 'All the Customers and',
  usersCompleteOfOurPlatform: 'Users of our platform may be required to complete a',
  kyxAndAmlBefore: 'KYC and AML before, during or after registering or',
  makingPaymentOnOur: 'making payment on our website. The KYC and AML is',
  aMadatoryRequirement: 'a mandatory requirement, and no Customer or User',
  shallBeAllowed: 'shall be allowed to seek a refund merely because they',
  doNotWishToComplete: 'do not wish to complete the KYC and AML',
  complianceOurKyc: 'compliance. Our KYC and AML vendor is compliant',
  withLocalData: 'with local data protection and privacy regulations. If',
  youDontWantToGO: 'you do not want to go through KYC and AML',
  complianceYouShall: 'compliance, you shall not purchase any product or',
  serviceOnThis: 'service on this website and immediately leave the',
  websiteIfYou: 'website. If you refuse to complete the KYC and AML',
  requirementsWithinThe: 'requirements within the stipulated time frame, then the',
  companyHasThe: 'company has the absolute right to block your account',
  andRefuseTo: 'and refuse to provide any refund.',

  listTitleEuropeanUnion: 'European Union:',
  ifYouAreAn: 'If you are an EU member country',
  citizenAndMade: 'citizen and made a purchase on our website from any',
  ofTheEuMember: 'of the EU member countries, you can claim a refund',
  fromTheDateOf: 'from the date of purchase until 14 calendar days.',

  listTitleUnitedKingdom: 'United Kingdom:',
  ifYouAreAUk: 'If you are a UK citizen and made a',
  purchaseOnOurWebsite: 'purchase on our website from the UK, you can claim a',
  refundFromTheDate: 'refund from the date of purchase until 14 calendar days.',
};

export const secondPageText = {
  iConsentToMonthly:
    'I consent to monthly, quarterly or yearly recurring billing as applicable with the specific package. I consent that I have',
  readAndAccepted:
    'read and accepted the above Refund and Cancellation Policy, and Terms of Use as provided on the website before',
  makingThisPurchase: 'making this purchase.',

  iAlsoUnderstand:
    'I also understand that I am waiving my right to dispute this charge with my bank after receiving the products/services',
  detailsInMyEmail:
    'details in my email, except as per the applicable Refund and Cancellation Policy. I consent that the merchant can claim',
  damagesAndCompensation: 'damages and compensation if I file for a fraudulent chargeback.',

  signingThisI:
    'Signing this, I acknowledge the charges described herein and assume full responsibility for the said charges and',
  agreeToHonour: 'agree to honour and abide by the terms of payment.',
};

export const paragraphOne = [
  firstPageText.thanksForShopping,
  firstPageText.buyTheServices,
  firstPageText.sureYouHave,
  firstPageText.purchasingOurService,
];

export const paragraphTwo = [
  firstPageText.asWithAnyShoppingExp,
  firstPageText.policyThatApply,
  firstPageText.ourAttorneys,
  firstPageText.anOrderOr,
  firstPageText.setForth,
  firstPageText.availableOnOur,
  firstPageText.makingAPurchase,
];

export const standardTermsAA = [
  {
    title: firstPageText.listTitleRefund,
    text: firstPageText.ifYouDid,
    x: 22,
  },
  firstPageText.purchasedFromOur,
  firstPageText.asPerTheApplicable,
  firstPageText.questionAskedRefund,
  firstPageText.applicableLawMandates,
  firstPageText.thatMeansACustomer,
  firstPageText.beRefundedWithin,
  firstPageText.dateOfPurchase,
  firstPageText.answeringAnyQuestions,
  firstPageText.requestForARefund,
  firstPageText.refundEmail,
];

export const standardTermsAB = [
  {
    title: firstPageText.listTitleMonthToMonth,
    text: firstPageText.pleaseNoteThatWe,
    x: 85,
  },
  firstPageText.doNotOfferRefunds,
  firstPageText.subscriptionPlanToAvoid,
  firstPageText.subscriptionFeeFor,
  firstPageText.yourSubscriptionBefore,
  firstPageText.additionallyYourSubs,
  firstPageText.toMonthBasis,
  firstPageText.subsIsSuspended,
  firstPageText.cancelYourSubs,
  firstPageText.effectiveAtTheEnd,
  firstPageText.willContinueToHave,
  firstPageText.remainderOfThat,
  firstPageText.refund,
];

export const standardTermsAC = [
  {
    title: firstPageText.listTitleEWalletAndGift,
    text: firstPageText.pleaseNoteThatAny,
    x: 70,
  },
  firstPageText.paymentMadeFrom,
  firstPageText.purchaseOrSubs,
  firstPageText.notToBeRefundable,
  firstPageText.theInitial,
];

export const standardTermsADLeft = [
  {
    title: firstPageText.listTitleDelivery,
    text: firstPageText.afterYourCon,
    x: 25,
  },
  firstPageText.websiteYouWill,
  firstPageText.detailsAndAn,
  `${firstPageText.ordersEmail} ${firstPageText.toYourRegistered}`,
  firstPageText.theLoginDetails,
  firstPageText.asAPopupNotification,
  firstPageText.detailsInASafe,
  firstPageText.detailsOrCannot,
  firstPageText.activeWithin7,
  firstPageText.pleaseContactOur,
];

export const standardTermsADRight = [
  firstPageText.onOurWebsite,
  firstPageText.calendarDaysAsProvided,
  firstPageText.confirmedAndSatisfactory,
  firstPageText.servicesAsPurchased,
];

export const standardTermsAE = [
  {
    title: firstPageText.listTitleSubscriptionActive,
    text: firstPageText.kindlyNoteThat,
    x: 55,
  },
  firstPageText.subscriptionDayShall,
  firstPageText.subscriptionIsActive,
];

export const standardTermsAF = [
  {
    title: firstPageText.listTitleClaimsAndDispute,
    text: firstPageText.ifWeInformYou,
    x: 54,
  },
  firstPageText.receivedAClaim,
  firstPageText.concerningOneOf,
  firstPageText.promptlyDeliver,
  firstPageText.confirmThatYou,
  firstPageText.agreementWithUs,
  firstPageText.ofUseIfYouFail,
  firstPageText.wasUnableToCompile,
  firstPageText.policiesOrIf,
  firstPageText.causedByOur,
  firstPageText.itWasReceived,
  firstPageText.shallPromptlyReimburse,
  firstPageText.customerPurchase,
  firstPageText.anyTaxesAndAll,
  firstPageText.bankOrOther,
  firstPageText.penaltyFeesAssociated,
  firstPageText.anyChargebackOrRefund,
  firstPageText.inEachCaseTo,
];

export const standardTermsAG = [
  {
    title: firstPageText.listTitleDigitalNature,
    text: firstPageText.pleaseKeepInMind,
    x: 70,
  },
  firstPageText.mostOfOurServices,
  firstPageText.thereIsNoPhysical,
  firstPageText.theMoment,
];

export const standardTermsAH = [firstPageText.thisRefundAnd, firstPageText.partOfOur];

export const standardTermsAI = [
  {
    title: firstPageText.listTitleAdditionalCharge,
    text: firstPageText.weWillNotBe,
    x: 52,
  },
  firstPageText.additionalChargeLevied,
  firstPageText.processorOrAThird,
  firstPageText.cancellationOrRefund,
  firstPageText.responsibilityOfThe,
  firstPageText.refundSuchCharges,
];

export const standardTermsAJ = [
  {
    title: firstPageText.listTitleKycAndAmlMandate,
    text: firstPageText.allTheCustomersAnd,
    x: 70,
  },
  firstPageText.usersCompleteOfOurPlatform,
  firstPageText.kyxAndAmlBefore,
  firstPageText.makingPaymentOnOur,
  firstPageText.aMadatoryRequirement,
  firstPageText.shallBeAllowed,
  firstPageText.doNotWishToComplete,
  firstPageText.complianceOurKyc,
  firstPageText.withLocalData,
  firstPageText.youDontWantToGO,
  firstPageText.complianceYouShall,
  firstPageText.serviceOnThis,
  firstPageText.websiteIfYou,
  firstPageText.requirementsWithinThe,
  firstPageText.companyHasThe,
  firstPageText.andRefuseTo,
];

export const standardTermsBA = [
  {
    title: firstPageText.listTitleEuropeanUnion,
    text: firstPageText.ifYouAreAn,
    x: 48,
  },
  firstPageText.citizenAndMade,
  firstPageText.ofTheEuMember,
  firstPageText.fromTheDateOf,
];

export const standardTermsBB = [
  {
    title: firstPageText.listTitleUnitedKingdom,
    text: firstPageText.ifYouAreAUk,
    x: 50,
  },
  firstPageText.purchaseOnOurWebsite,
  firstPageText.refundFromTheDate,
];

export const leftListA = [
  {
    list: standardTermsAA,
    char: 'a.',
  },
  {
    list: standardTermsAB,
    char: 'b.',
  },
  {
    list: standardTermsAC,
    char: 'c.',
  },
  {
    list: standardTermsADLeft,
    char: 'd.',
  },
];

export const rightListA = [
  {
    list: standardTermsADRight,
    char: '',
  },
  {
    list: standardTermsAE,
    char: 'e.',
  },
  {
    list: standardTermsAF,
    char: 'f.',
  },
  {
    list: standardTermsAG,
    char: 'g.',
  },
  {
    list: standardTermsAH,
    char: 'h.',
  },
  {
    list: standardTermsAI,
    char: 'i.',
  },

  {
    list: standardTermsAJ,
    char: 'j.',
  },
];

export const rightListB = [
  {
    list: standardTermsBA,
    char: 'a.',
  },
  {
    list: standardTermsBB,
    char: 'b.',
  },
];

export const pageTwoParagraphOne = [
  secondPageText.iConsentToMonthly,
  secondPageText.readAndAccepted,
  secondPageText.makingThisPurchase,
];

export const pageTwoParagraphTwo = [
  secondPageText.iAlsoUnderstand,
  secondPageText.detailsInMyEmail,
  secondPageText.damagesAndCompensation,
];

export const pageTwoParagraphThree = [secondPageText.signingThisI, secondPageText.agreeToHonour];
