import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import Button from '../button';
import styles from './cart-subtotal.module.scss';
import StaticMessages from '../../constants/staticMessages';
import { redirectToOtherSite } from '../../store/actions';
import { REDIRECTION_TYPES } from '../../constants/types';

const CartSubtotal = ({
  subtotal = 0,
  itemsCount = 0,
  onCheckoutClick,
  totalDiscount = 0,
  showAutoRenewalMessage,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const itemId = itemsCount === 1 ? 'cart_subtotal_block.item' : 'cart_subtotal_block.items';
  const itemStr = intl.formatMessage({ id: itemId });
  const subtotalStr = intl.formatMessage({ id: 'cart_subtotal_block.subtotal' });
  const buttonTxt = intl.formatMessage({ id: 'cart_subtotal_block.proceed_to_checkout' });
  const title = `${subtotalStr} (${itemsCount} ${itemStr}):`;
  const amount = `$${subtotal?.toFixed(2)}` || 0;

  const redirectToPortal = () => {
    dispatch(
      redirectToOtherSite({
        type: REDIRECTION_TYPES.PORTAL,
        authorized: true,
        link: `${process.env.GATSBY_PORTAL_LINK}/app/manageSubscription`,
      })
    );
  };

  return (
    <div className={styles.subtotalWrapper}>
      <div className={styles.subtotal}>
        <span className={styles.label}>{title}</span>
        <p className={styles.text}>{amount}</p>
      </div>

      {totalDiscount > 0 && (
        <div className={styles.subtotal}>
          <span className={styles.label}>Coupon Applied:</span>
          <p className={styles.text}>${totalDiscount.toFixed(2)}</p>
        </div>
      )}

      {showAutoRenewalMessage && (
        <span className={styles.subtotal}>
          <span className={styles.label}>
            {StaticMessages.autoRenewalMessage.part1}
            <strong
              role="button"
              tabIndex={0}
              onKeyDown={redirectToPortal}
              onClick={redirectToPortal}
            >
              {StaticMessages.autoRenewalMessage.part2}
            </strong>
            {StaticMessages.autoRenewalMessage.part3}
          </span>
        </span>
      )}

      <div className={styles.actions}>
        <Button color="primary" fullWidth className={styles.checkoutBtn} onClick={onCheckoutClick}>
          <i className="icon-cart" />
          <span>{buttonTxt}</span>
        </Button>
      </div>
    </div>
  );
};

CartSubtotal.propTypes = {
  subtotal: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  totalDiscount: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
  onCheckoutClick: PropTypes.func.isRequired,
  showAutoRenewalMessage: PropTypes.bool.isRequired,
};

export default CartSubtotal;
