import React from 'react';
import { Location } from '@reach/router';
import queryString from 'query-string';

const withLocation = (ComponentToWrap) => (props) =>
  (
    <Location>
      {({ location, navigate }) => {
        const queryParams = queryString.parse(location.search);
        // eslint-disable-next-line react/prop-types
        const webAlias = props['*'];

        return (
          <ComponentToWrap
            {...props}
            {...queryParams}
            webAlias={webAlias}
            location={location}
            navigate={navigate}
          />
        );
      }}
    </Location>
  );

export default withLocation;
