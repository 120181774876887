import { useState, useEffect, useCallback } from 'react';

import { isClient } from '../helpers/utils';

export default function useDropdown(dropRef, actionRef) {
  const dropEl = dropRef.current;
  const actionEl = actionRef.current;

  const [drop, setDrop] = useState(false);

  const toggleDrop = useCallback(
    (toggleState) => {
      setDrop(toggleState !== undefined ? toggleState : !drop);
    },
    [drop]
  );

  const onWindowClick = useCallback(
    (ev) => {
      const clickOnAction = actionEl && (ev.target === actionEl || actionEl.contains(ev.target));
      const clickOnDrop = dropEl && (ev.target === dropEl || dropEl.contains(ev.target));

      if (!clickOnAction && !clickOnDrop && drop === true) {
        toggleDrop(false);
      }
    },
    [drop]
  );

  const onEsc = useCallback(
    (ev) => {
      if (ev.keyCode === 27 && drop === true) {
        toggleDrop(false);
      }
    },
    [drop]
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isClient) {
      window.addEventListener('click', onWindowClick);
      return () => window.removeEventListener('click', onWindowClick);
    }
  });

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isClient) {
      window.addEventListener('keyup', onEsc);
      return () => window.removeEventListener('keyup', onEsc);
    }
  });

  return [drop, toggleDrop];
}
