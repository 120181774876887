import apiAction from '../apiActionCreator';

const type = 'GET_USER_SUBSCRIPTIONS';

const config = {
  url: '/payments/user/subscriptions',
  actionType: type,
  sendFormat: 'json',
  method: 'GET',
};

export default apiAction(config);
