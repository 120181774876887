import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import recoverEmail from '../services/api/actions/recoverEmail';
import passwordReset from '../services/api/actions/passwordReset';
import forgotUserName from '../services/api/actions/forgotUserName';
import signUp from '../services/api/actions/signUp';
import phoneVerify from '../services/api/actions/phoneVerify';

import setNotification from '../helpers/notifications';
import * as Constants from '../store/constants';
import { createNavigateTo, pageLinks } from '../helpers/navigation';
import { clearShoppingCart, setLoader, showSkipVerificationButton } from '../store/actions';
import { isClient } from '../helpers/utils';
import { selectRegisterValues } from '../store/selectors/global';

// eslint-disable-next-line require-yield
function* logOutUser() {
  // yield put(deleteToken.action());
  if (isClient) {
    localStorage.removeItem('AT');
    localStorage.removeItem('UN');
    yield put(clearShoppingCart());
    createNavigateTo(pageLinks.home)();
  }
}

function* onSuccessRequest({ type }) {
  yield put(setLoader(false));
  switch (type) {
    case forgotUserName.type.success: {
      yield call(createNavigateTo(pageLinks.forgotUsernameSuccess));
      break;
    }
    case recoverEmail.type.success: {
      yield call(createNavigateTo(pageLinks.forgotPasswordSuccess));
      break;
    }
    case passwordReset.type.success: {
      setNotification('success', {
        message: 'Password successfully changed.',
        title: 'Success',
      });
      yield call(createNavigateTo(pageLinks.signIn));
      break;
    }
    default:
      break;
  }
}

function* verifyWorker(action) {
  const { code } = action.meta.previousAction.payload.request.data;

  if (action.payload && !action.payload.data.valid && code) {
    setNotification('error', {
      message: 'Invalid verification code',
      title: 'Error',
    });
    return;
  }

  if (action.payload && action.payload.data.status === 'pending') {
    yield call(createNavigateTo(pageLinks.verification));
    return;
  }

  if (action.payload && action.payload.data.status === 'approved') {
    yield call(createNavigateTo(pageLinks.verificationSuccess));
  }
}

function* verifyErrorWorker(action) {
  const sentData = action.meta.previousAction.payload.request.data;
  const message = action.error?.response?.data?.message;

  if (
    message.includes('too many') ||
    message.includes('exceeded a limit') ||
    message.includes('limit for phone')
  ) {
    return setNotification('error', { message });
  }

  if (message.includes('Invalid parameter')) {
    return setNotification('error', { message: 'Phone number is invalid' });
  }

  if (sentData.code && sentData.phone) {
    // !SHOW SKIP BUTTON
    yield put(showSkipVerificationButton(true));
    return setNotification('info', {
      message:
        'You will need to verify your phone number via customer portal upon completing the registration',
    });
  }

  return null;
}

function* registerUserWorker(action) {
  const registerData = yield select(selectRegisterValues);
  yield put(
    signUp.action({
      ...registerData,
      phoneVerified: !!action.payload,
      birthDate: moment(registerData.birthDate).format('YYYY-MM-DD'),
    })
  );
}

export default function* authSaga() {
  yield all([
    yield takeLatest(
      [forgotUserName.type.success, recoverEmail.type.success, passwordReset.type.success],
      onSuccessRequest
    ),
    yield takeLatest(Constants.LOG_OUT_USER, logOutUser),
    yield takeLatest(phoneVerify.type.success, verifyWorker),
    yield takeLatest(phoneVerify.type.error, verifyErrorWorker),
    yield takeLatest(Constants.REGISTER_USER_AFTER_PHONE_VALIDATION, registerUserWorker),
  ]);
}
