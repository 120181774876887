import { all, call, delay, put, select, take, takeLatest } from 'redux-saga/effects';
import uniqBy from 'lodash/uniqBy';
import { v4 as uuidv4 } from 'uuid';
import addPaymentCardAndSetDefault from '../services/api/actions/addPaymentCardAndSetDefault';
import addPaymentCard from '../services/api/actions/addPaymentCard';
import setPaymentAsDefault from '../services/api/actions/setPaymentAsDefault';
import removePaymentMethod from '../services/api/actions/removePaymentMethod';
import ordersPurchase from '../services/api/actions/ordersPurchase';
import { createNavigateTo, pageLinks } from '../helpers/navigation';
import PaymentConstants, { ORDER_STATUSES } from '../constants/payment';

import {
  selectAvailablePaymentMethods,
  selectChosenPaymentMethod,
  selectCouponId,
  selectPayForFriendMode,
  selectPreselectedAddon,
  selectShouldUpdateAgreement,
  selectSubmitCount,
} from '../store/selectors/global';
import {
  selectEntityPaymentMethods,
  selectEntityProducts,
  selectEntityUserDetails,
} from '../store/selectors/entities';
import {
  selectAddressCountry,
  selectBillingAddresses,
  selectCart,
  selectPaymentsWithDefault,
  selectRequestedOrderData,
} from '../store/selectors';
import {
  getSignedAgreement,
  makeOrderWithSavedCart,
  selectCoupon,
  selectPaymentMethodFromSaved,
  setAuditTrialData,
  setAvailablePaymentMethods,
  setButtonLoaderType,
  setLoader,
  setModal,
  setModalError,
  setSubmitCount,
  toggleAgreementDataFlag,
  validateAddon,
} from '../store/actions';

import * as AppConstants from '../store/constants';
import {
  CREATE_PAYPAL_ACCOUNT,
  EMULATE_CREATION_DEFAULT_CARD,
  MAKE_ORDER_WITH_D_LOCAL_INSTALLMENT,
  MAKE_ORDER_WITH_SAVED_CART,
  UPDATE_CARD_INFO,
  UPDATE_PAYMENT_METHODS,
} from '../store/constants';

import {
  filterMethodsByInstance,
  filterPaymentMethodsByGateway,
  getMonthByNumber,
  getPaymentsByCountry,
  getPaymentTitle,
  isClient,
  isEqualArrayStrings,
  loadFraudNetJsonData,
  loadFraudNetScript,
  logEvent,
  sortPaymentMethods,
  transformCartToItems,
} from '../helpers/utils';

import { isAddonInstance, isRapidChangersInstance } from '../helpers/instanceHandler';

import { getCountryData } from '../helpers/countries';
import getUserPaymentMethods from '../services/api/actions/getUserPaymentMethods';
import setNotification from '../helpers/notifications';
import getBillingDetails from '../services/api/actions/getBillingDetails';
import getCoupons from '../services/api/actions/getCoupons';
import updatePaymentCard from '../services/api/actions/updatePaymentCard';
import updateBillAddress from '../services/api/actions/updateBillAddress';
import addBillAddress from '../services/api/actions/addBillAddress';
import getUserDetails from '../services/api/actions/getUserDetails';
import { ButtonLoaderTypes } from '../constants/types';
import calculatePrice from '../services/api/actions/calculatePrice';

import getOrderById from '../services/api/actions/getOrderById';
import { checkSitePage } from '../helpers/url';
import getUserElectronicSign from '../services/api/actions/getUserElectronicSign';
import addAgreement from '../services/api/actions/addAgreement';
import createPayment from '../services/api/actions/createPayment';
import ordersPurchaseForFriend from '../services/api/actions/ordersPurchaseForFriend';

const FRAUD_NET_ID = process.env.GATSBY_FROUDNET_ID || 'BEUNLEASH_CJGQ6BNUVL93A_PYMNT';

function* fraudNetWorker() {
  const uuid = uuidv4();
  const keys = { f: uuid, s: FRAUD_NET_ID };
  yield call(loadFraudNetJsonData, keys);
  yield delay(0);
  yield call(loadFraudNetScript, { fnUrl: 'https://c.paypal.com/da/r/fb.js' });
  return uuid;
}

function* createPaypalAccountWorker({ payload }) {
  const paypalUniqueId = yield call(fraudNetWorker);
  yield put(
    createPayment.action({
      ...payload,
      paypalUniqueId,
    })
  );
}

let ORDER_RETRY_COUNTER = 0;

function* preparePurchaseDataWorker() {
  const { products } = yield select(selectCart);
  const preselectedAddon = yield select(selectPreselectedAddon);
  const selectedPaymentMethod = yield select(selectChosenPaymentMethod);

  const itemsForRequest = transformCartToItems(
    uniqBy([...products, ...preselectedAddon], (e) => e.name),
    false
  );

  const selectedCoupon = yield select(selectCouponId);

  const id = localStorage.getItem('audit-trial-id');

  const redirectUrl =
    process.env.NODE_ENV === `production`
      ? `${window.location.origin}/checkout-result/?complete={result}`
      : 'https://shop.stg.befreedom.com/checkout-result/?complete={result}';

  const paymentInstrumentId = selectedPaymentMethod?.id || null;
  const paymentMethod = selectedPaymentMethod?.method || null;
  const couponInfo = selectedCoupon ? { couponCode: selectedCoupon } : {};

  const paymentInfoId = paymentInstrumentId
    ? { paymentInstrumentId, redirectUrl }
    : { redirectUrl };

  const paymentInfoMethod = paymentMethod ? { paymentMethod } : {};
  let gatewayAccountId = {};

  if (paymentMethod === 'astroPay') {
    paymentInfoMethod.paymentMethod = 'AstroPay Card';
  }

  if (paymentMethod === 'coinpayments') {
    paymentInfoMethod.paymentMethod = 'cryptocurrency';
  }

  if (paymentMethod === 'paypal-card') {
    gatewayAccountId = {};
    paymentInfoMethod.paymentMethod = 'paypal';
  }

  if (paymentMethod === 'paypal') {
    gatewayAccountId = {};
    paymentInfoMethod.paymentMethod = 'paypal';
  }

  if (paymentMethod === 'rapyd') {
    gatewayAccountId = { gatewayAccountId: 'rapyd' };
    paymentInfoMethod.paymentMethod = 'rapyd-checkout';
  }

  if (paymentMethod === 'paynote') {
    gatewayAccountId = { gatewayAccountId: 'paynote' };
    paymentInfoMethod.paymentMethod = 'Paynote';
  }

  if (paymentMethod === PaymentConstants.SHOP_METHODS.requestToPay) {
    gatewayAccountId = {};
    paymentInfoMethod.paymentMethod = undefined;
    paymentInfoMethod.requestCoordinatorId = selectedPaymentMethod.requestCoordinatorId;
  }

  const paymentData = { ...paymentInfoId, ...paymentInfoMethod };

  const autopay = ['payment-card', 'paypal', 'paypal-card', 'AmazonPay'].includes(
    selectedPaymentMethod?.method
  );

  const autopayFlag =
    autopay || (!paymentData.paymentMethod && !paymentData.paymentInstrumentId) || false;

  const data = {
    ...paymentData,
    ...couponInfo,
    ...gatewayAccountId,
    autopay: !!autopayFlag,
    items: itemsForRequest,
    // autopay: false,
    // items: [{ planId: 'ibp-oneday-usd', action: 'INITIAL' }],
    // paymentMethod: 'bank-transfer',
    // paymentMethod: 'cash-deposit',
  };

  if (!checkSitePage(pageLinks.checkout)) {
    return {
      ...couponInfo,
      items: itemsForRequest,
    };
  }

  if (id) {
    data.signingExternalId = id;
  }

  return data;

  // return IBP

  // return { ...data, items: [{ planId: 'ibp-oneday-usd', action: 'INITIAL' }] };
}

function* orderPurchaseWorker() {
  yield put(setLoader(true));
  const submitCount = yield select(selectSubmitCount);
  yield put(setSubmitCount(1));
  if (submitCount) {
    return;
  }
  let orderData = yield call(preparePurchaseDataWorker);
  const orderResultData = yield select(selectRequestedOrderData);

  logEvent('purchase');

  if (orderData.paymentMethod === PaymentConstants.SHOP_METHODS.paypal) {
    const paypalUniqueId = yield call(fraudNetWorker);
    orderData = { ...orderData, paypalUniqueId };
  }

  if (orderResultData) {
    yield put(ordersPurchaseForFriend.action({ ...orderData, orderId: orderResultData.orderId }));
    return;
  }

  yield put(ordersPurchase.action(orderData));
}

export function* calculatePriceWorker(action) {
  if (
    (isAddonInstance || checkSitePage(pageLinks.addons)) &&
    action.type === AppConstants.ADD_TO_CART
  ) {
    return;
  }

  yield delay(1000);
  const token = isClient && localStorage.getItem('AT');

  const payForFriendMode = yield select(selectPayForFriendMode);

  if (token && token !== 'null' && token.length > 5) {
    const orderData = yield call(preparePurchaseDataWorker);
    const requestOrderData = yield select(selectRequestedOrderData);

    if (payForFriendMode && requestOrderData) {
      yield put(
        calculatePrice.action({
          ...orderData,
          items: requestOrderData.itemsForPriceCalculate,
          requestOrderId: requestOrderData.orderId,
        })
      );

      return;
    }

    if (orderData) {
      yield put(calculatePrice.action(orderData));
    }
  }
}

function* retryOrderInfoWorker(action) {
  try {
    const payForFriendMode = yield select(selectPayForFriendMode);
    if (payForFriendMode) {
      return;
    }

    const { status, id } = action.payload.data;
    const isCheckoutResultPage = checkSitePage(pageLinks.checkoutSuccess);

    const isPendingStatus = [
      ORDER_STATUSES.PENDING,
      ORDER_STATUSES.ON_HOLD,
      ORDER_STATUSES.PROCESSING,
      ORDER_STATUSES.BLOCKCHAIN_PENDING,
    ].includes(status);

    if (isPendingStatus) {
      localStorage.setItem('POR', id);
      localStorage.setItem('POR-boughtProducts', localStorage.getItem('boughtProducts'));
    }

    if (isCheckoutResultPage && isPendingStatus && ORDER_RETRY_COUNTER <= 20) {
      yield delay(4000);
      // eslint-disable-next-line
      ORDER_RETRY_COUNTER = ORDER_RETRY_COUNTER + 1;
      yield put(getOrderById.withQuery(`/${id}`).action());
    }
    if (!isPendingStatus || !isCheckoutResultPage) {
      ORDER_RETRY_COUNTER = 0;
    }

    if (!isPendingStatus) {
      localStorage.removeItem('POR');
      localStorage.removeItem('POR-boughtProducts');
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log('RETRY_ORDER_ERROR', err);
  }
}

function* dLocalPurchaseWorker(action) {
  yield put(setLoader(true));
  const submitCount = yield select(selectSubmitCount);
  yield put(setSubmitCount(1));
  if (submitCount) {
    return;
  }

  const orderData = yield call(preparePurchaseDataWorker);

  const data = {
    ...orderData,
    gatewayAccountId: action.payload.gatewayAccountId,
    paymentInstrumentId: action.payload.paymentInstrumentId,
  };

  yield put(ordersPurchase.action(data));
}

function* onSuccessPayment(action) {
  try {
    const {
      approvalUrl,
      items,
      status,
      amount,
      amountFee,
      amountVat,
      paymentInstrumentId,
      paymentMethod,
      transactionId,
      fromEWalletAmount,
    } = action.payload.data;

    const savedPaymMethods = yield select(selectEntityPaymentMethods);
    const userDetails = yield select(selectEntityUserDetails);
    const billAddresses = yield select(selectBillingAddresses);
    const allProducts = yield select(selectEntityProducts);
    const requestToPayOrder = action.meta.previousAction.payload.request.data.requestCoordinatorId;

    yield put(setAuditTrialData({ step: 0, id: '' }));

    let primaryAddress = billAddresses.find((item) => !!item.primary);

    let successPayment = paymentMethod;

    if (paymentMethod === 'payment-card') {
      const methodList = savedPaymMethods.cards;
      const method = methodList.find((item) => item.id === paymentInstrumentId);
      successPayment = method.last4
        ? `${paymentMethod} ${method.brand} *${method.last4}`
        : paymentMethod;
      primaryAddress = method.billingAddress;
      const phone =
        method.billingAddress.phoneNumbers[0] && method.billingAddress.phoneNumbers[0].value;
      if (phone) {
        primaryAddress.phone = phone;
      }
    }

    const date = new Date();
    if (isClient) {
      const successPageInfo = {
        userName: localStorage.getItem('UN'),
        exigoId: null,
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        customerId: userDetails.customerId,
        email: userDetails.email,
        phone: primaryAddress.phone,
        requestToPayOrder,
        order: transactionId,
        date: `${getMonthByNumber(date.getMonth())} ${date.getUTCDate()}, ${date.getFullYear()}`,
        paymentMethod: successPayment,
        address: `${primaryAddress.country.toUpperCase()}, ${primaryAddress.state || ''} ${
          primaryAddress.address
        }, ${primaryAddress.zipCode || primaryAddress.postalCode}`,
        products: items.map((item) => {
          const productById = allProducts.find((product) =>
            product?.plans?.some((plan) => isEqualArrayStrings(plan.rebillyPlanIds, [item.planId]))
          );
          return {
            ...item,
            path: productById?.urlParam || '',
          };
        }),
        subtotal: amount,
        amountFee,
        amountVat,
        fromEWalletAmount,
      };

      localStorage.setItem('boughtProducts', JSON.stringify(successPageInfo));
      localStorage.removeItem('shoppingCart');
    }

    yield put(selectCoupon(null));
    yield put(selectPaymentMethodFromSaved(null));
    yield put(getCoupons.action());

    if (approvalUrl && status === 'waiting-approval') {
      window.location.href = approvalUrl;
    }

    if (!approvalUrl || status === 'completed') {
      yield put(setLoader(false));
      yield call(createNavigateTo(`${pageLinks.checkoutSuccess}/?complete=${status}`));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('on success payment error', e);
  }
}

function* updatePaymentMethods(action) {
  const details = yield select(selectEntityUserDetails);

  if (details.shouldAddPaymentMethod) {
    yield put(setModalError(null));
    yield put(getUserDetails.action());
  }

  yield put(setLoader(false));

  yield put(getUserPaymentMethods.action());

  if (
    action.type === addPaymentCardAndSetDefault.type.success ||
    action.type === setPaymentAsDefault.type.success ||
    action.type === UPDATE_PAYMENT_METHODS ||
    action.type === addPaymentCard.type.success
  ) {
    yield put(getBillingDetails.action());
    yield put(setModal(null));
    document.body.className = document.body.className.replace('modal-is-active', '');
  }

  if (action.type === removePaymentMethod.type.success) {
    yield put(getBillingDetails.action());
  }

  if (action.type !== UPDATE_PAYMENT_METHODS) {
    setNotification('success', {
      message: 'Your payment data updated',
      title: 'Success',
    });
  }
}

function* hideModalAndSetSelected(action) {
  try {
    let { paymentInstrumentId } = action.payload.data;
    while (paymentInstrumentId) {
      const request = yield take(getUserPaymentMethods.type.success);
      if (request) {
        const { cards, paypal, bankAccounts } = request.payload.data;

        const defaultMethod = [...cards, ...paypal, ...bankAccounts].find(
          // eslint-disable-next-line no-loop-func
          (item) => item.id === paymentInstrumentId
        );

        if (defaultMethod) {
          yield put(selectPaymentMethodFromSaved(defaultMethod));
          yield put(setModal(null));
          paymentInstrumentId = null;
          document.body.className = document.body.className.replace('modal-is-active', '');
        }
      }
    }
  } catch (e) {
    // eslint-disable-next-line
    console.warn('Error while setting default method', e);
  }
}

// function* defaultPaymentMethodWorker() {
//   try {
//     const selectedPaymentMethod = yield select(selectChosenPaymentMethod);
//     const { cards, paypal, bankAccounts } = yield select(selectPaymentsWithDefault);
//     const defaultMethod = [...cards, ...paypal, ...bankAccounts].find((item) => item.isDefault);
//
//     if (defaultMethod && defaultMethod.id !== selectedPaymentMethod?.id) {
//       yield put(selectPaymentMethodFromSaved(defaultMethod));
//     }
//   } catch (e) {
//     // eslint-disable-next-line
//     console.warn('Error while setting default method', e);
//   }
// }

// eslint-disable-next-line require-yield
function* onCreatePaymentMethod(action) {
  window.location.href = action.payload.data.approvalUrl;
}

// TODO: refactor
function* updateCardInfo(action) {
  const selectedPayment = yield select(selectChosenPaymentMethod);
  const availablePaymentMethods = yield select(selectAvailablePaymentMethods);
  const { bankAccounts } = yield select(selectPaymentsWithDefault);

  const { address, address2, phone, city, country, zipCode, primary, state, firstName, lastName } =
    action.meta.previousAction.payload.request.data;

  const defaultPlaidMethod = bankAccounts.find((item) => !!item.isDefault) || null;

  if (action.type === addBillAddress.type.success && !primary) {
    return;
  }

  // Show notification on change address if method not supported

  const countryData = getCountryData(country);
  const countryName = countryData ? countryData.name : 'selected country';

  if (selectedPayment && !availablePaymentMethods.includes(selectedPayment.method)) {
    setNotification('info', {
      message: `${getPaymentTitle(
        selectedPayment.method
      )} is not available for ${countryName}. The payment method was changed to a default one.`,
      title: 'Info',
    });
    yield put(selectPaymentMethodFromSaved(null));
  }

  if (
    !selectedPayment &&
    defaultPlaidMethod &&
    !availablePaymentMethods.includes(defaultPlaidMethod.method)
  ) {
    setNotification('info', {
      message: `${getPaymentTitle(
        defaultPlaidMethod.method
      )} is not available for ${countryName}. The payment method was changed.`,
      title: 'Info',
    });
  }

  if (selectedPayment && selectedPayment.method === PaymentConstants.SHOP_METHODS.card) {
    const cardData = {
      paymentInstrumentId: selectedPayment.id,
      expMonth: selectedPayment.expMonth,
      expYear: selectedPayment.expYear,
      billingAddress: {
        address,
        city,
        firstName,
        lastName,
        address2,
        phoneNumbers: [{ label: phone, value: phone, primary: true }],
        country: country.toUpperCase(),
        postalCode: zipCode,
        region: state || '',
      },
      stickyGatewayAccountId: selectedPayment.stickyGatewayAccountId,
    };

    yield put(updatePaymentCard.action(cardData));
    return;
  }

  if (!selectedPayment) {
    const methods = yield select(selectPaymentsWithDefault);

    const defaultCard =
      methods && methods.cards.length && methods.cards.find((item) => item.isDefault);
    if (defaultCard) {
      const cardData = {
        paymentInstrumentId: defaultCard.id,
        expMonth: defaultCard.expMonth,
        expYear: defaultCard.expYear,
        billingAddress: {
          address,
          city,
          address2,
          firstName,
          lastName,
          phoneNumbers: [{ label: phone, value: phone, primary: true }],
          country: country.toUpperCase(),
          postalCode: zipCode,
          region: state || '',
        },
        stickyGatewayAccountId: defaultCard.stickyGatewayAccountId,
      };

      yield put(updatePaymentCard.action(cardData));
    }
  }
}

function* updateSubmitCount() {
  yield put(setSubmitCount(0));
}

function* updateCvv(action) {
  const { id, cvv, expMonth, expYear } = action.payload;
  const cardData = {
    paymentInstrumentId: id,
    cvv,
    expMonth,
    expYear,
  };

  yield put(setLoader(true));

  const [responseAction] = yield all([yield put(updatePaymentCard.action(cardData))]);

  if (responseAction.type === updatePaymentCard.type.success) {
    yield put(setModal(null));

    if (isAddonInstance) {
      const { products } = yield select(selectCart);
      yield put(
        validateAddon(products.map((item) => ({ ...item, successActionType: 'PURCHASE' })))
      );
    } else {
      yield put(makeOrderWithSavedCart());
    }
  }

  if (responseAction.type === updatePaymentCard.type.error) {
    yield put(setLoader(null));
  }
}

function* createAndSetDefault(action) {
  const { method, paymentToken } = action.payload;
  yield put(addPaymentCard.action({ paymentToken }));

  while (true) {
    const request = yield take(addPaymentCard.type.success);
    if (request) {
      yield put(setPaymentAsDefault.action({ method, id: paymentToken }));
    }
  }
}

function* hidePurchaseButtonLoader() {
  yield put(setButtonLoaderType(''));
}

function* notifyAboutRapidChangersDiscount(action) {
  if (isRapidChangersInstance) {
    const { shoppingCartProductPrice, products } = yield select(selectCart);
    const { totalDiscount, totalAmount } = action.payload.data.data;

    if (
      products.some((item) => item.isCombo) &&
      totalAmount + totalDiscount < shoppingCartProductPrice
    ) {
      setNotification('success', {
        message: `Congrats, pay only ${totalAmount} (the difference) and get Elite`,
        title: '',
      });
    }
  }
}

function* showPurchaseButtonLoader() {
  yield put(setButtonLoaderType(ButtonLoaderTypes.PURCHASE));
}

function* processPaymentMethods() {
  const paymentMethodsForAskPayToFriend = [
    PaymentConstants.SHOP_METHODS.eWallet,
    PaymentConstants.SHOP_METHODS.coinPayments,
  ];
  const country = yield select(selectAddressCountry);
  const shoppingCart = yield select(selectCart);
  const selectedPaymentMethod = yield select(selectChosenPaymentMethod);
  const allMethods = getPaymentsByCountry(country);
  const payForFriendMode = yield select(selectPayForFriendMode);
  const availablePaymentMethods = yield select(selectAvailablePaymentMethods);
  const { cards, paypal, bankAccounts } = yield select(selectPaymentsWithDefault);
  const defaultMethod = [...cards, ...paypal, ...bankAccounts].find((item) => item.isDefault);

  const allMethodsList = filterMethodsByInstance(allMethods);
  const methods = uniqBy(
    filterPaymentMethodsByGateway(shoppingCart, allMethodsList)
      .sort(sortPaymentMethods)
      .map((i) => i?.name || i)
      .filter((item) => !!item)
  );

  if (payForFriendMode) {
    yield put(
      setAvailablePaymentMethods(
        methods.filter((item) => paymentMethodsForAskPayToFriend.includes(item))
      )
    );

    if (
      availablePaymentMethods.includes(selectedPaymentMethod?.method) ||
      availablePaymentMethods.includes(defaultMethod?.method)
    ) {
      yield put(selectPaymentMethodFromSaved(defaultMethod));
    }
  } else {
    yield put(setAvailablePaymentMethods(methods));
  }

  if (
    !payForFriendMode &&
    ((defaultMethod &&
      defaultMethod.id !== selectedPaymentMethod?.id &&
      methods.includes(selectedPaymentMethod?.method)) ||
      (!selectedPaymentMethod && defaultMethod?.id && methods.includes(defaultMethod?.method)))
  ) {
    yield put(selectPaymentMethodFromSaved(defaultMethod));
  }

  if (selectedPaymentMethod && !methods.includes(selectedPaymentMethod.method)) {
    yield put(selectPaymentMethodFromSaved(null));
  }
}

function* updatePdfAgreement() {
  yield delay(1000);
  const token = isClient && localStorage.getItem('AT');
  const validToken = token && token !== 'null' && token.length > 5;

  const shouldUpdateAgreement = yield select(selectShouldUpdateAgreement);
  const addresses = yield select(selectBillingAddresses);
  const requestedOrderData = yield select(selectRequestedOrderData);

  const withPrimary = addresses.some((adr) => adr?.primary);
  const { products, productCount } = yield select(selectCart);

  if (!validToken || !shouldUpdateAgreement || !withPrimary || !productCount) {
    return;
  }

  const preselectedAddon = yield select(selectPreselectedAddon);

  const items = transformCartToItems(
    uniqBy([...products, ...preselectedAddon], (e) => e.name),
    false
  );

  const requestOrderId = requestedOrderData?.id || undefined;
  yield put(getUserElectronicSign.action({ items, requestOrderId }));

  yield put(setLoader(false));

  while (true) {
    yield take(getUserElectronicSign.type.success);
    yield put(toggleAgreementDataFlag(false));
  }
}

// ! If user on the checkout page we updating agreement immediately
// ! in other cases we just change the flag and update agreement when user goes to the checkout page;
function* agreementFlagWorker() {
  yield put(toggleAgreementDataFlag(true));
  if (checkSitePage(pageLinks.checkout)) {
    yield put(getSignedAgreement());
  }
}

function* paymentSaga() {
  yield all([
    yield takeLatest(
      [
        addPaymentCardAndSetDefault.type.success,
        setPaymentAsDefault.type.success,
        addPaymentCard.type.success,
        removePaymentMethod.type.success,
        updatePaymentCard.type.success,
        UPDATE_PAYMENT_METHODS,
      ],
      updatePaymentMethods
    ),
    yield takeLatest(MAKE_ORDER_WITH_SAVED_CART, orderPurchaseWorker),
    yield takeLatest(CREATE_PAYPAL_ACCOUNT, createPaypalAccountWorker),
    yield takeLatest(MAKE_ORDER_WITH_D_LOCAL_INSTALLMENT, dLocalPurchaseWorker),
    yield takeLatest(
      [ordersPurchase.type.success, ordersPurchaseForFriend.type.success],
      onSuccessPayment
    ),
    yield takeLatest(
      [
        AppConstants.ADD_TO_CART,
        AppConstants.REMOVE_FROM_CART,
        addBillAddress.type.success,
        getUserDetails.type.success,
        getBillingDetails.type.success,
        getUserPaymentMethods.type.success,
      ],
      processPaymentMethods
    ),
    yield takeLatest(
      [calculatePrice.type.success, calculatePrice.type.error],
      hidePurchaseButtonLoader
    ),

    yield takeLatest(
      [AppConstants.SELECT_COUPON, AppConstants.CLEAR_SELECTED_COUPONS, calculatePrice.type.start],
      showPurchaseButtonLoader
    ),

    yield takeLatest([ordersPurchase.type.success, ordersPurchase.type.error], updateSubmitCount),
    yield takeLatest([getOrderById.type.success], retryOrderInfoWorker),
    yield takeLatest([calculatePrice.type.success], notifyAboutRapidChangersDiscount),
    yield takeLatest(
      [addAgreement.type.success, AppConstants.GET_SIGNED_AGREEMENT, getUserDetails.type.success],
      updatePdfAgreement
    ),
    yield takeLatest(createPayment.type.success, onCreatePaymentMethod),
    yield takeLatest([updateBillAddress.type.success, addBillAddress.type.success], updateCardInfo),
    yield takeLatest(EMULATE_CREATION_DEFAULT_CARD, createAndSetDefault),
    yield takeLatest(UPDATE_CARD_INFO, updateCvv),
    yield takeLatest(
      [
        AppConstants.ADD_VALID_ADDON_TO_CART,
        AppConstants.ADD_TO_CART,
        AppConstants.REMOVE_FROM_CART,
        updateBillAddress.type.success,
        addBillAddress.type.success,
        addAgreement.type.success,
        calculatePrice.type.start,
      ],
      agreementFlagWorker
    ),
    // yield takeLatest(
    //   [getBillingDetails.type.success, getUserPaymentMethods.type.success],
    //   defaultPaymentMethodWorker
    // ),
    yield takeLatest(
      [
        AppConstants.ON_VALIDATE_ADDON,
        AppConstants.ADD_TO_CART,
        AppConstants.REMOVE_FROM_CART,
        AppConstants.SELECT_COUPON,
        AppConstants.CLEAR_SELECTED_COUPONS,
        AppConstants.SELECT_PAYMENT_METHOD_FROM_SAVED,
        AppConstants.RECALCULATE_SHOPPING_CART_PRICE,
        updatePaymentCard.type.success,
      ],
      calculatePriceWorker
    ),

    // yield takeLatest([ordersPurchase.type.start, ADD_TO_CART], fraudNetSaga),
    yield takeLatest(
      [addPaymentCard.type.success, addPaymentCardAndSetDefault.type.success],
      hideModalAndSetSelected
    ),
  ]);
}

export default paymentSaga;
