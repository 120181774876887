import { all, call, delay, put, select, take, takeLatest } from 'redux-saga/effects';
import refreshTokenSaga from './refreshTokenSaga';
import paymentSaga from './paymentSaga';
// eslint-disable-next-line import/no-cycle
import tokenSaga from './tokenSaga';
import addonsSaga from './addons';
import liveChatSaga from './liveChatSaga';
// eslint-disable-next-line import/no-cycle
import expectSubscriptionsSaga from './expectSubscriptionsSaga';
import errorSaga from './errorSaga';
import authSaga from './authSaga';

import getToken from '../services/api/actions/getToken';
import getRedirectionToken from '../services/api/actions/getRedirectionToken';
import getWoocommerceToken from '../services/api/actions/getWoocommerceToken';
import signUp from '../services/api/actions/signUp';
import setNotification from '../helpers/notifications';
import {
  preselectCoupon,
  setLoader,
  setPayForFriendMode,
  setUserStartCountry,
} from '../store/actions';

import { selectCart } from '../store/selectors';
import { selectUserCountry } from '../store/selectors/global';
import { INSTANCE_TYPES, REDIRECTION_TYPES, TOKEN_TYPES } from '../constants/types';
import {
  selectEntityUserDetails,
  selectMetaSiteInfo,
  selectRefferalInfo,
} from '../store/selectors/entities';
import {
  getEnvConfig,
  getShopSiteUrlByCountry,
  isClient,
  isUserLogged,
  transformBaseUrl,
} from '../helpers/utils';
import {
  isAddonInstance,
  isRetailAddonInstance,
  isTrivaInstance,
} from '../helpers/instanceHandler';
import { getSearchParam } from '../helpers/url';
import addPaymentCardAndSetDefault from '../services/api/actions/addPaymentCardAndSetDefault';
import renewSubscription from '../services/api/actions/renewSubscription';
import upgradeSubscription from '../services/api/actions/upgradeSubscription';
import recoverEmail from '../services/api/actions/recoverEmail';
import passwordReset from '../services/api/actions/passwordReset';
import forgotUserName from '../services/api/actions/forgotUserName';
import deleteBillAddress from '../services/api/actions/deleteBillAddress';
import updateBillAddress from '../services/api/actions/updateBillAddress';
import addBillAddress from '../services/api/actions/addBillAddress';
import getUserDetails from '../services/api/actions/getUserDetails';
import addPaymentCard from '../services/api/actions/addPaymentCard';
import setPaymentAsDefault from '../services/api/actions/setPaymentAsDefault';
import checkSorUser from '../services/api/actions/checkSorUser';
import removeCard from '../services/api/actions/removePaymentMethod';
import * as AppConstants from '../store/constants';
import { FETCH_REFFERIAL_INFO, SET_PRODUCTS } from '../store/constants';
import createPayment from '../services/api/actions/createPayment';
import getUserRefferal from '../services/api/actions/getUserRefferal';
import getProducts from '../services/api/actions/getProducts';
import getComboProducts from '../services/api/actions/getComboProducts';
import { isLocalStorageAvailable } from '../services/storage';
import validateCardAndAdd from '../services/api/actions/validateCardAndAdd';
import getCouponById from '../services/api/actions/getCouponById';
import getMetaData from '../services/api/actions/getMetaData';
import getUserRefferalSocialNetworks from '../services/api/actions/getUserRefferalSocialNetworks';
import socketSaga from './socketSaga';
import ordersPurchase from '../services/api/actions/ordersPurchase';

const startLoaderActions = [
  signUp.type.start,
  addPaymentCard.type.start,
  getRedirectionToken.type.start,
  getToken.type.start,
  addPaymentCardAndSetDefault.type.start,
  renewSubscription.type.start,
  renewSubscription.type.start,
  upgradeSubscription.type.start,
  recoverEmail.type.start,
  passwordReset.type.start,
  forgotUserName.type.start,
  deleteBillAddress.type.start,
  updateBillAddress.type.start,
  setPaymentAsDefault.type.start,
  removeCard.type.start,
  addBillAddress.type.start,
  createPayment.type.start,
  checkSorUser.type.start,
  validateCardAndAdd.type.start,
  ordersPurchase.type.start,
];

export function* updateSiteData({ customerId, country }) {
  yield put(getMetaData.action());
  yield delay(0);
  const countryCode = country && country.toUpperCase();

  if (customerId || country) {
    let retailId = '';

    if (isAddonInstance && !isRetailAddonInstance) {
      retailId = '10010';
    }

    // eslint-disable-next-line
    const userIdQuery = customerId
      ? `&userId=${customerId}`
      : retailId
      ? `&userId=${retailId}`
      : '';

    const uiCountryQuery = country && country !== 'undefined' ? `uiCountry=${countryCode}` : '';
    const query = !isTrivaInstance ? uiCountryQuery + userIdQuery : '';

    const [products, combo] = yield all([
      yield put(getProducts.withQuery(`?${query}`).action()),
      yield put(getComboProducts.withQuery(`?${query}`).action()),
    ]);

    if (
      products.type === getProducts.type.success &&
      combo.type === getComboProducts.type.success
    ) {
      yield put({
        type: SET_PRODUCTS,
        payload: [...products.payload.data.data, ...combo.payload.data.data],
      });
    }
  }
}

function* fetchRefferalWorker({ payload }) {
  yield put(getUserRefferal.withQuery(`/${payload}`).action());

  while (true) {
    const response = yield take(getUserRefferal.type.success);
    if (response.payload) {
      const { customerId } = response.payload.data;
      yield put(getUserRefferalSocialNetworks.withQuery(`/${customerId}`).action());
      const refData = yield select(selectRefferalInfo) || {};

      const country =
        refData.country && refData.country.length
          ? refData.country
          : process.env.GATSBY_INSTANCE_COUNTRY;
      yield call(updateSiteData, { customerId, country });
    }
  }
}

function* updateUserDetails(action) {
  const { meta, type } = action;
  const unsupportedCardPaypalCountries = ['xk'];
  const userCountry = meta.previousAction.payload.request.data.country;

  yield put(setLoader(false));

  if (
    unsupportedCardPaypalCountries.includes(userCountry) &&
    (type === addBillAddress.type.success || type === updateBillAddress.type.success)
  ) {
    setNotification('info', {
      message:
        "Your billing country doesn't support Card Payments / PayPal. But You will be able to use Bit Coin and E-wallet",
      title: '',
    });
  } else {
    setNotification('success', {
      message: 'Billing address updated',
      title: 'Success',
    });
  }

  yield put(getUserDetails.action());
}

function* showLoader() {
  yield put(setLoader(true));
}

function* checkEnrollAndGetProducts() {
  const refData = yield select(selectRefferalInfo);
  const isEnrollment = isClient && window.location.href.includes('enrollment');
  const tempToken = isClient && getSearchParam('tempTok', window.location.href);
  const beBackOfficeToken = isClient && getSearchParam('Token', window.location.href);
  const userSavedCountry = yield select(selectUserCountry);
  const queryCountry = isClient && getSearchParam('country', window.location.href);

  if (refData && !isEnrollment && !queryCountry) {
    const { customerId } = refData;

    const country =
      refData.country && refData.country.length
        ? refData.country
        : process.env.GATSBY_INSTANCE_COUNTRY;

    yield call(updateSiteData, { country, customerId });
    return;
  }

  if (!isUserLogged() && !tempToken && !beBackOfficeToken) {
    const country = queryCountry || userSavedCountry || process.env.GATSBY_INSTANCE_COUNTRY;
    if (country && country.length) {
      yield call(updateSiteData, { country });
    }

    if (!country && isAddonInstance) {
      yield call(updateSiteData, { country: 'CO' });
    }

    if (queryCountry) {
      yield put(setUserStartCountry(queryCountry));
    }
  }
}

const notify = (message) => {
  setNotification('error', {
    message,
    title: 'Error',
  });
};

function* redirectionWorker(action) {
  const {
    payload: { type, authorized, link },
  } = action;

  let siteUrl = link;
  let tokenType = TOKEN_TYPES.PORTAL;

  const userDetails = yield select(selectEntityUserDetails);
  const region = userDetails?.region;

  // ? FINDING SHOP URL BY COUNTRY
  if (type === REDIRECTION_TYPES.SHOP) {
    siteUrl = transformBaseUrl(region?.defaultBaseUrl);

    if (
      region?.defaultBaseUrl.includes('addonmkt') ||
      region?.defaultBaseUrl.includes('star.one')
    ) {
      const { regions } = yield select(selectMetaSiteInfo);
      const { country } = yield select(selectEntityUserDetails);
      const regionsWithOutAddons = regions?.filter(
        (reg) => reg.code !== 'addonmkt' && reg.code !== 'star'
      );
      const shopUrl = yield call(getShopSiteUrlByCountry, {
        regions: regionsWithOutAddons,
        country,
      });

      siteUrl = transformBaseUrl(shopUrl);
    }

    tokenType = TOKEN_TYPES.SHOP;
  }

  // ? GETTING TOKEN FOR REDIRECTION
  if (isUserLogged() && authorized) {
    yield put(getRedirectionToken.withQuery(`?instanceType=${INSTANCE_TYPES.SHOP}`).action());
    while (true) {
      const request = yield take(getRedirectionToken.type.success);
      const { redirectionToken } = request.payload.data;

      if (type === REDIRECTION_TYPES.WOOCOMMERCE) {
        yield put(getWoocommerceToken.action(redirectionToken));
        const response = yield take(getWoocommerceToken.type.success);
        window.location.href = `${siteUrl}/?${TOKEN_TYPES.WOOCOMMERCE}=${response.payload.data.data}`;
        return;
      }

      if (isAddonInstance && type === REDIRECTION_TYPES.SHOP) {
        window.location.href = `${siteUrl}/pricing?${tokenType}=${redirectionToken}`;
      }

      yield put(setPayForFriendMode(false));
      window.location.href = `${siteUrl}/?${tokenType}=${redirectionToken}`;
      return;
    }
  }

  window.location.href = link;
}

// eslint-disable-next-line consistent-return
function* checkAnAbilityToUseCoupon(action) {
  try {
    const { products } = yield select(selectCart);
    const details = yield select(selectEntityUserDetails);
    const cartPlanInfo = products.map(({ rebillyPlanIds, isRenew, isUpgrade }) => ({
      rebillyPlanIds,
      RENEW: isRenew,
      UPGRADE: isUpgrade,
      INITIAL: !isRenew && !isUpgrade,
    }));

    const {
      planIds: couponPlanIds,
      couponCode,
      forAction,
      elite,
      title,
      usageLeft,
      countries,
      validated,
    } = action.payload;

    const userCountry = details && details.country;

    const isAvailableForUserCountry = countries.includes(userCountry);

    const found =
      !forAction ||
      cartPlanInfo.some(
        (product) =>
          product.rebillyPlanIds.some((id) => couponPlanIds.indexOf(id) >= 0) &&
          product[forAction.toUpperCase()]
      );

    const elitePlan = products.find((item) => item.product.name.toLowerCase() === 'elite');

    if (!products.length) {
      return notify("You don't have any plans in your shopping cart");
    }
    if (!isAvailableForUserCountry) {
      return notify("This coupon can't be used in your country");
    }
    if (products.length > 1 && title === 'FREELIVE') {
      return notify("You couldn't apply this coupon for selected plans");
    }
    if ((!found && products.length) || (elite && !elitePlan)) {
      return notify("You couldn't apply this coupon for selected plans");
    }
    if (usageLeft < 1) {
      return notify('You had exhausted the limit of possible coupon usages.');
    }
    if (found && products.length && validated) {
      yield put(preselectCoupon(couponCode));
      // yield put(selectCoupon(couponCode));
    }

    const [response] = yield all([
      yield put(getCouponById.withQuery(`?code=${couponCode}`).action()),
    ]);

    if (response.type === getCouponById.type.success) {
      yield put(preselectCoupon(response.payload.data.data.couponCode));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}

function* onCountrySelect({ payload }) {
  yield call(updateSiteData, { country: payload });
}

function* onUserDetailsUpdate({ payload }) {
  const { customerId, country } = payload.data.data;
  yield call(updateSiteData, { customerId, country });
}

function* mySaga() {
  if (!isLocalStorageAvailable() || !isClient) {
    return;
  }

  if (isClient) {
    window.getEnvConfig = getEnvConfig;
  }

  yield all([
    refreshTokenSaga(),
    authSaga(),
    expectSubscriptionsSaga(),
    tokenSaga(),
    paymentSaga(),
    errorSaga(),
    checkEnrollAndGetProducts(),
    addonsSaga(),
    socketSaga(),

    yield takeLatest(startLoaderActions, showLoader),
    yield takeLatest(AppConstants.CHECK_COUPON, checkAnAbilityToUseCoupon),
    yield takeLatest(FETCH_REFFERIAL_INFO, fetchRefferalWorker),
    yield takeLatest(AppConstants.UPDATE_PRODUCT_LIST, onCountrySelect),
    yield takeLatest(AppConstants.START_REDIRECTION, redirectionWorker),
    yield takeLatest(getUserDetails.type.success, onUserDetailsUpdate),

    yield takeLatest(
      [deleteBillAddress.type.success, updateBillAddress.type.success, addBillAddress.type.success],
      updateUserDetails
    ),

    liveChatSaga(),
  ]);
}

export default mySaga;
