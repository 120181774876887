import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'gatsby-plugin-intl';
import { useDispatch } from 'react-redux';

import Button from '../button';
import AnnualSubInfo from '../annual-sub-info';
import { isClient } from '../../helpers/utils';
import monthlyIcon from '../../images/pricing/monthly-icon.png';
import Checkbox from '../checkbox';
import Links from '../btc-links';
import { redirectToOtherSite } from '../../store/actions';
import { ButtonLoaderTypes, REDIRECTION_TYPES } from '../../constants/types';
import styles from './checkout-total.module.scss';
import CheckoutTotalCheckbox from '../checkout-total-checkbox';

const CheckoutTotal = ({
  onSubmit,
  cartData,
  showBtcDiscount,
  showInstallmentsButton,
  onInstallmentsPayment,
  showAgreement,
  electronicSignments,
  isPrimaryAddress,
  isPaymentSet,
  shouldSignAgreement,
  coinPaymentDiscount,
  userDetails,
  payForFriendMode,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const checkoutTxt = intl.formatMessage({ id: 'purchase_checkout_total.checkout' });
  const safeAndSecureTxt = intl.formatMessage({
    id: 'purchase_checkout_total.safe_&_secure_payment',
  });
  const [isCheckboxesChecked, setCheckboxes] = useState(false);
  const signAnAgreement = 'Sign an agreement';
  const submitTxt = intl.formatMessage({ id: 'purchase_checkout_total.submit_purchase' });
  const {
    products,
    subTotal,
    productCount,
    processingFee,
    vatAmount,
    totalDiscount,
    btcDiscountTotal,
    btcPriceTotal,
    withDiscount,
  } = cartData || {};

  const recurringPrice = products?.reduce((acc, product) => {
    let accumulator = acc;
    accumulator += product.recurringPrice || 0;
    return accumulator;
  }, 0);

  const hasAnnual = () => products?.some((item) => item.isAnnual);

  const openInNewTab = () => {
    if (electronicSignments?.length) {
      // eslint-disable-next-line no-unused-expressions
      window.open(electronicSignments[0]?.url, '_blank')?.focus();
    }
  };

  const redirectToPortal = () => {
    dispatch(
      redirectToOtherSite({
        type: REDIRECTION_TYPES.PORTAL,
        authorized: true,
        link: `${process.env.GATSBY_PORTAL_LINK}/app/manageSubscription`,
      })
    );
  };
  const total = subTotal + processingFee + vatAmount;
  const isSomeProductAnnual = products.some((p) => p.isAnnual);
  const annualPlanNames = isSomeProductAnnual
    ? products
        .filter((p) => p.isAnnual)
        .map((p) => p.name)
        .join(', ')
    : '';

  const isDisabledBtn =
    (isClient && !productCount) || !isPaymentSet || !isPrimaryAddress || !isCheckboxesChecked;

  const showCouponAppliedDiscount = !showBtcDiscount && totalDiscount > 0;

  return (
    <div className={styles.checkoutTotal}>
      <div className={styles.content}>
        <div className={styles.total}>
          <span className={styles.label}>{`${checkoutTxt} (${productCount} item)`}</span>
          <span className={clsx(styles.cost, 'dont-translate')}>
            ${showBtcDiscount && btcPriceTotal ? btcPriceTotal : (total || 0).toFixed(2)}
          </span>
        </div>
        {isSomeProductAnnual && !payForFriendMode && (
          <AnnualSubInfo annualPlanNames={annualPlanNames} />
        )}
        <div data-browsing-ignore className={styles.items}>
          {products.map((item) => (
            <CheckoutItem
              key={item.id}
              label={item.name}
              price={item.price}
              bgColor={item.bgColor}
              color={item.color}
              icon={item.icon}
              intervalSize={item.intervalSize}
              bgType={`${item.productName}-${item.level}`.toLowerCase()}
              recurrentPrice={item.recurringPrice}
              btcDiscount={showBtcDiscount && item.btcDiscount}
              btcPrice={showBtcDiscount && item.btcPrice}
              isAnnual={item.isAnnual}
              withDiscount={withDiscount}
            />
          ))}
        </div>
        {showBtcDiscount && !!btcDiscountTotal && (
          <div className={styles.fee}>
            <span className={styles.label}>Discount 5%</span>
            <span className={clsx(styles.text, 'dont-translate')}>
              ${btcDiscountTotal?.toFixed(2) || 0}
            </span>
          </div>
        )}
        {showCouponAppliedDiscount && (
          <div className={styles.fee}>
            <span className={styles.label}>Coupon applied:</span>
            <span className={clsx(styles.text, 'dont-translate')}>${totalDiscount.toFixed(2)}</span>
          </div>
        )}
        {vatAmount > 0 && (
          <div className={styles.fee}>
            <span className={styles.label}>Vat:</span>
            <span className={clsx(styles.text, 'dont-translate')}>${vatAmount || 0}</span>
          </div>
        )}
        <div className={styles.fee}>
          <span className={styles.label}>Processing fee:</span>
          <span className={clsx(styles.text, 'dont-translate')}>${processingFee || 0}</span>
        </div>

        <CheckoutTotalCheckbox
          setCheckboxes={setCheckboxes}
          countryCode={userDetails?.country}
          recurringPrice={recurringPrice}
          redirectToPortal={redirectToPortal}
          hasAnnual={hasAnnual()}
        />

        {coinPaymentDiscount?.message && (
          <div className={clsx(styles.fee)}>
            <span className={styles.btcText}>{coinPaymentDiscount?.message}</span>
          </div>
        )}
        <p className={styles.safePayment}>
          <i className="icon-lock" />
          <span>{safeAndSecureTxt}</span>
        </p>

        {shouldSignAgreement ? (
          <Button
            fullWidth
            size="large"
            disabled={isDisabledBtn}
            className={styles.submitButton}
            onClick={showAgreement}
            loaderType={ButtonLoaderTypes.PURCHASE}
          >
            {signAnAgreement}
          </Button>
        ) : (
          <Button
            fullWidth
            size="large"
            disabled={isDisabledBtn}
            loaderType={ButtonLoaderTypes.PURCHASE}
            className={styles.submitButton}
            onClick={onSubmit}
          >
            {submitTxt}
          </Button>
        )}

        {electronicSignments?.length === 1 && (
          <div className={styles.agreement}>
            <Checkbox onChange={() => {}} checked />
            <span>You have signed an agreement.</span>
            <div
              className={styles.agreementLink}
              role="button"
              tabIndex={0}
              onKeyDown={openInNewTab}
              onClick={openInNewTab}
            >
              Show in the new tab
            </div>
          </div>
        )}

        {electronicSignments?.length > 1 &&
          electronicSignments.map((e) => (
            <div className={styles.electronicSignListContainer} key={e.url}>
              <div
                className={styles.agreementLink}
                role="button"
                tabIndex={0}
                onKeyDown={() => window.open(e.url, '_blank')?.focus()}
                onClick={() => window.open(e.url, '_blank')?.focus()}
              >
                Open signed agreement
              </div>
            </div>
          ))}
        {coinPaymentDiscount?.links && (
          <Links cartAmount={subTotal} data={coinPaymentDiscount.links} type="iframe" />
        )}
        {!payForFriendMode && showInstallmentsButton && (
          <div className={styles.dLocalButton}>
            <p className={styles.dLocalDivider}>
              <span>Or</span>
            </p>

            <Button
              fullWidth
              size="large"
              disabled={isDisabledBtn}
              variant="transparent"
              className={styles.submitButton}
              onClick={onInstallmentsPayment}
            >
              <div className={styles.imageButton}>
                <img src={monthlyIcon} alt="" />
                <div>Pay Via Installment</div>
              </div>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

CheckoutTotal.defaultProps = { showBtcDiscount: false, electronicSignments: [] };

CheckoutTotal.propTypes = {
  userDetails: PropTypes.shape({
    country: PropTypes.string.isRequired,
  }).isRequired,
  showBtcDiscount: PropTypes.bool,
  payForFriendMode: PropTypes.bool.isRequired,
  electronicSignments: PropTypes.arrayOf(PropTypes.string),
  showAgreement: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showInstallmentsButton: PropTypes.bool.isRequired,
  onInstallmentsPayment: PropTypes.func.isRequired,
  // showAutoRenewalMessage: PropTypes.bool.isRequired,
  isPrimaryAddress: PropTypes.bool.isRequired,
  isPaymentSet: PropTypes.bool.isRequired,
  shouldSignAgreement: PropTypes.bool.isRequired,
  coinPaymentDiscount: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      links: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  cartData: PropTypes.shape({
    products: PropTypes.arrayOf(
      PropTypes.shape({
        SKU: PropTypes.string,
        currency: PropTypes.string.isRequired,
        cvPoints: PropTypes.number,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        level: PropTypes.string.isRequired,
        levelId: PropTypes.string,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        product: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string.isRequired,
          rebillyProductId: PropTypes.string,
        }),
        productName: PropTypes.string.isRequired,
        rebillyPlanId: PropTypes.string,
        recurringPrice: PropTypes.number.isRequired,
        recurringSKU: PropTypes.string,
        setupPrice: PropTypes.number.isRequired,
      })
    ),
    subTotal: PropTypes.number.isRequired,
    productCount: PropTypes.number.isRequired,
    btcDiscountTotal: PropTypes.number,
    btcPriceTotal: PropTypes.number,
    paymentAction: PropTypes.func,
    processingFee: PropTypes.number,
    shoppingCartProductPrice: PropTypes.number,
    showInitialDiscount: PropTypes.bool,
    totalDiscount: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    vatAmount: PropTypes.number,
  }).isRequired,
};

export default CheckoutTotal;

function CheckoutItem({
  label,
  price,
  recurrentPrice,
  icon,
  bgColor,
  color,
  btcDiscount,
  isAnnual,
  intervalSize,
  withDiscount,
}) {
  const intl = useIntl();

  const toMaintainTxt = intl.formatMessage({ id: 'purchase_checkout_total.to_maintain' });
  const aMonthAfterTxt = intl.formatMessage({ id: 'purchase_checkout_total.a_month_after' });
  const aYearAfterTxt = intl.formatMessage({ id: 'purchase_checkout_total.a_year_after' });
  const oneYearSubscription = intervalSize > 350;
  const recurringText = oneYearSubscription ? aYearAfterTxt : aMonthAfterTxt;
  const btcDiscountMessage =
    'EXCLUSIVE DISCOUNT FOR LIMITED TIME ONLY - 10% Discount for one entire year';

  return (
    <div className={styles.item}>
      <div className={styles.itemImageWrapper} style={{ color, backgroundColor: bgColor }}>
        <i className={`icon-${icon}`} />
      </div>
      <div className={styles.itemContentWrapper}>
        <div className={styles.itemContent}>
          <p className={clsx(styles.itemLabel, 'dont-translate')}>{label}</p>
          {!!btcDiscount && (
            <p className={styles.itemDescription}>
              <span> -5% discount via E-Wallet or BTC</span>
            </p>
          )}
          {!!isAnnual && (
            <p className={styles.itemDescription}>
              <span>{btcDiscountMessage}</span>
            </p>
          )}
          {!!recurrentPrice && !isAnnual && (
            <p className={styles.itemDescription}>
              {`$${recurrentPrice} ${recurringText}`}
              &nbsp;
              <span>{toMaintainTxt}</span>
            </p>
          )}
        </div>
        <div className={styles.priceContainer}>
          {!!btcDiscount && <span className={styles.oldPrice}>{`$${price}`}</span>}
          <span
            className={clsx(styles.itemPrice, withDiscount && styles.lineThrough)}
          >{`$${price}`}</span>
        </div>
      </div>
    </div>
  );
}

CheckoutItem.defaultProps = {
  btcDiscount: 0,
  recurrentPrice: null,
  isAnnual: null,
  withDiscount: false,
};

CheckoutItem.propTypes = {
  label: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  isAnnual: PropTypes.bool,
  intervalSize: PropTypes.number.isRequired,
  withDiscount: PropTypes.bool,
  btcDiscount: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  recurrentPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
};
