/* eslint-disable camelcase */
import _ from 'lodash';
import clsx from 'clsx';
import React, { memo } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import { createStructuredSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { useIntl } from 'gatsby-plugin-intl';
import LogoImage from '../images/logoImage';
import LanguageSelect from '../language-select';
import { pageLinks } from '../../helpers/navigation';
import { isAddonInstance, isOnlineEra } from '../../helpers/instanceHandler';
import { filterMemberShipPages, filterShopPages } from '../../helpers/pages';
import { isClient, getInstanceName } from '../../helpers/utils';
import styles from './footer.module.scss';
import { selectEntityUserDetails } from '../../store/selectors/entities';
import { getCountryData } from '../../helpers/countries';
import checkMobile from '../../hooks/checkMobile';
import { selectProductsNavigationData } from '../../store/selectors';
import { selectPayForFriendMode } from '../../store/selectors/global';

const stateSelector = createStructuredSelector({
  userDetails: selectEntityUserDetails,
  productsNavigation: selectProductsNavigationData,
  payForFriendMode: selectPayForFriendMode,
});

const query = graphql`
  query {
    sgAddressToDelete: file(relativePath: { eq: "footer/sgAddressToDelete.jpg" }) {
      childImageSharp {
        fixed(width: 206, height: 102) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    amex: file(relativePath: { eq: "cards/amex.png" }) {
      childImageSharp {
        fixed(width: 75, height: 26) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    visa: file(relativePath: { eq: "cards/visa.png" }) {
      childImageSharp {
        fixed(width: 65, height: 20) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mastercard: file(relativePath: { eq: "cards/mastercard.png" }) {
      childImageSharp {
        fixed(width: 50, height: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    discover: file(relativePath: { eq: "cards/discover.png" }) {
      childImageSharp {
        fixed(width: 72, height: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    prismic {
      allFooters {
        edges {
          node {
            befreedom {
              address
              address_as_image
            }
            betoinspireprod {
              address
              address_as_image
            }
            onlineline {
              address
              address_as_image
            }
            sgonlineline {
              address
              address_as_image
            }
            euonlineline {
              address
              address_as_image
            }
            rapidchangers {
              address
              address_as_image
            }
            beunleash {
              address
              address_as_image
            }
            br {
              address
              address_as_image
            }
            brbeunleash {
              address
              address_as_image
            }
            fr {
              address
              address_as_image
            }
            gb {
              address
              address_as_image
            }
            onlineera {
              address
              address_as_image
              instagram {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              twitter {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              facebook {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              vimeo {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              youtube {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              pinterest {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
            euonlineera {
              address
              address_as_image
              instagram {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              twitter {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              facebook {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              vimeo {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              youtube {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              pinterest {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
            newparadime {
              address
              address_as_image
              instagram {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              twitter {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              facebook {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              vimeo {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              youtube {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              pinterest {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line no-unused-vars
// let pricingLinks = [
//   {
//     id: 0,
//     name: 'pricing_live',
//     url: `${pageLinks.pricingLive}`,
//   },
//   {
//     id: 1,
//     name: 'pricing_learn',
//     url: `${pageLinks.pricingLearn}`,
//   },
//   {
//     id: 2,
//     name: 'pricing_elite',
//     url: `${pageLinks.pricingElite}`,
//   },
//   {
//     id: 3,
//     name: 'pricing_nitro',
//     url: `${pageLinks.pricingNitro}`,
//   },
// ];

const memberShipLinks = [
  // {
  //   id: 0,
  //   name: 'membership_privileges_travel',
  //   url: pageLinks.membershipTravel,
  // },
  // {
  //   id: 1,
  //   name: 'membership_privileges_learn',
  //   url: pageLinks.membershipLearn,
  // },
  // {
  //   id: 2,
  //   name: 'membership_privileges_lifestyle',
  //   url: pageLinks.membershipLifestyle,
  // },
  // {
  //   id: 3,
  //   name: 'membership_privileges_vip_concierge',
  //   url: pageLinks.membershipConcierge,
  // },
];

const policyLinks = [
  {
    id: 0,
    name: 'privacy_policy',
    url: pageLinks.privacy,
  },
  {
    id: 1,
    name: 'gdpr_policy',
    url: pageLinks.gdpr,
  },
  {
    id: 2,
    name: 'terms_of_use',
    url: pageLinks.terms,
  },
  {
    id: 3,
    name: 'refund_policy',
    url: pageLinks.refund,
  },
];

const aboutUsLinks = [
  // {
  //   id: 0,
  //   name: 'careers',
  //   url: pageLinks.joinUs,
  // },
  // {
  //   id: 1,
  //   name: 'contact_us',
  //   url: pageLinks.contactUs,
  // },
  //
  // {
  //   id: 2,
  //   name: 'affiliate',
  //   url: pageLinks.promote,
  // },
];

const blogLinks = [
  {
    id: 4,
    name: 'careers',
    url: pageLinks.joinUs,
  },
  {
    id: 5,
    name: 'contact_us',
    url: pageLinks.contactUs,
  },

  {
    id: 6,
    name: 'affiliate',
    url: pageLinks.promote,
  },
];

// const socialTypes = {
//   facebook: 'facebook',
//   instagram: 'instagram',
//   youtube: 'youtube',
//   vimeo: 'vimeo',
//   pinterest: 'pinterest',
//   twitter: 'twitter',
// };

// const socialNameTypes = {
//   facebook: 'Facebook',
//   instagram: 'Instagram',
//   youtube: 'YouTube',
//   vimeo: 'Vimeo',
//   pinterest: 'Pinterest',
//   twitter: 'Twitter',
// };

// const defaultSocialLinks = [
//   socialTypes.instagram,
//   socialTypes.facebook,
//   socialTypes.youtube,
//   socialTypes.vimeo,
//   socialTypes.pinterest,
//   socialTypes.twitter,
// ];

const languageWithLargeWords = () => isClient && window.localStorage.getItem('wglang') === 'de';

const Footer = () => {
  const intl = useIntl();
  const isMobile = checkMobile();
  // eslint-disable-next-line no-unused-vars
  // let address = '';
  let addressImage = null;
  const data = useStaticQuery(query);
  const linkState = { modal: true };
  const { userDetails, productsNavigation, payForFriendMode } = useSelector(stateSelector);

  if (!isAddonInstance && data && data.prismic) {
    try {
      addressImage =
        data.prismic.allFooters.edges[0].node[process.env.GATSBY_INSTANCE_NAME.toLowerCase()][0]
          .address_as_image;
    } catch (e) {
      // eslint-disable-next-line
      console.error('ADDRESS_AS_IMAGE_ERROR', e);
    }
  }

  // if (isOnlineEra || isNewParadimeInstance) {
  //   const inst = process.env.GATSBY_INSTANCE_NAME.toLowerCase();
  //   const facebook = data?.prismic?.allFooters?.edges[0]?.node[inst][0]?.facebook?.url;
  //   const instagram = data?.prismic?.allFooters?.edges[0]?.node[inst][0]?.instagram?.url;
  //   const youtube = data?.prismic?.allFooters?.edges[0]?.node[inst][0]?.youtube?.url;
  //   const vimeo = data?.prismic?.allFooters?.edges[0]?.node[inst][0]?.vimeo?.url;
  //   const pinterest = data?.prismic?.allFooters?.edges[0]?.node[inst][0]?.pinterest?.url;
  //   const twitter = data?.prismic?.allFooters?.edges[0]?.node[inst][0]?.twitter?.url;
  //
  //   const links = {
  //     facebook,
  //     instagram,
  //     youtube,
  //     vimeo,
  //     pinterest,
  //     twitter,
  //   };
  //
  //   socialLinks = defaultSocialLinks
  //     .map((social, id) => {
  //       return {
  //         id,
  //         name: socialNameTypes[social],
  //         icon: `icon-${social}`,
  //         url: links[social],
  //       };
  //     })
  //     .filter((socialData) => socialData?.url && socialData);
  // }

  const currentYear = new Date().getFullYear();

  const siteName = isClient && getInstanceName();

  const weAccept = intl.formatMessage({ id: 'weAccept' });

  const renderAddress = () =>
    addressImage ? (
      <div className={styles.addressImageContainer}>
        <img
          className={clsx(styles.addressImg, 'address-img')}
          src={isMobile ? addressImage?.address_as_image_mob?.url : addressImage?.url}
          alt="address"
        />
      </div>
    ) : null;

  function createDocLink(docPath) {
    const countryCode = userDetails && userDetails.country;
    const countryData = getCountryData(countryCode);
    const countryName = countryData ? countryData.name : null;
    if (!countryName) {
      return docPath;
    }
    const searchParams = new URLSearchParams(`country=${countryName}`);
    return `${docPath}?${searchParams.toString()}`;
  }

  const renderLangBlock = () => (
    <LanguageSelect
      invertDark
      id="footerLanguages"
      className={styles.footerLanguage}
      menuPlacement="bottom"
    />
  );

  const isSgInstance = isClient && window?.location?.href?.includes('sg.online-line.com'); // TODO: delete

  return (
    <footer className={clsx(styles.footer)}>
      <div className="container">
        <div className={clsx(styles.wrapper)}>
          <div className={clsx(styles.contactInfo)}>
            <Link to="/" className={clsx(styles.footer_logo)}>
              <LogoImage variant={LogoImage.variant.ONLINE_LINE} />
            </Link>
            <div className={styles.footer_address_wrapper}>
              {isSgInstance ? (
                <div className={styles.addressImageContainer}>
                  <Img
                    className={clsx(styles.addressImg, 'address-img')}
                    fixed={data.sgAddressToDelete.childImageSharp.fixed}
                    alt="address"
                  />
                </div>
              ) : (
                renderAddress()
              )}
            </div>
          </div>

          {!isAddonInstance && !payForFriendMode && (
            <div className={clsx(styles.footerMenu)}>
              <div className={clsx(styles.footerMenu_col)}>
                <Link to="/pricing" className={clsx(styles.footer_link__main)}>
                  {_.upperCase(intl.formatMessage({ id: 'pricing' }))}
                </Link>

                <ul>
                  {productsNavigation.map((item) => (
                    <li key={`${item.urlParam}`}>
                      <Link className={clsx(styles.footer_link)} to={`/pricing/${item.urlParam}`}>
                        {_.upperFirst(item.name)}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className={clsx(styles.footerMenu_col)}>
                <Link
                  to={pageLinks.memberShipPrivileges}
                  className={clsx(styles.footer_link__main)}
                >
                  {_.upperCase(intl.formatMessage({ id: 'membership_privileges' }))}
                </Link>

                <ul>
                  {memberShipLinks.filter(filterMemberShipPages).map((x) => (
                    <li key={x.name}>
                      <Link className={clsx(styles.footer_link)} to={x.url}>
                        {_.upperFirst(intl.formatMessage({ id: x.name }))}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className={clsx(styles.footerMenu_col)}>
                <Link to={pageLinks.aboutUs} className={clsx(styles.footer_link__main)}>
                  {_.upperCase(intl.formatMessage({ id: 'about_us' }))}
                </Link>

                <ul>
                  {aboutUsLinks.filter(filterShopPages).map((item) => (
                    <li key={`${item.id}-${Math.random()}`}>
                      <Link className={clsx(styles.footer_link)} to={item.url}>
                        {_.upperFirst(intl.formatMessage({ id: item.name }))}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              {blogLinks.filter(filterShopPages).map((item) => (
                <div className={clsx(styles.footerMenu_col)} key={`${item.id}-${Math.random()}`}>
                  <Link to={item.url} className={clsx(styles.footer_link__main)}>
                    {_.upperCase(intl.formatMessage({ id: item.name }))}
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>

        <div
          className={clsx(
            styles.policy_wrapper,
            languageWithLargeWords && styles.policy_wrapper_column
          )}
        >
          {policyLinks.map((x) => (
            <Link
              key={JSON.stringify(x)}
              to={createDocLink(x.url)}
              state={linkState}
              className={clsx(styles.policy_link)}
            >
              {_.upperCase(intl.formatMessage({ id: x.name }))}
            </Link>
          ))}
        </div>

        <div className={styles.additional_wrapper}>
          <div className={styles.block_language}>{renderLangBlock()}</div>

          {/*
          <ul className={styles.footer_socials}>
            {(isOnlineEra || isNewParadimeInstance) && (
              <>
                {socialLinks?.map((x) => (
                  <li key={`${x.id}-4`}>
                    <a href={x.url} title={x.name} target="_blank" rel="noopener noreferrer">
                      <i className={x.icon} />
                    </a>
                  </li>
                ))}
              </>
            )}
          </ul> */}

          <div>
            <div className={styles.cardWrapper}>
              <div className={styles.cardWrapperTitle}>{weAccept}</div>
              <div className={styles.cardWrapperImages}>
                <div className={styles.cardWrapperImage}>
                  <Img fixed={data.amex.childImageSharp.fixed} />
                </div>
                <div className={styles.cardWrapperImage}>
                  <Img fixed={data.visa.childImageSharp.fixed} />
                </div>
                <div className={styles.cardWrapperImage}>
                  <Img fixed={data.mastercard.childImageSharp.fixed} />
                </div>
                {isOnlineEra && (
                  <div className={styles.cardWrapperImage}>
                    <Img fixed={data.discover.childImageSharp.fixed} />
                  </div>
                )}
              </div>
            </div>

            <form action="" className={styles.form}>
              {/* <Input
                id="emailSubscribe"
                label={intl.formatMessage({ id: 'subscribe_to_our_newsletter' })}
                placeholder={intl.formatMessage({ id: 'email' })}
                value={footerEmail}
                onChange={onFooterEmailChange}
                wrapperClassName={clsx(styles.footer_input_wrapper)}
                labelClassName={clsx(styles.footer_label)}
                inputClassName={clsx(styles.footer_input)}
               /> */}
            </form>
          </div>
        </div>

        <div className={clsx(styles.reserved)}>
          <span>
            {siteName}
            <sup>TM</sup>
          </span>
          <span>{currentYear}</span>
          <span>{intl.formatMessage({ id: 'all_rights_reserved' })}</span>
          <span>©</span>
        </div>
      </div>
    </footer>
  );
};
export default memo(Footer, () => false);
