import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Banner from '../components/banner';
import MembershipNav from '../components/membership-nav';
import BannerRefferal from '../components/banner-refferal';
import { getInstanceName, logEvent } from '../helpers/utils';

import styles from '../styles/pages/membership.module.scss';

export const query = graphql`
  query {
    dating: file(relativePath: { eq: "membership/learn/dating.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    datingMobile: file(relativePath: { eq: "membership/learn/dating-mobile.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    friends: file(relativePath: { eq: "membership/learn/friends.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    friendsMobile: file(relativePath: { eq: "membership/learn/friends-mobile.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    meeting: file(relativePath: { eq: "membership/learn/meeting.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    meetingMobile: file(relativePath: { eq: "membership/learn/meeting-mobile.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    photo: file(relativePath: { eq: "membership/learn/photo.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    photoMobile: file(relativePath: { eq: "membership/learn/photo-mobile.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    table: file(relativePath: { eq: "membership/learn/table.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tableMobile: file(relativePath: { eq: "membership/learn/table-mobile.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const MembershipLearn = () => {
  const data = useStaticQuery(query);
  const intl = useIntl();

  useEffect(() => logEvent('visited_membership'), []);

  const BeTheBest = intl.formatMessage({ id: 'learn.banner1.BeTheBest' });

  const aPowerfulPracticalLearning = intl.formatMessage({
    id: 'learn.banner2.aPowerfulPracticalLearning',
  });
  const accessibleOnline = intl.formatMessage({ id: 'learn.banner2.accessibleOnline' });

  const personalizedPaths = intl.formatMessage({ id: 'learn.banner3.personalizedPaths' });
  const optimizedTechnical = intl.formatMessage({ id: 'learn.banner3.optimizedTechnical' });

  const support360 = intl.formatMessage({ id: 'learn.banner4.360Support' });
  const expertInstruction = intl.formatMessage({ id: 'learn.banner4.expertInstruction' });
  const structure = intl.formatMessage({ id: 'learn.banner4.structure' });
  const community = intl.formatMessage({ id: 'learn.banner4.community' });

  const ourIltimateGoal = intl.formatMessage({ id: 'learn.banner5.ourIltimateGoal' });
  const creatingAMoreInformed = intl.formatMessage({ id: 'learn.banner5.creatingAMoreInformed' });

  const siteName = getInstanceName();
  const title = intl.formatMessage({ id: 'pageTitle.membershipLearn' });

  return (
    <>
      <SEO title={`${title} - ${siteName}`} />
      <Layout fluid pageWrapperClassName={styles.page_wrapper}>
        <Banner
          isHero
          mobilePositionTopLG
          imageDataMobile={data.friendsMobile.childImageSharp.fluid}
          imageData={data.friends.childImageSharp.fluid}
        >
          <div className="refferalWrapperAbsolute">
            <BannerRefferal />
          </div>

          <h2 className="common_title color_white">{BeTheBest}</h2>
        </Banner>

        <Banner
          contained
          mobileTextHalf
          mobilePositionCenter
          imageDataMobile={data.tableMobile.childImageSharp.fluid}
          imageData={data.table.childImageSharp.fluid}
        >
          <h2 className="common_title color_white">{aPowerfulPracticalLearning}</h2>

          <p className="common_subtitle common_subtitle_sm color_white">{accessibleOnline}</p>
        </Banner>

        <Banner
          mobileAlignCenter
          mobilePositionTopLG
          desktopPositionTop
          shortContentWidth
          imageDataMobile={data.meetingMobile.childImageSharp.fluid}
          imageData={data.meeting.childImageSharp.fluid}
        >
          <h2 className="common_title color_white">{personalizedPaths}</h2>

          <p className="common_subtitle common_subtitle_sm color_white">{optimizedTechnical}</p>
        </Banner>

        <Banner
          mobilePositionCenter
          mobileColorWhite
          imageDataMobile={data.datingMobile.childImageSharp.fluid}
          imageData={data.dating.childImageSharp.fluid}
          desktopPositionRight
        >
          <h2 className="common_title mobile_color_white">{support360}</h2>
          <div className="common_subtitle mobile_color_white">
            <div>• {expertInstruction}</div>
            <div>• {structure}</div>
            <div>• {community}</div>
          </div>
        </Banner>

        <Banner
          mobilePositionTopLG
          imageDataMobile={data.photoMobile.childImageSharp.fluid}
          imageData={data.photo.childImageSharp.fluid}
        >
          <h2 className="common_title color_white">{ourIltimateGoal}</h2>
          <p className="common_subtitle color_white">{creatingAMoreInformed}</p>
        </Banner>

        <MembershipNav />
      </Layout>
    </>
  );
};

export default MembershipLearn;
