import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { createNavigateTo, pageLinks } from '../../helpers/navigation';
import { customText } from './differenseByInstanses';
import Button from '../button';
import Banner from '../banner';

const query = graphql`
  query {
    imageDesktop: file(relativePath: { eq: "home/home-cta-bg-desktop.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const HomeCTA = () => {
  const data = useStaticQuery(query);
  const title = customText?.title;
  const subtitle = customText?.subtitle;
  const button = customText?.button;
  const { imageMobile, imageDesktop } = data;

  return (
    <Banner
      mobilePositionTopLG
      imageDataMobile={imageMobile?.childImageSharp?.fluid}
      imageData={imageDesktop.childImageSharp.fluid}
    >
      <h2 className="common_title color_white">{title}</h2>
      <p className="common_subtitle color_white">{subtitle}</p>
      <Button size="large" color="primary" onClick={createNavigateTo(pageLinks.pricing)}>
        {button}
      </Button>
    </Banner>
  );
};

export default HomeCTA;
