import apiAction from '../apiActionCreator';

const type = 'VALIDATE_CARD_AND_ADD';

const config = {
  url: '/payments/card/validate-and-add',
  actionType: `${type}/VALIDATE_PAYMENT_CARD_AND_ADD`,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
