import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './loader-agreement.module.scss';
import Button from '../button';

const MAX_COUNT_OF_ATTEMPT = 1;
const TIME_BEFORE_REFRESH = 30;
const LoaderAgreement = ({ setAttempt, attempt, completeSigning, show }) => {
  const [timeToRefresh, setTimeToRefresh] = useState(0);
  useEffect(() => {
    let intervalId;
    if (attempt || show) {
      setTimeToRefresh(TIME_BEFORE_REFRESH);
      intervalId = setInterval(() => {
        setTimeToRefresh((t) => (t > 0 ? t - 1 : 0));
      }, 1000);
    }
    return () => {
      setAttempt(0);
      clearInterval(intervalId);
    };
  }, [attempt, show]);

  return (
    <div className={styles.modalLoader}>
      <div className={styles.modalLoaderContent}>
        <div>
          <p>Please bear with us for a minute while we arrange your agreement</p>
        </div>
        {attempt < MAX_COUNT_OF_ATTEMPT && (
          <Button
            disabled={timeToRefresh}
            fullWidth
            size="large"
            className={styles.submitButton}
            onClick={() => {
              setAttempt(attempt + 1);
            }}
          >
            Refresh {timeToRefresh ? ` (${timeToRefresh})` : null}
          </Button>
        )}
        {attempt >= MAX_COUNT_OF_ATTEMPT && (
          <Button
            disabled={timeToRefresh}
            fullWidth
            size="large"
            className={styles.submitButton}
            onClick={completeSigning}
          >
            I will sign later {timeToRefresh ? ` (${timeToRefresh})` : null}
          </Button>
        )}
      </div>
    </div>
  );
};

export default LoaderAgreement;

LoaderAgreement.propTypes = {
  setAttempt: PropTypes.func.isRequired,
  attempt: PropTypes.number.isRequired,
  completeSigning: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
