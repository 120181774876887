import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Img from 'gatsby-image';
import Slider from 'infinite-react-carousel';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

import { pageLinks } from '../../helpers/navigation';

import styles from './home-carousel.module.scss';

import BannerRefferal from '../banner-refferal';

const query = graphql`
  query {
    learn: file(relativePath: { eq: "home/hero-home-learn-desktop.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

// ? add to query to return concierge and travel images

// concierge: file(relativePath: { eq: "home/hero-home-concierge-desktop.png" }) {
//   childImageSharp {
//     fluid(quality: 90, maxWidth: 1920) {
//     ...GatsbyImageSharpFluid_withWebp
//     }
//   }
// }
//
// travel: file(relativePath: { eq: "home/hero-home-travel-desktop.png" }) {
//   childImageSharp {
//     fluid(quality: 90, maxWidth: 1920) {
//     ...GatsbyImageSharpFluid_withWebp
//     }
//   }
// }
// lifestyle: file(relativePath: { eq: "home/hero-home-lifestyle-desktop.jpg" }) {
//   childImageSharp {
//     fluid(quality: 90, maxWidth: 1920) {
//     ...GatsbyImageSharpFluid_withWebp
//     }
//   }
// }

const textContentId = {
  travel: {
    text: 'home_carousel.live_limitless',
    onlineLineText: 'Bespoke experiences',

    buttonText: 'home_carousel.start_your_journey',
    link: pageLinks.membershipTravel,
  },
  learn: {
    text: 'home_carousel.learn_your_power',
    onlineLineText: 'Learn for your future!',
    buttonText: 'home_carousel.charge_your_mind_now',
    link: pageLinks.membershipLearn,
  },
  lifestyle: {
    text: 'home_carousel.your_better_life',
    onlineLineText: 'Savour the future of luxury lifestyle.',
    buttonText: 'home_carousel.enjoy_your_style',
    link: pageLinks.membershipLifestyle,
  },
  concierge: {
    text: 'home_carousel.wished_fulfilled',
    onlineLineText: 'Your absolute convenience',
    buttonText: 'home_carousel.feel_served',
    link: pageLinks.membershipConcierge,
  },
};

const getIconName = (id) => {
  switch (id) {
    case 'travel': {
      return 'icon-compass';
    }
    case 'learn': {
      return 'icon-book-bronze';
    }
    case 'lifestyle': {
      return 'icon-boat-bronze';
    }
    case 'concierge': {
      return 'icon-concierge';
    }
    default: {
      return 'icon-compass';
    }
  }
};

const getControls = () => {
  switch (process.env.GATSBY_INSTANCE_NAME) {
    default: {
      return ['learn'];
    }
  }
};

const controlItems = getControls();

const HomeCarousel = () => {
  const sliderRef = useRef(null);
  const intl = useIntl();
  const [itemId, setItemId] = useState(controlItems[0]);
  const data = useStaticQuery(query);

  const images = controlItems.map((item) => data[item].childImageSharp.fluid);

  const text = textContentId[itemId].onlineLineText;

  // const buttonText = intl.formatMessage({ id: textContentId[itemId].buttonText });
  // const linkTo = textContentId[itemId].link;

  return (
    <div className={clsx(styles.wrapper, styles[itemId])}>
      <Slider
        ref={sliderRef}
        className={styles.slider}
        dotsClass={styles.controls}
        afterChange={(idx) => setItemId(controlItems[idx])}
        arrows={false}
        swipe={false}
      >
        {images.map((image) => (
          <Img key={image.src} fluid={image} className={styles.sliderImage} />
        ))}
      </Slider>

      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <div className="referalHomepage">
            <BannerRefferal />
          </div>
          <div className={styles.text}>
            <h2 className={styles.title}>{text}</h2>
          </div>
          {/* <div className={styles.action}>
            <Button className={styles.actionButton} component={Link} to={linkTo}>
              {buttonText}
            </Button>
          </div> */}
        </div>
      </div>

      <div className={styles.controls}>
        {controlItems.length > 1 &&
          controlItems.map((item) => {
            const id = `home_carousel.${item}`;
            const iconName = getIconName(item);
            const onClick = () => {
              const idx = controlItems.indexOf(item);
              sliderRef.current.slickGoTo(idx);
              setItemId(item);
            };

            return (
              <ControlButton
                key={item}
                iconName={iconName}
                selected={item === itemId}
                text={intl.formatMessage({ id })}
                onClick={onClick}
              />
            );
          })}
      </div>
    </div>
  );
};

export default HomeCarousel;

function ControlButton({ selected, iconName, text, onClick }) {
  return (
    <div
      role="presentation"
      onClick={onClick}
      className={clsx(styles.controlButton, { [styles.selected]: selected })}
    >
      <i className={iconName} />
      <span>{text}</span>
    </div>
  );
}

ControlButton.defaultProps = { selected: false };

ControlButton.propTypes = {
  selected: PropTypes.bool,
  iconName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
