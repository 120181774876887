import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'gatsby-plugin-intl';
import styles from './addon.module.scss';
import { doNotShowRenewalPriceAddons } from '../../helpers/utils';
import coins from '../../images/addons/coins.jpg';
import { isAddonInstance } from '../../helpers/instanceHandler';
import Description from '../addon-description/description';
import { checkSitePage } from '../../helpers/url';
import { pageLinks } from '../../helpers/navigation';

const wsAddons = ['ADD-RET-US30', 'ADD-US30'];

const Addon = React.memo(
  ({
    id,
    description,
    name,
    price,
    disabled,
    onClick,
    isExpired,
    isRenew,
    currentSubscription,
    recurringPrice,
    imageUrl,
    intervalSize,
  }) => {
    const intl = useIntl();

    const addToCartText = intl.formatMessage({ id: 'addons.addToCart' });
    const soonText = intl.formatMessage({ id: 'addons.soon' });
    const luxeDollars = id === 'ADD-QUESTDOLLARS';
    const addonWithOutRenew = doNotShowRenewalPriceAddons.includes(id);

    let buttonText = addToCartText;

    if (isRenew && !addonWithOutRenew) {
      buttonText = 'Renew';
    } else if (disabled) {
      buttonText = soonText;
    }

    const image = imageUrl || coins;

    const oneYearSubscription = intervalSize > 350;

    return (
      <div className={styles.addon}>
        <div>
          <div
            className={clsx(
              styles.headerAddon,
              currentSubscription ? styles.current : '',
              isExpired && !addonWithOutRenew ? styles.expired : ''
            )}
          >
            Your current Add-on
          </div>

          <div className={styles.container}>
            <div className={styles.image}>
              <img src={image} alt="bg" />
            </div>

            <span className={clsx(styles.nameTitle, 'dont-translate')}>{name}</span>

            {oneYearSubscription && (
              <div className={clsx(styles.priceInfo, styles.oneYearSubscription)}>
                <span className={clsx(styles.price, styles.disableMargin)}>
                  365 Days Subscription
                </span>
              </div>
            )}

            {recurringPrice && !luxeDollars && !addonWithOutRenew && !oneYearSubscription && (
              <div className={styles.priceInfo}>
                <span className={styles.name}>Renewal Price</span>
                {price && <span className={styles.price}>{recurringPrice}$</span>}
              </div>
            )}

            {price && addonWithOutRenew && currentSubscription && (
              <div className={styles.priceInfo}>
                <span className={styles.name}>Entry Price</span>
                <span className={styles.entryPrice}>{price}$</span>
              </div>
            )}

            {price && !currentSubscription && (
              <div className={styles.priceInfo}>
                <span className={styles.name}>Entry Price</span>
                <span className={styles.entryPrice}>{price}$</span>
              </div>
            )}
          </div>
          {!wsAddons.includes(id) && (
            <button
              disabled={disabled || wsAddons.includes(id)}
              className={styles.button}
              type="button"
              onClick={onClick}
            >
              {buttonText}
            </button>
          )}

          {wsAddons.includes(id) && !addonWithOutRenew && (
            <button disabled={disabled} className={styles.button} type="button" onClick={onClick}>
              {buttonText}
            </button>
          )}

          {wsAddons.includes(id) && !isRenew && addonWithOutRenew && (
            <button disabled={disabled} className={styles.button} type="button" onClick={onClick}>
              {buttonText}
            </button>
          )}
        </div>

        {(isAddonInstance || checkSitePage(pageLinks.addons)) &&
          description &&
          description.length > 0 && (
            <div className={styles.services}>
              {description.map((item) => (
                <ServiceItem key={item} service={item} color="#000" />
              ))}
            </div>
          )}

        {!isAddonInstance && !checkSitePage(pageLinks.addons) && (
          <Description items={description} color="#000" />
        )}
      </div>
    );
  }
);

Addon.defaultProps = {
  disabled: false,
  onClick: null,
  description: [],
  recurringPrice: '',
  isRenew: false,
  currentSubscription: false,
  isExpired: false,
};

Addon.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  imageUrl: PropTypes.string.isRequired,
  isExpired: PropTypes.bool,
  isRenew: PropTypes.bool,
  currentSubscription: PropTypes.bool,
  recurringPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  intervalSize: PropTypes.number.isRequired,
};

function ServiceItem({ service, color }) {
  return (
    <div className={styles.serviceItem}>
      <i className={clsx('icon-check-rounded', styles.serviceItemIcon)} style={{ color }} />
      <p>{service}</p>
    </div>
  );
}

ServiceItem.propTypes = {
  service: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default Addon;
