import apiAction from '../apiActionCreator';

const type = 'GET_REDIRECTION_TOKEN';

const config = {
  url: '/auth/get-redirection-token',
  actionType: type,
  sendFormat: 'json',
  method: 'GET',
};

export default apiAction(config);
