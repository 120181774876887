import 'rc-tabs/assets/index.css';
import React from 'react';
// import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { countries } from 'countries-list';
import { setUserStartCountry, updateProducts } from '../../store/actions';
import { selectUserCountry } from '../../store/selectors/global';
import LanguageListItem from '../language-list-item';
import styles from './locale-tabs.module.scss';
import { createNavigateTo, pageLinks } from '../../helpers/navigation';
import { forbiddenCountries } from '../../constants';
import { selectCountriesInRegion } from '../../store/selectors/entities';

const getAllCountries = () =>
  Object.keys(countries)
    .map((item) => ({ ...countries[item.toUpperCase()], countryCode: item.toUpperCase() }))
    .filter((item) => !!item);

const LocaleTabs = () => {
  const dispatch = useDispatch();
  const userCountry = useSelector(selectUserCountry);
  const availableCountries = useSelector(selectCountriesInRegion);

  const countryList = availableCountries.length
    ? availableCountries
        .map((item) => ({ ...countries[item.toUpperCase()], countryCode: item.toUpperCase() }))
        .filter((item) => !!item)
    : getAllCountries();

  const allowedCountries = countryList?.filter(
    (item) => !forbiddenCountries.includes(item.countryCode.toLowerCase())
  );

  const onCountrySelect = (countryCode) => {
    dispatch(setUserStartCountry(countryCode));
    dispatch(updateProducts(countryCode));

    createNavigateTo(pageLinks.home)();
  };

  return (
    <div className={styles.tabPaneContent}>
      <ul className={styles.languagesList}>
        {/* eslint-disable-next-line react/prop-types */}
        {allowedCountries.map(({ name, emoji, countryCode }) => (
          <LanguageListItem
            key={countryCode}
            name={name}
            flag={emoji}
            className={styles.languageListItem}
            onCountrySelect={() => onCountrySelect(countryCode)}
            isActive={userCountry === countryCode}
          />
        ))}
      </ul>
    </div>
  );
};

// LocaleTabs.propTypes = {
//   name: PropTypes.string.isRequired,
//   countries: PropTypes.string.isRequired,
//   domainUrl: PropTypes.string.isRequired,
// };

export default LocaleTabs;
