import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Flag from 'react-world-flags';
import { subYears } from 'date-fns';
import moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link, useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import SEO from '../components/seo';
import InputBlockRegistation from '../components/inputBlockRegistation';
import Input from '../components/input';
import InputPassword from '../components/inputPassword';
import InputSelect from '../components/inputSelect';
import DatePicker from '../components/date-picker';
import InputPhone from '../components/input-phone';
import CountrySelect from '../components/country-select';
import Checkbox from '../components/checkbox';
import Button from '../components/button';
import CartItemRegistration from '../components/cart-item-registration';
import CartItemRegistrationTotal from '../components/cart-item-registration-total';
import validateEmail from '../services/api/actions/validateEmail';
import validateUserName from '../services/api/actions/validateUserName';
import { selectCart } from '../store/selectors';
import { selectRegisterValues } from '../store/selectors/global';
import { FETCH_REFFERIAL_INFO } from '../store/constants';
import { selectRefferalInfo, selectCountriesInRegion } from '../store/selectors/entities';
import { saveReferralInfo, startRegistration, updateProducts } from '../store/actions';
import useLiveCheck from '../hooks/useLiveCheck';

import {
  availableLanguages,
  getInstanceName,
  hasCommonWords,
  hasConsecutiveSymbols,
  hasLoginPassSameString,
  hasRequiredSymbols,
  isClient,
  scrollToMiddle,
  checkSearchableSelect,
  logEvent,
  hasRestrictedSymbols,
} from '../helpers/utils';

import { getCountryData } from '../helpers/countries';

import { COMMON_WORDS } from '../constants/words';

import styles from '../styles/pages/registration.module.scss';
import phoneVerify from '../services/api/actions/phoneVerify';
import signUp from '../services/api/actions/signUp';

import { createNavigateTo, pageLinks } from '../helpers/navigation';
import Modal from '../components/modal';

const anyLetterRegExp =
  /^[a-zA-Z\u00C0-\u00FFŸŒ¢]+([a-zA-Z\u00C0-\u00FFŸŒ¢\s'`]?)+[a-zA-Z\u00C0-\u00FFŸŒ¢]+$/;
const anyLetterErrorMessage = 'These characters are not supported';

const today = new Date();
const minDate = new Date('1900-01-01');

const defaultEnrollId = '23039';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required')
    .min(2, 'First Name should contain at least two letters')
    .matches(anyLetterRegExp, anyLetterErrorMessage),

  middleName: Yup.string().matches(anyLetterRegExp, anyLetterErrorMessage),
  lastName: Yup.string()
    .required('Last name is required')
    .min(2, 'Last Name should contain at least two letters')

    .matches(anyLetterRegExp, anyLetterErrorMessage),
  country: Yup.string().required('Country is required'),
  email: Yup.string().email().required('Email is required'),

  birthDate: Yup.date('Date of Birth is required')
    .nullable()
    .typeError("Field shouldn't be empty")
    .required('Date of Birth is required')
    .min(minDate, 'Date of birth cannot be before 1900')
    .max(subYears(today, 18), 'You should be at least 18 years old to continue'),

  phone: Yup.string()
    .matches(/^[0-9]{5,13}$/, 'Must be a valid number')
    .required('Phone is required'),
  language: Yup.string().required('Language is required'),

  loginName: Yup.string()
    .required('Username is required')
    .test('valid', 'Username should have English letters and numbers only', (val = '') => {
      const isEngLetterAndNumbers = RegExp(/[^a-zA-Z0-9]+/g).test(val.replace(/\u200C/g, ''));
      const numbersOnly = RegExp(/[^0-9]+/g).test(val.replace(/\u200C/g, ''));
      return !isEngLetterAndNumbers && numbersOnly;
    })
    .max(50, 'Username must be not more than 50 characters'),

  // .matches(/^[a-zA-Z0-9]+$/, 'username should have English letters and numbers only'),
  loginPassword: Yup.lazy((value) => {
    if (value) {
      return Yup.mixed()
        .test(
          'has-consecutive',
          'should not have 3 consecutive letters or numbers',
          hasConsecutiveSymbols
        )
        .test(
          'has-min-required-characters',
          'should have 1 letter / 1 number / 1 uppercase / 1 special characters',
          hasRequiredSymbols
        )
        .test('is-long-enough', 'minimum 10 characters long', (password) => password.length >= 10)
        .test(
          'common-words',
          `should not use common-words: ${COMMON_WORDS.words.join()}`,
          (password) => hasCommonWords(password, COMMON_WORDS.pattern)
        )
        .test('test-same', 'login and password could not be the same', hasLoginPassSameString)
        .test(
          'unsupported symbols',
          'please use only a-z letters, numbers and special symbols "!$%^&*()_+|~=`{}:;<>?,.@#"',
          hasRestrictedSymbols
        );
    }
    return Yup.mixed().required('Password is required');
  }),

  privacy: Yup.bool().oneOf([true], 'Field must be checked'),
  gdpr: Yup.bool().oneOf([true], 'Field must be checked'),
  terms: Yup.bool().oneOf([true], 'Field must be checked'),
  refund: Yup.bool().oneOf([true], 'Field must be checked'),
});

const initialValues = {
  country: '',
  countryCode: '',
  email: '',
  birthDate: '',
  enrollerId: '',
  firstName: '',
  middleName: '',
  language: '',
  lastName: '',
  loginName: '',
  loginPassword: '',
  phone: '',
  privacy: false,
  gdpr: false,
  terms: false,
  refund: false,
  emailVerified: false,
  placementType: '',
  placementToken: '',
  loginNameVerified: false,
};

const getFlag = (name) => <Flag code={name} width="32" height="16" />;

const options = availableLanguages.map(({ value, label, locale }) => ({
  value,
  label: (
    <div className="inputFlagSelect">
      {getFlag(locale)}
      <span>{label}</span>
    </div>
  ),
}));

const stateSelector = createStructuredSelector({
  cartData: selectCart,
  storedValues: selectRegisterValues,
  availableCountryCodes: selectCountriesInRegion,
});

function createDocLink(docPath, countryName) {
  if (!countryName) {
    return docPath;
  }
  const searchParams = new URLSearchParams(`country=${countryName}`);
  return `${docPath}?${searchParams.toString()}`;
}

const RegistrationPage = () => {
  const linkState = { modal: true };
  const intl = useIntl();
  const dispatch = useDispatch();
  const refferalInfo = useSelector(selectRefferalInfo);
  const { thirdPartyStatuses } = useLiveCheck();

  useEffect(() => logEvent('sign_up'), []);

  const {
    storedValues,
    availableCountryCodes,
    cartData: { products, subTotal, productCount },
  } = useSelector(stateSelector);

  useEffect(() => {
    if (isClient && !productCount) {
      createNavigateTo(pageLinks.pricing)();
    }
  });

  const isRefferalInfoPresent = !!(refferalInfo && refferalInfo.customerId);
  const startedWithReferralLink = !!(refferalInfo && refferalInfo.startedWithLink);

  if (isRefferalInfoPresent) {
    initialValues.enrollerId = refferalInfo.webalias || refferalInfo.customerId;
    initialValues.placementType = refferalInfo.placementType;
    initialValues.placementToken = refferalInfo.placementToken;
  }

  const [savedValues, setSavedValues] = useState(null);
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showModal, toggleModal] = useState(false);
  const [enrollModalConfirmed, confirmEnrollModal] = useState(false);

  const fk = useFormik({
    initialValues:
      savedValues ||
      (storedValues.phone
        ? {
            ...storedValues,
            enrollerId: refferalInfo ? refferalInfo.webalias || '' : '',
            phone: storedValues.phone.replace(`+${getCountryData(storedValues.country).phone}`, ''),
          }
        : null) ||
      initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnBlur: false,
    initialTouched: initialValues,
    onSubmit: async (values) => {
      if (!enrollModalConfirmed && (startedWithReferralLink || !values.enrollerId.length)) {
        toggleModal(true);
        scrollToMiddle('registerModal');
        return;
      }

      if (!values.loginNameVerified) {
        // eslint-disable-next-line
        const isValid = await validateUserNameField();
        if (!isValid) {
          return;
        }
      }

      const phone = values.countryCode
        ? `${values.countryCode}${values.phone}`
        : `+${getCountryData(values.country).phone}${values.phone}`;

      const isTwilioLive = thirdPartyStatuses && thirdPartyStatuses.twilio;

      const isZhCountries = ['cn', 'hk', 'sg', 'tw'].includes(values.country?.toLowerCase());

      if (isZhCountries || values.phone.includes('117788') || !isTwilioLive) {
        // ?WITHOUT TWILIO
        dispatch(
          signUp.action({
            ...values,
            firstName: values.firstName.trim(),
            middleName: values.middleName.trim(),
            lastName: values.lastName.trim(),
            language: Number(values.language),
            loginName: values.loginName.replace(/\u200C/g, ''),
            loginPassword: values.loginPassword.replace(/\u200C/g, ''),
            // eslint-disable-next-line no-nested-ternary
            enrollerId: refferalInfo
              ? refferalInfo.customerId.toString()
              : values.enrollerId.length
              ? values.enrollerId
              : defaultEnrollId,
            phone,
            birthDate: moment(values.birthDate).format('YYYY-MM-DD'),
          })
        );
        return;
      }

      // !WITH TWILIO
      dispatch(
        startRegistration({
          ...values,
          language: Number(values.language),
          // eslint-disable-next-line no-nested-ternary
          enrollerId: refferalInfo
            ? refferalInfo.customerId.toString()
            : values.enrollerId.length
            ? values.enrollerId
            : defaultEnrollId,
          loginName: values.loginName.replace(/\u200C/g, ''),
          loginPassword: values.loginPassword.replace(/\u200C/g, ''),
          phone,
        })
      );

      dispatch(phoneVerify.action({ phone }));
    },
  });

  useEffect(() => {
    if (fk.values.country && fk.errors.country) {
      fk.setFieldError('country', null);
    }
  }, [fk.values.country, fk.errors.country]);

  if (refferalInfo && refferalInfo.error && !fk.errors.enrollerId) {
    fk.setFieldError('enrollerId', 'Please enter correct Referral ID or Referral Name');
  }

  if (refferalInfo && refferalInfo.customerId && fk.errors.enrollerId && !refferalInfo.error) {
    const errors = { ...fk.errors };
    delete errors.enrollerId;
    fk.setErrors(errors);
  }

  const countryData = getCountryData(fk.values.country);
  const countryName = countryData ? countryData.name : '';

  const accHolderFirstNameText = 'Account holder first name';
  const accHolderMiddleNameText = 'Account holder middle name';
  const accHolderLastNameText = 'Account holder last name';
  const welcome = intl.formatMessage({ id: 'welcome' });
  const createAnAccount = intl.formatMessage({ id: 'createAnAccount' });
  const putInYourInformation = intl.formatMessage({ id: 'putInYourInformation' });
  const registeredAlready = intl.formatMessage({ id: 'registeredAlready' });
  const signIn = intl.formatMessage({ id: 'signIn' });
  const refferalIDText = intl.formatMessage({ id: 'refferalID' });
  const refferalIDRefferalNameText = intl.formatMessage({ id: 'refferalID_refferalName' });
  const yourFullNameText = intl.formatMessage({ id: 'yourFullName' });
  const firstNameText = intl.formatMessage({ id: 'firstName' });
  const middleNameText = intl.formatMessage({ id: 'middleName' });
  const lastNameText = intl.formatMessage({ id: 'lastName' });
  const yourCountryText = intl.formatMessage({ id: 'yourCountry' });
  const countryText = intl.formatMessage({ id: 'country' });
  const personalInformation = intl.formatMessage({ id: 'personalInformation' });
  const email = intl.formatMessage({ id: 'email' });
  const dateOfBirthday = intl.formatMessage({ id: 'dateOfBirthday' });
  const phone = intl.formatMessage({ id: 'phone' });
  const phoneNumber = intl.formatMessage({ id: 'phoneNumber' });
  const language = intl.formatMessage({ id: 'language' });
  const createUsernamePassword = intl.formatMessage({ id: 'createUsernamePassword' });
  const yourUsername = intl.formatMessage({ id: 'yourUsername' });
  const createUsername = intl.formatMessage({ id: 'createUsername' });
  const yourPassword = intl.formatMessage({ id: 'yourPassword' });
  const createPassword = intl.formatMessage({ id: 'createPassword' });
  const haveReadUnderstood = intl.formatMessage({ id: 'haveReadUnderstood' });
  const getStarted = intl.formatMessage({ id: 'getStarted' });
  const privacyPolicy = intl.formatMessage({ id: 'privacy_policy' });
  const gdprPolicy = intl.formatMessage({ id: 'gdpr_policy' });
  const termsOfUse = intl.formatMessage({ id: 'terms_of_use' });
  const refundPolicy = intl.formatMessage({ id: 'refund_policy' });
  // const userIcons = <i className="icon-users" />;

  // const submitDisabled = !fk.isValid || emailError.length > 0;
  const submitDisabled = !!emailError.length || !!nameError?.length;

  const handleBlur = (e) => {
    if (!fk.errors[e.target.id] && !fk.values[e.target.id].length) {
      fk.handleBlur(e);
    }
  };

  const onEnrollerFieldBlur = (e) => {
    const value = e.target.value.trim();

    if (value.length) {
      dispatch({ type: FETCH_REFFERIAL_INFO, payload: value });
    } else {
      dispatch(saveReferralInfo(null));
      dispatch(updateProducts(process.env.GATSBY_INSTANCE_COUNTRY));
      if (fk.errors.enrollerId) {
        const errors = { ...fk.errors };
        delete errors.enrollerId;
        fk.setErrors(errors);
      }
    }
    return handleBlur(e);
  };
  const handleFocus = (e) => {
    // Needs to prevent autofill without cursor
    if (fk.isValid || savedValues) {
      return;
    }

    if (e.target.value.trim().length) {
      setSavedValues({
        ...fk.values,
        loginName: fk.values.loginName.trim(),
        loginPassword: fk.values.loginPassword.trim(),
      });
      fk.resetForm(savedValues);
    }
  };

  const validateEmailAddress = async () => {
    if (!fk.errors.email) {
      fk.setFieldTouched('email');
      const responseData = await dispatch(
        validateEmail.withQuery(`?email=${encodeURIComponent(fk.values.email)}`).action()
      );
      const { payload, error } = responseData;

      if (payload && !payload.data.isSameAddress) {
        fk.setFieldValue('emailVerified', true);
        setEmailError('');
        return;
      }
      if (payload && payload.data.isSameAddress) {
        fk.setFieldError('email', 'Email address already taken');
        setEmailError('Email address already taken');
        return;
      }
      if (error) {
        fk.setFieldError('email', responseData.error.response.data.message);
        setEmailError('Email address already taken');
      }
    }
  };

  const validateUserNameField = async () => {
    if (!fk.errors.loginName && fk.values.loginName.length) {
      fk.setFieldTouched('loginName');
      const value = fk.values.loginName.replace(/\u200C/g, '');
      const responseData = await dispatch(validateUserName.action({ username: value }));
      const { payload, error } = responseData;
      if (error) {
        fk.setFieldError('loginName', responseData.error.response.data.message);
        setNameError(responseData.error.response.data.message);
        fk.setFieldValue('loginNameVerified', false);
        return false;
      }
      if (payload) {
        fk.setFieldValue('loginNameVerified', true);
        setNameError('');
        return true;
      }
    }

    if (fk.errors.loginName && fk.values.loginName === '') {
      setNameError('');
    }
    return false;
  };

  const showErrorMessage = (id) =>
    (((fk.values[id] && fk.values[id].length) || fk.touched[id]) && fk.errors[id]) || '';

  // Fixed unsupported regExp on mobile browsers
  const filterSymbols = (e) => {
    e.target.value = e.target.value
      .replace('  ', ' ')
      .replace('``', '`')
      .replace("''", "'")
      .replace("`'", '`')
      .replace("'`", "'");
    return e;
  };

  const lang = options.find((item) => item.value === fk.values.language);

  const renderEnrollModal = () => {
    const buttonText = isRefferalInfoPresent ? 'Confirm' : 'Skip';

    const onConfirmSkipClick = () => {
      confirmEnrollModal(true);
      toggleModal(false);
      fk.handleSubmit();
    };

    const onClose = () => {
      toggleModal(false);
    };

    return (
      <Modal
        isShown={showModal}
        title="Confirmation"
        modalType="enrollModal"
        onCloseClick={onClose}
        idOverlay="registerModal"
        withScroll
      >
        <div className={styles.modalInfo}>
          {isRefferalInfoPresent ? (
            <div className={styles.modalText}>
              <strong> Please confirm the referral information below.</strong>
              <br />
              <br />
              If this is not a correct referral info, please visit the site with the referral link
              again. If you have an issue with a referral link, please contact the person that
              referred you to this Shop.
              <br />
            </div>
          ) : (
            <div className={styles.modalText}>
              <strong> Were you referred by someone ?</strong>
              <br /> You can provide a referral name in the referral information section.
              <br />
              Otherwise, please skip it.
            </div>
          )}

          <div className={styles.inputs}>
            {isRefferalInfoPresent && (
              <>
                <Input
                  id="name"
                  label="Referral full name"
                  placeholder=""
                  value={`${refferalInfo.firstName} ${refferalInfo.lastName}`}
                  fullwidth
                  disabled
                />
                <Input
                  id="enrollerId"
                  label={refferalIDRefferalNameText}
                  placeholder={refferalIDText}
                  onChange={fk.handleChange}
                  value={fk.values.enrollerId}
                  fullwidth
                  disabled
                />
              </>
            )}
          </div>

          <div className={clsx(styles.buttons)}>
            <Button
              className={styles.button}
              size="large"
              variant="outlined"
              defaultWidth
              onClick={onClose}
            >
              Back
            </Button>
            <Button
              className={styles.button}
              size="large"
              defaultWidth
              onClick={onConfirmSkipClick}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const renderForm = () => (
    <form onSubmit={fk.handleSubmit} className={styles.form}>
      <h2 className={clsx(styles.pageTitle)}>{welcome}</h2>
      <div className={clsx(styles.subtitle)}>{createAnAccount}</div>
      <div className={clsx(styles.headerLink)}>
        <p>
          {putInYourInformation}
          <span className={clsx(styles.headerLinkDivider)}>|</span>
          <i className="icon-eye" />
          <span>{registeredAlready}</span>
          <Link to="/login/?isFormOpen=true">{signIn}</Link>
        </p>
      </div>
      <InputBlockRegistation title={yourFullNameText}>
        <div className={clsx(styles.inputsWrapper, styles.inputsWrapper_three_cols)}>
          <Input
            id="firstName"
            label={accHolderFirstNameText}
            placeholder={firstNameText}
            onChange={(e) => fk.handleChange(filterSymbols(e))}
            value={fk.values.firstName}
            onBlur={handleBlur}
            fullwidth
            required
            errorMessage={showErrorMessage('firstName')}
            helper="First name as indicated in your Passport/ID card/Driver license"
          />

          <Input
            id="middleName"
            label={accHolderMiddleNameText}
            placeholder={middleNameText}
            onChange={(e) => fk.handleChange(filterSymbols(e))}
            onBlur={handleBlur}
            value={fk.values.middleName}
            fullwidth
            errorMessage={showErrorMessage('middleName')}
            helper="Middle name as indicated in your Passport/ID card/Driver license"
          />

          <Input
            id="lastName"
            label={accHolderLastNameText}
            placeholder={lastNameText}
            onChange={(e) => fk.handleChange(filterSymbols(e))}
            onBlur={handleBlur}
            value={fk.values.lastName}
            fullwidth
            required
            errorMessage={showErrorMessage('lastName')}
            helper="Last name as indicated in your Passport/ID card/Driver license"
          />
        </div>
      </InputBlockRegistation>

      <InputBlockRegistation
        title={
          <div>
            Referral Information
            {!startedWithReferralLink ? <span className={styles.lowercase}> (optional)</span> : ''}
          </div>
        }
      >
        <div className={clsx(styles.inputsWrapper)}>
          <Input
            id="enrollerId"
            label={refferalIDRefferalNameText}
            placeholder="optional"
            onChange={fk.handleChange}
            value={fk.values.enrollerId}
            onBlur={onEnrollerFieldBlur}
            fullwidth
            disabled={startedWithReferralLink}
            required={false}
            errorMessage={showErrorMessage('enrollerId')}
          />

          {isRefferalInfoPresent && (
            <Input
              id="name"
              label="Referral Full Name"
              placeholder=""
              value={`${refferalInfo.firstName} ${refferalInfo.lastName}`}
              fullwidth
              disabled
              onChange={() => {}}
            />
          )}
        </div>
      </InputBlockRegistation>

      <InputBlockRegistation title={personalInformation}>
        <div className={clsx(styles.inputsWrapper)}>
          <CountrySelect
            id="country"
            value={fk.values.country}
            label={countryText}
            placeholder={yourCountryText}
            onChange={(e) => {
              fk.setFieldValue('country', e.value);
              fk.setFieldValue('countryCode', '');
            }}
            required
            filterKeys={availableCountryCodes}
            isSearchable={checkSearchableSelect()}
            errorMessage={showErrorMessage('country')}
          />

          <Input
            id="email"
            label={email}
            placeholder={email}
            onChange={fk.handleChange}
            onBlur={validateEmailAddress}
            value={fk.values.email}
            fullwidth
            type="email"
            required
            errorMessage={showErrorMessage('email') || emailError}
          />

          <div>
            <InputPhone
              id="phone"
              label={phone}
              placeholder={phoneNumber}
              onChange={fk.handleChange}
              onBlur={handleBlur}
              value={fk.values.phone}
              country={fk.values.country}
              countryCode={fk.values.countryCode}
              setFieldValue={fk.setFieldValue}
              fullwidth
              type="tel"
              required
              errorMessage={showErrorMessage('phone')}
            />
          </div>

          <InputSelect
            id="language"
            label={language}
            options={options}
            placeholder={language}
            defaultValue={lang}
            onChange={(e) => fk.setFieldValue('language', e.value)}
            required
            errorMessage={showErrorMessage('language')}
          />

          <DatePicker
            id="birthDate"
            label={dateOfBirthday}
            value={fk.values.birthDate}
            onChange={(e) => {
              fk.setFieldTouched('birthDate');
              fk.setFieldValue('birthDate', e);
            }}
            required
            errorMessage={showErrorMessage('birthDate')}
            minDate={minDate}
            maxDate={today}
          />
        </div>
      </InputBlockRegistation>

      <InputBlockRegistation title={createUsernamePassword}>
        <div className={clsx(styles.inputsWrapper)}>
          <Input
            id="loginName"
            name="loginName"
            label={createUsername}
            placeholder={yourUsername}
            onChange={(e) => {
              fk.setFieldValue('loginName', e.target.value.trim(), true);
            }}
            onBlur={validateUserNameField}
            value={fk.values.loginName}
            fullwidth
            // icon={fk.values.loginName ? iconChecked : null}
            required
            errorMessage={showErrorMessage('loginName') || nameError}
            onfocus={handleFocus}
          />

          <InputPassword
            id="loginPassword"
            label={yourPassword}
            placeholder={createPassword}
            value={fk.values.loginPassword}
            onChange={(e) => fk.setFieldValue('loginPassword', e.target.value.trim(), true)}
            onBlur={handleBlur}
            fullWidth={false}
            required
            errorMessage={showErrorMessage('loginPassword')}
            onfocus={handleFocus}
          />
        </div>
      </InputBlockRegistation>

      <InputBlockRegistation bordered={false}>
        <div className={clsx(styles.inputsWrapper)}>
          <div>
            <Checkbox
              id="terms"
              checked={fk.values.privacy}
              onChange={() => fk.setFieldValue('privacy', !fk.values.privacy)}
              errorMessage={showErrorMessage('privacy')}
            >
              <div className={clsx(styles.checkboxChildren)}>
                {haveReadUnderstood}
                <Link
                  to={createDocLink('/privacy-policy', countryName)}
                  state={linkState}
                  className={clsx(styles.checkboxLink)}
                >
                  {privacyPolicy}
                </Link>
              </div>
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={fk.values.gdpr}
              onChange={() => fk.setFieldValue('gdpr', !fk.values.gdpr)}
              errorMessage={showErrorMessage('gdpr')}
            >
              <div className={clsx(styles.checkboxChildren)}>
                {haveReadUnderstood}
                <Link
                  to={createDocLink('/gdpr-policy', countryName)}
                  state={linkState}
                  className={clsx(styles.checkboxLink)}
                >
                  {gdprPolicy}
                </Link>
              </div>
            </Checkbox>
          </div>
        </div>
        <div className={clsx(styles.inputsWrapper)}>
          <div>
            <Checkbox
              id="terms"
              checked={fk.values.terms}
              onChange={() => fk.setFieldValue('terms', !fk.values.terms)}
              errorMessage={showErrorMessage('terms')}
            >
              <div className={clsx(styles.checkboxChildren)}>
                {haveReadUnderstood}
                <Link
                  to={createDocLink('/terms-of-use', countryName)}
                  state={linkState}
                  className={clsx(styles.checkboxLink)}
                >
                  {termsOfUse}
                </Link>
              </div>
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={fk.values.refund}
              onChange={() => fk.setFieldValue('refund', !fk.values.refund)}
              errorMessage={showErrorMessage('refund')}
            >
              <div className={clsx(styles.checkboxChildren)}>
                {haveReadUnderstood}
                <Link
                  to={createDocLink('/refund-policy', countryName)}
                  state={linkState}
                  className={clsx(styles.checkboxLink)}
                >
                  {refundPolicy}
                </Link>
              </div>
            </Checkbox>
          </div>
        </div>
      </InputBlockRegistation>

      <InputBlockRegistation bordered={false}>
        <div className={clsx(styles.inputsWrapper)}>
          <Button
            withArrow
            color="primary"
            size="large"
            fullWidth
            type="submit"
            disabled={submitDisabled}
            className={clsx(styles.submitButton)}
          >
            {getStarted}
          </Button>
        </div>
      </InputBlockRegistation>
    </form>
  );

  const renderCart = () => {
    const createYearMonthText = (intervalSize) => (intervalSize > 350 ? 'year' : 'month');

    return (
      <div className={styles.cartItemsWrapper}>
        <CartItemRegistrationTotal number={productCount} price={subTotal} />
        {products.map(
          ({
            name,
            productName,
            level,
            price,
            recurringPrice,
            color,
            bgColor,
            icon,
            intervalSize,
          }) => (
            <CartItemRegistration
              key={`${name}${price}`}
              name={name}
              price={price}
              color={color}
              bgColor={bgColor}
              showRecurringDescription={!!recurringPrice}
              descriptionPrimary={`$${recurringPrice?.toFixed(2)} a ${createYearMonthText(
                intervalSize
              )}  after`}
              description="to maintain your current subscription level"
              icon={icon}
              bgType={`${productName}-${level}`.toLowerCase()}
            />
          )
        )}
      </div>
    );
  };

  const siteName = getInstanceName();
  const title = intl.formatMessage({ id: 'pageTitle.registrationSuccess' });

  return (
    <>
      <SEO title={`${title} - ${siteName}`} />
      <Layout
        pageWrapperClassName={clsx(styles.page_wrapper, 'withBannerHeader')}
        withRefferalBanner={false}
      >
        <div className={styles.wrapper}>
          <div className={styles.formWrapper}>{renderForm()}</div>
          <div>{renderEnrollModal()}</div>
          <div className={styles.cartWrapper}>{renderCart()}</div>
        </div>
      </Layout>
    </>
  );
};

export default RegistrationPage;
