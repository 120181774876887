import apiAction from '../apiActionCreator';

const type = 'FORGOT_USERNAME';

const config = {
  url: '/user/forgot-username',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
