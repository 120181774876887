import React from 'react';
import { useIntl, Link } from 'gatsby-plugin-intl';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { pageLinks } from '../helpers/navigation';

import styles from '../styles/pages/not-found.module.scss';

const NotFoundPage = () => {
  const intl = useIntl();
  const notFound = intl.formatMessage({ id: 'not_found_page.page_not_found' });
  const cannotBeAccessed = intl.formatMessage({ id: 'not_found_page.cannot_be_accessed' });
  const checkURL = intl.formatMessage({ id: 'not_found_page.check_url' });
  const goHome = intl.formatMessage({ id: 'not_found_page.go_home' });

  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="container">
        <div className={styles.pageNotFound}>
          <h2 className={styles.title}>{notFound}</h2>
          <p className={styles.errorCode}>404</p>
          <div className={styles.subTitleWrapper}>
            <p className={styles.subTitle}>{cannotBeAccessed}</p>
            <p className={styles.subTitle}>
              {checkURL}
              &nbsp;
              <Link to={pageLinks.home} className={styles.link}>
                {goHome}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
