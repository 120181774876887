import clsx from 'clsx';

import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { useIntl } from 'gatsby-plugin-intl';

import SEO from '../components/seo';
import Layout from '../components/layout';
import Button from '../components/button';

import { EMAIL_MEDIA, EMAIL_GENERAL } from '../constants';

import styles from '../styles/pages/contact-us.module.scss';
import { getInstanceName, isEuOnlineLine, isOnlineLineInstance } from '../helpers/instanceHandler';
import checkMobile from '../hooks/checkMobile';
import { logEvent } from '../helpers/utils';

export const query = graphql`
  query MyQuery {
    prismic {
      allContactuspages {
        edges {
          node {
            betoinspireprod {
              address
              img
            }
            beunleash {
              address
              img
            }
            onlineline {
              address
              img
            }
            euonlineline {
              address
              img
            }
            fr {
              address
              img
            }
            gb {
              address
              img
            }
            br {
              address
              img
            }
            brbeunleash {
              address
              img
            }
            befreedom {
              address
              img
            }
            newparadime {
              address
              img
            }
            rapidchangers {
              address
              img
            }
          }
        }
      }
    }
  }
`;

const ContactUsPage = () => {
  const intl = useIntl();
  const data = useStaticQuery(query);
  const isMobile = checkMobile();
  const pageTitle = intl.formatMessage({ id: 'contact_us_page.love_to_hear_from_you' });
  const buttonTxt = intl.formatMessage({ id: 'contact_us_page.click_here' });
  const portalLink = process.env.GATSBY_PORTAL_LINK;

  let addressImage = '';
  let address = '';

  useEffect(() => logEvent('visited_contact_us'), []);

  if (process.env.GATSBY_INSTANCE_NAME && data) {
    const prismicData =
      data.prismic.allContactuspages.edges[0].node[
        process.env.GATSBY_INSTANCE_NAME.toLowerCase()
        // eslint-disable-next-line dot-notation
      ] || data.prismic.allContactuspages.edges[0].node['onlineline'];

    const text = prismicData[0].address;
    addressImage = prismicData[0].img;
    address = RichText.render(text);
  }

  const cards = [
    {
      icon: 'icon-help',
      title: intl.formatMessage({ id: 'contact_us_page.access_our_vip' }),
      subtitle: intl.formatMessage({ id: 'contact_us_page.get_instant_help' }),
      color: 'purple',
      link: portalLink,
    },
    {
      icon: 'icon-media',
      title: intl.formatMessage({ id: 'contact_us_page.media_press' }),
      subtitle: intl.formatMessage({ id: 'contact_us_page.contact_a_member' }),
      color: 'blue',
      link: `mailto:${EMAIL_MEDIA}`,
    },
    {
      icon: 'icon-mail',
      title: intl.formatMessage({ id: 'contact_us_page.general_inquiries' }),
      subtitle: intl.formatMessage({ id: 'contact_us_page.someone_from_our_team' }),
      color: 'cyan',
      link: `mailto:${EMAIL_GENERAL}`,
    },
  ];

  const siteName = getInstanceName();
  const title = intl.formatMessage({ id: 'pageTitle.contactUs' });

  return (
    <>
      <SEO title={`${title} - ${siteName}`} />
      <Layout fluid>
        <div className="container">
          <div className={styles.contactUs}>
            <h2 className={styles.title}>{pageTitle}</h2>
            {/* {address && <div className={styles.addressContainer}>{address}</div>} */}
            {(!isOnlineLineInstance || isEuOnlineLine) && addressImage && (
              <div className={styles.addressImage}>
                <img
                  className="address-img"
                  src={isMobile ? addressImage?.imgmob?.url : addressImage?.url}
                  alt="address"
                />
              </div>
            )}
            <div className={clsx(styles.cards, address ? {} : styles.cardSpace)}>
              {cards.map((card) => (
                <div key={card.title} className={styles.card}>
                  <div>
                    <i className={clsx(card.icon, styles.icon, styles[card.color])} />
                  </div>
                  <h3 className={styles.cardTitle}>{card.title}</h3>
                  <p className={styles.cardSubtitle}>{card.subtitle}</p>
                  <Button
                    fullWidth
                    size="large"
                    component="a"
                    href={card.link}
                    className={clsx(styles.link, styles[card.color])}
                  >
                    {buttonTxt}
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ContactUsPage;
