import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import styles from './banner-refferal-header.module.scss';
import { SocialIconsLibrary } from '../banner-refferal/constants';

const BannerRefferalHeader = ({ customerId, firstName, lastName, email, social }) => {
  const id = customerId;
  const fullName = `${firstName} ${lastName}`;

  return (
    <div className={styles.bannerWrapper}>
      <section className={styles.userInfo}>
        <div className={styles.userTitle}>
          <span className={styles.userInfoLabel}>Your refere:</span>
          <span className={styles.userName}>{fullName}</span>
        </div>

        <span className={styles.verticalDivider} />

        <div className={styles.userInfoSection}>
          <span className={styles.userInfoLabel}>ID:</span>
          <span className={styles.userInfoText}>{id}</span>
        </div>

        <div className={styles.userInfoSection}>
          <span className={styles.userInfoLabel}>Email:</span>
          <span className={styles.userInfoText}>
            <a href={email} target="_blank" rel="noopener noreferrer">
              {email}
            </a>
          </span>
        </div>

        <div className={styles.userInfoSection}>
          <span className={clsx(styles.userInfoLabel, styles.black)}>Social:</span>

          {social.map(({ name, url }) => {
            if (SocialIconsLibrary[name.toLowerCase()]) {
              return (
                <span key={name} className={styles.userInfoIcon}>
                  <a href={url} title={name} target="_blank" rel="noopener noreferrer">
                    {SocialIconsLibrary[name.toLowerCase()]}
                  </a>
                </span>
              );
            }
            return null;
          })}
        </div>
      </section>
    </div>
  );
};

BannerRefferalHeader.defaultProps = {
  social: [],
};
BannerRefferalHeader.propTypes = {
  customerId: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  social: PropTypes.arrayOf(PropTypes.object),
};

export default BannerRefferalHeader;
