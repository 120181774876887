import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { selectEntityUserDetails } from '../src/store/selectors/entities';
import { isClient } from '../src/helpers/utils';

export const useSift = () => {
  const stateSelector = createStructuredSelector({
    userDetails: selectEntityUserDetails,
  });
  const { userDetails } = useSelector(stateSelector);
  const id = userDetails && userDetails.customerId;
  const session = id ? `${id}-${moment().format('YYYY-DD-MM')}` : '';
  const beaconKey = process.env.GATSBY_SIFT_BEACON_KEY;

  if (isClient) {
    // eslint-disable-next-line no-underscore-dangle,no-multi-assign
    const _sift = window._sift || [];
    _sift.push(['_setAccount', beaconKey]);
    _sift.push(['_setUserId', id]);
    _sift.push(['_setSessionId', session]);
    _sift.push(['_trackPageview']);
  }
};
