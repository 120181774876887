import apiAction from '../apiActionCreator';

const type = 'CHECK_IF_USER_EXIST_IN_SOR';

const config = {
  url: '/auth/check-user-status',
  actionType: type,
  sendFormat: 'json',
  method: 'GET',
};

export default apiAction(config);
