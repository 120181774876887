export const hideCrispChat = () => {
  const crispChatElement = document.getElementsByClassName('crisp-client');
  const liveChatElement = document.getElementById('chat-widget-container');

  if (crispChatElement?.length) {
    Array.from(crispChatElement).forEach((element) => {
      // eslint-disable-next-line no-param-reassign
      element.style.display = 'none';
    });
  }
  if (liveChatElement) {
    liveChatElement.style.display = 'block';
  }
};

export const showCrispChat = () => {
  const crispChatElement = document.getElementsByClassName('crisp-client');
  const liveChatElement = document.getElementById('chat-widget-container');

  if (crispChatElement?.length) {
    Array.from(crispChatElement).forEach((element) => {
      if (element.style.display === 'none') {
        // eslint-disable-next-line no-param-reassign
        element.style.display = 'block';
      }
    });
  }
  if (liveChatElement) {
    liveChatElement.style.display = 'none';
  }
};

export const getLiveChatParams = (userDetails, subscriptions) => {
  if (!userDetails) {
    return null;
  }

  const roles = userDetails.roles?.map((role) => role.name)?.join(', ') || '-';
  const subs = subscriptions?.map((subscription) => subscription.level)?.join(', ') || '-';

  return [
    { name: 'Exigo ID', value: userDetails.customerId },
    { name: 'Legacy Rank', value: roles },
    { name: 'Enroller ID', value: userDetails?.enroller?.customerId || '-' },
    { name: 'Country Code', value: userDetails.country },
    { name: 'Email Address', value: userDetails.email },
    { name: 'Language', value: userDetails.languageId },
    { name: 'Existing Rebilly subscription', value: subs },
  ];
};
