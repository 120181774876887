const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 52,
    minHeight: 52,
    borderWidth: 1,
    borderColor: `${state.selectProps.errorMessage ? 'red' : '#96929a'} !important`,
    borderRadius: 3,
    boxShadow: 'none',
    paddingLeft: 16,
    pointerEvents: state.selectProps.isDisabled ? 'none' : 'auto',
    cursor: 'pointer',
    backgroundColor: 'transparent',
  }),

  indicatorSeparator: () => ({}),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: 15,
    color: '#11031c',
    padding: 0,
    outline: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#5e5e5e',
  }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    paddingLeft: 20,
    height: 50,
    cursor: 'pointer',
    transition: '.15s ease-in-out',
    color: '#11031c',
    backgroundColor: state.isSelected ? '#dedede' : '#fff',

    '&:hover': { backgroundColor: '#f7f7f7', color: '#11031c' },
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: state.selectProps.isDisabled ? '#96929a' : '#11031c',
  }),
};

export default customStyles;
