import clsx from 'clsx';

import React from 'react';
import PropTypes from 'prop-types';

import styles from './loginBlock.module.scss';

const LoginBlock = (props) => {
  const { title, isActive, onClick, children } = props;

  return (
    <div className={clsx(styles.wrapper, isActive ? styles.activeWrapper : null)}>
      <button className={clsx(styles.title)} type="button" onClick={onClick}>
        <i className={clsx(styles.circle)} />
        <span>{title}</span>

        {/* <div className={clsx(styles.info)}>
          <i className="icon-info" />
        </div> */}
      </button>

      {isActive && <div>{children}</div>}
    </div>
  );
};

LoginBlock.defaultProps = { isActive: false };

LoginBlock.propTypes = {
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LoginBlock;
