import clsx from 'clsx';

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import PropTypes from 'prop-types';

import { useIntl } from 'gatsby-plugin-intl';

import styles from './billing-payment-block-card.module.scss';

const query = graphql`
  query {
    visa: file(relativePath: { eq: "payment/visa-pic.png" }) {
      childImageSharp {
        fixed(width: 38) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mastercard: file(relativePath: { eq: "payment/mastercard-pic.png" }) {
      childImageSharp {
        fixed(width: 38) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    amex: file(relativePath: { eq: "payment/amex-pic.png" }) {
      childImageSharp {
        fixed(width: 38) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ach: file(relativePath: { eq: "payment/ach.png" }) {
      childImageSharp {
        fixed(width: 38) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    astropaycard: file(relativePath: { eq: "payment/astropay.jpg" }) {
      childImageSharp {
        fixed(width: 40, height: 24) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    paymentcard: file(relativePath: { eq: "payment/card-pic.png" }) {
      childImageSharp {
        fixed(width: 38) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const BillingPaymentBlockCard = ({ brand, last4, isDefault, selected, wrapperClassName }) => {
  const intl = useIntl();
  const data = useStaticQuery(query);

  const defaultText = intl.formatMessage({ id: 'button.default' });

  const renderBrand = () => {
    const brandImage = brand.replace(' ', '').replace('-', '').toLowerCase();

    const imgSrc = data[brandImage] && data[brandImage].childImageSharp.fixed;

    if (imgSrc) {
      return <Img fixed={imgSrc} />;
    }

    return data.card ? <Img fixed={data.card.childImageSharp.fixed} /> : <div />;
  };

  return (
    <div
      className={clsx(
        styles.button,
        selected && styles.selected,
        wrapperClassName && wrapperClassName
      )}
    >
      <div className={styles.content}>
        <div className={styles.imgWrapper}>{data && renderBrand(brand)}</div>
        <div className={styles.info}>
          <p>{brand || 'Credit card'}</p>

          <p>**** **** **** {last4}</p>
        </div>
        <div className="color_primary">{isDefault ? defaultText : ''} </div>
      </div>
    </div>
  );
};

BillingPaymentBlockCard.propTypes = {
  brand: PropTypes.string,
  last4: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isDefault: PropTypes.bool.isRequired,
  selected: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

BillingPaymentBlockCard.defaultProps = {
  brand: null,
  selected: true,
  wrapperClassName: null,
};

export default BillingPaymentBlockCard;
