import React from 'react';
import PropTypes from 'prop-types';

import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styles from './banner-bitcoin-discount-header.module.scss';

const strings = {
  offer: 'special offer',
  pay: 'Pay with BTC or e-Wallet to get 5% Discount!',
};

const query = graphql`
  query {
    btc: file(relativePath: { eq: "payment/btc.png" }) {
      childImageSharp {
        fixed(width: 110) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    close: file(relativePath: { eq: "payment/close-banner.png" }) {
      childImageSharp {
        fixed(width: 15) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const BannerBitcoinDiscountHeader = ({ close }) => {
  const data = useStaticQuery(query);

  const btcImage = data.btc && data.btc.childImageSharp.fixed;
  const closeImg = data.close && data.close.childImageSharp.fixed;

  return (
    <div className={styles.bannerWrapper}>
      <div className={styles.bannerContainer}>
        <span className={styles.offer}>{strings.offer}</span>
        <span className={styles.pay}>{strings.pay}</span>
        {btcImage && (
          <div className={styles.btcImage}>
            <Img fixed={btcImage} />
          </div>
        )}
      </div>
      {closeImg && (
        <div className={styles.close} onClick={close} onKeyDown={close} role="button" tabIndex={0}>
          <Img fixed={closeImg} />
        </div>
      )}
    </div>
  );
};

BannerBitcoinDiscountHeader.propTypes = {
  close: PropTypes.func.isRequired,
};

export default BannerBitcoinDiscountHeader;
