export const drawSideLines = (pdf) => {
  if (!pdf) {
    return null;
  }
  // yellow line left
  pdf.setFillColor(255, 240, 72);
  pdf.rect(0, 0, 10, 500, 'F');
  pdf.circle(5, 500, 5, 'F');
  pdf.triangle(0, 500, 8, 504, 0, 510, 'F');

  // purple 1 line left
  pdf.setFillColor(161, 8, 181);
  pdf.rect(0, 0, 20, 420, 'F');
  pdf.circle(10, 420, 10, 'F');
  pdf.triangle(0, 420, 17, 427, 0, 440, 'F');

  // purple 2 line left
  pdf.setFillColor(97, 17, 171);
  pdf.rect(0, 0, 30, 270, 'F');
  pdf.circle(20, 270, 10, 'F');
  // pdf.setFillColor(97, 17, 100);
  pdf.triangle(0, 250, 26, 278, 0, 300, 'F');

  // yellow line right
  pdf.setFillColor(255, 240, 72);
  pdf.rect(430, 100, 10, 500, 'F');
  pdf.circle(435, 100, 5, 'F');
  // TODO: fix triangle
  pdf.triangle(433, 95.5, 450, 92, 450, 105, 'F');

  // purple 1 line right
  pdf.setFillColor(161, 8, 181);
  pdf.rect(420, 185, 20, 420, 'F');
  pdf.circle(430, 185, 10, 'F');
  // TODO: fix triangle
  pdf.triangle(427, 175.5, 450, 168, 450, 190, 'F');

  // purple 2 line left
  pdf.setFillColor(97, 17, 171);
  pdf.rect(410, 330, 30, 270, 'F');
  pdf.circle(420, 330, 10, 'F');
  // TODO: fix triangle
  pdf.triangle(415, 321.5, 500, 266, 500, 370, 'F');

  pdf.setFillColor(93, 1, 162);
  pdf.rect(550, 750, 10, 200, 'F');

  return null;
};

export const getCompanyName = (paymentMethod) => {
  switch (paymentMethod) {
    case 'AmazonPay':
      return 'MWC Living LLC';
    case 'paynote':
      return 'Uplift LLC';
    case 'payULatam':
    case 'astroPay card':
    case 'astroPay':
      return 'Better Experience DMCC';
    case 'paytabs':
    case 'ccavenue':
    case 'dlocal':
    case 'ngenius':
    case 'rapyd':
    case 'rapyd-cash':
      return 'Index Standard DMCC';
    default:
      return 'BEFREE';
  }
};
