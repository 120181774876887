import { isClient } from '../src/helpers/utils';

const createCrispChat = () => {
  if (isClient) {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = 'b14f26a1-77d0-428b-8ac7-e7f7a9faeafd';
    const d = document;
    const s = d.createElement('script');
    s.src = 'https://client.crisp.chat/l.js';
    s.async = 1;
    d.getElementsByTagName('head')[0].appendChild(s);
  }
};

export default createCrispChat;
