import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'gatsby-plugin-intl';

import styles from './cart-item.module.scss';

const CartItem = ({
  name,
  price,
  icon,
  selected,
  onDelete,
  recurrentPrice,
  color,
  bgColor,
  intervalSize,
}) => {
  const intl = useIntl();
  const itemStr = intl.formatMessage({ id: 'item' });
  const priceStr = intl.formatMessage({ id: 'price' });
  const deleteStr = intl.formatMessage({ id: 'delete' });
  const aMonthAfterTxt = intl.formatMessage({ id: 'purchase_checkout_total.a_month_after' });
  const aYearAfterTxt = intl.formatMessage({ id: 'purchase_checkout_total.a_year_after' });
  const toMaintainTxt = intl.formatMessage({ id: 'purchase_checkout_total.to_maintain' });

  const oneYearSubscription = intervalSize > 350;

  const recurringText = oneYearSubscription ? aYearAfterTxt : aMonthAfterTxt;

  return (
    <li className={clsx(styles.item, { [styles.selected]: selected })}>
      <div className={styles.info}>
        <div className={styles.image} style={{ color, backgroundColor: bgColor }}>
          <i className={`icon-${icon}`} style={{ color, backgroundColor: bgColor }} />
        </div>
        <div className={styles.description}>
          <p className={styles.nameHeader}>{`${itemStr}:`}</p>
          <p className={styles.name}>{name}</p>
          {!!recurrentPrice && (
            <p className={styles.itemDescription}>
              {`$${recurrentPrice} ${recurringText}`}
              &nbsp;
              <span>{toMaintainTxt}</span>
            </p>
          )}
        </div>
      </div>

      <div className={styles.cost}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className={styles.action} onClick={onDelete}>
          <i className={clsx('icon-trash-bin', styles.deleteIcon)} />
          <span>{deleteStr}</span>
        </div>
        <div className={styles.summary}>
          <p className={styles.priceHeader}>{`${priceStr}:`}</p>
          <span className={styles.price}>{`$${price}`}</span>
        </div>
      </div>
    </li>
  );
};

CartItem.defaultProps = { selected: false, recurrentPrice: null };

CartItem.propTypes = {
  selected: PropTypes.bool,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  recurrentPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
  icon: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  intervalSize: PropTypes.number.isRequired,
};

export default CartItem;
