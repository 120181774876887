const cache = {};

function set(key, value) {
  cache[key] = value;
}

function get(key) {
  return cache[key];
}

function getAll() {
  return Object.values(cache);
}

function remove(key) {
  // eslint-disable-next-line no-prototype-builtins
  const flag = cache.hasOwnProperty(key);
  if (flag) {
    delete cache[key];
  }
  return flag;
}

function clear() {
  Object.keys(cache).forEach((key) => {
    delete cache[key];
  });
}

function size() {
  return Object.values(cache).length;
}

export default {
  get,
  set,
  remove,
  clear,
  size,
  getAll,
};
