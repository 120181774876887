import React, { useRef, useState } from 'react';
import PropTypes, { shape } from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'gatsby-plugin-intl';
import { withFramePayCardComponent } from '@rebilly/framepay-react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Loader from '../loader';
import setNotification from '../../helpers/notifications';
import { isClient, normalizeLatinLetters } from '../../helpers/utils';
import Button from '../button';
import BillingAddressFormV2 from '../billing-address-form-v2';
import styles from './preauthorize-form.module.scss';
import InputRadio from '../input-radio';
import BillingAddressBlock from '../billing-address-block';
import AddCartItem from '../add-cart-item';
import { selectBillingAddresses } from '../../store/selectors';
import { selectEntityUserDetails } from '../../store/selectors/entities';
import { selectModalError } from '../../store/selectors/global';
import { getCountryData } from '../../helpers/countries';
import addBillAddress from '../../services/api/actions/addBillAddress';

const strings = {
  title: 'Billing Address',
  addAddress: 'Add Address',
  submit: 'Submit',
  next: 'Next',
  superImportant:
    'Your Privacy is super important to us - we’ll only use your information as described in our',
  termsOfUse: 'terms of use',
  and: 'and',
  privacyPolicy: 'Privacy Policy.',
};

const stateSelector = createStructuredSelector({
  billingAddresses: selectBillingAddresses,
  modalError: selectModalError,
  userDetails: selectEntityUserDetails,
});

const filterValidAddress = (array) =>
  array.filter((item) => item.city && item.address && item.zipCode);

const PreauthorizeFormCard = ({
  onReceiveToken,
  Rebilly,
  CardNumberElement,
  CardCvvElement,
  CardExpiryElement,
  step,
  setStep,
  steps,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const formRef = useRef(null);

  const { billingAddresses, userDetails } = useSelector(stateSelector);

  const primaryAddress = billingAddresses.map((address) => !!address.primary);

  const [selectedAddress, selectAddress] = useState(primaryAddress);

  const [cardExpiryValid, setCardExpiryState] = useState(false);

  const [cardNumberValid, setCardNumberState] = useState(false);

  const [cardCvvValid, setCardCvvState] = useState(false);

  const showAddressList = () => setStep(steps.ADDRESSES);
  const showAddressForm = () => setStep(steps.ADD_ADDRESS);

  const [focusedItem, setFocused] = useState('');
  const [loading, setLoading] = useState(true);

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { address, city, country, zipCode, firstName, lastName, address2, phone, state } =
      selectedAddress || {};

    Rebilly.createToken(formRef.current, {
      billingAddress: {
        firstName: normalizeLatinLetters(firstName),
        lastName: normalizeLatinLetters(lastName),
        // ? Kosovo check and replace with Serbia
        country: country.toUpperCase() === 'XK' ? 'RS' : country.toUpperCase(),
        city,
        address,
        address2,
        postalCode: zipCode,
        region: state || '',
        emails: [
          {
            label: 'main',
            value: userDetails.email || '',
            primary: true,
          },
        ],
        phoneNumbers: [
          {
            label: 'main',
            value: phone || userDetails.phone,
            primary: true,
          },
        ],
      },
    })
      .then((data) => {
        onReceiveToken({ ...data });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        setNotification('error', {
          message: err.details && err.details.length && err.details[0],
          title: 'Error',
        });
      });
  };

  const saveAddress = (values) => {
    const data = {
      ...values,
      phone: `+${getCountryData(values.country).phone}${values.phone}`,
      primary: true,
    };

    dispatch(addBillAddress.action(data));
  };

  const isFocused = (field) => (focusedItem === field ? styles.focused : null);

  const cardLabel = intl.formatMessage({ id: 'purchase_checkout_form.card_number' });
  const addAddress = intl.formatMessage({ id: 'purchase_checkout.addAddress' });
  const isFormValid = cardExpiryValid && cardNumberValid && cardCvvValid;

  return (
    <div className={styles.checkoutForm}>
      <>
        <div style={{ display: step === steps.CARD ? 'block' : 'none' }}>
          <form ref={formRef}>
            <div className={styles.cardInfo}>
              <div className={styles.row}>
                <div className={clsx(styles.rebillyFramepay, isFocused('name'))}>
                  <span className="basicLabel">{cardLabel}</span>

                  <CardNumberElement
                    onBlur={() => setFocused('')}
                    onFocus={() => setFocused('name')}
                    onReady={() => setLoading(false)}
                    onChange={(e) => setCardNumberState(!e.error && e.completed)}
                  />
                  {loading && (
                    <div className={styles.loader}>
                      <Loader isLoading isSmall />
                    </div>
                  )}
                </div>

                <div
                  className={clsx(styles.rebillyFramepay, styles.noPadding, isFocused('exp-date'))}
                >
                  <span className="basicLabel">Expiration Date</span>
                  <CardExpiryElement
                    onBlur={() => setFocused('')}
                    onFocus={() => setFocused('exp-date')}
                    onChange={(e) => setCardExpiryState(!e.error)}
                  />
                  {loading && (
                    <div className={styles.loader}>
                      <Loader isLoading isSmall />
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.row}>
                <div className={clsx(styles.rebillyFramepay, isFocused('cvv'))}>
                  <span className="basicLabel">CVV</span>
                  <CardCvvElement
                    onBlur={() => setFocused('')}
                    onFocus={() => setFocused('cvv')}
                    onChange={(e) => setCardCvvState(!e.error)}
                  />
                  {loading && (
                    <div className={styles.loader}>
                      <Loader isLoading isSmall />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
          <div className={styles.btnContainer}>
            <Button
              fullWidth
              size="large"
              disabled={!isFormValid}
              className={styles.submitButton}
              onClick={showAddressList}
            >
              {strings.next}
            </Button>
          </div>
        </div>
      </>

      {step === steps.ADD_ADDRESS && (
        <div className={styles.preAuth}>
          <div className={styles.addressForm}>
            <BillingAddressFormV2
              onAddBillingAddress={(values) => {
                saveAddress(values);
                setStep(steps.ADDRESSES);
              }}
              moveStepBack={() => setStep(steps.ADDRESSES)}
            />
          </div>
        </div>
      )}

      {step === steps.ADDRESSES && (
        <>
          <div>
            {filterValidAddress(billingAddresses).map((addressItem) => {
              const checked =
                selectedAddress?.id === addressItem.id || (!selectedAddress && addressItem.primary);
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              return (
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => selectAddress(addressItem)}
                  className={clsx(styles.address, styles.addAddressWrapper)}
                  key={addressItem.id}
                  onClick={() => selectAddress(addressItem)}
                >
                  <InputRadio
                    className={styles.radio}
                    checked={checked}
                    value="address"
                    onChange={() => {}}
                  />
                  <BillingAddressBlock selected={checked} onClick={() => {}} {...addressItem} />
                </div>
              );
            })}
            <div className={styles.addAddressWrapper}>
              <AddCartItem onAdd={showAddressForm} title={addAddress} />
            </div>
          </div>

          <div className={styles.btnContainer}>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              type="button"
              onClick={() => setStep(steps.CARD)}
            >
              Back
            </Button>

            <Button fullWidth size="large" disabled={!selectedAddress} onClick={handleSubmit}>
              {strings.submit}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

PreauthorizeFormCard.propTypes = {
  onReceiveToken: PropTypes.func.isRequired,
  Rebilly: shape({ createToken: PropTypes.func }),
  CardNumberElement: PropTypes.elementType.isRequired,
  CardCvvElement: PropTypes.elementType.isRequired,
  CardExpiryElement: PropTypes.elementType.isRequired,
  steps: PropTypes.arrayOf(PropTypes.number).isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
};

PreauthorizeFormCard.defaultProps = {
  Rebilly: {
    createToken: () => {},
  },
};

let withWrapper = (component) => component;
if (isClient) {
  withWrapper = withFramePayCardComponent;
}

export default withWrapper(PreauthorizeFormCard);
