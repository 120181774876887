import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLanguages } from '../store/actions';

const filterAllLanguages = (languages) =>
  languages.map((elem) => (elem.language_to ? elem.language_to : ''));

const useWeglot = () => {
  const dispatch = useDispatch();

  const setDefaultLanguages = (languages) => {
    dispatch(setLanguages(languages));
  };

  const isClient = typeof window === 'object';

  useEffect(() => {
    // This 3-d party lib is connected into gatsby-config.js
    if (isClient && window.Weglot) {
      window.Weglot.on('initialized', () => {
        const languagesFrom = window.Weglot.options.language_from;
        const languagesOptions = window.Weglot.options.languages;
        setDefaultLanguages([languagesFrom, ...filterAllLanguages(languagesOptions)]);
      });
    }
  }, []);
};

export default useWeglot;
