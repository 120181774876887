import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';

import checkMobile from '../../hooks/checkMobile';

import styles from './banner.module.scss';

const Banner = ({
  imageDataMobile,
  imageData,
  children,
  className,
  contained,
  isHero,
  shortContentWidth,
  fluidHeight,
  mobileTextHalf,
  mobilePositionCenter,
  mobileAlignCenter,
  mobilePositionTopSM,
  mobilePositionTopLG,
  desktopPositionTop,
  desktopPositionCenter,
  desktopPositionRight,
}) => {
  const isMobile = checkMobile();

  const renderImageData = () => {
    if (imageDataMobile && isMobile) {
      return imageDataMobile;
    }

    return imageData;
  };

  return (
    <div className={contained ? 'container' : null}>
      <BackgroundImage
        Tag="div"
        className={clsx(
          styles.bgImage,
          fluidHeight ? styles.fluidHeight : null,
          isHero ? styles.isHero : null,
          contained ? styles.wrapperContained : null,
          desktopPositionTop ? styles.desktopPositionTop : null,
          className
        )}
        fluid={renderImageData()}
        backgroundColor="transparent"
        preserveStackingContext
        imgStyle={styles.image}
      >
        <div className={contained ? styles.bannerContained : 'container'}>
          <div
            className={clsx(
              styles.is_content,
              shortContentWidth ? styles.shortContentWidth : null,
              mobilePositionCenter ? styles.mobilePositionCenter : null,
              mobileTextHalf ? styles.mobileTextHalf : null,
              mobilePositionTopSM ? styles.mobilePositionTopSM : null,
              mobilePositionTopLG ? styles.mobilePositionTopLG : null,
              mobileAlignCenter ? styles.mobileAlignCenter : null,
              desktopPositionRight ? styles.desktopPositionRight : null,
              desktopPositionCenter || desktopPositionTop ? styles.desktopPositionCenter : null
            )}
          >
            {children}
          </div>
        </div>
      </BackgroundImage>
    </div>
  );
};

Banner.defaultProps = {
  imageDataMobile: null,
  className: null,
  contained: false,
  isHero: false,
  shortContentWidth: false,
  fluidHeight: false,
  mobilePositionCenter: false,
  mobilePositionTopSM: false,
  mobilePositionTopLG: false,
  mobileAlignCenter: false,
  mobileTextHalf: false,
  desktopPositionTop: false,
  desktopPositionCenter: false,
  desktopPositionRight: false,
};

Banner.propTypes = {
  imageDataMobile: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  imageData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  contained: PropTypes.bool,
  isHero: PropTypes.bool,
  shortContentWidth: PropTypes.bool,
  fluidHeight: PropTypes.bool,

  mobilePositionCenter: PropTypes.bool,
  mobileTextHalf: PropTypes.bool,
  mobilePositionTopSM: PropTypes.bool,
  mobilePositionTopLG: PropTypes.bool,
  mobileAlignCenter: PropTypes.bool,

  desktopPositionTop: PropTypes.bool,
  desktopPositionCenter: PropTypes.bool,
  desktopPositionRight: PropTypes.bool,
};

export default Banner;
