import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Link } from 'gatsby';
import * as Yup from 'yup';

import { pageLinks } from '../../helpers/navigation';
import { createDocLink } from '../../helpers/utils';
import Checkbox from '../checkbox';
import styles from './checkout-total-checkbox.module.scss';

const strings = {
  confirmYouRead:
    'By clicking this checkbox, you confirm that you read, understood and agreed to the',
  terms: 'Terms of Use,',
  privacy: 'Privacy Policy,',
  gdpr: 'GDPR Policy,',
  refund: 'Refund Policy.',

  agreeRecurring1:
    'By clicking this checkbox, you agree to the recurring billing and that your card will automatically be charged',
  agreeRecurring2month: 'every month.',
  agreeRecurring2year: 'every year.',
  agreeRecurring2:
    ' You can cancel the subscription(s) anytime by logging into the customer portal and turning it off from',
  manageSubscriptions: 'Manage Subscription,',
  agreeRecurring3: ' or you can send an email to',
  emailSupport: 'support@livesupportnow.com',
};

const initialValues = {
  hasRead: false,
  hasAgreedRecurring: false,
};

const validationSchema = Yup.object().shape({
  hasRead: Yup.boolean().required('Required'),
  hasAgreedRecurring: Yup.boolean().required('Required'),
});

const CheckoutTotalCheckbox = (props) => {
  const linkState = { modal: true };
  const { countryCode, recurringPrice, redirectToPortal, setCheckboxes, hasAnnual } = props;

  const termsUrl = createDocLink(pageLinks.terms, countryCode);
  const privacyUrl = createDocLink(pageLinks.privacy, countryCode);
  const gdprUrl = createDocLink(pageLinks.gdpr, countryCode);
  const refundUrl = createDocLink(pageLinks.refund, countryCode);

  const fk = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
  });

  useEffect(() => {
    if (fk.values.hasRead && fk.values.hasAgreedRecurring) {
      setCheckboxes(true);
    } else {
      setCheckboxes(false);
    }
  }, [fk.values.hasRead, fk.values.hasAgreedRecurring]);

  const urlConfig = [
    {
      href: termsUrl,
      title: strings.terms,
    },
    {
      href: privacyUrl,
      title: strings.privacy,
    },
    {
      href: gdprUrl,
      title: strings.gdpr,
    },
    {
      href: refundUrl,
      title: strings.refund,
    },
  ];

  return (
    <div>
      <Checkbox
        onChange={() => fk.setFieldValue('hasRead', !fk.values?.hasRead)}
        checked={fk.values?.hasRead}
      >
        <div className={styles.checkboxContent}>
          <span>{strings.confirmYouRead}</span>
          {urlConfig.map((urlData) => (
            <Link key={urlData.title} to={urlData.href} state={linkState} className={styles.url}>
              {urlData.title}
            </Link>
          ))}
        </div>
      </Checkbox>
      <Checkbox
        onChange={() => fk.setFieldValue('hasAgreedRecurring', !fk.values?.hasAgreedRecurring)}
        checked={fk.values?.hasAgreedRecurring}
      >
        <div className={styles.checkboxContent}>
          <span>{strings.agreeRecurring1}</span>
          <span>{` $${recurringPrice} `}</span>
          <span>
            {(hasAnnual ? strings.agreeRecurring2year : strings.agreeRecurring2month) +
              strings.agreeRecurring2}
          </span>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            onKeyDown={redirectToPortal}
            role="button"
            tabIndex={0}
            className={styles.url}
            onClick={redirectToPortal}
          >
            {strings.manageSubscriptions}
          </a>
          <span>{strings.agreeRecurring3}</span>
          <a
            className={styles.url}
            href={`mailto:${strings.emailSupport}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {strings.emailSupport}
          </a>
        </div>
      </Checkbox>
    </div>
  );
};

CheckoutTotalCheckbox.propTypes = {
  countryCode: PropTypes.string.isRequired,
  recurringPrice: PropTypes.number.isRequired,
  redirectToPortal: PropTypes.func.isRequired,
  setCheckboxes: PropTypes.func.isRequired,
  hasAnnual: PropTypes.bool.isRequired,
};

export default CheckoutTotalCheckbox;
