import apiAction from '../apiActionCreator';

const type = 'GET_ORDER_BY_ID';

const config = {
  url: '/orders',
  actionType: type,
  sendFormat: 'json',
  method: 'GET',
};

export default apiAction(config);
