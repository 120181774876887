import PropTypes from 'prop-types';

export const fluidImage = PropTypes.shape({
  node: PropTypes.shape({
    image: PropTypes.shape({
      childImageSharp: PropTypes.shape({ fluid: PropTypes.string.isRequired }),
    }),
  }),
});

export const cartDataPropTypes = PropTypes.shape({
  products: PropTypes.arrayOf(
    PropTypes.shape({
      SKU: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      cvPoints: PropTypes.number.isRequired,
      id: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired,
      levelId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      product: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        rebillyProductId: PropTypes.string.isRequired,
      }),
      productName: PropTypes.string.isRequired,
      rebillyPlanId: PropTypes.string.isRequired,
      recurringPrice: PropTypes.number.isRequired,
      recurringSKU: PropTypes.string.isRequired,
      setupPrice: PropTypes.number.isRequired,
    })
  ),
  subTotal: PropTypes.number.isRequired,
  productCount: PropTypes.number.isRequired,
}).isRequired;
