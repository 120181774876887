import clsx from 'clsx';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './modal.module.scss';

const Modal = ({
  title,
  children,
  isShown,
  onCloseClick,
  modalType,
  idOverlay,
  isSticky,
  className,
  withScroll,
  modalHeaderContainerStyles,
}) => {
  // TODO: move it to utils
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!withScroll && isShown) {
      document.body.classList.toggle('modal-is-active');
    }

    const overlay = document.getElementById(idOverlay || 'modal-overlay');

    if (overlay) {
      let xDown = null;
      let yDown = null;

      const getTouches = (evt) => evt.touches || evt.originalEvent.touches;

      const handleTouchStart = (evt) => {
        const firstTouch = getTouches(evt)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
      };

      const handleTouchMove = (evt) => {
        if (!xDown || !yDown) {
          return;
        }

        overlay.addEventListener('touchstart', handleTouchStart, false);
        overlay.addEventListener('touchmove', handleTouchMove, false);

        const xUp = evt.touches[0].clientX;
        const yUp = evt.touches[0].clientY;

        const xDiff = xDown - xUp;
        const yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
          onCloseClick();
          document.body.className = document.body.className.replace('modal-is-active', '');
        } else {
          onCloseClick();
          document.body.className = document.body.className.replace('modal-is-active', '');
        }
        /* reset values */
        xDown = null;
        yDown = null;
      };

      return () => {
        overlay.removeEventListener('touchstart', handleTouchStart, false);
        overlay.removeEventListener('touchmove', handleTouchMove, false);
        if (document.body.classList.contains('modal-is-active')) {
          document.body.className = document.body.className.replaceAll('modal-is-active', '');
        }
      };
    }
  }, []);

  useEffect(() => {
    if (!withScroll) {
      if (isShown && !document.body.classList.contains('modal-is-active')) {
        document.body.classList.add('modal-is-active');
      }
    }
  });

  return (
    <div className={clsx(isShown ? styles.modal : styles.hidden)}>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button
        type="button"
        id={idOverlay || 'modal-overlay'}
        className={styles.overlay}
        // onClick={onCloseClick}
      />
      <div
        className={clsx(
          styles.wrapper,
          styles[modalType],
          className,
          isSticky ? styles.wrapperFixed : {}
        )}
      >
        <div className={clsx(styles.header, 'modalHeader', modalHeaderContainerStyles || {})}>
          <h2 className={clsx('common_title', 'color_primary_dark', styles.title)}>{title}</h2>
          <button type="button" className={styles.close} onClick={onCloseClick}>
            <i className="icon-close" />
          </button>
        </div>
        <div id="modal-content" className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
};

Modal.defaultProps = {
  children: null,
  modalType: '',
  isSticky: false,
  withScroll: false,
  className: '',
  modalHeaderContainerStyles: null,
  idOverlay: '',
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  isShown: PropTypes.bool.isRequired,
  withScroll: PropTypes.bool,
  onCloseClick: PropTypes.func.isRequired,
  modalType: PropTypes.string,
  idOverlay: PropTypes.string,
  isSticky: PropTypes.bool,
  className: PropTypes.string,
  modalHeaderContainerStyles: PropTypes.string,
};

export default Modal;
