import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { useIntl } from 'gatsby-plugin-intl';
import { selectAddonsWithSubscriptions } from '../../store/selectors/entities';
import withLocation from '../../hoc/withLocation';
import { hideAddonPanel, validateAddon } from '../../store/actions';
import Layout from '../layout';
import SEO from '../seo';
import CartItemPopup from '../cart-item-popup';
import BannerRefferal from '../banner-refferal';
import Modals from '../modals';
import Addon from '../addon';

import styles from '../../styles/pages/addons.module.scss';

const wsAddons = ['ADD-RET-US30', 'ADD-US30'];
const availableAddonList = [
  'ADD-BFE05',
  'ADD-TE08',
  'ADD-GPRM09',
  'ADD-PR08',
  'ADD-NFX01',
  'ADD-SHIFT',
  'ADD-RLC03',
  'ADD-RLC365',
  ...wsAddons,
];

// eslint-disable-next-line react/prop-types
const AddonsPage = ({ webAlias, PlacementType: placementType, Token: refferalToken }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const addons = useSelector(selectAddonsWithSubscriptions);
  const addonsText = intl.formatMessage({ id: 'addons.addons' });
  const comingSoonText = intl.formatMessage({ id: 'addons.commingSoon' });
  const soonAddons = addons.filter((item) => !availableAddonList.includes(item.sku));
  const availableAddons = addons.filter((item) => availableAddonList.includes(item.sku));

  useEffect(
    () => () => {
      dispatch(hideAddonPanel());
    },
    []
  );

  const onAddClick = (addon) => {
    dispatch(validateAddon([{ ...addon, successActionType: 'ADD_TO_CART' }]));
  };

  return (
    <>
      <SEO title="Addons" />
      <Layout pageWrapperClassName="page_wrapper addons">
        <div className="refferalWrapper">
          <BannerRefferal
            webAlias={webAlias}
            placementType={placementType}
            refferalToken={refferalToken}
          />
        </div>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <CartItemPopup isAddonsPage isAddon />
          </div>

          <div className={styles.title}>{addonsText}</div>
          <div className={styles.addons}>
            {availableAddons.map((item) => (
              <Addon
                key={item.id}
                id={item.sku}
                name={item.product.name}
                intervalSize={item.intervalSize}
                price={item.price}
                recurringPrice={item.recurringPrice}
                description={item.bullets}
                onClick={() => onAddClick(item)}
                expiresAt={item.expiresAt}
                isExpired={item.isExpired}
                isRenew={item.isRenew}
                currentSubscription={item.currentSubscription}
                imageUrl={item.imageUrl}
              />
            ))}
          </div>

          <div className={styles.subtitle}>{comingSoonText}</div>
          <div className={styles.addons}>
            {soonAddons.map((item) => (
              <Addon
                disabled
                key={item.id}
                id={item.sku}
                name={item.product.name}
                description={item.bullets}
                price={null}
                imageUrl={item.imageUrl}
              />
            ))}
          </div>
        </div>

        <Modals page="" />
      </Layout>
      <ReactTooltip className={styles.tooltip} />
    </>
  );
};

export default withLocation(AddonsPage);
