import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import styles from './billing-address-block.module.scss';

const BillingAddressBlock = ({
  firstName,
  lastName,
  country,
  city,
  zipCode,
  address,
  address2,
  primary,
  selected,
  wrapperClassName,
  exigo,
  ...rest
}) => {
  const intl = useIntl();
  const defaultText = intl.formatMessage({ id: 'button.default' });
  const registerText = intl.formatMessage({ id: 'purchase_checkout.registerAddress' });
  const isRegisterAddress = exigo && !primary;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className={clsx(styles.button, wrapperClassName, selected && styles.selected)} {...rest}>
      <div className={styles.content}>
        <i className={clsx('icon-house', styles.icon)} />
        <div className={styles.info}>
          <p>
            {firstName} {lastName}
          </p>
          <p>
            {country && country.toUpperCase()} {city} {zipCode} {address} {address2 || ''}
          </p>
        </div>
        <div className="color_primary">{primary ? defaultText : ''} </div>
        <div className={styles.rightText}>{isRegisterAddress ? registerText : ''}</div>
      </div>
    </div>
  );
};

BillingAddressBlock.propTypes = {
  wrapperClassName: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  zipCode: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  primary: PropTypes.bool.isRequired,
  selected: PropTypes.bool,
  exigo: PropTypes.bool.isRequired,
};

BillingAddressBlock.defaultProps = {
  wrapperClassName: null,
  selected: false,
};

export default BillingAddressBlock;
