import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import clsx from 'clsx';

import styles from './avatar.module.scss';

const Avatar = ({ imageData }) => {
  const content = imageData ? (
    <Img className={styles.image} fluid={imageData} />
  ) : (
    <i className={clsx('icon-user', styles.icon)} />
  );

  return <div className={styles.avatar}>{content}</div>;
};

Avatar.defaultProps = { imageData: '' };

Avatar.propTypes = { imageData: PropTypes.string };

export default Avatar;
