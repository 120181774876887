import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useIntl, Link } from 'gatsby-plugin-intl';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ArrowBack from '../components/arrowBack';
import Input from '../components/input';
import Button from '../components/button';

import { getSiteUrl } from '../helpers/utils';
import {
  getInstanceName,
  isAddonInstance,
  isRetailAddonInstance,
} from '../helpers/instanceHandler';

import { createNavigateTo, pageLinks } from '../helpers/navigation';
import forgotUserName from '../services/api/actions/forgotUserName';

import styles from '../styles/pages/forgot-username.module.scss';
import { redirectToOtherSite } from '../store/actions';
import { REDIRECTION_TYPES } from '../constants/types';

const strings = {
  subTitleText: `Enter the email address associated with your account and we'll send you a reminder`,
};

const ForgotUsernamePage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const label = intl.formatMessage({ id: 'forgot_pass.email' });
  const placeholder = intl.formatMessage({ id: 'forgot_pass.typeIn' });
  const titleTxt = intl.formatMessage({ id: 'forgot_pass.forgot_username' });
  const haventAccountTxt = intl.formatMessage({ id: 'forgot_pass.havent_account' });
  const createAccountTxt = intl.formatMessage({ id: 'forgot_pass.create_an_account' });
  const buttonTxt = intl.formatMessage({ id: 'forgot_pass.lets_continue' });
  const backTxt = intl.formatMessage({ id: 'forgot_pass.back' });

  const [values, setValues] = useState({ email: '' });
  const onChange = ({ target }) => {
    const { name, value } = target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const moveToRegistration = (e) => {
    e.preventDefault();

    if (isAddonInstance && !isRetailAddonInstance) {
      return dispatch(redirectToOtherSite({ type: REDIRECTION_TYPES.SHOP, authorized: false }));
    }

    return createNavigateTo(pageLinks.registration)();
  };

  const onSubmit = () => {
    const data = {
      email: values.email,
      initiator: 'SHOP',
      url: getSiteUrl(),
    };
    dispatch(forgotUserName.action(data));
  };

  const isDisabled = !values.email;

  const siteName = getInstanceName();
  const title = intl.formatMessage({ id: 'pageTitle.forgotUsername' });

  return (
    <>
      <SEO title={`${title} - ${siteName}`} />
      <Layout fluid pageWrapperClassName="page_wrapper">
        <div className="container">
          <ArrowBack text={backTxt} navigationTo="/" />
          <div className={styles.header}>
            <h2 className={styles.title}>{titleTxt}</h2>
            <p className={styles.subtitle}>{strings.subTitleText}</p>
          </div>
          <div className={styles.content}>
            <div className={styles.wrapper}>
              <Input
                label={label}
                id="email"
                value={values.email}
                name="email"
                onChange={onChange}
                placeholder={placeholder}
                fullwidth
                type="email"
              />
            </div>
            <div className={styles.action}>
              <Button fullWidth size="large" disabled={isDisabled} onClick={onSubmit}>
                {buttonTxt}
              </Button>
            </div>
            <div className={styles.footer}>
              <i className="icon-user-check" />
              <span>{haventAccountTxt}</span>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link className={styles.link} onClick={moveToRegistration}>
                {createAccountTxt}
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ForgotUsernamePage;
