import React, { useEffect } from 'react';

import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../layout';
import SEO from '../seo';
import HomeCTA from '../home-cta';
import HomeBanner from '../home-banner';
import HomeCarousel from '../home-carousel';
import { logEvent, getInstanceName, isClient } from '../../helpers/utils';

import styles from '../../styles/pages/home.module.scss';
import { isLocalStorageAvailable } from '../../services/storage';
import BlockedCookiesModal from '../blocked-cookies-modal';
import PreviousOrderModal from '../previous-order-modal';

const Shop = () => {
  let isRealLocalStorage = false;
  const intl = useIntl();
  const siteName = getInstanceName();
  const title = intl.formatMessage({ id: 'pageTitle.home' });

  useEffect(() => logEvent('visited_home'), []);

  if (isClient) {
    isRealLocalStorage = isLocalStorageAvailable();
  }

  return (
    <Layout fluid>
      <SEO title={`${title} - ${siteName}`} />
      <PreviousOrderModal />
      <div className={styles.carousel}>
        <HomeCarousel />
      </div>
      <div className={styles.banner}>
        <HomeBanner />
      </div>
      <div className={styles.cta}>
        <HomeCTA />
      </div>
      {!isRealLocalStorage && <BlockedCookiesModal />}
    </Layout>
  );
};

export default Shop;
