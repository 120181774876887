import { useState, useEffect } from 'react';

import { isClient } from '../helpers/utils';

export default function useLiveCheck() {
  const [thirdPartyStatuses, setResult] = useState(null);

  useEffect(() => {
    if (isClient) {
      const liveCheck = async () => {
        const result = await fetch(`${process.env.GATSBY_API_URL}/third-party/status`)
          .then((response) => response.json())
          .then((data) => data.response)
          // eslint-disable-next-line no-console
          .catch((e) => console.error('Error while live checking', e));

        if (result) {
          setResult(result);
        }
      };

      liveCheck();
    }
  }, []);

  return { thirdPartyStatuses };
}
