import apiAction from '../apiActionCreator';

const type = 'CHANGE_EMAIL';

const config = {
  url: '/user/change-email',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
