import { all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import { setExpectedSubscriptions } from '../store/actions';
import { selectExpectedSubscriptions, selectUserSubscriptions } from '../store/selectors/entities';
import ordersPurchase from '../services/api/actions/ordersPurchase';
import getUserSubscriptions from '../services/api/actions/getUserSubscriptions';
import getUserExpectedSubscriptions from '../services/api/actions/getUserExpectedSubscriptions';
import { transformCartToItems } from '../helpers/utils';

function* checkExpectedSubscriptions() {
  const expectedSubscriptions = yield select(selectExpectedSubscriptions);

  if (expectedSubscriptions) {
    const isTimePast = new Date(expectedSubscriptions.tillDate) < moment().toDate();
    if (isTimePast) {
      yield put(setExpectedSubscriptions(null));
      return;
    }

    const requestTimeOut = 1000 * 30;

    yield delay(requestTimeOut);

    const [response] = yield all([yield put(getUserSubscriptions.action())]);

    if (response.type === getUserSubscriptions.type.error) {
      return;
    }

    const subscriptions = response.payload.data.data;

    const successfullyReceivedSubscriptions = expectedSubscriptions.items.every(
      (expectedSubscription) =>
        subscriptions.find(
          (item) =>
            item.isCombo === expectedSubscription.isCombo &&
            item.level === expectedSubscription.level &&
            item.name === expectedSubscription.name &&
            item.status === 'Active'
        )
    );

    if (successfullyReceivedSubscriptions) {
      yield put(setExpectedSubscriptions(null));
      return;
    }

    yield call(checkExpectedSubscriptions);
  }
}

function* saveExpectedData(action) {
  const { result, status } = action.payload.data;

  const successStatus = result === 'approved' && status === 'completed';

  if (successStatus) {
    const purchaseRequestItems = action && action.meta.previousAction.payload.request.data.items;
    let items = purchaseRequestItems || [];
    const userSubscriptions = yield select(selectUserSubscriptions);

    if (userSubscriptions) {
      items = [...items, ...transformCartToItems(userSubscriptions)];
    }

    items = uniqBy(items, (e) => e.planId);

    const [{ payload }] = yield all([yield put(getUserExpectedSubscriptions.action({ items }))]);

    if (payload) {
      const tillDate = moment().add(5, 'minutes').toDate();
      yield put(setExpectedSubscriptions({ items: payload.data.data, tillDate }));
      yield call(checkExpectedSubscriptions);
    }
  }
}

function* userDataSaga() {
  yield fork(checkExpectedSubscriptions);
  yield all([yield takeLatest(ordersPurchase.type.success, saveExpectedData)]);
}

export default userDataSaga;
