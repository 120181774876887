import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { removeFromCart, validateAddon } from '../../store/actions';
import AddCartItem from '../add-cart-item';
import CartSubtotal from '../cart-subtotal';
import CartItem from '../cart-item';
import CouponInput from '../coupon-input';
import checkMobile from '../../hooks/checkMobile';
import { createNavigateTo, pageLinks } from '../../helpers/navigation';
import { selectCart, showAutoRenewalMessage } from '../../store/selectors';
import { checkIfPathProtected, logEvent, isUserLogged } from '../../helpers/utils';
import { isAddonInstance } from '../../helpers/instanceHandler';
import BannerBtcDiscountShoppingCart from '../banner-btc-discount-shopping-cart';
import styles from './cart.module.scss';

const stateSelector = createStructuredSelector({
  cartData: selectCart,
  showAutoRenewal: showAutoRenewalMessage,
});

const Cart = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isMobile = checkMobile();
  const { cartData, showAutoRenewal } = useSelector(stateSelector);
  const { products, subTotal, productCount, totalDiscount, showInitialDiscount } = cartData;
  const addItemToCard = createNavigateTo(pageLinks.pricing);

  const toCheckOut = () => {
    if (isAddonInstance && isUserLogged() && productCount) {
      return dispatch(
        validateAddon(products.map((item) => ({ ...item, successActionType: 'MOVE_TO_CHECKOUT' })))
      );
    }
    return createNavigateTo(checkIfPathProtected(pageLinks.checkout))();
  };

  const deleteProduct = (plan) => {
    logEvent('remove_from_cart');

    if (plan.isTicket) {
      return dispatch(removeFromCart(plan.level));
    }

    if (plan.isAddon) {
      return dispatch(removeFromCart(plan.product.name));
    }

    return dispatch(removeFromCart(plan.product.url));
  };

  const renderSubTotal = () => (
    <>
      <div className={styles.subtotalContainer}>
        {showInitialDiscount && <BannerBtcDiscountShoppingCart />}
      </div>
    </>
  );

  return (
    <div>
      <div className={styles.row}>
        <div className={styles.columnLeft}>
          <div>
            <h2 className={styles.header}>{intl.formatMessage({ id: 'my_shopping_cart' })}</h2>
          </div>

          <div className={styles.itemsInCart}>
            <AddCartItem onAdd={addItemToCard} />
            <ul className={styles.cartList}>
              {products.map((plan) => (
                <CartItem
                  key={plan.name}
                  name={plan.name}
                  price={plan.price}
                  color={plan.color}
                  icon={plan.icon}
                  bgColor={plan.bgColor}
                  intervalSize={plan.intervalSize}
                  onDelete={() => deleteProduct(plan)}
                  recurrentPrice={plan.isAnnual ? null : plan.recurringPrice}
                />
              ))}
            </ul>
          </div>
          <div>{!isMobile && renderSubTotal()}</div>
        </div>

        <div className={styles.columnRight}>
          <div className={styles.coupon}>{isUserLogged() && <CouponInput page="cart" />}</div>

          <CartSubtotal
            itemsCount={productCount}
            subtotal={subTotal}
            onCheckoutClick={toCheckOut}
            totalDiscount={totalDiscount}
            showAutoRenewalMessage={showAutoRenewal}
          />
          {isMobile && renderSubTotal()}
        </div>
      </div>
    </div>
  );
};

export default Cart;
