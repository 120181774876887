export default {
  icon: {
    // icon for combined field
    display: true, // false to hide
    fill: '#fff',
  },
  classes: {}, // when needed,
  style: {
    base: {
      height: 50,
      color: '#000',
      fontSize: 15,
      letterSpacing: 0,
      fontWeight: 400,
      fontFamily: 'Poppins',
      padding: '16px 24px',
      border: 'none',
      '::placeholder': {
        fontSize: 14,
        letterSpacing: 0,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#b3a6b9',
      },
    },
    focus: { boxShadow: '0px 0px 10px 4px rgba(93, 1, 162, 0.15)' },
    invalid: {
      color: 'red',
      '::placeholder': { color: 'firebrick' },
    },
  },
};
