import apiAction from '../apiActionCreator';

const type = 'ADD_BILLING_ADDRESS';

const config = {
  url: '/user/address/add',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
