import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'gatsby-plugin-intl';
import { getPlanColors } from '../../helpers/utils';
import styles from './plan-level-card.module.scss';
import FlagLabel from '../flag-label';

const PlanLevelCard = ({
  isActive,
  isCurrent,
  canRenew,
  canUpgrade,
  addToCart,
  isButtonHide,
  recurringPrice,
  productUrlParam,
  isCombo,
  level,
  isEliteBought,
  moveToElite,
  showEliteMessage,
  price,
  upgradePrice,
  averageSaving,
  moreThanThree,
  color,
  bgColor,
  bullets,
  isSmallFont,
  icon,
  comboMessage,
  isPartOfCombo,
  intervalSize,
  extraMonths,
}) => {
  const intl = useIntl();
  const perMonthTxt = intl.formatMessage({ id: 'plan_level_card.per_month' });
  const entryPriceTxt = intl.formatMessage({ id: 'plan_level_card.entry_price' });
  const upgradePriceTxt = intl.formatMessage({ id: 'plan_level_card.upgrade_price' });
  const fromAvailSavings = intl.formatMessage({ id: 'plan_level_card.from_the_available_savings' });
  const yourCurrentPlan = intl.formatMessage({ id: 'plan_level_card.your_current_plan' });
  let actionTxt = intl.formatMessage({ id: 'plan_level_card.become_a_member' });
  const renew = intl.formatMessage({ id: 'plan_level_card.renew' });
  const upgrade = intl.formatMessage({ id: 'plan_level_card.upgrade' });
  const liveSaving = 'from the available savings';
  const isOnlineLine = process.env.GATSBY_INSTANCE_NAME === 'onlineLine';
  actionTxt = canRenew ? renew : actionTxt;
  actionTxt = canUpgrade ? upgrade : actionTxt;
  const isEntryPrice = !canRenew && !canUpgrade && !isCurrent && !isButtonHide;
  const colors = getPlanColors({ bgColor, color });
  const showDiscountLabel = false; // !isButtonHide && !canRenew && !canUpgrade;
  const savingsText =
    isOnlineLine && productUrlParam && productUrlParam.toLocaleLowerCase() === 'live'
      ? liveSaving
      : fromAvailSavings;

  return (
    <div className={clsx(styles.card, moreThanThree ? styles.small : '')}>
      <div className={styles.bgWrapper}>
        <div
          style={{ backgroundColor: colors.mainBg, color: colors.text }}
          className={styles.bgImage}
        >
          {isCurrent && (
            <div className={clsx(styles.currentPlan, { [styles.active]: isActive })}>
              <div className={clsx(styles.oval, { [styles.active]: isActive })} />
              {yourCurrentPlan}
            </div>
          )}
          {showDiscountLabel && (
            <FlagLabel
              bgColor={colors.discount}
              textColor={colors.text}
              isSmallFont={isSmallFont}
              isCurrent={isCurrent}
            />
          )}
          <div className={styles.content}>
            <div className={styles.labelWrapper}>
              <div
                className={clsx(styles.label, 'dont-translate')}
                style={{ backgroundColor: colors.mainLabel, color: colors.text }}
              >
                <i className={`icon-${icon} dont-translate`} style={{ color: colors.text }} />
                {level.toLowerCase()}
              </div>
            </div>

            {extraMonths && (
              <div className={clsx(styles.label, styles.promo)}>
                <div style={{ color: colors.text }}>{`Get ${extraMonths} Month${
                  extraMonths > 1 ? 's' : ''
                } Free!`}</div>
              </div>
            )}

            <div className={styles.price}>
              {recurringPrice > 0 && (
                <p className={styles.priceRecurring} style={{ color: colors.text }}>
                  {`$${recurringPrice}`}
                </p>
              )}

              <div>
                {recurringPrice > 0 && (
                  <p className={styles.perMonth} style={{ color: colors.text }}>
                    {intervalSize > 31 ? `per ${intervalSize} days` : perMonthTxt}
                  </p>
                )}

                {isEntryPrice && (
                  <p className={styles.priceEntry} style={{ color: colors.text }}>
                    {`${entryPriceTxt} $${price}`}
                  </p>
                )}

                {canUpgrade && (
                  <p className={styles.priceEntry} style={{ color }}>
                    {`${upgradePriceTxt} $${upgradePrice}`}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {!isButtonHide && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          className={styles.action}
          style={{ backgroundColor: colors.mainBg, color: colors.text }}
          onClick={addToCart}
        >
          <div className={styles.actionLinkWrapper} style={{ color: colors.text }}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" style={{ color: colors.text }}>
              <span>{actionTxt}</span>
            </a>
          </div>
          <div
            className={styles.actionIconWrapper}
            style={{ backgroundColor: colors.mainDark, color: colors.text }}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#">
              <i className="icon-cart" style={{ color: colors.text }} />
            </a>
          </div>
        </div>
      )}

      {isButtonHide && !isCombo && isEliteBought && isPartOfCombo && (
        <div
          className={styles.action}
          style={{ backgroundColor: colors.mainBg, color: colors.text }}
        >
          <div
            className={styles.actionLinkWrapper}
            style={{ backgroundColor: colors.mainDark, color: colors.text }}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}

            <span className={styles.eliteIncludes}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" style={{ color: colors.text }}>
                Included in your{' '}
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                <span role="button" onClick={moveToElite} className={styles.link}>
                  Elite
                </span>{' '}
                Subscription
              </a>
            </span>
          </div>
        </div>
      )}

      {showEliteMessage && isCombo && !isEliteBought && (
        <div
          className={styles.action}
          style={{ backgroundColor: colors.mainBg, color: colors.text }}
        >
          <div
            className={styles.actionLinkWrapper}
            style={{ backgroundColor: colors.mainDark, color: colors.text }}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}

            {comboMessage && (
              <span className={clsx(styles.eliteIncludes, styles.hideClick)}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" style={{ color: colors.text }}>
                  {comboMessage}
                </a>
              </span>
            )}
          </div>
        </div>
      )}

      <div className={styles.services}>
        {bullets.map((item) => (
          <ServiceItem key={item} service={item} color={colors.mainLabel} />
        ))}
      </div>
      {averageSaving && (
        <div className={styles.savings}>
          <p>
            <span className={styles.savingPercent}>{`*${averageSaving}% `}</span>
            {averageSaving && savingsText}
          </p>
        </div>
      )}
    </div>
  );
};

PlanLevelCard.defaultProps = {
  isCurrent: false,
  isActive: false,
  canRenew: false,
  canUpgrade: false,
  recurringPrice: '',
  comboMessage: '',
  price: '',
  upgradePrice: 0,
  averageSaving: '',
  addToCart: () => {},
  isButtonHide: false,
  isSmallFont: false,
  icon: 'boat-silver',
  showEliteMessage: false,
  isEliteBought: false,
  extraMonths: 0,
};

PlanLevelCard.propTypes = {
  icon: PropTypes.string,
  isCurrent: PropTypes.bool,
  isActive: PropTypes.bool,
  canRenew: PropTypes.bool,
  canUpgrade: PropTypes.bool,
  isButtonHide: PropTypes.bool,
  isCombo: PropTypes.bool.isRequired,
  productUrlParam: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  isEliteBought: PropTypes.bool,
  moveToElite: PropTypes.func.isRequired,
  showEliteMessage: PropTypes.bool,
  isPartOfCombo: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  bullets: PropTypes.arrayOf(PropTypes.string).isRequired,
  addToCart: PropTypes.func,
  recurringPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  upgradePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  averageSaving: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line react/forbid-prop-types
  moreThanThree: PropTypes.bool.isRequired,
  isSmallFont: PropTypes.bool,
  comboMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  intervalSize: PropTypes.number.isRequired,
  extraMonths: PropTypes.number,
};

export default PlanLevelCard;

function ServiceItem({ service, color }) {
  return (
    <div className={styles.serviceItem}>
      <i className={clsx('icon-check-rounded', styles.serviceItemIcon)} style={{ color }} />
      <p>{service}</p>
    </div>
  );
}

ServiceItem.propTypes = {
  service: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};
