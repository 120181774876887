import React from 'react';
import Addons from './addons';
import Shop from './shop';
import PricingPage from '../../pages/pricing';
import { isAddonInstance, isRetailAddonInstance } from '../../helpers/instanceHandler';

export default (props) => {
  if (isAddonInstance && !isRetailAddonInstance) {
    return <PricingPage />;
  }

  if (isRetailAddonInstance) {
    return <Addons {...props} />;
  }

  return <Shop />;
};
