import React from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import AddCartItem from '../add-cart-item';
import TitleButton from '../title-button';
import BillingPaymentBlock from '../billing-payment-block';
import BillingPaymentBlockCard from '../billing-payment-block-card';
import {
  selectPaymentsWithDefault,
  selectCoinPaymentDiscount,
  selectCart,
} from '../../store/selectors';
import styles from './billing-payment-form.module.scss';
import BillingPaymentModalForm from '../billing-payment-modal-form';
import {
  selectAvailablePaymentMethods,
  selectChosenPaymentMethod,
  selectModalError,
} from '../../store/selectors/global';
import { getPaymentTitle } from '../../helpers/utils';
import Links from '../btc-links';
import PaymentConstants from '../../constants/payment';
import { isOnlineEraUs, isAddonInstance } from '../../helpers/instanceHandler';

const stateSelector = createStructuredSelector({
  savedMethods: selectPaymentsWithDefault,
  selectedPaymentMethod: selectChosenPaymentMethod,
  coinPaymentDiscount: selectCoinPaymentDiscount,
  modalError: selectModalError,
  shoppingCart: selectCart,
  availablePaymentMethods: selectAvailablePaymentMethods,
});

const BillingPaymentForm = ({ modal, toggleModal, showInitialDiscount }) => {
  const intl = useIntl();

  const {
    savedMethods,
    selectedPaymentMethod,
    modalError,
    coinPaymentDiscount,
    shoppingCart,
    availablePaymentMethods,
  } = useSelector(stateSelector);

  const { cards, paypal, bankAccounts } = savedMethods || {};

  const paymentMethodText = intl.formatMessage({ id: 'purchase_checkout.payment_method' });
  const addPaymentMethodText = intl.formatMessage({ id: 'purchase_checkout.add_payment_method' });
  const changeText = intl.formatMessage({ id: 'button.change' });

  const defaultCard = cards && cards.find((card) => card.isDefault);
  const defaultPaypal = paypal && paypal.find((item) => item.isDefault);
  const defaultBankAccounts = bankAccounts && bankAccounts.find((item) => item.isDefault);
  const isPrimaryPaymentPresent = !!defaultCard || !!defaultPaypal || !!defaultBankAccounts;

  const availableBankAccounts = availablePaymentMethods.includes('ach')
    ? defaultBankAccounts
    : null;

  const defaultMethod =
    defaultCard?.method || availableBankAccounts?.method || defaultPaypal?.method;

  const defaultMethodTheSameAsSelected = defaultMethod?.id === selectedPaymentMethod?.id;

  const renderCardBlock = (card) => <BillingPaymentBlockCard {...card} />;

  const renderOtherPaymentBlock = (data) => {
    const firstName = (data.billingAddress && data.billingAddress?.firstName) || '';
    const lastName = (data.billingAddress && data.billingAddress?.lastName) || '';
    return (
      <BillingPaymentBlock
        wrapperClassName={styles.addItemWrapper}
        method={data.method}
        title={data.title || 'PayPal'}
        subtitle={`${firstName} ${lastName} `}
        isDefault={data.isDefault}
      />
    );
  };

  const renderSelectedPaymentBlock = () => {
    if (selectedPaymentMethod) {
      const title = getPaymentTitle(selectedPaymentMethod.method);

      // OtherBlock
      const otherBlock = [
        'paypal',
        'bitpay',
        'bank-transfer',
        'coinpayments',
        'cash-deposit',
        'e-wallet',
        'astroPay',
        'paypal-card',
        'rapyd',
        'rapyd-cash',
        'AmazonPay',
        'paynote',
        PaymentConstants.SHOP_METHODS.requestToPay,
        PaymentConstants.SHOP_METHODS.MaxiCash,
      ].includes(selectedPaymentMethod.method);

      if (otherBlock) {
        return renderOtherPaymentBlock({ ...selectedPaymentMethod, title });
      }

      // Card
      const paymentCardBlock = ['ach', 'payment-card'].includes(selectedPaymentMethod.method);

      if (paymentCardBlock) {
        return renderCardBlock({ ...selectedPaymentMethod, title });
      }
    }
    return null;
  };

  const renderDefaultPaymentBlock = () => {
    if (
      !availablePaymentMethods.includes(defaultMethod?.method) ||
      defaultMethodTheSameAsSelected
    ) {
      return null;
    }
    if (defaultCard) {
      return renderCardBlock(defaultCard);
    }
    if (availableBankAccounts) {
      return renderCardBlock({ ...availableBankAccounts, title: 'Plaid' });
    }
    if (defaultPaypal) {
      return renderOtherPaymentBlock(defaultPaypal);
    }
    return null;
  };

  const showModal = (type = null) => {
    toggleModal(type);
  };

  return (
    <>
      {modal && modal.length > 0 && (
        <BillingPaymentModalForm
          {...{
            modal,
            toggleModal,
            savedMethods,
            isPrimaryPaymentPresent,
            modalError,
            showInitialDiscount,
            availablePaymentMethods,
          }}
        />
      )}
      <TitleButton title={paymentMethodText}>
        <div className={styles.btcContainer}>
          {!isOnlineEraUs && !isAddonInstance && coinPaymentDiscount && (
            <div className={styles.btcInfo}>
              {coinPaymentDiscount?.message && (
                <span className={styles.btcText}>{coinPaymentDiscount.message}</span>
              )}
              {coinPaymentDiscount?.links && (
                <Links cartAmount={shoppingCart.subTotal} data={coinPaymentDiscount.links} />
              )}
            </div>
          )}

          <button className="titleButton" type="button" onClick={() => showModal('change')}>
            <i className="icon-card" />
            <span>{changeText}</span>
          </button>
        </div>
      </TitleButton>

      <div data-browsing-ignore className={styles.paymentMethodsBlock}>
        {renderSelectedPaymentBlock()}
        {renderDefaultPaymentBlock()}
      </div>
      <div className={styles.addItemWrapper}>
        <AddCartItem onAdd={() => showModal('add')} title={addPaymentMethodText} />
      </div>
    </>
  );
};

BillingPaymentForm.defaultProps = {
  modal: '',
};

BillingPaymentForm.propTypes = {
  modal: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
  showInitialDiscount: PropTypes.bool.isRequired,
};

export default React.memo(
  BillingPaymentForm,
  (props, nextProps) => props.modal === nextProps.modal
);
