import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import LiveChat from 'react-livechat';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import SEO from '../components/seo';
import BillingAddressForm from '../components/billing-address-form';
import BillingPaymentForm from '../components/billing-payment-form/billing-payment-form';
import PayForFriendFailModal from '../components/pay-for-friend-fail-modal';
import Modals from '../components/modals';
import CheckoutTotal from '../components/checkout-total';
import {
  isClient,
  SIGNING_AUDIT_TRAIL_TYPE,
  isUserLogged,
  installmentCountries,
  logEvent,
} from '../helpers/utils';

import { hideCrispChat, showCrispChat, getLiveChatParams } from '../helpers/chat';

import { getInstanceName, isAddonInstance, isTrivaInstance } from '../helpers/instanceHandler';

import {
  makeOrderWithSavedCart,
  selectPaymentMethodFromSaved,
  setModal,
  makeOrderWithDLocalInstallment,
  updateCardInfo,
  validateAddon,
  getSignedAgreement,
  setAuditTrialData,
  setPayForFriendMode,
} from '../store/actions';

import {
  selectBillingAddresses,
  selectCart,
  selectPaymentsWithDefault,
  selectPreAuthorizeModalCondition,
  showAutoRenewalMessage,
  selectPaymentCardForCvvConfirm,
  selectCoinPaymentDiscount,
  selectRequestedOrderData,
} from '../store/selectors';

import {
  selectAgreementDocumentPlans,
  selectAgreementItemsForSignWithPlanId,
  selectAuditTrial,
  selectElectronicSignments,
  selectEntityBillingDetails,
  selectEntityUserDetails,
  selectEntityUserSubscriptions,
  selectIfUserNeedToSignAgreement,
} from '../store/selectors/entities';

import {
  selectChosenPaymentMethod,
  selectModal,
  selectPayForFriendMode,
} from '../store/selectors/global';
import setNotification from '../helpers/notifications';
import { createNavigateTo, pageLinks } from '../helpers/navigation';
import ArrowBack from '../components/arrowBack';
import styles from '../styles/pages/checkout.module.scss';
import CouponInput from '../components/coupon-input';
import BannerRefferal from '../components/banner-refferal';
import BannerBitcoinDiscountHeader from '../components/banner-btc-discount';
import AutoRenewInfoModal from '../components/autoRenewInfoModal';
import UpdateCvvModal from '../components/update-cvv-modal';
import SignPdfModal from '../components/sign-pdf-modal';
import showPdfAgreement from '../components/checkout-total/showPdfAgreement';
import PreAuthorisationModal from '../components/preauthorisation-modal';
import UpsellingAddons from '../components/upselling-addons';
import setAuditTrail from '../services/api/actions/setAuditTrail';
import { getCompanyName } from '../helpers/pdfjs';
import PaymentConstants from '../constants/payment';

const stateSelector = createStructuredSelector({
  cartData: selectCart,
  billingAddresses: selectBillingAddresses,
  selectedPaymentMethod: selectChosenPaymentMethod,
  entityBillingDetails: selectEntityBillingDetails,
  modal: selectModal,
  userDetails: selectEntityUserDetails,
  savedMethods: selectPaymentsWithDefault,
  showAutoRenewal: showAutoRenewalMessage,
  preAuthorizeCondition: selectPreAuthorizeModalCondition,
  electronicSignments: selectElectronicSignments,
  shouldSignAgreement: selectIfUserNeedToSignAgreement,
  cvvCardForConfirmation: selectPaymentCardForCvvConfirm,
  coinPaymentDiscount: selectCoinPaymentDiscount,
  auditTrial: selectAuditTrial,
  agreementPlans: selectAgreementDocumentPlans,
  planIds: selectAgreementItemsForSignWithPlanId,
  payForFriendMode: selectPayForFriendMode,
  requestedOrderData: selectRequestedOrderData,
  subscriptions: selectEntityUserSubscriptions,
});

let AUDIT_ID = null;

const Checkout = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [showAutoPayModal, toggleAutoPayModal] = React.useState(false);
  const [payForFriendFailModal, togglePayForFriendFailModal] = React.useState(false);
  const [modalPlanName, setModalPlanName] = React.useState('');
  const [showSignPdf, toggleSignPdf] = useState(false);
  const [showPreAuthorizeModal, togglePreAuthorizeModal] = React.useState(false);
  const [localAuditTrialId, setLocalAuditTrialId] = React.useState(null);
  const liveChatRef = useRef(null);

  const {
    cartData,
    billingAddresses,
    selectedPaymentMethod,
    modal,
    userDetails,
    savedMethods,
    showAutoRenewal,
    electronicSignments,
    shouldSignAgreement,
    entityBillingDetails,
    cvvCardForConfirmation,
    coinPaymentDiscount,
    auditTrial,
    agreementPlans,
    planIds,
    payForFriendMode,
    requestedOrderData,
    subscriptions,
  } = useSelector(stateSelector);

  const showCvvModal = modal === 'CVV_MODAL';

  const isEwalletOrBtcPaymentMethod = false;

  const showInitialDiscount = cartData && cartData.showInitialDiscount;

  const [showBtcDiscount, setShowBtcDiscount] = useState(
    showInitialDiscount && isEwalletOrBtcPaymentMethod
  );

  const backToTxt = intl.formatMessage({ id: 'purchase_checkout.back_to_plans' });
  const primaryAddress = billingAddresses && billingAddresses.find((item) => !!item.primary);
  const isPrimaryAddress = !!primaryAddress;
  const isEntry = cartData?.products?.some((product) => !product.isRenew && !product.isUpgrade);
  const paymentCompanyName = getCompanyName(selectedPaymentMethod?.method);
  const isAskFriendToPayMethod =
    selectedPaymentMethod?.method === PaymentConstants.SHOP_METHODS.requestToPay;
  const liveChatParams = getLiveChatParams(userDetails, subscriptions);

  const setAuditTrialStatus = async (step) => {
    // eslint-disable-next-line no-console
    console.log('setAuditTrialStatus func');
    // eslint-disable-next-line no-console
    console.log(
      "!localStorage.getItem('audit-trial-id') ",
      !localStorage.getItem('audit-trial-id')
    );
    // eslint-disable-next-line no-console
    console.log('step > auditTrial.step', step > auditTrial.step);
    // eslint-disable-next-line no-console
    console.log('isUserLogged()', isUserLogged());

    if (isUserLogged() && step > auditTrial.step) {
      // eslint-disable-next-line no-console
      console.warn('INSIDE');

      const auditTrialRequestData = {
        type: Object.values(SIGNING_AUDIT_TRAIL_TYPE).find((v) => v.step === step).type,
      };
      if (step !== SIGNING_AUDIT_TRAIL_TYPE.INITIATED.step) {
        auditTrialRequestData.externalId = auditTrial.id || localAuditTrialId || AUDIT_ID;

        // eslint-disable-next-line no-console
        console.log('auditTrial.id', auditTrial.id);
        // eslint-disable-next-line no-console
        console.log('localAuditTrialId', localAuditTrialId);
        // eslint-disable-next-line no-console
        console.log('AUDIT_ID', AUDIT_ID);
      }

      const res = await dispatch(setAuditTrail.action(auditTrialRequestData));

      const AUDIT_TRIAL_ID = res?.payload?.data?.data;
      AUDIT_ID = res?.payload?.data?.data;

      setLocalAuditTrialId(AUDIT_TRIAL_ID);

      let auditTrialData = { ...auditTrial, step };

      if (
        step === SIGNING_AUDIT_TRAIL_TYPE.INITIATED.step &&
        res.type !== setAuditTrail.type.error &&
        AUDIT_TRIAL_ID
      ) {
        auditTrialData = { ...auditTrialData, id: AUDIT_TRIAL_ID };
      }

      // if (step === SIGNING_AUDIT_TRAIL_TYPE.SIGNING_COMPLETED.step) {
      //   localStorage.setItem('audit-trial-id', auditTrial.id);
      // }

      if (step === SIGNING_AUDIT_TRAIL_TYPE.CANCELED.step) {
        auditTrialData = { id: '', step: 0 };
      }

      dispatch(setAuditTrialData(auditTrialData));

      return res?.payload?.data?.data;
    }
    return null;
  };

  useEffect(() => {
    if (!isTrivaInstance && isClient && !isUserLogged()) {
      createNavigateTo(pageLinks.pricing)();
    }
  });

  useEffect(() => {
    logEvent('begin_checkout');
    dispatch(getSignedAgreement());
    dispatch(setAuditTrialData({ id: '', step: 0 }));

    if (isClient) {
      try {
        localStorage.removeItem('audit-trial-id');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('CHECKOUT setAuditTrialData', err);
      }
    }
  }, []);

  useEffect(() => {
    if (
      payForFriendMode &&
      requestedOrderData &&
      requestedOrderData?.status !== 'Waiting for payment'
    ) {
      togglePayForFriendFailModal(true);
    }
  }, [togglePayForFriendFailModal, payForFriendMode, requestedOrderData?.status]);

  useEffect(() => {
    if (isAddonInstance && cartData.productCount) {
      dispatch(
        validateAddon(cartData.products.map((item) => ({ ...item, successActionType: 'NONE' })))
      );
    }

    if (cartData.productCount) {
      const isAnnual = cartData.products.some((p) => p.isAnnual);
      if (isAnnual) {
        dispatch(
          selectPaymentMethodFromSaved({
            method: 'coinpayments',
            username: isClient && localStorage.getItem('UN'),
          })
        );
      }
    }
  }, []);

  useLayoutEffect(() => {
    hideCrispChat();

    return () => {
      showCrispChat();
    };
  }, []);

  useEffect(() => {
    if (liveChatRef?.current?.disable_sounds) {
      liveChatRef?.current?.disable_sounds();
    }
  }, [liveChatRef?.current]);

  const submitPayment = () => {
    if (isAddonInstance) {
      return dispatch(
        validateAddon(cartData.products.map((item) => ({ ...item, successActionType: 'PURCHASE' })))
      );
    }

    return dispatch(makeOrderWithSavedCart());
  };

  const checkAndSubmit = () => {
    if (!payForFriendMode && cartData && !cartData.productCount) {
      setNotification('error', {
        message: 'Shopping cart is empty',
        title: 'Error',
      });
      return;
    }

    if (!isPrimaryAddress) {
      setNotification('error', {
        message: 'Please add/select your primary address',
        title: 'Error',
      });
      return;
    }

    if (
      payForFriendMode &&
      requestedOrderData &&
      requestedOrderData?.status !== 'Waiting for payment'
    ) {
      togglePayForFriendFailModal(true);
      return;
    }

    if (cvvCardForConfirmation) {
      dispatch(setModal('CVV_MODAL'));
      return;
    }

    //! HIDE-PREAUTH
    // if (preAuthorizeCondition) {
    //   return togglePreAuthorizeModal(true);
    // }

    const renewAutoPayItems = cartData.products
      .filter((item) => !item.autopay && item.isRenew)
      .map((plan) => plan.name)
      .reduce((acc, val, index, arr) => {
        const lastIndex = arr.length - 1;
        return `${acc} ${val}${index === lastIndex ? '' : ', '}`;
      }, '');

    if (renewAutoPayItems && !payForFriendMode) {
      toggleAutoPayModal(true);
      setModalPlanName(renewAutoPayItems);
      return;
    }

    submitPayment();
  };

  const siteName = getInstanceName();
  const title = intl.formatMessage({ id: 'pageTitle.checkout' });

  const toggleModal = (name = '') => {
    dispatch(setModal(name));
  };

  const hideBtcDiscount = () => setShowBtcDiscount(false);

  let installmentData = null;

  // TODO: move to selectors
  if (savedMethods && installmentCountries.includes(primaryAddress?.country?.toUpperCase())) {
    const cardSelected =
      selectedPaymentMethod?.method === 'payment-card' && selectedPaymentMethod.isDefault;
    const defaultCardPresent = savedMethods?.cards.find((item) => item.isDefault);

    installmentData = cardSelected || defaultCardPresent;
  }

  const submitPaymentWithInstallments = () => {
    const { id, method } = installmentData;

    // eslint-disable-next-line no-shadow
    const data = {
      gatewayAccountId: 'dlocal-installment',
      paymentInstrumentId: id,
      method,
    };

    dispatch(makeOrderWithDLocalInstallment(data));
  };

  // TODO: move to sign modal
  const showPdf = (action) => {
    if (!isClient) {
      return;
    }

    try {
      // eslint-disable-next-line consistent-return
      return showPdfAgreement(
        action,
        billingAddresses?.find((address) => address.primary),
        cartData,
        paymentCompanyName,
        agreementPlans
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error while showing PDF Document', error);
    }
  };

  // eslint-disable-next-line no-shadow
  const onSaveCvv = (data) => {
    dispatch(updateCardInfo(data));
  };

  const selectAnotherPayment = () => {
    dispatch(setModal('change'));
  };

  const hideCvvModal = () => {
    dispatch(setModal(null));
  };

  const onFailModalConfirm = () => {
    dispatch(setPayForFriendMode(false));
    togglePayForFriendFailModal(false);
    createNavigateTo(pageLinks.home)();
  };

  return (
    <>
      <SEO title={`${title} - ${siteName}`} />
      {showBtcDiscount && <BannerBitcoinDiscountHeader close={hideBtcDiscount} />}
      <Layout pageWrapperClassName="page_wraper">
        {!isTrivaInstance && (
          <div className="refferalWrapper">
            <BannerRefferal />
          </div>
        )}

        {!payForFriendMode && !isTrivaInstance && (
          <ArrowBack
            text={backToTxt}
            navigationTo={pageLinks.pricing}
            className={styles.checkoutArrowBack}
          />
        )}

        <h2 className="common_title color_primary_dark">{title}</h2>
        <div>
          <div>
            <div className={styles.wrapper}>
              <div className={styles.right_side}>
                <BillingAddressForm modal={modal} toggleModal={toggleModal} />
                {isPrimaryAddress && (
                  <BillingPaymentForm
                    toggleModal={toggleModal}
                    modal={modal}
                    showInitialDiscount={showInitialDiscount}
                  />
                )}
              </div>

              <div className={styles.left_side}>
                {!isAddonInstance && !payForFriendMode && !isTrivaInstance && (
                  <UpsellingAddons variant="preview" />
                )}

                {!isAskFriendToPayMethod && !isTrivaInstance && (
                  <div className={styles.coupon}>
                    <CouponInput page="checkout" />
                  </div>
                )}

                {showAutoPayModal && (
                  <AutoRenewInfoModal
                    plans={modalPlanName}
                    fullName={userDetails?.fullName}
                    hideModal={() => toggleAutoPayModal(false)}
                    onConfirm={submitPayment}
                  />
                )}

                {payForFriendFailModal && (
                  <PayForFriendFailModal
                    fullName={userDetails?.fullName}
                    hideModal={() => togglePayForFriendFailModal(false)}
                    onConfirm={onFailModalConfirm}
                  />
                )}

                {showCvvModal && cvvCardForConfirmation && (
                  <UpdateCvvModal
                    card={cvvCardForConfirmation}
                    fullName={userDetails?.fullName}
                    hideModal={hideCvvModal}
                    onConfirm={onSaveCvv}
                    onSkipClick={selectAnotherPayment}
                  />
                )}

                {showPreAuthorizeModal && (
                  <PreAuthorisationModal
                    hideModal={() => togglePreAuthorizeModal(false)}
                    countryCode={userDetails && userDetails.country}
                    onSkipClick={submitPayment}
                  />
                )}

                <CheckoutTotal
                  className={styles.purchaseTotal}
                  onSubmit={checkAndSubmit}
                  onInstallmentsPayment={submitPaymentWithInstallments}
                  isEntry={isEntry}
                  isPrimaryAddress={isPrimaryAddress}
                  isPaymentSet={!!selectedPaymentMethod}
                  cartData={cartData}
                  showBtcDiscount={isEwalletOrBtcPaymentMethod}
                  showInstallmentsButton={!!installmentData}
                  showAgreement={() => {
                    setAuditTrialStatus(SIGNING_AUDIT_TRAIL_TYPE.INITIATED.step);
                    toggleSignPdf(true);
                  }}
                  userDetails={userDetails}
                  showAutoRenewalMessage={showAutoRenewal}
                  electronicSignments={electronicSignments}
                  billingAddresses={billingAddresses}
                  shouldSignAgreement={
                    !isTrivaInstance &&
                    shouldSignAgreement &&
                    selectedPaymentMethod?.method &&
                    selectedPaymentMethod?.method !== PaymentConstants.SHOP_METHODS.requestToPay
                  }
                  coinPaymentDiscount={coinPaymentDiscount}
                  payForFriendMode={payForFriendMode}
                />
              </div>
            </div>
          </div>
        </div>

        {showSignPdf &&
          (selectedPaymentMethod || entityBillingDetails?.defaultPaymentInstrument) && (
            <SignPdfModal
              show={showSignPdf}
              toggle={toggleSignPdf}
              onSubmit={submitPayment}
              userDetails={userDetails}
              cartData={cartData}
              electronicSignments={electronicSignments}
              showPdf={() => showPdf('getBlob')}
              billingAddresses={billingAddresses}
              setAuditTrialStatus={setAuditTrialStatus}
              externalId={auditTrial.id}
              planIds={planIds}
              requestOrderId={requestedOrderData?.id}
            />
          )}

        {isAddonInstance && <Modals page="checkout" />}
        {!isAddonInstance && !payForFriendMode && !isTrivaInstance && <UpsellingAddons />}
        {isUserLogged() && (
          <LiveChat
            visitor={{
              name: `${userDetails?.firstName} ${userDetails?.lastName}`,
              email: userDetails?.email,
            }}
            license={process.env.GATSBY_LIVECHAT_LICENSE}
            onChatLoaded={(ref) => {
              liveChatRef.current = ref;
            }}
            params={liveChatParams}
          />
        )}
      </Layout>
    </>
  );
};

export default Checkout;
