import axios from 'axios';
import * as Sentry from '@sentry/gatsby';
import { API_URL } from './utils';
import { patchRequestHeadersInterceptor } from './tokenHandler';

const instance = axios.create({
  baseURL: API_URL,
  responseType: 'json',
});

instance.interceptors.request.use(patchRequestHeadersInterceptor, (error) => Promise.reject(error));

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status !== 401) {
      Sentry.captureException(error);
    }
    return Promise.reject(error);
  }
);

export default instance;
