const SHOP_METHODS = {
  eWallet: 'e-wallet',
  bankTransfer: 'bank-transfer',
  astroPay: 'astroPay',
  card: 'payment-card',
  ach: 'ach',
  paypal: 'paypal',
  paypalCard: 'paypal-card',
  cashDeposit: 'cash-deposit',
  coinPayments: 'coinpayments',
  rapyd: 'rapyd',
  AmazonPay: 'AmazonPay',
  paynote: 'paynote',
  requestToPay: 'requestToPay',
  MaxiCash: 'MaxiCash',
};

export const ORDER_STATUSES = {
  PENDING: 'Pending', //! === loader
  ON_HOLD: 'On hold', //! === loader
  PROCESSING: 'Processing', //! === loader
  BLOCKCHAIN_PENDING: 'Blockchain Pending', //! === loader

  PAID: 'Paid', //! === success

  CANCELED: 'Canceled', //! === fail
  REFUNDED: 'Refunded', //! === fail/refunded
  WITH_ERROR: 'Error', //! === fail
  EXPIRED: 'Expired', //! === fail
};

export default { SHOP_METHODS };
