import { navigate } from 'gatsby';

const shopLinks = {
  home: '/',
  pricing: '/pricing',
  pricingAlias: '/pricing:alias',
  pricingLive: '/pricing/live',
  pricingLearn: '/pricing/learn',
  pricingElite: '/pricing/elite',
  pricingNitro: '/pricing/nitro',
  shoppingCart: '/shopping-cart',
  memberShipPrivileges: '/membership',
  membershipTravel: '/membership/travel',
  membershipLearn: '/membership/learn',
  membershipLifestyle: '/membership/lifestyle',
  membershipConcierge: '/membership/concierge',
  verification: '/verification',
  verificationSuccess: '/verification-success',
  blog: '/blog',
  aboutUs: '/about-us',
  joinUs: '/join-us',
  contactUs: '/contact-us',
  account: '/',
  checkout: '/checkout',
  checkoutSuccess: '/checkout-result',
  additionalToPlan: '/',
  privacy: '/privacy-policy',
  gdpr: '/gdpr-policy',
  terms: '/terms-of-use',
  refund: '/refund-policy',
  cancellation: '/cancellation-policy',
  policies: '/policies-and-procedures',
  signIn: '/login',
  forgotPassword: '/forgot-password',
  forgotUsername: '/forgot-username',
  forgotPasswordSuccess: '/forgot-password-success',
  forgotUsernameSuccess: '/forgot-username-success',
  createNewPassword: '/create-new-password',
  becomeIBP: '/become-ibp',
  registrationSuccess: '/registration-success',
  regions: '/regions',
  registration: '/registration',
  addons: '/addons',
  preAuthorisation: '/pre-authorisation',
  promote: '/promote',
  promoteSignUp: '/promote-sign-up',
  signUp2: '/sign-up-2',
  signUp3: '/sign-up-3',
  signUp4: '/sign-up-4',
  preCheckout: '/pre-checkout',
  ibpAgreement: '/ibp-agreement',
  returnPolicy: '/payment-return-policy',
  annualPackages: '/annual-packages',
};

const addonLinks = {
  ...shopLinks,
  pricing: '/',
};

const getLinks = () => {
  if (process.env.GATSBY_INSTANCE_NAME === 'addon') {
    return addonLinks;
  }
  return shopLinks;
};

const path = process.env.GATSBY_INSTANCE_NAME === 'addon' ? 'addons' : 'pricing';

const stagingExternalLinks = {
  alternativePaymentMethod: `https://staging-onlinecommerce.kinsta.cloud/${path}`,
};
const productionExternalLinks = {
  alternativePaymentMethod: `https://checkout.online-era.com/${path}`,
};

export const createNavigateTo =
  (to = '/', options) =>
  () =>
    navigate(to, options);

// TODO move addons to separate inst
export const externalLinks =
  process.env.GATSBY_INSTANCE_TYPE === 'prod' || process.env.GATSBY_REBILLY_KEY.includes('live')
    ? productionExternalLinks
    : stagingExternalLinks;

export const pageLinks = getLinks();
