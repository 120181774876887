import apiAction from '../apiActionCreator';

const type = 'USER_SIGN_UP';

const config = {
  url: '/auth/signup-cp',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
