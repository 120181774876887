import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import { useIntl } from 'gatsby-plugin-intl';
import ArrowBack from '../arrowBack';
import { pageLinks } from '../../helpers/navigation';
import styles from './subscription-plan-hero.module.scss';
import { isAddonInstance } from '../../helpers/instanceHandler';

const SubscriptionPlanHero = ({ image, name, price, productBgColor }) => {
  const intl = useIntl();
  const titleTxt = intl.formatMessage({ id: 'subscription_plan_hero.subscription_plan' });
  const startingFromTxt = intl.formatMessage({ id: 'subscription_plan_hero.starting_from' });
  const backToTxt = intl.formatMessage({ id: 'subscription_plan_hero.back_to_plans' });
  const hideStartingFromPrice = true;

  const callToAction1 = '';
  const callToAction2 = '';

  return (
    <BackgroundImage fadeIn Tag="div" fluid={image} className={styles.bgImage}>
      <div className={styles.content}>
        <ArrowBack
          text={backToTxt}
          navigationTo={isAddonInstance ? pageLinks.annualPackages : pageLinks.pricing}
        />

        <div className={styles.title}>
          <p
            className="dont-translate"
            style={{
              textTransform: 'capitalize',
            }}
          >
            {name || ''} &nbsp;
          </p>
          <p>{titleTxt}</p>
        </div>
        {!hideStartingFromPrice && price && (
          <div className={styles.price}>
            <p>
              {startingFromTxt}
              &nbsp;
              <span style={{ color: productBgColor }}>{`$${price}/month`}</span>
            </p>
          </div>
        )}
        <div className={styles.price}>
          <p>{callToAction1}</p>
        </div>
        <div className={styles.price}>
          <p>{callToAction2}</p>
        </div>
      </div>
    </BackgroundImage>
  );
};

SubscriptionPlanHero.defaultProps = {
  price: null,
};

SubscriptionPlanHero.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  productBgColor: PropTypes.string.isRequired,
};

export default SubscriptionPlanHero;
