import apiAction from '../apiActionCreator';

const type = 'PURCHASE_FRIENDS_ORDER';

const config = {
  url: '/orders/request/purchase',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
