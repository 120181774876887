import React from 'react';
import PropTypes from 'prop-types';

import Img from 'gatsby-image';
import styles from './cart-item-registration.module.scss';

const CartItemRegistartion = ({
  name,
  price,
  descriptionPrimary,
  description,
  icon,
  bgColor,
  color,
  ibpFluid,
  showRecurringDescription,
}) => (
  <div className={styles.item}>
    {ibpFluid ? (
      <div className={styles.ibpImage}>
        <Img fluid={ibpFluid} />
      </div>
    ) : (
      <div className={styles.image} style={{ color, backgroundColor: bgColor }}>
        <i className={`icon-${icon}`} />
      </div>
    )}

    <div className={styles.info}>
      <p className={styles.name}>{name}</p>
      {showRecurringDescription && descriptionPrimary ? (
        <p className={styles.descriptionPrimary}>{descriptionPrimary}</p>
      ) : null}
      {showRecurringDescription && description ? (
        <p className={styles.description}>{description}</p>
      ) : null}
    </div>

    <div className={styles.price}>{`${price}$`}</div>
  </div>
);

CartItemRegistartion.defaultProps = {
  descriptionPrimary: null,
  description: null,
  icon: '',
  ibpFluid: null,
};

CartItemRegistartion.propTypes = {
  name: PropTypes.string.isRequired,
  descriptionPrimary: PropTypes.string,
  description: PropTypes.string,
  bgColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.string,
  price: PropTypes.number.isRequired,
  ibpFluid: PropTypes.element,
  showRecurringDescription: PropTypes.bool.isRequired,
};

export default CartItemRegistartion;
