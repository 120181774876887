import apiAction from '../apiActionCreator';

const type = 'SET_PAYMENT_AS_DEFAULT';

const config = {
  url: '/payments/card/make-default',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
