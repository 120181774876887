import clsx from 'clsx';

import React from 'react';
import PropTypes from 'prop-types';

import styles from './inputBlockRegistation.module.scss';

const InputBlockRegistation = (props) => {
  const { title, children, bordered } = props;

  return (
    <div className={clsx(styles.wrapper, bordered ? null : styles.withoutBorder)}>
      {title ? <div className={clsx(styles.title)}>{title}</div> : null}

      {children}
    </div>
  );
};

InputBlockRegistation.defaultProps = {
  title: null,
  bordered: true,
};

InputBlockRegistation.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  bordered: PropTypes.bool,
};

export default InputBlockRegistation;
