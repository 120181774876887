import apiAction from '../apiActionCreator';

const type = 'TWILIO_PHONE_VERIFICATION';

const config = {
  url: '/auth/phone-verification',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
