import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import Input from '../input';
import validateReferral from '../../services/api/actions/validateReferral';
import BillingPaymentBlock from '../billing-payment-block';
import Button from '../button';
import styles from './request-to-pay-block.module.scss';

const RequestToPayBlock = ({ onSubmit, method }) => {
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [referral, setReferral] = useState(null);
  const dispatch = useDispatch();

  const getReferralInfo = async (e) => {
    e?.preventDefault();

    if ((e.target.value === value && referral) || !value.length) {
      return;
    }

    const { payload, error } = await dispatch(validateReferral.withQuery(`/${value}`).action());

    if (payload) {
      setReferral(payload.data);
    }

    if (error) {
      const message = error?.message.includes('400')
        ? 'Invalid customer ID or Name'
        : error.message;
      setErrorMessage(message);
    }
  };

  const handleChange = (e) => {
    if (errorMessage) {
      setErrorMessage('');
    }

    if (referral) {
      setReferral(null);
    }

    setValue(e.target.value.trim());
  };

  return (
    <>
      <BillingPaymentBlock method={method} isDefault={false} isDisabled={false} />

      <div className={styles.form_wrapper}>
        <form onSubmit={getReferralInfo}>
          <Input
            wrapperClassName={styles.input}
            id="enrollerId"
            label="Customer ID/Referral Name"
            placeholder="Please enter ID/Referral Name"
            onChange={handleChange}
            value={value}
            onBlur={getReferralInfo}
            fullwidth
            disabled={false}
            required
            errorMessage={errorMessage}
          />

          <Button
            variant="outlined"
            defaultWidth
            disabled={!value.length || referral}
            type="submit"
          >
            Check Name
          </Button>
        </form>

        {referral && (
          <Input
            id="name"
            label="Order will be forwarded to"
            placeholder=""
            value={`${referral.firstName} ${referral.lastName}`}
            fullwidth
            disabled
            onChange={() => {}}
          />
        )}
      </div>

      <div className={styles.modal_right_side_footer}>
        <Button
          size="large"
          defaultWidth
          disabled={!referral}
          onClick={() => {
            onSubmit({
              method,
              requestCoordinatorId: referral.customerId,
            });
          }}
        >
          Select
        </Button>
      </div>
    </>
  );
};

RequestToPayBlock.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  method: PropTypes.string.isRequired,
};

export default RequestToPayBlock;
