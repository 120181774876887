import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'gatsby-plugin-intl';
import Modal from '../modal';
import stylesModal from '../modals/modals.module.scss';
import Button from '../button';
import { setModalInfo } from '../../store/actions';
import { selectAddonsModalInfo } from '../../store/selectors/global';
import { selectEntityUserDetails, selectOrderResultData } from '../../store/selectors/entities';
import { ORDER_STATUSES } from '../../constants/payment';
import styles from './previous-order-modal.module.scss';
import OrderSummary from '../order-summary';
import payCancelledOrder from '../../services/api/actions/payCancelledOrder';

const PreviousOrderModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const modalInfo = useSelector(selectAddonsModalInfo);
  const orderData = useSelector(selectOrderResultData);
  const userDetails = useSelector(selectEntityUserDetails);
  const hideModal = () => {
    localStorage.removeItem('POR');
    localStorage.removeItem('POR-boughtProducts');
    dispatch(setModalInfo({ type: null }));
  };

  const retryRequest = async () => {
    const redirectUrl =
      process.env.NODE_ENV === `production`
        ? `${window.location.origin}/checkout-result/?complete={result}`
        : 'https://shop.stg.befreedom.com/checkout-result/?complete={result}';

    await dispatch(
      payCancelledOrder.action({
        orderId: orderData.id,
        redirectUrl,
      })
    );
    hideModal();
  };
  return (
    <Modal
      closeButton={false}
      onCloseClick={hideModal}
      className={clsx(stylesModal.modal, stylesModal.previousOrderModal)}
      modalHeaderContainerStyles={clsx(styles.headerReset)}
      isShown={modalInfo.type === 'ORDER_RESULT' && orderData}
      modalType="ORDER_RESULT"
    >
      <div className={styles.modalWrapper}>
        <div className={styles.textWrapper}>
          <p className={styles.text}>
            {`${intl.formatMessage({ id: 'hello' })}, `}
            <span>{userDetails?.firstName}</span>!
          </p>
          <p className={styles.text}>
            Your order has been&nbsp;
            <span className={styles[orderData?.status.toLowerCase()]}>
              {orderData?.status.toLowerCase() || ''}
            </span>
            !
          </p>
          <p className={clsx(styles.text)}>{orderData?.message}</p>
        </div>
        <OrderSummary
          isOrderPlaced={orderData?.status === ORDER_STATUSES.PAID}
          electronicSignments={[]}
          isPreviousOrder
        />
        <div className={clsx(styles.buttonWrapper)}>
          {orderData?.status !== ORDER_STATUSES.PAID && (
            <Button className={stylesModal.modalButton} onClick={retryRequest} size="large">
              Retry
            </Button>
          )}
          <Button className={stylesModal.modalButton} onClick={hideModal} size="large">
            Got it
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PreviousOrderModal;
