import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal';
import Button from '../button';
import styles from './modals.module.scss';

const AutoRenewInfoModal = ({ onConfirm, hideModal, plans, fullName }) => {
  const onConfirmClick = () => {
    onConfirm();
    hideModal();
  };

  return (
    <Modal
      onCloseClick={hideModal}
      className={styles.modal}
      isShown
      title={`Dear ${fullName}`}
      idOverlay="renewModal"
    >
      <div className={styles.modalWrapper}>
        <p className={styles.modalText}>
          Your <strong className={styles.plansText}>{plans}</strong> will be auto-renewed upon its
          expiration date. You can manage your subscriptions on your customer portal under “Manage
          subscription” section.
        </p>
        <Button className={styles.modalButton} onClick={onConfirmClick} size="large">
          Ok
        </Button>
      </div>
    </Modal>
  );
};

AutoRenewInfoModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  plans: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
};

export default AutoRenewInfoModal;
