import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { isRetailAddonInstance } from '../helpers/instanceHandler';
import SEO from '../components/seo';
import Layout from '../components/layout';
import BannerRefferal from '../components/banner-refferal/banner-refferal';
import Addon from '../components/addon';
import CartItemPopup from '../components/cart-item-popup';
import Modals from '../components/modals';
import withLocation from '../hoc/withLocation';
import { hideAddonPanel, validateAddon } from '../store/actions';
import { selectAddonsWithSubscriptions } from '../store/selectors/entities';
import styles from '../styles/pages/addons.module.scss';
import { isClient, isUserLogged, logEvent } from '../helpers/utils';
import { createNavigateTo, pageLinks } from '../helpers/navigation';

const wsAddons = ['ADD-RET-US30', 'ADD-US30'];
const availableAddonList = [
  'ADD-BFE05',
  'ADD-TE08',
  'ADD-GPRM09',
  'ADD-PR08',
  'ADD-NFX01',
  'ADD-SHIFT',
  'ADD-RLC03',
  'ADD-RLC365',
  ...wsAddons,
];

// eslint-disable-next-line react/prop-types
const AddonsPage = ({ webAlias, PlacementType: placementType, Token: refferalToken }) => {
  const dispatch = useDispatch();
  const addons = useSelector(selectAddonsWithSubscriptions);
  const addonsText = 'Add-Ons';
  const comingSoonText = 'Coming soon';
  const soonAddons =
    (isUserLogged() && addons.filter((item) => !availableAddonList.includes(item.sku))) || [];
  const availableAddons =
    (isUserLogged() && addons.filter((item) => availableAddonList.includes(item.sku))) || [];

  useEffect(() => {
    if (isClient && !isUserLogged()) {
      createNavigateTo(pageLinks.pricing)();
    }
  });

  useEffect(() => {
    dispatch(hideAddonPanel());
    logEvent('visited_addons');
    return () => dispatch(hideAddonPanel());
  }, []);

  const onAddClick = (addon) => {
    logEvent('add_to_cart');
    dispatch(validateAddon([{ ...addon, successActionType: 'ADD_TO_CART' }]));
  };

  return (
    <>
      <SEO title="Addons" />
      <Layout pageWrapperClassName="page_wrapper addons">
        <div className="refferalWrapper">
          <BannerRefferal
            webAlias={webAlias}
            placementType={placementType}
            refferalToken={refferalToken}
          />
        </div>

        <div className={styles.container}>
          <div className={styles.wrapper}>
            <CartItemPopup isAddonsPage />
          </div>

          <div className={styles.title}>{addonsText}</div>
          <div className={styles.addons}>
            {availableAddons.map((item) => (
              <Addon
                key={item.id}
                id={item.sku}
                name={item.product.name}
                intervalSize={item.intervalSize}
                price={item.price}
                recurringPrice={item.recurringPrice}
                description={item.bullets}
                onClick={() => onAddClick(item)}
                expiresAt={item.expiresAt}
                isExpired={item.isExpired}
                isRenew={item.isRenew}
                currentSubscription={item.currentSubscription}
                imageUrl={item.imageUrl}
              />
            ))}
          </div>

          <div className={styles.subtitle}>{comingSoonText}</div>
          <div className={styles.addons}>
            {soonAddons.map((item) => (
              <Addon
                disabled
                key={item.id}
                id={item.sku}
                name={item.product.name}
                description={item.bullets}
                price={null}
                imageUrl={item.imageUrl}
              />
            ))}
          </div>
        </div>

        <Modals page="" />
      </Layout>
      <ReactTooltip className={styles.tooltip} />
    </>
  );
};

export default isRetailAddonInstance ? withLocation(AddonsPage) : AddonsPage;
