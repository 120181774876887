import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Img from 'gatsby-image';
import clsx from 'clsx';
import { createStructuredSelector } from 'reselect';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Button from '../components/button';
import {
  selectPhoneVerificationButtonState,
  selectRegisterValues,
} from '../store/selectors/global';
import { getInstanceName } from '../helpers/utils';
import phoneVerify from '../services/api/actions/phoneVerify';
import styles from '../styles/pages/verifications.module.scss';
import ArrowBack from '../components/arrowBack';
import { pageLinks } from '../helpers/navigation';
import setNotification from '../helpers/notifications';
import { registerUser } from '../store/actions/index';

const query = graphql`
  query {
    verifyIcon: file(relativePath: { eq: "verify-icon.png" }) {
      childImageSharp {
        fixed(width: 162) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const stateSelector = createStructuredSelector({
  registerValues: selectRegisterValues,
  allowToSkipPhoneVerification: selectPhoneVerificationButtonState,
});

const RegistrationSuccessPage = () => {
  const dispatch = useDispatch();
  const data = useStaticQuery(query);
  const {
    allowToSkipPhoneVerification,
    registerValues: { phone },
  } = useSelector(stateSelector);

  const [value, setValue] = useState({ 1: '', 2: '', 3: '', 4: '' });
  const [counter, setCounter] = useState(0);

  const isRetryAllowed = counter <= 2;

  const siteName = getInstanceName();

  const onChange = (e, position) => {
    const isNumber = /^-?[\d.]+(?:e-?\d+)?$/.test(Number(e.target.value));
    if (!isNumber) {
      return;
    }

    const nextValue = { ...value };
    const backSpace = e.keyCode === 8;
    nextValue[position] = e.target.value;

    setValue(nextValue);

    const nextPosition = position + 1;

    if (
      value[nextPosition.toString()] &&
      value[nextPosition.toString()].length &&
      !(!e.target.value.length && backSpace)
    ) {
      return;
    }

    const nextInput = document.getElementById(`input#${Number(position) + 1}`);
    const prevInput = document.getElementById(`input#${Number(position) - 1}`);

    if (nextInput && e.target.value.length) {
      nextInput.focus();
      return;
    }
    if (!e.target.value.length && backSpace && prevInput) {
      prevInput.focus();
    }
  };

  const onFocus = (position) => {
    const textFiled = document.getElementById(`input#${position}`);
    textFiled.addEventListener('focus', () => {
      // eslint-disable-next-line react/no-this-in-sfc, no-unused-expressions
      this && this.select && this.select();
    });
  };

  const onKeyPress = (e, position) => {
    const backSpace = e.keyCode === 8;
    const arrowLeft = e.keyCode === 37;
    const arrowRight = e.keyCode === 39;
    const caretStart = e.target.selectionStart === 0;
    const inputValue = e.target.value;

    const nextInput = document.getElementById(`input#${Number(position) + 1}`);
    const prevInput = document.getElementById(`input#${Number(position) - 1}`);

    if (!inputValue && backSpace && prevInput) {
      prevInput.focus();
    }

    if (arrowLeft && caretStart && prevInput) {
      prevInput.focus();
    }

    if (arrowRight && !caretStart && nextInput) {
      nextInput.focus();
    }
  };

  const code = Object.values(value).join('');
  const fullValue = code.length === 4;

  const resendCode = async (e) => {
    e.preventDefault();

    if (isRetryAllowed) {
      const { payload } = await dispatch(phoneVerify.action({ phone }));

      if (payload) {
        setCounter((prevValue) => prevValue + 1);
        setNotification('success', {
          message: 'Request was sent',
          title: '',
        });
      }
    }
  };

  const verifyCode = () => dispatch(phoneVerify.action({ code, phone }));

  const skipPhoneVerification = () => dispatch(registerUser(false));

  useEffect(() => {
    if (!isRetryAllowed) {
      setTimeout(() => setCounter(0), 30000);
    }
  }, [counter]);

  return (
    <>
      <SEO title={`Verification - ${siteName}`} />
      <Layout fluid pageWrapperClassName="page_wrapper">
        <div className="container">
          <div className={styles}>
            <ArrowBack text="back to registration" navigationTo={pageLinks.registration} />
          </div>

          <>
            <div className={styles.wrapper}>
              <div className={styles.title}>Verification</div>

              <div className={styles.textInfo}>Code is sent to: {phone}</div>

              <div className={styles.img}>
                <Img fixed={data.verifyIcon.childImageSharp.fixed} />
              </div>

              <div className={clsx(styles.textInfo, styles.long)}>
                Please enter the code that has been sent to your phone number
              </div>

              <form action="" className={styles.form}>
                {[1, 2, 3, 4].map((item) => (
                  <input
                    name={item}
                    id={`input#${item}`}
                    type="tel"
                    maxLength={1}
                    className={clsx(styles.input, value[item].length ? styles.filled : '')}
                    onChange={(e) => onChange(e, item)}
                    onKeyDown={(e) => onKeyPress(e, item)}
                    onFocus={() => onFocus(item)}
                    value={value[item]}
                  />
                ))}
              </form>

              <div className={styles.footer}>
                Didn’t receive code?
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <span
                  title={!isRetryAllowed && "Sorry, retry isn't allowed for next 30 seconds"}
                  className={clsx(styles.referalLink, !isRetryAllowed && styles.disabledRetry)}
                  onClick={resendCode}
                >
                  Request again
                </span>
              </div>
            </div>
          </>
        </div>

        <div className={styles.action}>
          {allowToSkipPhoneVerification ? (
            <Button
              className={styles.button}
              size="large"
              disabled={!fullValue}
              onClick={skipPhoneVerification}
            >
              Skip
            </Button>
          ) : (
            <Button
              className={styles.button}
              size="large"
              disabled={!fullValue}
              onClick={verifyCode}
            >
              Verify
            </Button>
          )}
        </div>
      </Layout>
    </>
  );
};

export default RegistrationSuccessPage;
