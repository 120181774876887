import FakeLocalStorage from './fakeStorage';

export const isLocalStorageAvailable = () => {
  const isLocalStorage = 'isLocalStorage';
  try {
    localStorage.setItem(isLocalStorage, isLocalStorage);
    localStorage.removeItem(isLocalStorage);
    return true;
  } catch (e) {
    return false;
  }
};

export const useStorage = () => {
  if (isLocalStorageAvailable()) {
    return localStorage;
  }

  return new FakeLocalStorage();
};
