/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import clsx from 'clsx';
import React from 'react';

import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
// import * as Sentry from '@sentry/gatsby';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { selectEmailBannerFlag, selectLoader } from '../store/selectors/global';
import Header from './header';
import Footer from './footer';
import Loader from './loader';
import BannerRefferalHeader from './banner-refferal-header';
import CookieBannerCmp from './cookie-banner';
import BannerEmailCheck from './banner-email-check';
import useWeglot from '../hooks/useWeglot';
import { selectRefferalInfo } from '../store/selectors/entities';
import { isClient } from '../helpers/utils';
import { useSift } from '../../config/sift';
import { isTrivaInstance } from '../helpers/instanceHandler';

const stateSelector = createStructuredSelector({
  isLoading: selectLoader,
  refferalInfo: selectRefferalInfo,
  isWrongEmail: selectEmailBannerFlag,
});

const Layout = ({ children, pageClassName, pageWrapperClassName, fluid, withRefferalBanner }) => {
  const { isLoading, refferalInfo, isWrongEmail } = useSelector(stateSelector);
  const showBannerHeader = refferalInfo && refferalInfo.showBannerHeader;
  const bannerClassName = showBannerHeader ? 'withBannerHeader' : '';

  useWeglot();
  useSift();

  return (
    // <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>} showDialog>
    <div className={clsx(pageClassName, 'layout')}>
      <Header />
      <div className={clsx('globalWrapper', pageWrapperClassName, bannerClassName)}>
        <div className={fluid ? null : 'container'}>
          <main>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {showBannerHeader && withRefferalBanner && <BannerRefferalHeader {...refferalInfo} />}
            {isWrongEmail && <BannerEmailCheck />}
            {children}
            {isClient && <Loader isLoading={isLoading} />}
          </main>
        </div>
      </div>
      {!isTrivaInstance && <Footer />}
      <NotificationContainer />
      {!isTrivaInstance && <CookieBannerCmp />}
    </div>
    // </Sentry.ErrorBoundary>
  );
};

Layout.defaultProps = {
  pageClassName: null,
  fluid: null,
  pageWrapperClassName: null,
  withRefferalBanner: true,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageClassName: PropTypes.string,
  pageWrapperClassName: PropTypes.string,
  fluid: PropTypes.bool,
  withRefferalBanner: PropTypes.bool,
};

export default Layout;
