/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { withErrorBoundary } from 'react-error-boundary';
import { createStructuredSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { RichText } from 'prismic-reactjs';
import Layout from '../../components/layout';
import SubscriptionPlanHero from '../../components/subscription-plan-hero';
import PlanLevelCard from '../../components/plan-level-card';
import Accordion from '../../components/accordion';
import JoinCTA from '../../components/join-cta';
import CartItemPopup from '../../components/cart-item-popup';
import { selectPlans, selectCart } from '../../store/selectors';
import { selectCouponId } from '../../store/selectors/global';
import { selectEntityCoupons, selectRefferalInfo } from '../../store/selectors/entities';
import { addToCart, preselectCoupon, selectCoupon, setBannerRefferal } from '../../store/actions';
import setNotification from '../../helpers/notifications';
import { isClient, getInstanceName, logEvent } from '../../helpers/utils';
import SEO from '../../components/seo';
import BannerQuestCoupon from '../../components/banner-quest-coupon/banner-quest-coupon';
import styles from './plan.module.scss';
import { createNavigateTo } from '../../helpers/navigation';
import BannerRefferal from '../../components/banner-refferal';
import { fluidImage } from '../../shared/propTypes';

export const query = graphql`
  query Plan($image_name: String) {
    defaultImage: file(relativePath: { eq: "plan/default-plan.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1440, maxHeight: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    image(name: { eq: $image_name }) {
      id
      name
      image {
        id
        childImageSharp {
          fluid {
            base64
            tracedSVG
            srcWebp
            srcSetWebp
            originalImg
            originalName
            srcSet
            src
            aspectRatio
            sizes
            presentationWidth
            presentationHeight
          }
        }
      }
    }

    prismic {
      allFrequentlyaskedquestions {
        edges {
          node {
            question
            answer
          }
        }
      }
    }
  }
`;

const getPromoMonthAmount = (name) => {
  const planName = name.toLowerCase();

  switch (true) {
    case planName.includes('gold'): {
      return 2;
    }
    case planName.includes('prime'): {
      return 4;
    }

    default: {
      return 1;
    }
  }
};

const questionsType = 'allFrequentlyaskedquestions';

const stateSelector = (productName, showAnnualPlans) =>
  createStructuredSelector({
    plans: selectPlans(productName, showAnnualPlans),
    cartData: selectCart,
    selectedCoupon: selectCouponId,
    couponList: selectEntityCoupons,
    referralInfo: selectRefferalInfo,
  });

const Plan = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const siteName = getInstanceName();

  const {
    data,
    pageContext: { productBgColor, productUrl, isAnnual },
  } = props;

  const { plans, cartData, selectedCoupon, couponList, referralInfo } = useSelector(
    stateSelector(productUrl, isAnnual)
  );

  const [selectedPlan, setSelected] = useState(null);
  // eslint-disable-next-line react/prop-types
  const helpContent = data.prismic[questionsType].edges.map((doc) => ({
    title: RichText.asText(doc.node.question),
    description: doc.node.answer,
  }));

  const planList = plans;
  const basePlanData = plans?.length && plans[0];

  useEffect(() => {
    if (basePlanData) {
      logEvent(`visited_pricing_${basePlanData?.product.name}`);
    }
  }, []);

  const isRenewBannerShown = basePlanData?.isRenewBannerShown;
  const price = basePlanData?.recurringPrice || null;

  const onAddToCart = (plan) => {
    const { products } = cartData;

    logEvent('add_to_cart');

    const alreadyAddedPlan = products.some((p) => p.id === plan.id);

    if (!alreadyAddedPlan) {
      if (referralInfo?.showBanner) {
        dispatch(setBannerRefferal(false));
      }

      const appliedCoupon =
        couponList && couponList.find((item) => item.couponCode === selectedCoupon);

      const elitePlan = products.find((item) => item.isCombo);

      const cartPlansWhichIsPartOfCombo = products.filter((item) => item.isPartOfCombo);
      const ticketInCart = products.some((item) => item.isTicket);

      if (plan.isTicket && products.length && !ticketInCart) {
        setNotification('info', {
          message:
            'We have replaced your current item with an event ticket item since you cannot buy an event ticket and subscription plan in one order',
          title: '',
        });
      }

      if (!plan.isTicket && products.length && ticketInCart) {
        setNotification('info', {
          message:
            'We have replaced your current item with a subscription plan item since you cannot buy an event ticket and subscription plan in one order',
          title: '',
        });
      }

      if (plan.isCombo && cartPlansWhichIsPartOfCombo.length && !elitePlan) {
        const productNames = cartPlansWhichIsPartOfCombo
          .map((item) => ` ${item.name} `)
          .reduce((acc, val, idx, src) => `${acc} ${!src[idx + 1] ? val : `${val} and`} `, '');

        setNotification('info', {
          message: `Your Cart item/s updated with Elite subscription since ${productNames} already included with Elite Subscription`,
          title: '',
        });
      }

      if (appliedCoupon) {
        dispatch(preselectCoupon(null));
        dispatch(selectCoupon(null));
      }

      dispatch(addToCart('product', plan));
    }

    setSelected({
      productName: plan.product.name,
      level: plan.name,
      color: plan.color,
      bgColor: plan.bgColor,
      icon: plan.icon,
    });

    if (isClient) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const moveToElite = () => {
    createNavigateTo('/pricing/elite')();
  };

  const fullImage =
    data?.image?.image?.childImageSharp?.fluid || data?.defaultImage?.childImageSharp?.fluid;

  if (!basePlanData) {
    return null;
  }

  return (
    <Layout fluid>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <SEO title={`Pricing ${basePlanData.product.name} - ${siteName}`} />
      <div className={styles.planReferralWrapper}>
        <div className="refferalWrapper">
          <BannerRefferal />
        </div>
      </div>
      {selectedPlan && <CartItemPopup {...selectedPlan} />}
      <div className={styles.heroWrapper}>
        {fullImage && (
          <SubscriptionPlanHero
            name={!isAnnual && basePlanData?.product?.name}
            price={price}
            image={fullImage}
            productBgColor={productBgColor}
          />
        )}
      </div>

      {isRenewBannerShown && !isAnnual && <BannerQuestCoupon />}

      <div className={styles.plan}>
        <div className={styles.levels}>
          {planList &&
            planList.map((plan) => (
              <PlanLevelCard
                addToCart={(e) => {
                  e.preventDefault();
                  onAddToCart(plan);
                }}
                key={plan.id}
                isSmallFont={planList.length > 3}
                recurringPrice={plan.recurringPrice}
                price={plan.price || plan.setupPrice}
                averageSaving={
                  plan.additionalInfo && plan.additionalInfo.length ? plan.additionalInfo : null
                }
                isCurrent={plan.isCurrent}
                isCombo={plan.isCombo}
                isActive={plan.isActive}
                canRenew={plan.isRenew}
                canUpgrade={plan.isUpgrade}
                isButtonHide={plan.isButtonHide}
                upgradePrice={plan.upgradePrice}
                productUrlParam={plan.product.url}
                level={plan.name}
                moveToElite={moveToElite}
                showEliteMessage={plan.showEliteMessage}
                isEliteBought={plan.isEliteBought}
                moreThanThree={planList.length > 3}
                color={plan.color}
                bgColor={plan.bgColor}
                imageUrl={plan.imageUrl}
                bullets={plan.bullets}
                icon={plan.icon}
                comboMessage={plan.comboMessage}
                isPartOfCombo={plan.isPartOfCombo}
                intervalSize={plan.intervalSize}
                extraMonths={
                  isAnnual &&
                  plan.product.url !== 'learn-enterprise' &&
                  getPromoMonthAmount(plan.name, plan)
                }
              />
            ))}
        </div>
        <div className={styles.help}>
          <h2 className={styles.helpHeader}>
            {intl.formatMessage({ id: 'help.how_we_can_help_you' })}
          </h2>
          <Accordion items={helpContent} />
        </div>
        <div className={styles.cta}>
          <JoinCTA />
        </div>
      </div>
    </Layout>
  );
};

Plan.propTypes = {
  data: PropTypes.shape({
    defaultImage: fluidImage.isRequired,
    image: PropTypes.shape({ image: fluidImage }).isRequired,
  }).isRequired,

  pageContext: PropTypes.shape({
    productBgColor: PropTypes.string.isRequired,
    productUrl: PropTypes.string.isRequired,
    isAnnual: PropTypes.bool.isRequired,
  }).isRequired,
};

const ComponentWithErrorBoundary = withErrorBoundary(Plan, {
  FallbackComponent: () => <div>ERROR</div>,
  onError(error, info) {
    // eslint-disable-next-line
    console.log('error', error);
    // eslint-disable-next-line
    console.log('info', info);
  },
});

export default ComponentWithErrorBoundary;
