import React from 'react';
import PropTypes from 'prop-types';
import styles from './flag-label.module.scss';

const strings = {
  discount: 'Discount for buying',
  via: 'via E-Wallet or BTC',
  count: '5%',
  off: 'OFF',
};

const FlagLabel = ({ bgColor, textColor, isSmallFont, isCurrent }) => (
  <div
    className={styles.btcDiscount}
    style={{
      top: isCurrent ? '33px' : '-5px',
      backgroundColor: bgColor,
      color: textColor,
    }}
  >
    <div
      className={styles.btcText}
      style={{
        color: textColor,
        opacity: 0.8,
        fontSize: isSmallFont ? 15 : 18,
      }}
    >
      {`${strings.discount}
      ${strings.via}`}
    </div>

    <div className={styles.container}>
      <SVGFlag background={bgColor} />
      <div className={styles.textContainer} data-color={textColor}>
        <div className={styles.count}>{strings.count}</div>
        <div className={styles.off} />
      </div>
    </div>
  </div>
);

export default FlagLabel;

FlagLabel.propTypes = {
  bgColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  isSmallFont: PropTypes.bool.isRequired,
  isCurrent: PropTypes.bool.isRequired,
};

const SVGFlag = ({ background }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="90px"
    height="93px"
    viewBox="0 0 180.000000 186.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,186.000000) scale(0.100000,-0.100000)"
      fill={background}
      stroke="none"
    >
      <path
        d="M28 1852 c-9 -2 -19 -13 -22 -25 -3 -12 -6 -428 -6 -923 l0 -902 450
190 450 190 367 -155 c203 -86 405 -171 451 -191 l82 -35 0 904 c0 776 -2 906
-15 918 -12 12 -128 16 -762 21 -411 3 -800 7 -864 9 -63 1 -123 1 -131 -1z"
      />
    </g>
  </svg>
);

SVGFlag.propTypes = {
  background: PropTypes.string.isRequired,
};
