import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './description.module.scss';

const Description = ({ items, color }) => {
  const [fullSizeDescription, toggleDescription] = useState(false);

  if (!items?.length) {
    return null;
  }

  const onSeeMoreClick = () => {
    toggleDescription((prev) => !prev);
  };

  const descriptions = fullSizeDescription ? items : [items[0], items[1]];
  const showMoreButton = items.length > 2;

  return (
    <>
      <div className={styles.services}>
        {descriptions.map((item) => (
          <div key={item} className={styles.serviceItem}>
            <i className={clsx('icon-check-rounded', styles.serviceItemIcon)} style={{ color }} />
            <p>{item}</p>
          </div>
        ))}
      </div>

      {showMoreButton && (
        <button className={styles.button} type="button" onClick={onSeeMoreClick}>
          {`Show ${fullSizeDescription ? 'less' : 'more'} `}
        </button>
      )}
    </>
  );
};

Description.defaultProps = {
  items: [],
};

Description.propTypes = {
  color: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string),
};

export default Description;
