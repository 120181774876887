/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// import * as React from 'react';
// import { Profiler } from 'react';
import './static/style-lib/loading.min.css';
import './src/styles/app.global.scss';
import 'firebase/analytics';
import { createNavigateTo, pageLinks } from './src/helpers/navigation';
import wrapWithProvider from './wrap-with-provider';
import { getSearchParam } from './src/helpers/url';
import { isAddonInstance } from './src/helpers/instanceHandler';

// const { registerLinkResolver } = require('@prismicio/gatsby-source-prismic-graphql');
// const { linkResolver } = require('./src/utils/linkResolver');
// registerLinkResolver(linkResolver);

export const wrapRootElement = wrapWithProvider;

export const onInitialClientRender = () => {
  if (!isAddonInstance && window.location.pathname.includes('/enrollment')) {
    const path = window.location.pathname.split('/')[2];
    const moveToPricing = !!(path && path.length);
    setTimeout(createNavigateTo(moveToPricing ? pageLinks.pricing : pageLinks.home), 0);
  }

  if (window.Weglot && window.Weglot.initialize) {
    window.Weglot.initialize({
      api_key: 'wg_c928b7985fc050254840551fa97857ca5',
      hide_switcher: true,
    });
  }

  const lang = getSearchParam('lang', window.location.href);

  if (window.Weglot) {
    window.Weglot.on('initialized', () => {
      const allowedLang = ['en', 'de', 'fr', 'pt', 'es', 'zh', 'ko', 'ru', 'ja', 'sk'];
      if (lang && allowedLang.includes(lang)) {
        window.Weglot.switchTo(lang);
      } else if (lang) {
        window.Weglot.switchTo('en');
      }
    });
  }
};

export const onServiceWorkerUpdateReady = () => {
  // eslint-disable-next-line no-alert
  const answer = window?.confirm(
    `This application has been updated. Reload to display the latest version ?`
  );

  if (answer === true) {
    window?.location?.reload();
  }
};

// export const wrapPageElement = ({ element, props }) => {
//   const capturePageMetrics = () => console.log('capturePageMetrics');
//
//   console.warn('element, props', element, props);
//   // See https://reactjs.org/docs/profiler.html#onrender-callback for onRender parameters
//   return (
//     <Profiler id={props.uri} onRender={capturePageMetrics}>
//       {element}
//     </Profiler>
//   );
// };
