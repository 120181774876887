import { createSelector } from 'reselect';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import { isEqualArrayStrings, updatePlanPrice } from '../../helpers/utils';
import { isAddonInstance, isRetailAddonInstance } from '../../helpers/instanceHandler';

import productsResolver from '../../helpers/productsResolver';
import { selectCouponId } from './global';

const isRapidChangersInstance = true; // !NEED TO HIDE LIVE AND KEEP NORMAL ELITE LOGIC

const selectStateProducts = (state) => state.entities.products;
const selectStateReferralInfo = (state) => state.entities.userRefferal;
const selectStateUserDetails = (state) => state.entities.userDetails;
const selectStateWallets = (state) => state.entities.wallets;
const selectStateCalculatedPrice = (state) => state.entities.calculatedPrice;
const selectStateUserSubscriptions = (state) => state.entities.userSubscriptions;
const selectCoupons = (state) => state.entities.coupons;
const selectStatePaymentMethods = (state) => state.entities.paymentMethods;
const selectStateBillingDetails = (state) => state.entities.billingDetails;
export const selectUserDetailsCountry = (state) => state.entities.userDetails?.country;

export const selectMetaSiteInfo = (state) => state.entities.metaInfo;
export const selectAuditTrial = (state) => state.entities.auditTrial;
export const selectOrderResultData = (state) => state.entities.orderResultData;
export const upsellingAddonsDataSelector = (state) => state.entities.upsellingAddons;
export const selectRegions = createSelector(selectMetaSiteInfo, (meta) => meta?.regions);
export const selectStateCoupons = createSelector(selectCoupons, (coupons) => coupons);

export const selectEntityPaymentMethods = createSelector(
  selectStatePaymentMethods,
  (paymentMethods) => paymentMethods
);

export const selectEntityBillingDetails = createSelector(
  selectStateBillingDetails,
  (billingDetails) => billingDetails
);

export const selectExpectedSubscriptions = (state) => state.entities.expectedSubscriptions;

export const selectRefferalInfo = createSelector(
  selectStateReferralInfo,
  (userRefferal) => userRefferal
);

export const selectEntityUserDetails = createSelector(
  selectStateUserDetails,
  (userDetails) => userDetails
);

export const selectEntityWallets = createSelector(selectStateWallets, (wallets) => wallets);

export const selectCalculatedPrice = createSelector(
  selectStateCalculatedPrice,
  (calculatedPrice) => calculatedPrice
);

export const selectEntityUserSubscriptions = createSelector(
  selectStateUserSubscriptions,
  (userSubscriptions) => userSubscriptions
);

const selectAllProductsAfterProductResolveFn = createSelector(selectStateProducts, (productList) =>
  productsResolver(productList, true)
);

export const selectUserSubscriptions = createSelector(
  [selectEntityUserSubscriptions, selectExpectedSubscriptions],
  (boughtSubscriptions, expectedSubscriptions) =>
    (expectedSubscriptions && expectedSubscriptions.items) || boughtSubscriptions
);

export const selectEntityProducts = createSelector(
  selectAllProductsAfterProductResolveFn,
  (productList) => {
    if (isAddonInstance) {
      return productList.filter((product) => product.productWithAnnualPlans);
    }

    return productList.filter((product) => !product.plans[0].isAddon);
  }
);

export const selectEntityProductsAddons = createSelector(selectStateProducts, (products) =>
  products.filter((product) => product.plans[0].isAddon)
);

export const selectAllPlans = createSelector(selectAllProductsAfterProductResolveFn, (products) => {
  if (products) {
    return products.flatMap((item) =>
      item.plans.map(({ name, ...rest }) => ({
        ...rest,
        name: item.name,
        level: name,
      }))
    );
  }
  return null;
});

const processingAddons = (products) => {
  if (products?.length) {
    return products
      .map((item) => item.plans.map((plan) => ({ ...plan, product: { name: item.name } })))
      .reduce((acc, val) => [...acc, ...val])
      .map((item) => {
        const price = isRetailAddonInstance
          ? item.retailPrice || 0 + item.setupPrice
          : item.setupPrice;
        const { recurringPrice } = item;

        const rebillyPlanId = item.rebillyPlanIds[0];

        return { ...item, price, recurringPrice, rebillyPlanId };
      });
  }

  return [];
};

export const selectAddons = createSelector(selectEntityProductsAddons, processingAddons);

const processingAddonsAndSubscriptions = (addons, boughtProducts) => {
  if (addons) {
    return addons.map((item) => {
      const boughtAddon =
        boughtProducts &&
        boughtProducts.find((product) => product.plans[0] === item.rebillyPlanIds[0]);

      const sku = item.skus[0];

      if (boughtAddon) {
        const expiresAt = moment(boughtAddon.expireAt).diff(moment(), 'd');
        const isExpired = expiresAt <= 0;
        const isRenew = !boughtAddon.plans[0].includes('luxe');
        const price = isRenew ? item.recurringPrice : item.price;
        return {
          ...item,
          expiresAt,
          isExpired,
          isRenew,
          currentSubscription: true,
          price,
          sku,
        };
      }

      return { ...item, sku };
    });
  }

  return addons;
};

export const selectAddonsWithSubscriptions = createSelector(
  [selectAddons, selectEntityUserSubscriptions],
  processingAddonsAndSubscriptions
);

export const selectEntityCoupons = createSelector(
  [
    selectStateCoupons,
    selectUserDetailsCountry,
    selectCouponId,
    selectAllPlans,
    selectEntityUserSubscriptions,
  ],
  (coupons, country, selectedCouponId, plans, subscriptions) => {
    if (!coupons?.length || !country || !subscriptions || !plans) {
      return null;
    }

    const validateSpecificCoupon = (coupon) => {
      const inspectedTitleFormat = coupon.title?.includes('show_if_user_has:');

      if (inspectedTitleFormat) {
        const expectedProduct = coupon.title?.replace('show_if_user_has:', '');

        if (expectedProduct && subscriptions && !subscriptions.length) {
          return false;
        }

        if (expectedProduct && subscriptions && subscriptions.length) {
          const existingSubscription = subscriptions.map(
            ({ name, level }) => `${name.toLowerCase()}-${level.toLowerCase()}`
          );
          return existingSubscription.includes(expectedProduct);
        }
      }
      return true;
    };

    if (plans && coupons && coupons.length) {
      const plansById = {};

      plans.forEach((item) => {
        plansById[item.rebillyPlanIds[0]] = item;
      });

      let list;

      if (coupons) {
        const today = Date.now();

        list = uniqBy(coupons, (e) => e.couponCode)
          .filter(
            (item) =>
              new Date(item.expiredAt) > today &&
              new Date(item.startAt) < today &&
              item.planIds.length &&
              validateSpecificCoupon(item)
          )
          .map((item) => {
            const expiresAt = moment(item.expiredAt).diff(moment(), 'd');
            const availablePlans = item.planIds.map((id) => plansById[id]).filter((plan) => !!plan);

            const expectedProduct = item.title?.replace('show_if_user_has:', '');

            const availableForUserCountry = item.countries && item.countries.includes(country);

            let titleDescription = item.elite
              ? 'Elite'
              : availablePlans.reduce(
                  (acc, val) => `${acc} ${val && val.name} ${val && val.level}`,
                  ''
                );

            if (expectedProduct) {
              titleDescription = expectedProduct.replace('-', ' ');
            }

            const planName = item.autogenerated ? 'FREEQUEST' : item.couponCode;

            const discountValue =
              item.currency === 'USD' ? `$${item.discount}` : `${item.discount}%`;

            return {
              ...item,
              validFor: expiresAt,
              discountValue,
              titleDescription,
              planName,
              availableForUserCountry,
            };
          });

        if (selectedCouponId.length) {
          list = list.map((item) => ({
            ...item,
            isApplied: selectedCouponId.includes(item.couponCode),
          }));
        }
      }

      return list && list.length ? list : null;
    }
    return coupons;
  }
);

export const selectProductsWithSubscriptions = createSelector(
  [selectEntityProducts, selectUserSubscriptions, selectAllPlans],
  (products, subscriptions, allPlanList) => {
    try {
      if (products && subscriptions && subscriptions.length) {
        const boughtSubscriptions = subscriptions.reduce((acc, subscription) => {
          const boughtPlan = allPlanList.find((pl) =>
            isEqualArrayStrings(pl.skus, subscription.skus)
          );

          if (boughtPlan && !boughtPlan.isTicket && boughtPlan.product.url) {
            acc[boughtPlan.product.url.toLowerCase()] = subscription;
          }

          return acc;
        }, {});

        const isEliteBought = subscriptions.some((item) => item.isCombo);

        // TODO: names are changeable
        const isRenewBannerShown =
          boughtSubscriptions.live &&
          boughtSubscriptions.learn &&
          boughtSubscriptions.live.level !== boughtSubscriptions.learn.level &&
          !isEliteBought;

        return products.map((product) => {
          const boughtSubscription = boughtSubscriptions[product?.urlParam?.toLowerCase()];

          if (boughtSubscription) {
            const { skus, status, autopay } = boughtSubscription;

            const boughtPlan = product.plans.find((item) => isEqualArrayStrings(skus, item.skus));

            if (!boughtPlan) {
              return product;
            }

            const plans = product.plans.map((item) => {
              const plan = item;

              const isCurrentPlanBought =
                boughtPlan && isEqualArrayStrings(boughtPlan?.skus, plan?.skus);

              const eliteBoughtAndThisPlanIncludedToCombo = isEliteBought && plan.isPartOfCombo;

              const isButtonHide =
                boughtPlan.priority > plan.priority ||
                (eliteBoughtAndThisPlanIncludedToCombo && boughtPlan.priority === plan.priority);

              const isUpgrade = !isCurrentPlanBought && boughtPlan.priority < plan.priority;
              const isActive = status !== 'Expired';

              const upgradePrice = isUpgrade && plan.setupPrice - boughtPlan.setupPrice;

              const showEliteMessage = eliteBoughtAndThisPlanIncludedToCombo;

              return {
                ...item,
                isRenew: isCurrentPlanBought,
                isCurrent: !eliteBoughtAndThisPlanIncludedToCombo && isCurrentPlanBought,
                isUpgrade,
                isActive,
                isButtonHide,
                upgradePrice,
                showEliteMessage,
                isEliteBought,
                isRenewBannerShown,
                autopay,
                boughtPlansId: boughtSubscription.plans,
              };
            });

            return { ...product, plans };
          }

          if ((boughtSubscriptions.live || boughtSubscriptions.learn) && product.plans[0].isCombo) {
            const comboPlans = product.plans.map((item) => {
              // eslint-disable-next-line array-callback-return, consistent-return

              // const includedPlanNames = item.skus.reduce((acc, val) => {
              //   const includedPlan = allPlanList.find(
              //     (plan) => !plan.isCombo && isEqualArrayStrings([val], plan.skus)
              //   );
              //   if (includedPlan) {
              //     const { name, level } = includedPlan;
              //     acc.push(`${name} ${level}`);
              //   }
              //
              //   return acc;
              // }, []);

              // const comboMessage = `Buy ${includedPlanNames.join(' and ')} to get ${
              //   item.product.name
              // } ${item.name}`;

              // ? SHOW button to by ELite separately;
              // const showBuyButton =
              //   planLevel !== liveLevel && planLevel !== learnLevel && planLevel !== 'bronze';

              //! Allow users to purchase Elite if part is already purchased
              const zeroPriority = { priority: 0 };

              const { priority: livePriority } = boughtSubscriptions.live
                ? allPlanList.find((i) =>
                    isEqualArrayStrings(boughtSubscriptions.live.skus, i.skus)
                  )
                : zeroPriority;

              const { priority: learnPriority } = boughtSubscriptions.learn
                ? allPlanList.find((i) =>
                    isEqualArrayStrings(boughtSubscriptions.learn.skus, i.skus)
                  )
                : zeroPriority;

              // ? Learn has roockie plan which has less priority
              const maxBoughtPlanPriority =
                livePriority > learnPriority - 1 ? livePriority : learnPriority - 1;

              // ! Prev Rapid
              // const comboHighPriorityPlan = isRapidChangersInstance
              //   ? true
              //   : item.priority > maxBoughtPlanPriority;

              const comboHighPriorityPlan = item.priority >= maxBoughtPlanPriority;

              const showBuyButton =
                (!boughtSubscriptions.live && !boughtSubscriptions.learn) || comboHighPriorityPlan;

              let comboItems = null;

              if (comboHighPriorityPlan) {
                const firstPlan = allPlanList.find(
                  (plan) => !plan.isCombo && plan.rebillyPlanIds[0] === item.rebillyPlanIds[0]
                );

                const firstPlanName = firstPlan?.product?.url;

                const secondPlan = allPlanList.find(
                  (plan) => !plan.isCombo && plan.rebillyPlanIds[0] === item.rebillyPlanIds[1]
                );

                const secondPlanName = secondPlan?.product?.url;

                comboItems = [
                  {
                    action: boughtSubscriptions[firstPlanName] ? 'UPGRADE' : 'INITIAL',
                    isCombo: true,
                    planId: item.rebillyPlanIds[0],
                  },
                  {
                    action: boughtSubscriptions[secondPlanName] ? 'UPGRADE' : 'INITIAL',
                    isCombo: true,
                    planId: item.rebillyPlanIds[1],
                  },
                ];

                if (isRapidChangersInstance) {
                  const firstPlanSameLevel =
                    boughtSubscriptions[firstPlanName] &&
                    boughtSubscriptions[firstPlanName].level === firstPlan.level;

                  const secondPlanSameLevel =
                    boughtSubscriptions[secondPlanName] &&
                    boughtSubscriptions[secondPlanName].level === secondPlan.level;

                  comboItems = [
                    {
                      // eslint-disable-next-line
                      action: firstPlanSameLevel
                        ? 'UPGRADE'
                        : boughtSubscriptions[firstPlanName]
                        ? 'UPGRADE'
                        : 'INITIAL',
                      isCombo: true,
                      planId: item.rebillyPlanIds[0],
                    },
                    {
                      // eslint-disable-next-line
                      action: secondPlanSameLevel
                        ? 'UPGRADE'
                        : boughtSubscriptions[secondPlanName]
                        ? 'UPGRADE'
                        : 'INITIAL',
                      isCombo: true,
                      planId: item.rebillyPlanIds[1],
                    },
                  ].filter((i) => !!i.action);
                }
              }
              //! end

              // eslint-disable-next-line
              // debugger;

              return {
                ...item,
                // isButtonHide: isRapidChangersInstance ? false : !showBuyButton,
                // showEliteMessage: isRapidChangersInstance ? false : !showBuyButton,
                // comboMessage: isRapidChangersInstance ? false : comboMessage,
                comboMessage: false,
                isButtonHide: !showBuyButton,
                showEliteMessage: !showBuyButton,
                isEliteBought,
                comboItems,
              };
            });

            return { ...product, plans: comboPlans };
          }

          return product;
        });
      }

      return products;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('selectProductsWithSubscriptions', e);
    }

    return null;
  }
);

export const selectElectronicSignments = (state) => state.entities.electronicSignment?.agreements;

export const selectIfUserNeedToSignAgreement = (state) =>
  state.entities.electronicSignment?.requestSign;

export const selectAgreementItemsForSign = (state) =>
  state.entities.electronicSignment?.itemsForSign;

export const selectAgreementItemsForSignWithPlanId = createSelector(
  [selectAgreementItemsForSign],
  (items) => {
    if (items?.length) {
      return items.map((item) => item?.shopPlanId);
    }
    return null;
  }
);

export const selectAgreementDocumentPlans = createSelector(
  [selectProductsWithSubscriptions, selectAddonsWithSubscriptions, selectAgreementItemsForSign],
  (productsWithSubscriptions, addonsWithSubscriptions, items) => {
    if (productsWithSubscriptions?.length && items?.length) {
      const plans = [
        ...productsWithSubscriptions.flatMap((product) => product.plans),
        ...addonsWithSubscriptions,
      ];

      const comboPlans = items.filter((i) => i.isCombo).flatMap((comboItem) => comboItem.planId);
      const otherPlans = items.filter((i) => !i.isCombo).map((planItem) => [planItem.planId]);

      return [comboPlans, ...otherPlans]
        .map((planIds) => plans.find((plan) => isEqualArrayStrings(plan.rebillyPlanIds, planIds)))
        .filter((item) => !!item)
        .map((item) => ({
          ...item,
          price: updatePlanPrice(item),
          name: `${item.product.name} ${item.name}`,
        }));
    }
    return [];
  }
);

export const selectLegalDocuments = createSelector(
  selectMetaSiteInfo,
  (meta) => meta?.legalDocuments
);

export const selectMetaGateways = createSelector(selectMetaSiteInfo, (meta) => meta?.gateways);

export const selectBaseUrlByRegion = (countryCode) =>
  createSelector(selectRegions, (regions) => {
    if (regions) {
      return regions.reduce((acc, region) => {
        const isCountryInRegion = region.countries.some((country) => country.code === countryCode);

        if (isCountryInRegion) {
          // eslint-disable-next-line no-param-reassign
          acc = region.defaultBaseUrl;
        }

        return acc;
      }, null);
    }
    return regions;
  });

export const selectCountriesInRegion = createSelector(selectMetaSiteInfo, (meta) => {
  if (meta && meta.currentInstance) {
    return meta.currentInstance.countries.map((country) => country.code);
  }
  return [];
});

const upsellingAddons = (state) => state.entities.upsellingAddons?.addons;
export const selectCoordinator = (state) => state.entities.coordinator;

const processedUpsellingAddons = createSelector(upsellingAddons, processingAddons);

export const selectUpsellingAddonsWithSubscriptions = createSelector(
  [processedUpsellingAddons, selectEntityUserSubscriptions],
  processingAddonsAndSubscriptions
);
