import React from 'react';
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Banner from '../banner';

const query = graphql`
  query {
    imageBg: file(relativePath: { eq: "home/home-people-powered-banner.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1140, maxHeight: 610) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const CUSTOM_INSTANCE_TEXT = {
  rapidchangers: {
    title: 'Built on product, powered by people',
    text:
      'Whatever your learning style, we have a program that fits. Coming from instructors worldwide,' +
      ' our courses span over five languages and cover just about anything you’d want to know.',
  },
};

const HomeBanner = () => {
  const intl = useIntl();
  const data = useStaticQuery(query);

  const defaultMessages = {
    title: intl.formatMessage({ id: 'home_banner.people_powered_community' }),
    text: intl.formatMessage({ id: 'home_banner.we_are_the_vangaurds' }),
  };

  const { title, text } = CUSTOM_INSTANCE_TEXT[process.env.GATSBY_INSTANCE_NAME] || defaultMessages;

  return (
    <Banner
      contained
      shortContentWidth
      mobilePositionTopSM
      imageData={data.imageBg.childImageSharp.fluid}
    >
      <div className={clsx('dark_bg')}>
        <h2 className={clsx('common_title', 'color_white')}>{title}</h2>
        <p className={clsx('common_subtitle common_subtitle_sm ', 'color_white')}>{text}</p>
      </div>
    </Banner>
  );
};

export default HomeBanner;
