import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'gatsby-plugin-intl';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3';
import { Link } from 'gatsby';
import SEO from '../../components/seo';
import Loader from '../../components/loader';
import styles from './documents.module.scss';
import { pageLinks } from '../../helpers/navigation';

const linksByInstance = {
  beunleash: {
    privacy_policy: {
      url: 'https://drive.google.com/file/d/1L3Y2pS_sisODkhqLg7pE9IYJ-Uiu2PYH/preview',
    },
    gdpr_policy: {
      url: 'https://drive.google.com/file/d/1GRI3W7Stzs2LL-DnsHpcz24T8cBFnDNw/preview',
    },
    terms_of_use: {
      url: 'https://drive.google.com/file/d/14HOIFetxyAPcU3uhI4DuO8_f1xPqtjSz/preview',
    },
    refund_policy: {
      url: 'https://drive.google.com/file/d/12MGJu8uSoTegjhbRcLMEib7rWbkEAtVw/preview',
    },
    cancellation_policy: {
      url: 'https://drive.google.com/file/d/12MGJu8uSoTegjhbRcLMEib7rWbkEAtVw/preview',
    },
    payment_and_return_policy: {
      url: 'https://drive.google.com/file/d/18eQDFgRWxf92SsghH-1-A7h8r5zh3jyV/preview',
    },
    ibi_agreement: {
      url: 'https://drive.google.com/file/d/1ygS9plyEZyyURhEZDoKeE8y65U9xE374/preview',
    },
  },
  befreedom: {
    privacy_policy: {
      url: 'https://drive.google.com/file/d/1L3Y2pS_sisODkhqLg7pE9IYJ-Uiu2PYH/preview',
    },
    gdpr_policy: {
      url: 'https://drive.google.com/file/d/1GRI3W7Stzs2LL-DnsHpcz24T8cBFnDNw/preview',
    },
    terms_of_use: {
      url: 'https://drive.google.com/file/d/14HOIFetxyAPcU3uhI4DuO8_f1xPqtjSz/preview',
    },
    refund_policy: {
      url: 'https://drive.google.com/file/d/12MGJu8uSoTegjhbRcLMEib7rWbkEAtVw/preview',
    },
    cancellation_policy: {
      url: 'https://drive.google.com/file/d/1uxVpzvWIbnTuUeGKFUGv6tbFjIXh4aCw/preview',
    },
    payment_and_return_policy: {
      url: 'https://drive.google.com/file/d/18eQDFgRWxf92SsghH-1-A7h8r5zh3jyV/preview',
    },
    ibi_agreement: {
      url: 'https://drive.google.com/file/d/1ygS9plyEZyyURhEZDoKeE8y65U9xE374/preview',
    },
  },
};

const Documents = ({ pageContext }) => {
  const { titleId, documentId } = pageContext;
  const [fullSize, setFullSize] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const intl = useIntl();

  const [links, setLinks] = useState({});
  const title = intl.formatMessage({ id: titleId });

  const toggleFullSize = (e) => {
    e.preventDefault();
    setFullSize(!fullSize);
  };

  const url = links && links[documentId] && links[documentId].url;

  useEffect(() => {
    if (window.location.href.includes('befreedom')) {
      setLinks(linksByInstance.befreedom);
    } else {
      setLinks(linksByInstance.beunleash);
    }
  }, []);

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <>
          <div className={styles.header}>
            <div className={styles.closeLink}>
              <Link
                to={modal ? closeTo : pageLinks.home}
                state={{ noScroll: true }}
                className={styles.link}
              >
                <span>Close</span>
                <i className="icon-close" />
              </Link>
            </div>
          </div>

          <SEO title={title} />
          <h1 className={styles.title}>{title}</h1>
          <Loader isLoading={loading} isSmall={false} />
          <div
            className={clsx(styles.wrapper, { [styles.fullSize]: fullSize })}
            onDoubleClick={(e) => e.preventDefault()}
          >
            <div
              tabIndex="-1"
              role="presentation"
              className={styles.widthToggle}
              onClick={toggleFullSize}
            />
            {url && (
              <iframe
                onLoad={() => setLoading(false)}
                title={title}
                width="100%"
                height="100%"
                src={url}
                frameBorder="0"
                style={{ position: 'relative' }}
              />
            )}
          </div>
        </>
      )}
    </ModalRoutingContext.Consumer>
  );
};

Documents.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  pageContext: PropTypes.shape({
    titleId: PropTypes.string.isRequired,
    documentId: PropTypes.string.isRequired,
    docPath: PropTypes.string,
    docUrl: PropTypes.string,
  }).isRequired,
};

export default Documents;
