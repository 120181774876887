import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal';
import Button from '../button';
import styles from './modals.module.scss';

const PayForFriendFailModal = ({ onConfirm, hideModal, fullName }) => {
  const onConfirmClick = () => {
    onConfirm();
    hideModal();
  };

  return (
    <Modal
      onCloseClick={hideModal}
      className={styles.modal}
      isShown
      title={`Dear ${fullName}`}
      idOverlay="renewModal"
    >
      <div className={styles.modalWrapper}>
        <p className={styles.modalText}>Sorry, the order payment request has expired</p>
        <Button className={styles.modalButton} onClick={onConfirmClick} size="large">
          Got It.
        </Button>
      </div>
    </Modal>
  );
};

PayForFriendFailModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  fullName: PropTypes.string.isRequired,
};

export default PayForFriendFailModal;
