import apiAction from '../apiActionCreator';

const type = 'GET_USER_ELECTRONIC_SIGN';

const config = {
  url: '/agreement/for-order',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
