import React from 'react';
import { useDispatch } from 'react-redux';
import Img from 'gatsby-image';
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Button from '../components/button';
import { registerUser } from '../store/actions';
import { getInstanceName } from '../helpers/utils';
import styles from '../styles/pages/verifications.module.scss';
import ArrowBack from '../components/arrowBack';
import { pageLinks } from '../helpers/navigation';

const query = graphql`
  query {
    verifyIcon: file(relativePath: { eq: "verify-success.png" }) {
      childImageSharp {
        fixed(width: 162) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bgLeft: file(relativePath: { eq: "confeti-left.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 507) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bgRight: file(relativePath: { eq: "confeti-right.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 507) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const RegistrationSuccessPage = () => {
  const dispatch = useDispatch();
  const data = useStaticQuery(query);
  const siteName = getInstanceName();

  const onNextClick = () => dispatch(registerUser(true));

  return (
    <>
      <SEO title={`Verification Success - ${siteName}`} />
      <Layout fluid pageWrapperClassName="page_wrapper">
        <div className="container">
          <div className={styles}>
            <ArrowBack text="back to registration" navigationTo={pageLinks.registration} />
          </div>

          <div className={styles.relative}>
            <BackgroundImage
              Tag="div"
              className={styles.summary_bg_overlay}
              fluid={[data.bgLeft.childImageSharp.fluid, data.bgRight.childImageSharp.fluid]}
              backgroundColor="transparent"
            />

            <>
              <div className={clsx(styles.wrapper, styles.verifySuccess)}>
                <div className={styles.title}>Verification Success</div>

                <div className={clsx(styles.textInfo, styles.long)}>
                  Thank you for your support, we have successfully verified your mobile number.
                </div>

                <div className={styles.img}>
                  <Img fixed={data.verifyIcon.childImageSharp.fixed} />
                </div>
              </div>
            </>
          </div>
        </div>

        <div className={styles.nextButton}>
          <Button className={styles.button} size="large" onClick={onNextClick}>
            Next
          </Button>
        </div>
      </Layout>
    </>
  );
};

export default RegistrationSuccessPage;
