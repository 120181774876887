import apiAction from '../apiActionCreator';

const type = 'VALIDATE_USERNAME';

const config = {
  url: '/auth/check-uniq-username',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
