import moment from 'moment';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import firebase from 'gatsby-plugin-firebase';
import { pageLinks } from './navigation';
import { useStorage } from '../services/storage';
import { getCountryData } from './countries';
import { isOnlineEraUs, isProduction, isRetailAddonInstance } from './instanceHandler';

import PaymentConstants from '../constants/payment';
import setNotification from './notifications';

export const availableLanguages = [
  {
    value: 0,
    locale: 'gb',
    label: 'English (United Kingdom)',
  },
  {
    value: 6,
    locale: 'fr',
    label: 'French',
  },
  {
    value: 2,
    locale: 'de',
    label: 'German',
  },
  {
    value: 16,
    locale: 'pt',
    label: 'Portuguese',
  },
  {
    value: 1,
    locale: 'es',
    label: 'Spanish',
  },
  {
    value: 9,
    locale: 'th',
    label: 'Thai',
  },
  {
    value: 204,
    locale: 'cn',
    label: 'Chinese',
  },
  {
    value: 20,
    locale: 'sk',
    label: 'Slovak',
  },
  // {
  //   value: 17,
  //   locale: 'it',
  //   label: 'Italian',
  // },
];

export const getPaymentTitle = (method) => {
  if (method === 'ewallet' || method === 'e-wallet') {
    return 'E-wallet';
  }
  if (method === 'bitpay') {
    return 'BitPay';
  }
  if (method === PaymentConstants.SHOP_METHODS.requestToPay) {
    return 'Ask a Friend to Pay';
  }
  if (method === 'paypal') {
    return 'PayPal';
  }
  if (method === 'ebanx') {
    return 'EBANX';
  }
  if (method === 'top') {
    return 'Top';
  }
  if (method === PaymentConstants.SHOP_METHODS.card || method === 'payment-card') {
    return 'Credit & Debit Card';
  }
  if (method === 'ach') {
    return 'Plaid';
  }
  if (method === 'coinpayments') {
    return `Pay With ${isOnlineEraUs ? 'Cryptocurrencies' : 'BITCOIN/Crypto Currency'}`;
  }
  if (method === 'paypal-card') {
    return 'USA alternative checkout';
  }
  if (method === 'astroPay') {
    return 'AstroPay Card';
  }
  if (method === 'cash-deposit') {
    return 'Cash Deposit';
  }
  if (method === 'bank-transfer') {
    return 'Bank Transfer';
  }
  if (method === 'rapyd') {
    return 'Bank Transfer / Cash Option 2';
  }

  if (method === 'AmazonPay') {
    return 'AmazonPay';
  }
  if (method === 'MaxiCash') {
    return 'MaxiCash';
  }
  if (method === 'paynote') {
    return 'Instant ACH Bank Payment';
  }
  return '';
};

const adminShopPaymentMethods = {
  'e-wallet': PaymentConstants.SHOP_METHODS.eWallet,
  'bank-transfer': PaymentConstants.SHOP_METHODS.bankTransfer,
  'AstroPay Card': PaymentConstants.SHOP_METHODS.astroPay,
  paypal: PaymentConstants.SHOP_METHODS.paypal,
  'payment-card': PaymentConstants.SHOP_METHODS.card,
  ach: PaymentConstants.SHOP_METHODS.ach,
  'cash-deposit': PaymentConstants.SHOP_METHODS.cashDeposit,
  bitcoin: PaymentConstants.SHOP_METHODS.coinPayments,
  'rapyd-checkout': PaymentConstants.SHOP_METHODS.rapyd,
  AmazonPay: PaymentConstants.SHOP_METHODS.AmazonPay,
  MaxiCash: PaymentConstants.SHOP_METHODS.MaxiCash,
  Paynote: PaymentConstants.SHOP_METHODS.paynote,
  cryptocurrency: PaymentConstants.SHOP_METHODS.coinPayments,
};

export const sortPaymentMethods = (a, b) => a.priority - b.priority;

export const filterMethodsByInstance = (methods) =>
  methods?.filter((method) => {
    if (method === 'paypal-card') {
      return null;
    }

    return method;
  });

export const convertToShopPaymentMethod = (adminMethods) => {
  let formatted = [];

  const bankTransfer = adminMethods.find((i) => i.name === 'bank-transfer');
  const cashDeposit = adminMethods.find((i) => i.name === 'cash-deposit');

  adminMethods.forEach(({ name, priority }) => {
    if (adminShopPaymentMethods[name]) {
      if (name === 'rapyd-checkout') {
        formatted.push({
          name: PaymentConstants.SHOP_METHODS.rapyd,
          priority: bankTransfer ? bankTransfer.priority + 1 : 3,
        });
        formatted.push({
          name: PaymentConstants.SHOP_METHODS.rapydCash,
          priority: cashDeposit ? cashDeposit.priority + 1 : 4,
        });
      }

      if (name === 'paypal') {
        formatted.push({
          name: PaymentConstants.SHOP_METHODS.paypal,
          priority,
        });

        formatted.push({
          name: PaymentConstants.SHOP_METHODS.paypalCard,
          priority: priority ? priority + 1 : 1,
        });
      } else {
        formatted.push({ name: adminShopPaymentMethods[name], priority });
      }
    }
  });

  if (
    formatted.includes(PaymentConstants.SHOP_METHODS.coinPayments) &&
    formatted.includes(PaymentConstants.SHOP_METHODS.eWallet)
  ) {
    formatted = new Set([
      { name: PaymentConstants.SHOP_METHODS.eWallet, priority: 1 },
      { name: PaymentConstants.SHOP_METHODS.coinPayments, priority: 1 },
      ...formatted,
    ]);
  }

  return [...formatted];
};

export const isClient = typeof window === 'object';

export const isUserLogged = () => !!useStorage().getItem('AT');

export const checkIfPathProtected = (path) => {
  if (isClient && !isUserLogged() && path === pageLinks.checkout) {
    return pageLinks.signIn;
  }
  return path;
};

export const getMonthByNumber = (num) =>
  [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][num];

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getSiteUrl = () => {
  if (isClient) {
    return window.location.origin;
  }
  return '';
};

export const checkConsecutives = (word, length) => {
  if (word && word.length >= length) {
    for (let i = 0; i <= word.length; i += 1) {
      // iterating over 3-char sequences
      const charCode = word[i] && word[i].charCodeAt(0);
      const charCodeTwo = word[i + 1] && word[i + 1].charCodeAt(0);
      const charCodeThree = word[i + 2] && word[i + 2].charCodeAt(0);

      if (charCodeTwo - charCode === 1 && charCodeThree - charCode === 2) {
        return true;
      }
    }
  }

  return false;
};

export const hasConsecutiveSymbols = (value = '') => {
  const numbers = value.replace(/\W/g, ' ').match(/(\d+)/g);
  const letters = value.replace(/\W/g, ' ').match(/[a-zA-Z]+/g);

  const isConsecutiveNumbers = numbers && numbers.find((item) => checkConsecutives(item, 3));
  const isConsecutiveLetters = letters && letters.find((item) => checkConsecutives(item, 3));

  return !(isConsecutiveNumbers || isConsecutiveLetters);
};

export function hasLoginPassSameString() {
  return this.parent.loginName !== this.parent.loginPassword;
}

export const hasRequiredSymbols = (value = '') =>
  value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/);

export const hasRestrictedSymbols = (value = '') => {
  const regx = /^[a-zA-Z0-9-!$%^&*()_+|~=`{}:;<>?,.@#]*$/;
  return regx.test(value);
};

export const hasSpecialCharacters = (value = '') => value.match(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/);

export const hasCommonWords = (value, pattern) => !value.toLowerCase().match(pattern);

export const getInstanceName = () => {
  const siteNames = {
    fr: 'Betoinspire',
    gb: 'Betoinspire',
    br: 'Online-line',
    befreedom: 'Befreedom',
    betoinspireprod: 'Betoinspire',
    onlineLine: 'Online-line',
    euonlineLine: 'Online-line',
    beunleash: 'Beunleash',
    brbeunleash: 'Beunleash',
    onlineEra: 'Online-line',
    euonlineEra: 'Online-Era',
    newparadime: 'NewParadime',
    addon: 'Star One marketplace',
    rapidchangers: 'Rapid Changers',
    newroverse: 'Newroverse',
  };

  return siteNames[process.env.GATSBY_INSTANCE_NAME] || 'Shop Portal';
};

export const getPaymentAction = (plan) => {
  if (plan.isRenew) {
    return 'RENEW';
  }
  if (plan.isUpgrade) {
    return 'UPGRADE';
  }
  return 'INITIAL';
};

export const getPortalLink = () => {
  // TODO: return process.env.GATSBY_PORTAL_LINK, and add to staging process.env.GATSBY_PORTAL_LINK
  if (isProduction) {
    return process.env.GATSBY_PORTAL_LINK;
  }
  return process.env.GATSBY_PORTAL_LINK.replace('start', 'start.stg');
};

export const formatWithoutTimeZone = (date) =>
  moment.parseZone(date, 'YYYY-MM-DDTHH:mm:ss.SSS', false).format('YYYY-MM-DDTHH:mm:ss.SSS');

export const transformCartToItems = (data, withSamePlanLogic = true) => {
  let sameLevelPlans = false;

  if (withSamePlanLogic && data.length >= 2) {
    sameLevelPlans = data[0].level === data[1].level;
  }

  const result = data.flatMap((item) => {
    const plans = item.rebillyPlanIds || item.plans;
    const itemIsCombo = item.isCombo;
    const topUpAmount = Number(item?.topUpAmount);

    if (itemIsCombo && item.comboItems) {
      return item.comboItems;
    }

    return plans.map((id) => ({
      planId: id,
      action: getPaymentAction(item),
      isCombo: itemIsCombo || sameLevelPlans,
      ...(topUpAmount ? { topUpAmount } : {}),
    }));
  });

  return result;
};

export const installmentCountries = ['BR'];

const latinCountries = [
  'AR',
  'BO',
  'BR',
  'CL',
  'CO',
  'CR',
  'EC',
  'HN',
  'GB',
  'PE',
  'UY',
  'MX',
  'SV',
  'GT',
  'SE',
  'ES',
  'DE',
  'TR',
  'AM',
  'AT',
  'AU',
  'AZ',
  'BD',
  'CA',
  'CI',
  'CM',
  'CN',
  'DK',
  'FI',
  'GH',
  'ID',
  'IN',
  'JO',
  'JP',
  'KE',
  'KW',
  'ME',
  'MN',
  'MY',
  'NG',
  'NO',
  'NP',
  'OM',
  'PK',
  'PL',
  'QA',
  'RS',
  'RU',
  'SA',
  'TH',
  'TZ',
  'UG',
  'VN',
  'ZA',
  'LS',
  'EG',
  'AE',
  'CD',
];

const bankTransferCountries = ['AR', 'BO', 'BR', 'CL', 'CO', 'MX', 'PE'];
const cashDepositCountries = ['AR', 'BR', 'CL', 'CO', 'CR', 'EC', 'MX', 'PA', 'PY', 'PE', 'UY'];
const bankOption2Countries = ['BR', 'CL', 'CO', 'PE', 'CR'];

export const getPaymentsByCountry = (code = '') => {
  const allMethods = [
    PaymentConstants.SHOP_METHODS.requestToPay,
    PaymentConstants.SHOP_METHODS.card,
    'e-wallet',
    'paypal',
    'coinpayments',
    'paypal-card',
    PaymentConstants.SHOP_METHODS.MaxiCash,
  ];

  const country = code && code.toUpperCase();

  if (bankOption2Countries.includes(country)) {
    allMethods.push('rapyd');
  }

  if (country === 'US') {
    allMethods.push('AmazonPay');
    allMethods.push('paynote');
    // allMethods.push('ach'); removed ACH
  }
  if (latinCountries.includes(country)) {
    allMethods.push('astroPay');
  }
  if (bankTransferCountries.includes(country)) {
    allMethods.push('bank-transfer');
  }
  if (cashDepositCountries.includes(country)) {
    allMethods.push('cash-deposit');
  }

  return allMethods;
};

const checkEveryProductMethods = (shoppingCart, method) => {
  if (shoppingCart.products.length === 1) {
    return true;
  }

  try {
    return shoppingCart.products.every((item) =>
      item.gatewayMethods.some(
        (getawayMethod) => adminShopPaymentMethods[getawayMethod.name] === method
      )
    );
  } catch (e) {
    return false;
  }
};

export const filterPaymentMethodsByGateway = (shoppingCart, allMethods) => {
  if (!shoppingCart.productCount) {
    return allMethods.map((methodName) => ({ name: methodName, priority: 1 }));
  }

  const methodsByProducts = shoppingCart?.products.reduce((acc, product) => {
    product?.gatewayMethods?.forEach((m) => {
      if (!acc.includes(m.name)) {
        acc.push({ name: m.name, priority: m.priority });
      }
    });
    return convertToShopPaymentMethod(acc);
  }, []);

  return methodsByProducts.length
    ? [
        { name: PaymentConstants.SHOP_METHODS.requestToPay, priority: 1 },
        ...methodsByProducts.filter(
          ({ name }) => allMethods.includes(name) && checkEveryProductMethods(shoppingCart, name)
        ),
      ]
    : [];
};

export const getPaymentMethods = (products, countryName) => {
  const isAnnual = products.some((p) => p.isAnnual);
  if (isAnnual) {
    return ['e-wallet', 'coinpayments', PaymentConstants.SHOP_METHODS.requestToPay];
  }

  return getPaymentsByCountry(countryName);
};

export const normalizeLatinLetters = (text = '') =>
  text
    .normalize('NFKD')
    .replace(/[\u0080-\uF8FF\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E]/g, '') || '';

export const isEqualArrayStrings = (a, b) => {
  if (a?.length !== b?.length) {
    return false;
  }

  return isEqual(a.sort(), b.sort());
};

const isSafariMobile = isClient && /iPhone/i.test(navigator.userAgent);

export const checkSearchableSelect = () => {
  switch (process.env.GATSBY_INSTANCE_NAME) {
    default: {
      return true;
    }
  }
};

export const scrollToMiddle = (id) => {
  if (isClient) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });

      if (isSafariMobile) {
        const height = window.innerHeight;
        let top = height;

        if (height < 600) {
          top = height + 650;
        }

        if (height > 600 && height < 700) {
          top = height + 550;
        }

        if (height > 700) {
          top = height + 250;
        }

        window.scroll({
          top,
          behavior: 'smooth',
        });
      }
    }
  }
};

export const colorLuminance = (hexColor, luminance) => {
  let hex = hexColor;
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  const lum = luminance || 0;

  // convert to decimal and change luminosity
  let rgb = '#';
  let c;
  let i;

  for (i = 0; i < 3; i += 1) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += `00${c}`.substr(c.length);
  }

  return rgb;
};

export const getPlanColors = ({ bgColor, color }) => ({
  mainBg: colorLuminance(bgColor, 0),
  text: colorLuminance(color, 0),
  mainDark: colorLuminance(bgColor, -0.1),
  mainLabel: colorLuminance(bgColor, -0.15),
  discount: colorLuminance(bgColor, -0.35),
});

export function createDocLink(docPath, countryCode) {
  const countryData = getCountryData(countryCode);
  const countryName = countryData ? countryData.name : null;
  if (!countryName) {
    return docPath;
  }
  const searchParams = new URLSearchParams(`country=${countryName}`);
  return `${docPath}?${searchParams.toString()}`;
}

export const filterComboIncludedPlans = (newlyAddedPlan, shoppingCart) => {
  const oldShoppingCart = { ...shoppingCart.products };

  const ticketInCart = Object.values(oldShoppingCart).filter((item) => item.isTicket);
  const annualPlanInCart = Object.values(oldShoppingCart).filter((item) => item.isAnnual);
  const addonInCart = Object.values(oldShoppingCart).filter((item) => item.isAddon);

  if (
    (!newlyAddedPlan.isTicket && ticketInCart.length) ||
    (newlyAddedPlan.isAnnual && addonInCart.length) ||
    (newlyAddedPlan.isAddon && annualPlanInCart.length)
  ) {
    if (
      (newlyAddedPlan.isAnnual && addonInCart.length) ||
      (newlyAddedPlan.isAddon && annualPlanInCart.length)
    ) {
      setNotification('info', {
        message:
          'We have replaced your current item with an Annual Plan item since you cannot buy an Addon and subscription plan in one order',
        title: '',
      });
    }

    return { [newlyAddedPlan.product.url || newlyAddedPlan.product.name]: newlyAddedPlan };
  }

  if (newlyAddedPlan.isTicket) {
    const tickets = ticketInCart
      .map((val) => ({ [val.name]: val }))
      .reduce((acc, val) => ({ ...acc, ...val }), {});

    return {
      ...tickets,
      [newlyAddedPlan.name]: newlyAddedPlan,
    };
  }

  // eslint-disable-next-line consistent-return
  Object.values(oldShoppingCart).forEach((planInOldCart) => {
    //! REMOVES Products which are part of added Combo.
    if (newlyAddedPlan.isCombo && planInOldCart.isPartOfCombo) {
      return delete oldShoppingCart[planInOldCart.product.url];
    }

    //! REMOVES Combo when you added the product included in this Combo
    if (newlyAddedPlan.isPartOfCombo && planInOldCart.isCombo) {
      return delete oldShoppingCart[planInOldCart.product.url];
    }
  });

  return {
    ...oldShoppingCart,
    [newlyAddedPlan.product.url || newlyAddedPlan.product.name]: newlyAddedPlan,
  };
};

export const doNotShowRenewalPriceAddons = isRetailAddonInstance
  ? []
  : ['ADD-RET-US30', 'ADD-US30'];

export const getEnvConfig = () => ({
  GATSBY_API_URL: process.env.GATSBY_API_URL,
  GATSBY_REBILLY_KEY: process.env.GATSBY_REBILLY_KEY,
  GATSBY_INSTANCE_NAME: process.env.GATSBY_INSTANCE_NAME,
  GATSBY_INSTANCE_TYPE: process.env.GATSBY_INSTANCE_TYPE,
  GATSBY_PORTAL_LINK: process.env.GATSBY_PORTAL_LINK,
  GATSBY_GB_PROD_URL: process.env.GATSBY_GB_PROD_URL,
  GATSBY_BRAZIL_PROD_URL: process.env.GATSBY_BRAZIL_PROD_URL,
});

export function isMobileDevice() {
  if (isClient) {
    try {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    } catch (e) {
      return false;
    }
  }
  return false;
}

export const SIGNING_AUDIT_TRAIL_TYPE = {
  INITIATED: {
    step: 1,
    type: 'initiated',
  },
  DOCUMENT_VIEWED: {
    step: 2,
    type: 'document_viewed',
  },
  CANCELED: {
    step: 4,
    type: 'canceled',
  },
  DOCUMENT_SIGNED: {
    step: 3,
    type: 'document_signed',
  },
  SIGNING_COMPLETED: {
    step: 6,
    type: 'signing_completed',
  },
  SIGNING_NOT_FINISHED: {
    step: 5,
    type: 'signing_not_finished',
  },
};

export const getShopSiteUrlByCountry = ({ regions, country }) => {
  const countryList = regions.filter((region) => {
    const foundCountry = region.countries.reduce((acc, currentCountry) => {
      if (currentCountry.code === country) {
        // eslint-disable-next-line no-param-reassign
        acc = true;
        return acc;
      }

      return acc;
    }, false);
    return foundCountry;
  });
  const countriesSortedByPriority = orderBy(countryList, 'priority', 'desc');

  return countriesSortedByPriority[0]?.defaultBaseUrl;
};

export const transformBaseUrl = (url) =>
  `https://${isProduction ? url : url.replace('stg.', 'shop.stg.').replace('stg-', 'shop.stg-')}`;

export const skuList = {
  relicAddon: 'ADD-RLC03',
  shiftAddon: 'ADD-SHIFT',
  liveAddon: 'ADD-QUESTDOLLARS',
  senseAddon: 'ADD-SNS06',
  cosmicAddon: 'ADD-CLA08',
  wsTitanAddon1: 'ADD-RET-US30',
  wsTitanAddon2: 'ADD-US30',

  liveBronze: 'EBRZTRVL',
  liveSilver: 'ESLVTRVL',
  liveGold: 'EGLDTRVL',
  liveAnnualGold: 'ANN-GLD-LIV',

  learnBronze: 'BRENTRY01',
  learnSilver: 'SLENTRY01',
  learnGold: 'GDENTRY01',
  learnGoldPrime: 'PRMLRNENTRY',
  learnAnnualGold: 'ANN-GLD-LRN',

  nitroBronze: 'NTR-ACD-BRZ',
  nitroSilver: 'NTR-ACD-SLV',
  nitroPrimeGold: 'NTR-ACD-PRM',
  nitroGold: 'NTR-ACD-GLD',
};

export const eliteSkusList = {
  eliteBronze: [skuList.learnBronze, skuList.liveBronze],
  eliteSilver: [skuList.liveSilver, skuList.learnSilver],
  eliteGold: [skuList.liveGold, skuList.learnGold],
  elitePrime: [skuList.learnGoldPrime, skuList.liveGold],
  eliteAnnualGold: [skuList.liveAnnualGold, skuList.learnAnnualGold],
};

export const isRelicAddonChecker = (sku) => sku === skuList.relicAddon;
export const isShiftAddonChecker = (sku) => sku === skuList.shiftAddon;
export const isLiveAddonsChecker = (sku) => sku === skuList.liveAddon;
export const isSenseAddonChecker = (sku) => sku === skuList.senseAddon;
export const isCosmicAddonChecker = (sku) => sku === skuList.cosmicAddon;
export const isWSTitanAddonChecker = (sku) =>
  [skuList.wsTitanAddon1, skuList.wsTitanAddon2].includes(sku);

export const isLearnPlansChecker = (sku) =>
  [
    skuList.learnGold,
    skuList.learnBronze,
    skuList.learnSilver,
    skuList.learnGold,
    skuList.learnGoldPrime,
    skuList.learnAnnualGold,
  ].includes(sku);

export const isLivePlansChecker = (sku) =>
  [skuList.liveBronze, skuList.liveSilver, skuList.liveGold, skuList.liveAnnualGold].includes(sku);

export const isElitePlansChecker = (skus) =>
  [
    eliteSkusList.eliteBronze,
    eliteSkusList.eliteSilver,
    eliteSkusList.eliteGold,
    eliteSkusList.elitePrime,
    eliteSkusList.eliteAnnualGold,
  ].some((plan) => isEqual(plan?.sort(), skus?.sort()));

export const isNitroPlansChecker = (sku) =>
  [skuList.nitroBronze, skuList.nitroSilver, skuList.nitroPrimeGold, skuList.nitroGold].includes(
    sku
  );

const hasSubscriptionChecker = (subscriptions, type, byActiveStatus) => {
  const live = type === 'live';
  const learn = type === 'learn';
  const elite = type === 'elite';
  const nitro = type === 'nitro';

  const subscriptionList = byActiveStatus
    ? subscriptions?.filter((sub) => sub.status.toLowerCase() === 'active')
    : subscriptions;

  const isPlan = subscriptionList?.some((subscription) => {
    if (elite) {
      return isElitePlansChecker(subscription?.skus);
    }

    return subscription.skus.some((sku) => {
      if (live) {
        return isLivePlansChecker(sku);
      }

      if (learn) {
        return isLearnPlansChecker(sku);
      }

      if (nitro) {
        return isNitroPlansChecker(sku);
      }

      return false;
    });
  });

  return !!isPlan;
};

export const hasLiveSubscriptionChecker = (subscriptions, byActiveStatus) =>
  hasSubscriptionChecker(subscriptions, 'live', byActiveStatus);

export const hasLearnSubscriptionChecker = (subscriptions, byActiveStatus) =>
  hasSubscriptionChecker(subscriptions, 'learn', byActiveStatus);

export const hasEliteSubscriptionChecker = (subscriptions, byActiveStatus) =>
  hasSubscriptionChecker(subscriptions, 'elite', byActiveStatus);

export const hasNitroSubscriptionChecker = (subscriptions, byActiveStatus) =>
  hasSubscriptionChecker(subscriptions, 'nitro', byActiveStatus);

export const shouldAddNewPage = (cartData, pageLength) => {
  let bullets = [];
  cartData.products.forEach((product) => {
    const isEntry = !product.isRenew && !product.isUpdate;
    if (isEntry && !!product?.bullets.length) {
      bullets = [...bullets, ...product.bullets, product.name, product.level];
    }
  });

  return bullets.length >= pageLength;
};

export const BULLET_SPACE = {
  pageThree: 20,
  pageFour: 70,
  pageFive: 120,
  pageSix: 150,
};

export const updatePlanPrice = (plan) => {
  if (plan?.isRenew) {
    return plan.recurringPrice;
  }
  if (plan?.isUpgrade) {
    return plan.upgradePrice;
  }
  return (
    Number(plan?.topUpAmount || '0') || plan?.setupPrice || plan?.price || plan?.recurringPrice
  );
};

export const isBeFreeGateways = (orderGatewayAccountId) =>
  ['stripe-comm', 'stripe-MWC', 'paypal-live'].includes(orderGatewayAccountId);

export const logEvent = (event) => isProduction && firebase.analytics().logEvent(event);

export function loadFraudNetScript(options) {
  const script = document.createElement('script');
  script.src = options.fnUrl;
  script.type = 'text/javascript';
  document.body.appendChild(script);
}

export function loadFraudNetJsonData(data) {
  const script = document.createElement('script');
  script.type = 'application/json';
  script.id = 'fraudNet_scr';
  script.setAttribute('fncls', 'fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99');
  script.text = JSON.stringify(data);
  document.body.appendChild(script);
}

export const getCompanyDescriptorName = (paymentMethod, gateways) => {
  if (!gateways || !paymentMethod) {
    return '.';
  }
  const gateway = gateways.find((g) => g.rebillyId === paymentMethod);
  return gateway?.companyName;
};
