import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';
import styles from './title-button.module.scss';

const TitleButton = ({ title, children, small }) => (
  <div className={styles.wrapper}>
    <p className={clsx(small ? styles.smallTitle : styles.title)}>{title}</p>
    <div>{children}</div>
  </div>
);

TitleButton.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  small: PropTypes.bool,
};

TitleButton.defaultProps = { children: null, small: null };

export default TitleButton;
