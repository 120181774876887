import clsx from 'clsx';

import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ArrowBack from '../components/arrowBack';
import Button from '../components/button';
import LoginBlock from '../components/loginBlock';
import Input from '../components/input';
import InputPassword from '../components/inputPassword';
import getToken from '../services/api/actions/getToken';
import { pageLinks, createNavigateTo } from '../helpers/navigation';
import styles from '../styles/pages/login.module.scss';

import { logEvent, isClient } from '../helpers/utils';
import { getInstanceName, isRetailAddonInstance } from '../helpers/instanceHandler';

import { INITIATOR, INSTANCE_TYPES } from '../constants/types';

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required('this field is required'),
  password: Yup.mixed().required('this field is required'),
});

const renderHeadingContent = () => {
  const siteName = getInstanceName();
  const welcomeToElevateskills = `Welcome to ${siteName}, you are not logged in`;
  const toContinueWith = `To continue with the ${siteName} you need to log in or create an account`;
  return (
    <>
      <h2 className={clsx(styles.title)}>{welcomeToElevateskills}</h2>
      <h3 className={clsx(styles.subTitle)}>{toContinueWith}</h3>
    </>
  );
};

const SignIContent = (props) => {
  const intl = useIntl();
  const createAnAccount = `Create an account. New to ${getInstanceName()}?`;

  useEffect(() => logEvent('login'), []);

  const signInAlready = intl.formatMessage({ id: 'signInAlready' });
  const letsContinue = intl.formatMessage({ id: 'letsContinue' });
  const youWillBeRedirected = intl.formatMessage({ id: 'youWillBeRedirected' });
  const yourEmailorUsername = intl.formatMessage({ id: 'yourEmailorUsername' });
  const yourPassword = intl.formatMessage({ id: 'yourPassword' });
  const typeInEmail = intl.formatMessage({ id: 'typeInEmail' });
  const typeInPassword = intl.formatMessage({ id: 'typeInPassword' });
  const forgotUsernameText = intl.formatMessage({ id: 'forgotUsername' });

  const isOpenLogin = !!(isClient && window.location.search.replace('?isFormOpen=', '') === 'true');
  const initialBlock = isOpenLogin ? 'existingAccount' : null;
  const [activeBLock, setActiveBLock] = useState(initialBlock);
  const isExistingAccount = activeBLock === 'existingAccount';

  const [autofillEmail, setEmail] = useState(null);
  const [autofillPassword, setPassword] = useState(null);

  const fk = useFormik({
    initialValues: {
      email: autofillEmail || initialValues.email,
      password: autofillPassword || initialValues.password,
    },
    validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      props.onLoginClick(
        values.email.replace(/\u200C/g, ''),
        values.password.replace(/\u200C/g, '')
      );
    },
  });

  const onFormSubmit = (event) => {
    event.preventDefault();
    if (!isExistingAccount) {
      createNavigateTo(pageLinks.registration)();
    } else {
      fk.handleSubmit(event);
    }
  };

  const onForgotPassword = createNavigateTo(pageLinks.forgotPassword);
  const onForgotUsername = createNavigateTo(pageLinks.forgotUsername);

  const isDisabled = !activeBLock || (activeBLock === 'existingAccount' && !fk.isValid);

  const showErrorMessage = (id) => (fk.touched[id] ? fk.errors[id] : '');

  const forgotUsername = (
    <button
      tabIndex="-1"
      type="button"
      onClick={onForgotUsername}
      className={clsx(styles.forgotUsername)}
    >
      {forgotUsernameText}
    </button>
  );

  const handleFocus = (e) => {
    // Needs to prevent autofill without cursor
    if (e.target.value.trim().length) {
      setPassword(fk.values.password.trim());
      setEmail(fk.values.email.trim());
      fk.handleReset();
    }
  };

  return (
    <div className={clsx(styles.contentWrapper)}>
      <form onSubmit={onFormSubmit}>
        <div className={clsx(styles.loginBlockWrappers)}>
          <>
            <LoginBlock
              title={createAnAccount}
              onClick={() => setActiveBLock('createAccount')}
              isActive={activeBLock === 'createAccount'}
            >
              <div className={clsx(styles.createAccountText)}>{youWillBeRedirected}</div>
            </LoginBlock>

            <LoginBlock
              title={signInAlready}
              onClick={() => setActiveBLock('existingAccount')}
              isActive={activeBLock === 'existingAccount'}
            >
              <Input
                label={yourEmailorUsername}
                id="email"
                value={fk.values.email}
                onChange={(e) => fk.setFieldValue('email', e.target.value.trim(), true)}
                onBlur={fk.handleBlur}
                placeholder={typeInEmail}
                errorMessage={showErrorMessage('email')}
                fullwidth
                rightSideLink={forgotUsername}
                onfocus={handleFocus}
              />

              <InputPassword
                label={yourPassword}
                id="password"
                value={fk.values.password}
                onChange={(e) => fk.setFieldValue('password', e.target.value.trim(), true)}
                onBlur={fk.handleBlur}
                errorMessage={showErrorMessage('password')}
                forgotPassword
                onForgotPasswordClick={onForgotPassword}
                fullWidth={false}
                placeholder={typeInPassword}
                onfocus={handleFocus}
              />
            </LoginBlock>
          </>
        </div>

        <Button disabled={isDisabled} type="submit" fullWidth size="large">
          {letsContinue}
        </Button>
      </form>
    </div>
  );
};

const renderConditionsLinks = () => {
  const intl = useIntl();

  const agreeToElevateskills = `By continuing, you agree to ${getInstanceName()}`;
  const termsOfUse = intl.formatMessage({ id: 'terms_of_use' });
  const and = intl.formatMessage({ id: 'and' });
  const privacyPolicy = intl.formatMessage({ id: 'privacyPolicy' });

  return (
    <div className={clsx(styles.conditions)}>
      <i className="icon-file" />
      <p>
        <span>{agreeToElevateskills}</span>
        <Link to={pageLinks.terms}>{termsOfUse}</Link>
        <span>{and}</span>
        <Link to={pageLinks.privacy}>{privacyPolicy}</Link>
      </p>
    </div>
  );
};

const LoginPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const onLoginClick = (username, password) => {
    const data = {
      username,
      password,
      initiator: INITIATOR,
      instanceType: INSTANCE_TYPES.SHOP,
    };
    dispatch(getToken.action(data));
  };

  const siteName = getInstanceName();
  const title = intl.formatMessage({ id: 'pageTitle.signIn' });
  const backToText = isRetailAddonInstance ? 'add-ons' : 'plans';

  return (
    <>
      <SEO title={`${title} - ${siteName}`} />
      <Layout pageWrapperClassName="page_wrapper">
        <ArrowBack navigationTo={pageLinks.pricing} text={`back to ${backToText}`} />
        {renderHeadingContent()}
        <SignIContent onLoginClick={onLoginClick} />
        {renderConditionsLinks()}
      </Layout>
    </>
  );
};

SignIContent.propTypes = { onLoginClick: PropTypes.func.isRequired };

export default LoginPage;
