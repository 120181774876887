import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import Flag from 'react-world-flags';
import Select from 'react-select/async';
import { countries } from 'countries-list';
import customStyles from '../../constants/selectStyles';
import { forbiddenCountries } from '../../constants';

import styles from './country-select.module.scss';

const getFlag = (name) => <Flag code={name.toLowerCase()} width="32" height="16" />;
// eslint-disable-next-line no-unused-vars
const countryNameComparator = ([_, aCountry], [__, bCountry]) =>
  aCountry.name.localeCompare(bCountry.name);

const countryData = Object.entries(countries)
  .filter((item) => !forbiddenCountries.includes(item[0].toLowerCase()))
  .sort(countryNameComparator);

const makeOptions = (data) =>
  data.map(([code, country]) => ({
    value: code,
    label: (
      <div key={code} className="inputFlagSelect">
        {getFlag(code)}
        <span>{country.name}</span>
      </div>
    ),
  }));

const countryOptions = makeOptions(countryData);

const filterOptionsAsync = (inputValue, filterKeys) =>
  new Promise((resolve) => {
    // eslint-disable-next-line no-unused-vars

    let countryList = countryData;

    if (filterKeys && filterKeys.length) {
      countryList = countryData.filter((country) => filterKeys.includes(country[0]));
    }
    // eslint-disable-next-line no-unused-vars
    const data = countryList.filter(([_, country]) =>
      country.name.toLowerCase().includes(inputValue.toLowerCase())
    );

    resolve(makeOptions(data));
  });

const CountrySelect = ({
  id,
  onChange,
  value,
  className,
  invertDark,
  placeholder,
  label,
  filterKeys,
  isSearchable,
  disabled,
  errorMessage,
  ...rest
}) => {
  const options = filterKeys.length
    ? countryOptions.filter((item) => filterKeys.includes(item.value))
    : countryOptions;

  const defaultValue = options.find(
    (item) => item.value === value || (value && item.value === value.toLowerCase())
  );

  return (
    <div className={clsx(styles.wrapper, className)}>
      {label && (
        <label className="basicLabel" htmlFor={id}>
          {label}
          {rest.required && (
            <span aria-hidden className="asterisk">
              *
            </span>
          )}
        </label>
      )}

      <Select
        id={id}
        defaultOptions={options}
        loadOptions={(val) => filterOptionsAsync(val, filterKeys)}
        styles={customStyles}
        placeholder={placeholder}
        value={defaultValue || ''}
        onChange={onChange}
        isSearchable={!!isSearchable}
        cacheOptions
        maxMenuHeight={300}
        isDisabled={disabled}
        errorMessage={errorMessage}
      />
      {errorMessage ? <div className={styles.errorMessage}>{errorMessage}</div> : null}
    </div>
  );
};

CountrySelect.defaultProps = {
  options: countryOptions,
  value: '',
  label: '',
  placeholder: '',
  className: null,
  invertDark: null,
  disabled: false,
  filterKeys: [],
  errorMessage: '',
};

CountrySelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
    }).isRequired
  ),
  className: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  invertDark: PropTypes.bool,
  filterKeys: PropTypes.arrayOf(PropTypes.string),
  isSearchable: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
};

export default CountrySelect;
