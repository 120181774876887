import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import { Link } from 'gatsby';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import LanguageSelect from '../language-select';
import RegionSelect from '../region-select';
import LogoImage from '../images/logoImage';
import Button from '../button';
import useDropdown from '../../hooks/useDropDown';
import checkMobile from '../../hooks/checkMobile';
import { logOut, redirectToOtherSite } from '../../store/actions';
import {
  isAddonInstance,
  isRetailAddonInstance,
  isTrivaInstance,
} from '../../helpers/instanceHandler';
import { isUserLogged } from '../../helpers/utils';

import { REDIRECTION_TYPES } from '../../constants/types';

// eslint-disable-next-line import/named
import { selectCart, selectPlans } from '../../store/selectors';
import { selectEntityUserDetails } from '../../store/selectors/entities';
import { createNavigateTo, pageLinks } from '../../helpers/navigation';
import styles from './header.module.scss';
import { selectPayForFriendMode } from '../../store/selectors/global';

const navigationLinks = [
  {
    id: 0,
    title: 'home',
    url: pageLinks.home,
  },

  {
    id: 1,
    title: 'pricing',
    url: pageLinks.pricing,
    partiallyActive: true,
  },

  {
    id: 9,
    title: 'addons_title',
    url: pageLinks.addons,
  },

  {
    id: 2,
    title: 'membership_privileges',
    url: pageLinks.memberShipPrivileges,
    partiallyActive: true,
  },
  // { id: 3, title: 'blog', url: pageLinks.blog, partiallyActive: true },
  {
    id: 4,
    title: 'about_us',
    url: pageLinks.aboutUs,
  },
  {
    id: 5,
    title: 'annual',
    url: pageLinks.annualPackages,
  },
];

const filterFunc = (item) => {
  if (isAddonInstance && isRetailAddonInstance) {
    return ['home', 'annual'].includes(item.title);
  }

  if (isAddonInstance && !isRetailAddonInstance) {
    return ['home'].includes(item.title);
  }

  if (!isUserLogged()) {
    return !['addons_title', 'annual'].includes(item.title);
  }

  if (isTrivaInstance) {
    return null;
  }

  return item.title !== 'annual';
};

const stateSelector = createStructuredSelector({
  cartData: selectCart,
  userDetails: selectEntityUserDetails,
  annualPlans: selectPlans(null, true),
  payForFriendMode: selectPayForFriendMode,
});

const Header = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isMobile = checkMobile();

  const toggleRef = useRef(null);
  const dropMenuRef = useRef(null);
  const { activeNav } = props;
  const {
    cartData: { productCount },
    userDetails,
    payForFriendMode,
  } = useSelector(stateSelector);

  const fullName = userDetails && userDetails.fullName;
  const firstName = userDetails && userDetails.firstName;
  const lastName = userDetails && userDetails.lastName;
  const portalText = intl.formatMessage({ id: 'myPortal' });
  const cartText = intl.formatMessage({ id: 'myCart' });
  const menu = intl.formatMessage({ id: 'menu' });
  const close = intl.formatMessage({ id: 'close' });
  const logOutText = intl.formatMessage({ id: 'button.logOut' });

  const menuPlacement = isMobile ? 'bottom' : 'top';

  const [isDropDownOpen, toggleDropDown] = useDropdown(dropMenuRef, toggleRef);

  const [openedMobileNavigation, setOpenedMobileNavigation] = useState(false);

  const onNavBurgerClick = () => setOpenedMobileNavigation(!openedMobileNavigation);

  const toShoppingCart = createNavigateTo(pageLinks.shoppingCart);

  const onPortalClick = () => {
    dispatch(
      redirectToOtherSite({
        type: REDIRECTION_TYPES.PORTAL,
        authorized: true,
        link: process.env.GATSBY_PORTAL_LINK,
      })
    );
  };

  const onLogOutClick = () => {
    dispatch(logOut());
  };

  if (payForFriendMode) {
    return (
      <header className={clsx(styles.header, openedMobileNavigation ? styles.is_active : {})}>
        <div className={styles.header_wrapper}>
          <button className={styles.nav_burger} type="button" onClick={onNavBurgerClick}>
            <i className={clsx(openedMobileNavigation ? 'icon-close' : 'icon-menu')} />
            <span>{openedMobileNavigation ? close : menu}</span>
          </button>

          <div className={styles.header_first_part}>
            <Link to={pageLinks.checkout} className={styles.logo}>
              <LogoImage variant={LogoImage.variant.ONLINE_LINE} />
            </Link>

            <div className={styles.mobile_navigation}>
              <div className={clsx(styles.buttons, styles.column)}>
                <div className={styles.centered_select}>
                  <LanguageSelect id="headerLanguages" menuPlacement={menuPlacement} />
                </div>
                <div className={clsx(styles.buttons, fullName && styles.column)}>
                  <Button onClick={onPortalClick} size="small" className={styles.portalButton}>
                    {portalText}
                  </Button>
                </div>
                {fullName && (
                  <button
                    type="button"
                    className={clsx(styles.logOut, styles.mobileLogOut)}
                    onClick={onLogOutClick}
                  >
                    {logOutText}
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className={styles.header_info}>
            {fullName && (
              <>
                <div className={styles.dropdownWrapper}>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <div
                    className={clsx(
                      styles.header_user,
                      isDropDownOpen && styles.header_user_opened
                    )}
                    onClick={() => toggleDropDown(!isDropDownOpen)}
                  >
                    <p className={`${clsx(styles.username)} dont-translate`}>
                      {firstName}&nbsp;{lastName}
                    </p>
                    <i className="icon-arrow-down" />
                  </div>

                  {isDropDownOpen && (
                    <div className={styles.dropdown}>
                      <button type="button" className={styles.dropdownItem} onClick={onLogOutClick}>
                        {logOutText}
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button
          type="button"
          className={clsx(
            styles.navigation_overlay,
            openedMobileNavigation && styles.navigation_overlay_active
          )}
          onClick={onNavBurgerClick}
        />
      </header>
    );
  }

  if (isTrivaInstance) {
    return (
      <header className={clsx(styles.trivaHeader)}>
        <div className={styles.header_info}>
          {fullName && (
            <>
              <div className={styles.dropdownWrapper}>
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => toggleDropDown(!isDropDownOpen)}
                  className={clsx(
                    styles.header_user_trivia,
                    isDropDownOpen && styles.header_user_opened
                  )}
                  onClick={() => toggleDropDown(!isDropDownOpen)}
                >
                  <p className={`${clsx(styles.username)} dont-translate`}>
                    {firstName}&nbsp;{lastName}
                  </p>
                  <i className="icon-arrow-down" />
                </div>
                {isDropDownOpen && (
                  <div className={styles.dropdown}>
                    <button type="button" className={styles.dropdownItem} onClick={onLogOutClick}>
                      {logOutText}
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button
          type="button"
          className={clsx(
            styles.navigation_overlay,
            openedMobileNavigation && styles.navigation_overlay_active
          )}
          onClick={onNavBurgerClick}
        />
      </header>
    );
  }

  return (
    <header className={clsx(styles.header, openedMobileNavigation ? styles.is_active : {})}>
      <div className={styles.header_wrapper}>
        <button className={styles.nav_burger} type="button" onClick={onNavBurgerClick}>
          <i className={clsx(openedMobileNavigation ? 'icon-close' : 'icon-menu')} />
          <span>{openedMobileNavigation ? close : menu}</span>
        </button>

        <div className={styles.header_first_part}>
          <Link to={pageLinks.home} className={styles.logo}>
            <LogoImage variant={LogoImage.variant.ONLINE_LINE} />
          </Link>

          <div className={styles.mobile_navigation}>
            <nav className={styles.navigation}>
              <ul>
                {navigationLinks
                  .filter((item) => filterFunc(item))
                  .map((x) => (
                    <li key={x.id} className={activeNav ? styles.active_link : null}>
                      <Link
                        to={x.url}
                        activeClassName={styles.active}
                        className={styles.navigation_link}
                        partiallyActive={x.partiallyActive}
                      >
                        <span>{intl.formatMessage({ id: x.title })}</span>
                      </Link>
                    </li>
                  ))}
              </ul>
            </nav>

            <div className={clsx(styles.buttons, fullName && styles.column)}>
              <div>
                <LanguageSelect id="headerLanguages" menuPlacement={menuPlacement} />
              </div>

              {!isUserLogged() && !isAddonInstance && !isTrivaInstance && (
                <div className={styles.countrySelect}>
                  <RegionSelect />
                </div>
              )}

              {!isTrivaInstance && (
                <Button onClick={onPortalClick} size="small" className={styles.portalButton}>
                  {portalText}
                </Button>
              )}

              {!isUserLogged() && (
                <Link className={styles.loginButton} to="/login">
                  Log in
                </Link>
              )}

              {fullName && (
                <button
                  type="button"
                  className={clsx(styles.logOut, styles.mobileLogOut)}
                  onClick={onLogOutClick}
                >
                  {logOutText}
                </button>
              )}
            </div>
          </div>
        </div>

        <div className={styles.header_info}>
          {!fullName && <div className={clsx(styles.header_divider)} />}

          <button
            type="button"
            className={clsx(styles.cart, productCount ? styles.cartWithGoods : {})}
            onClick={toShoppingCart}
          >
            <span>{cartText}</span>
            <i className="icon-cart" />

            <div className={clsx(styles.cart_number)}>{productCount}</div>
          </button>

          {fullName && (
            <>
              <div className={styles.dropdownWrapper}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  className={clsx(styles.header_user, isDropDownOpen && styles.header_user_opened)}
                  onClick={() => toggleDropDown(!isDropDownOpen)}
                >
                  <p className={`${clsx(styles.username)} dont-translate`}>
                    {firstName}&nbsp;{lastName}
                  </p>
                  <i className="icon-arrow-down" />
                </div>
                {isDropDownOpen && (
                  <div className={styles.dropdown}>
                    <button type="button" className={styles.dropdownItem} onClick={onLogOutClick}>
                      {logOutText}
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button
        type="button"
        className={clsx(
          styles.navigation_overlay,
          openedMobileNavigation && styles.navigation_overlay_active
        )}
        onClick={onNavBurgerClick}
      />
    </header>
  );
};

Header.propTypes = {
  activeNav: PropTypes.bool,
};

Header.defaultProps = {
  activeNav: false,
};

export default Header;
