import clsx from 'clsx';

import React, { useEffect } from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

import SEO from '../components/seo';
import Layout from '../components/layout';
import Banner from '../components/banner';
import Button from '../components/button';

import { EMAIL_CAREERS } from '../constants';

import styles from '../styles/pages/join-us.module.scss';
import { getInstanceName, logEvent } from '../helpers/utils';

const query = graphql`
  query {
    heroDesktop: file(relativePath: { eq: "join-us/hero-join-us-desktop.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroMobile: file(relativePath: { eq: "join-us/hero-join-us-mobile.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bannerDesktop: file(relativePath: { eq: "join-us/banner-culture-desktop.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bannerMobile: file(relativePath: { eq: "join-us/banner-culture-mobile.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ctaBannerDesktop: file(relativePath: { eq: "join-us/banner-cta-join-desktop.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ctaBannerMobile: file(relativePath: { eq: "join-us/banner-cta-join-mobile.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const JoinUsPage = () => {
  const intl = useIntl();
  const data = useStaticQuery(query);

  useEffect(() => logEvent('visited_join_us'), []);

  const siteName = getInstanceName();
  const title = intl.formatMessage({ id: 'pageTitle.joinUs' });

  return (
    <>
      <SEO title={`${title} - ${siteName}`} />
      <Layout fluid>
        <Banner
          isHero
          imageData={data.heroDesktop.childImageSharp.fluid}
          imageDataMobile={data.heroMobile.childImageSharp.fluid}
        >
          <h2 className={clsx('common_title', styles.contrastText)}>
            {intl.formatMessage({ id: 'join_us_page.join_us' })}
          </h2>
          <p className={clsx('common_subtitle', styles.contrastText)}>
            {intl.formatMessage({ id: 'join_us_page.grow_professionally' })}
          </p>
        </Banner>

        <div className={styles.banner}>
          <Banner
            contained
            imageData={data.bannerDesktop.childImageSharp.fluid}
            imageDataMobile={data.bannerMobile.childImageSharp.fluid}
          >
            <h3 className={clsx('common_title', styles.contrastText)}>
              {intl.formatMessage({ id: 'join_us_page.culture' })}
            </h3>
            <p className={clsx('common_subtitle', styles.contrastText)}>
              {intl.formatMessage({ id: 'join_us_page.we_uphold' })}
            </p>
          </Banner>
        </div>

        <div className="container">
          <div className={styles.experienceWrapper}>
            <div>
              <h3 className="common_title">
                {intl.formatMessage({ id: 'join_us_page.experience' })}
              </h3>
              <p className={clsx('common_subtitle', styles.experienceSubtitle)}>
                {intl.formatMessage({ id: 'join_us_page.we_strive' })}
              </p>
              <p className={clsx('common_subtitle', styles.experienceText)}>
                {intl.formatMessage({ id: 'join_us_page.our_employees' })}
              </p>
              <p className={clsx('common_subtitle', styles.experienceText)}>
                {intl.formatMessage({ id: 'join_us_page.we_believe' })}
              </p>
            </div>
            <div className={styles.experienceBadges}>
              <div className={styles.badge}>
                <i className={clsx('icon-compensation_equity', styles.compensationEquity)} />
                <div>{intl.formatMessage({ id: 'join_us_page.compensation_equity' })}</div>
              </div>
              <div className={styles.badge}>
                <i className={clsx('icon-vacation_leaves', styles.vacationLeaves)} />
                <div>{intl.formatMessage({ id: 'join_us_page.vacation_leaves' })}</div>
              </div>
              <div className={styles.badge}>
                <i className={clsx('icon-health_wellness', styles.healthWellness)} />
                <div>{intl.formatMessage({ id: 'join_us_page.health_wellness' })}</div>
              </div>
              <div className={styles.badge}>
                <i className={clsx('icon-learning_growth', styles.learningGrowth)} />
                <div>{intl.formatMessage({ id: 'join_us_page.learning_growth' })}</div>
              </div>
            </div>
          </div>
        </div>
        <Banner
          shortContentWidth
          imageData={data.ctaBannerDesktop.childImageSharp.fluid}
          imageDataMobile={data.ctaBannerMobile.childImageSharp.fluid}
        >
          <h3 className={clsx('common_title', styles.contrastText)}>
            {intl.formatMessage({ id: 'join_us_page.join_our_team' })}
          </h3>
          <p className={clsx('common_subtitle', styles.contrastText)}>
            {intl.formatMessage({ id: 'join_us_page.we_like_to' })}
          </p>
          <Button
            size="large"
            component="a"
            href={`mailto:${EMAIL_CAREERS}`}
            className={styles.careerLink}
          >
            {intl.formatMessage({ id: 'join_us_page.get_in_touch' })}
          </Button>
        </Banner>
      </Layout>
    </>
  );
};

export default JoinUsPage;
