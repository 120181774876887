import { NotificationManager } from 'react-notifications';

export default (type, params) => {
  NotificationManager.listNotify = [];
  switch (type) {
    case 'info':
      NotificationManager.info(params.message, params.title, 15000, null, true);
      break;
    case 'success':
      NotificationManager.success(params.message, params.title, 5000, null, true);
      break;
    case 'warning':
      NotificationManager.warning(params.message, params.title, 15000, null, true);
      break;
    case 'error': {
      const defaultError = 'Sorry something went wrong';
      NotificationManager.error(params.message || defaultError, params.title, 10000, null, true);
      break;
    }
    default:
      break;
  }
};
