import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import Avatar from '../avatar';

import styles from './registration-thanks-banner.module.scss';

const RegistrationThanksBanner = ({ userName }) => {
  const intl = useIntl();

  const thanks = intl.formatMessage({ id: 'registrationSuccess.thanks' });
  const pleaseProceed = intl.formatMessage({ id: 'registrationSuccess.pleaseProceed' });

  return (
    <div>
      <div className={styles.avatarWrapper}>
        <Avatar />
      </div>
      <div className={styles.textWrapper}>
        <p className={styles.title}>
          {thanks}
          {userName && (
            <>
              ,
              <span>
                &nbsp;
                {userName}
              </span>
            </>
          )}
          !
        </p>
        <p className={styles.text}>{pleaseProceed}</p>
      </div>
    </div>
  );
};

RegistrationThanksBanner.propTypes = { userName: PropTypes.string.isRequired };

export default RegistrationThanksBanner;
