import React, { useState, useRef } from 'react';
import PropTypes, { shape } from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'gatsby-plugin-intl';
import { withFramePayCardComponent } from '@rebilly/framepay-react';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_PAYMENT_ERROR } from '../../store/constants';
import Loader from '../loader';
import setNotification from '../../helpers/notifications';
import { isClient, normalizeLatinLetters } from '../../helpers/utils';
import styles from './checkout-form.module.scss';
import Checkbox from '../checkbox';
import billingstyles from '../billing-payment-modal-form/billing-payment-modal-form.module.scss';
import { selectPrimaryBillingAddress } from '../../store/selectors';
import PaymentConstants from '../../constants/payment';

const CheckoutFormCard = ({
  renderProps,
  onReceiveToken,
  Rebilly,
  CardNumberElement,
  CardCvvElement,
  CardExpiryElement,
  method,
  isPrimaryPaymentPresent,
  userDetails,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [focusedItem, setFocused] = useState('');
  const [isDefault, setDefault] = useState(true);
  const [loading, setLoading] = useState(true);
  const primaryAddress = useSelector(selectPrimaryBillingAddress);

  const setAsDefaultText = intl.formatMessage({ id: 'purchase_checkout.setAsDefault' });
  const whatIsThisText = intl.formatMessage({ id: 'purchase_checkout.whatIsThis' });
  const weWillMakeThisText = intl.formatMessage({ id: 'purchase_checkout.weWillMakeThis' });

  const formRef = useRef(null);

  const redirectToAstroPay = () => {
    if (isClient) {
      window.location.href = 'https://astropay.com';
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // if (!primaryAddress) {
    //   setNotification('error', {
    //     message: 'Please select you primary address',
    //     title: 'Error',
    //   });
    //   return;
    // }

    const { address, city, country, zipCode, firstName, lastName, address2, phone, state } =
      primaryAddress || {};

    Rebilly.createToken(formRef.current, {
      billingAddress: {
        firstName: normalizeLatinLetters(firstName || userDetails?.firstName),
        lastName: normalizeLatinLetters(lastName || userDetails?.lastName),
        // ? Kosovo check and replace with Serbia
        country: country.toUpperCase() === 'XK' ? 'RS' : country.toUpperCase(),
        city,
        address,
        address2,
        postalCode: zipCode,
        region: state || '',
        emails: [
          {
            label: 'main',
            value: userDetails?.email,
            primary: true,
          },
        ],
        phoneNumbers: [
          {
            label: 'main',
            value: phone || userDetails?.phone,
            primary: true,
          },
        ],
      },
    })
      .then((data) => {
        onReceiveToken({ ...data, isDefault });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        setNotification('error', {
          message: err.details && err.details.length && err.details[0],
          title: 'Error',
        });

        dispatch({ type: CREATE_PAYMENT_ERROR, payload: err });
      });
  };

  const isFocused = (field) => (focusedItem === field ? styles.focused : null);

  const cardText = intl.formatMessage({ id: 'purchase_checkout_form.card_number' });
  const astroPayText = `AstroPay ${cardText}`;
  const cardLabel = method === 'astroPay' ? astroPayText : cardText;

  return (
    <div className={styles.checkoutForm}>
      <div>
        <div className={styles.formWrapper}>
          <form onSubmit={handleSubmit} ref={formRef}>
            <div className={styles.cardInfo}>
              <div className={styles.row}>
                <div className={clsx(styles.rebillyFramepay, isFocused('name'))}>
                  <span className="basicLabel">{cardLabel}</span>

                  <CardNumberElement
                    onBlur={() => setFocused('')}
                    onFocus={() => setFocused('name')}
                    onReady={() => setLoading(false)}
                  />
                  {loading && (
                    <div className={styles.loader}>
                      <Loader isLoading isSmall />
                    </div>
                  )}
                </div>

                <div
                  className={clsx(styles.rebillyFramepay, styles.noPadding, isFocused('exp-date'))}
                >
                  <span className="basicLabel">Expiration Date</span>
                  <CardExpiryElement
                    onBlur={() => setFocused('')}
                    onFocus={() => setFocused('exp-date')}
                  />
                  {loading && (
                    <div className={styles.loader}>
                      <Loader isLoading isSmall />
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.row}>
                <div className={clsx(styles.rebillyFramepay, isFocused('cvv'))}>
                  <span className="basicLabel">CVV</span>
                  <CardCvvElement onBlur={() => setFocused('')} onFocus={() => setFocused('cvv')} />
                  {loading && (
                    <div className={styles.loader}>
                      <Loader isLoading isSmall />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {method === 'astroPay' && (
        <div className={clsx(billingstyles.checkbox, styles.astropayText)}>
          <div className={clsx(billingstyles.checkboxChildren)}>
            Do not have a Astro Pay card already?
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
            <span role="button" className={styles.clickHere} onClick={redirectToAstroPay}>
              Click here
            </span>
            or purchase!
          </div>
        </div>
      )}

      {method === PaymentConstants.SHOP_METHODS.card && (
        <div className={clsx(billingstyles.checkbox)}>
          <p className={billingstyles.attentIntrnational}>
            Please make sure to enable e-commerce & international transactions on your bank account
          </p>
          <Checkbox
            disabled={!isPrimaryPaymentPresent}
            checked={isDefault}
            onChange={() => setDefault(!isDefault)}
          >
            <div className={clsx(billingstyles.checkboxChildren)}>
              {setAsDefaultText}
              <span>
                <i className={billingstyles.tooltip}>{weWillMakeThisText}</i>
                {whatIsThisText}
              </span>
            </div>
          </Checkbox>
        </div>
      )}

      {renderProps(handleSubmit)}
    </div>
  );
};

CheckoutFormCard.propTypes = {
  onReceiveToken: PropTypes.func.isRequired,
  Rebilly: shape({ createToken: PropTypes.func }),
  renderProps: PropTypes.func.isRequired,
  CardNumberElement: PropTypes.elementType.isRequired,
  CardCvvElement: PropTypes.elementType.isRequired,
  CardExpiryElement: PropTypes.elementType.isRequired,
  primaryAddress: PropTypes.shape({
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  method: PropTypes.string.isRequired,
  isPrimaryPaymentPresent: PropTypes.bool.isRequired,
  userDetails: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }).isRequired,
};

CheckoutFormCard.defaultProps = { Rebilly: { createToken: () => {} } };

let withWrapper = (componet) => componet;
if (isClient) {
  withWrapper = withFramePayCardComponent;
}

export default withWrapper(CheckoutFormCard);
