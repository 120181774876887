import * as Yup from 'yup';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useIntl } from 'gatsby-plugin-intl';
import { Link } from 'gatsby';
import { selectBillingAddresses } from '../../store/selectors';
import { selectEntityUserDetails } from '../../store/selectors/entities';
import { selectModalError } from '../../store/selectors/global';
import deleteBillAddress from '../../services/api/actions/deleteBillAddress';
import updateBillAddress from '../../services/api/actions/updateBillAddress';
import addBillAddress from '../../services/api/actions/addBillAddress';
import BillingAddressBlock from '../billing-address-block';
import AddCartItem from '../add-cart-item';
import InputRadio from '../input-radio';
import TitleButton from '../title-button';
import BillingAddressModalForm from '../billing-address-modal-form';
import styles from './billing-address-form.module.scss';
import changeProfileInfo from '../../services/api/actions/changeProfileInfo';
import { getCountryData } from '../../helpers/countries';
import { pageLinks } from '../../helpers/navigation';

const stateSelector = createStructuredSelector({
  billingAddresses: selectBillingAddresses,
  modalError: selectModalError,
  userDetails: selectEntityUserDetails,
});

const anyLetterRegExp =
  /^[a-zA-Z\u00C0-\u00FFŸŒ¢]+([a-zA-Z\u00C0-\u00FFŸŒ¢\s'`]?)+[a-zA-Z\u00C0-\u00FFŸŒ¢]+$/;
const anyLetterErrorMessage = 'These characters are not supported';

const validationSchema = (values) =>
  Yup.object().shape({
    firstName: Yup.string()
      .required('First name is required')
      .min(2, 'First Name should contain at least two letters')
      .max(50, 'First Name should be not more than 50 characters long')
      .matches(anyLetterRegExp, anyLetterErrorMessage),
    lastName: Yup.string()
      .required('Last name is required')
      .min(2, 'Last Name should contain at least two letters')
      .max(50, 'Last Name should be not more than 50 characters long')
      .matches(anyLetterRegExp, anyLetterErrorMessage),
    country: Yup.string().required('This field is required'),
    city: Yup.string()
      .matches(
        /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
        'Should contain only letters'
      )
      .max(50, 'City should be not more than 50 characters long')
      .required('City is required'),
    address: Yup.string()
      .matches(/^[^-\s][A-z0-9\u00C0-\u00FF\s,.ŸŒ¢-]*$/, 'These characters are not supported')
      .max(50, 'Address should be not more than 50 characters long')
      .required('Address is required'),
    address2: Yup.string()
      .matches(/^[^-\s][A-z0-9\u00C0-\u00FF\s,.ŸŒ¢-]*$/, 'These characters are not supported')
      .max(50, 'Address should be not more than 50 characters long'),
    zipCode: Yup.string()
      .matches(/^([^-\s][a-z0-9-\s]+)$/iu, 'Must be a valid zip-code')
      .max(10, 'Postal/zip code should be not more than 10 characters long')
      .required('ZIP/Postal Code is required'),

    phone: Yup.lazy((value) => {
      const isTestNumber = value?.includes('117788');

      if (isTestNumber) {
        return Yup.string().notRequired();
      }

      return Yup.string().when('country', (val) => {
        let code = val && getCountryData(val).phone;
        if (values.countryCode) {
          code = values.countryCode.replace('+', '');
        }

        if (val && code) {
          const minLength = 8 - code.length;
          const maxLength = 16 - code.length;
          return Yup.string()
            .required('Phone is required')
            .matches(/^[0-9]*$/, 'Must be a valid number')
            .min(minLength, `Phone number should be 8-16 digits long including country code`)
            .max(maxLength, `Phone number should be 8-16 digits long including country code`);
        }
        return Yup.string().required('Phone is required');
      });
    }),
    state: Yup.string().when('country', {
      is: 'US',
      then: () =>
        Yup.string()
          .required('State is required')
          .matches(anyLetterRegExp, 'Should contain only letters')
          .max(50, 'State should be not more than 45 characters long'),
    }),
  });

const ASK_USE_DEFAULT_ADDRESS_STEP = 0;
const SHOW_SELECTED_ADDRESS_STEP = 1;

const BillingAddressForm = ({ modal, onClose, toggleModal }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { billingAddresses: addressList, modalError, userDetails } = useSelector(stateSelector);

  const initialValues = {
    firstName: userDetails?.firstName || '',
    lastName: userDetails?.lastName || '',
    country: userDetails?.country || '',
    countryCode: '',
    state: '',
    city: '',
    address: '',
    zipCode: '',
    address2: '',
    phone: userDetails?.phone.replace(`+${getCountryData(userDetails.country).phone}`, '') || '',
  };

  const addAddress = intl.formatMessage({ id: 'purchase_checkout.addAddress' });
  const billingAddressText = intl.formatMessage({ id: 'purchase_checkout.billing_adress' });
  const editText = intl.formatMessage({ id: 'button.edit' });

  const filterValidAddress = (array) =>
    array.filter((item) => item.city && item.address && item.zipCode);

  const billingAddresses = filterValidAddress(addressList);

  const defaultAddress = billingAddresses.find((item) => !!item.primary);
  const registerAddress = billingAddresses.find((item) => !!item.exigo);

  const otherAddresses = billingAddresses.filter((item) => !item.primary);

  const address =
    billingAddresses.find((item) => !!item.primary) ||
    billingAddresses.find((item) => !!item.exigo) ||
    billingAddresses[0];

  const [selectedAddress, setSelected] = useState(defaultAddress || registerAddress);

  const [actionName, setAction] = useState('');

  const defaultStep =
    !defaultAddress && otherAddresses.length
      ? ASK_USE_DEFAULT_ADDRESS_STEP
      : SHOW_SELECTED_ADDRESS_STEP;

  const [step, setStep] = useState(defaultStep);

  const values = selectedAddress || initialValues;

  const getInitialCountryCode = () => {
    const country = getCountryData(values.country);
    let countryCode = '';
    if (country && country.phone.includes(',')) {
      const countryCodes = country.phone.split(',').map((code) => `+${code}`);
      countryCodes.map((code) => {
        const len = code.length;
        const cutCodeFromThePhoneNumber = values.phone.slice(0, len);
        if (code === cutCodeFromThePhoneNumber) {
          countryCode = code;
        }

        return null;
      });
    }

    return countryCode;
  };

  const getInitialPhoneNumber = (val) => {
    const country = getCountryData(val?.country);
    if (country && country.phone.includes(',')) {
      const countryCode = getInitialCountryCode();
      return val.phone && val.phone.replace(countryCode, '');
    }

    return val?.country && val?.phone
      ? val.phone.replace(`+${(getCountryData(val.country) || { phone: '' }).phone}`, '')
      : '';
  };

  const fk = useFormik({
    initialValues: {
      ...values,
      countryCode: getInitialCountryCode(),
      phone: getInitialPhoneNumber(values),
    },
    validationSchema: () => Yup.lazy((formValues) => validationSchema(formValues)),
  });

  useEffect(() => {
    if (modal !== 'newAddress' && address && address.id) {
      setSelected(address);
      fk.validateForm();
    }
  }, [modalError, modal]);

  useEffect(() => {
    const addressValues = {
      ...selectedAddress,
      country: selectedAddress?.country?.toUpperCase(),
      phone: getInitialPhoneNumber(selectedAddress),
    };
    fk.setValues(addressValues);
  }, [selectedAddress?.id]);

  const showModal = (type = '') => {
    toggleModal(modal ? '' : type);
    if (step === ASK_USE_DEFAULT_ADDRESS_STEP) {
      setStep(SHOW_SELECTED_ADDRESS_STEP);
    }
  };

  const onAddAddressClick = () => {
    const data = {
      ...fk.values,
      phone: fk.values.countryCode
        ? `${fk.values.countryCode}${fk.values.phone}`
        : `+${getCountryData(fk.values.country).phone}${fk.values.phone}`,
      primary: false,
    };
    if (billingAddresses && !billingAddresses.length) {
      data.primary = true;
    }
    if (modalError) {
      dispatch(changeProfileInfo.action(data));
    }

    dispatch(addBillAddress.action(data));

    showModal();
    setTimeout(fk.handleReset, 0);
  };

  const onUpdateAddressClick = () => {
    const data = {
      ...fk.values,
      phone: fk.values.countryCode
        ? `${fk.values.countryCode}${fk.values.phone}`
        : `+${getCountryData(fk.values.country).phone}${fk.values.phone}`,
    };

    const addressId = selectedAddress && selectedAddress.id;

    if (addressId) {
      if (modalError) {
        dispatch(changeProfileInfo.action(data));
      }
      dispatch(
        updateBillAddress.action({
          ...data,
          primary: true,
          addressId,
        })
      );
    } else {
      onAddAddressClick();
    }

    setStep(SHOW_SELECTED_ADDRESS_STEP);
    setTimeout(fk.handleReset, 0);
    showModal();
    setSelected({
      ...data,
      addressId,
    });
  };

  const onDeleteAddress = (id) => {
    dispatch(deleteBillAddress.action({ addressId: id }));
    setSelected(null);
  };

  const setAddressAsDefault = (addressItem) => {
    const addressId = addressItem.id;
    dispatch(
      updateBillAddress.action({
        ...addressItem,
        addressId,
        primary: true,
      })
    );
  };

  return (
    <>
      {modal && modal.length > 0 && (
        <BillingAddressModalForm
          {...{
            step,
            setStep,
            modal,
            toggleModal,
            userDetails,
            showModal,
            fk,
            onUpdateAddressClick,
            onAddAddressClick,
            onDeleteAddress,
            defaultAddress,
            otherAddresses,
            selectedAddress,
            setAddressAsDefault,
            actionName,
            setSelected,
            setAction,
            billingAddresses,
            modalError,
          }}
        />
      )}

      <TitleButton title={billingAddressText}>
        {onClose ? (
          <Link to={pageLinks.checkout} className={styles.closeIcon}>
            <i className="icon-close" />
          </Link>
        ) : (
          address &&
          !onClose && (
            <button
              className="titleButton"
              type="button"
              onClick={() => {
                if (!selectedAddress) {
                  setSelected(defaultAddress);
                }
                showModal('editAddress');
              }}
            >
              <i className="icon-edit" />
              <span>{editText}</span>
            </button>
          )
        )}
      </TitleButton>

      <div>
        <div data-browsing-ignore>
          {billingAddresses.map((addressItem) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div
              className={clsx(styles.address, styles.addAddressWrapper)}
              key={addressItem.id}
              onClick={() => {
                if (!addressItem.primary) {
                  setSelected(addressItem);
                  setAddressAsDefault(addressItem);
                }
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              <InputRadio
                className={styles.radio}
                checked={defaultAddress && addressItem.id === defaultAddress.id}
                value="address"
                onChange={() => {
                  setSelected(addressItem);
                }}
              />
              <BillingAddressBlock
                selected={defaultAddress && addressItem.id === defaultAddress.id}
                onClick={() => {}}
                {...addressItem}
              />
            </div>
          ))}
          <div className={styles.addAddressWrapper}>
            <AddCartItem
              onAdd={() => {
                setSelected(null);
                showModal('newAddress');
                setAction('new');
              }}
              title={addAddress}
            />
          </div>
        </div>
      </div>
    </>
  );
};

BillingAddressForm.defaultProps = { modal: '', onClose: null, toggleModal: () => null };

BillingAddressForm.propTypes = {
  modal: PropTypes.string,
  onClose: PropTypes.func,
  toggleModal: PropTypes.func,
};

export default BillingAddressForm;

// React.memo(
//   BillingAddressForm,
//   (props, nextProps) => props.modal === nextProps.modal
// );
