import { useState, useEffect } from 'react';

const checkMobile = () => {
  const isClient = typeof window === 'object';

  const detectMobileWidth = () => (isClient ? window.innerWidth < 767 : undefined);

  const [isMobile, setWindowSize] = useState(detectMobileWidth);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    const handleResize = () => setWindowSize(detectMobileWidth());

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

export default checkMobile;
