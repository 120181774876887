/* eslint-disable require-yield */
import { takeLatest, call, select, delay } from 'redux-saga/effects';
import { LOG_OUT_USER, TOGGLE_LIVE_CHAT } from '../store/constants';
import createCrispChat from '../../config/crispChat';
import {
  selectEntityUserDetails,
  selectEntityUserSubscriptions,
} from '../store/selectors/entities';
import getUserDetails from '../services/api/actions/getUserDetails';
import getTokenFinal from '../services/api/actions/getTokenFinal';
import { isClient } from '../helpers/utils';

const getChat = () => isClient && window.$crisp;

function* openChatWorker() {
  yield call(createCrispChat);
}

function* fillChatData(userData, userSubscriptions) {
  const { languageId, firstName, lastName, customerId, email, country } = userData;

  const CHAT = getChat();

  const subscriptions = userSubscriptions.reduce(
    // eslint-disable-next-line prefer-template
    (acc, val) => acc + `${val.name} ${val.level}(${val.status}) `,
    ''
  );

  const crispUserData = {
    languageId,
    shopUrl: window.location.href,
    firstName,
    lastName,
    customerId,
    email,
    country,
    subscriptions,
  };

  const sessionData = Object.keys(crispUserData).map((item) => [item, crispUserData[item]]);

  CHAT.push(['set', 'user:email', [email]]);
  CHAT.push(['set', 'user:nickname', [firstName]]);
  CHAT.push(['set', 'session:data', [sessionData]]);
}

function* chatDataWorker() {
  const userData = yield select(selectEntityUserDetails);
  const userSubscriptions = yield select(selectEntityUserSubscriptions);
  const CHAT = getChat();

  if (!CHAT) {
    yield call(openChatWorker);
    yield delay(0);
  }

  if (userData && userSubscriptions && CHAT) {
    yield call(fillChatData, userData, userSubscriptions);
  }
}

function* toggleChatWorker(payload) {
  const CHAT = getChat();
  const chatAction = payload ? 'open' : 'close';
  // eslint-disable-next-line no-unused-expressions
  CHAT && CHAT.push(['do', `chat:${chatAction}`]);
}

function* closeChatWorker() {
  const CHAT = getChat();
  if (CHAT) {
    CHAT.push(['do', 'chat:hide']);
  }
}

export default function* liveChatSaga() {
  yield takeLatest([getUserDetails.type.success], chatDataWorker);
  yield takeLatest([getTokenFinal.type.success], openChatWorker);
  yield takeLatest([TOGGLE_LIVE_CHAT], toggleChatWorker);
  yield takeLatest([LOG_OUT_USER], closeChatWorker);
}
