export const ONLINE_LINE_TYPE_CONTENT = [
  'br',
  'onlineLine',
  'rapidchangers',
  'euonlineLine',
  'newroverse',
].includes(process.env.GATSBY_INSTANCE_NAME);

export const isBeunleashInstance = process.env.GATSBY_INSTANCE_NAME === 'beunleash';
export const isRapidChangersInstance = process.env.GATSBY_INSTANCE_NAME === 'rapidchangers';

export const availableEnrollers = ['yafe', 'jama'];

export const isOnlineLineInstance = ['br', 'onlineLine', 'euonlineLine', 'sgonlineline'].includes(
  process.env.GATSBY_INSTANCE_NAME
);

export const isRetailAddonInstance = process.env.GATSBY_INSTANCE_TYPE === 'retail';
export const isAddonInstance = process.env.GATSBY_INSTANCE_NAME === 'addon';
export const isNewParadimeInstance = process.env.GATSBY_INSTANCE_NAME === 'newparadime';
export const isOnlineEra = ['onlineEra', 'euonlineEra'].includes(process.env.GATSBY_INSTANCE_NAME);
export const isOnlineEraUs = process.env.GATSBY_INSTANCE_NAME === 'onlineEra';
export const isSgOnlineLine = process.env.GATSBY_INSTANCE_NAME === 'sgonlineline';
export const isEuOnlineLine = process.env.GATSBY_INSTANCE_NAME === 'euonlineLine';
export const isBrBeunleashInstance = process.env.GATSBY_INSTANCE_NAME === 'brbeunleash';
export const isBefreedomInstance = process.env.GATSBY_INSTANCE_NAME === 'befreedom';
export const isBetoinspireInstance = process.env.GATSBY_INSTANCE_NAME === 'betoinspireprod';
export const isFrInstance = process.env.GATSBY_INSTANCE_NAME === 'fr';
export const isGbInstance = process.env.GATSBY_INSTANCE_NAME === 'gb';
export const isTrivaInstance = process.env.GATSBY_INSTANCE_NAME === 'triva';

export const isIbiInstances =
  isOnlineLineInstance ||
  isOnlineEra ||
  isBefreedomInstance ||
  isBeunleashInstance ||
  isBetoinspireInstance ||
  isFrInstance ||
  isGbInstance;

export const isProduction =
  process.env.GATSBY_INSTANCE_TYPE === 'prod' || process.env.GATSBY_REBILLY_KEY.includes('live');
export const BTC_PROMO_COUPON_ID = 'BTC-PROMO';

export const getInstanceName = () => {
  const siteNames = {
    fr: 'Betoinspire',
    gb: 'Betoinspire',
    br: 'Online-line',
    befreedom: 'Befreedom',
    betoinspireprod: 'Betoinspire',
    onlineLine: 'Online-line',
    euonlineLine: 'Online-line',
    beunleash: 'Beunleash',
    brbeunleash: 'Beunleash',
    onlineEra: 'Online-line',
    euonlineEra: 'Online-Era',
    newparadime: 'NewParadime',
    addon: 'Star One marketplace',
    rapidchangers: 'Rapid Changers',
    newroverse: 'Newroverse',
  };

  return siteNames[process.env.GATSBY_INSTANCE_NAME] || 'Shop Portal';
};
