import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const LogoImage = ({ variant, className }) => {
  const data = useStaticQuery(graphql`
    query {
      color: file(relativePath: { eq: "logo/logo_color.png" }) {
        childImageSharp {
          fixed(width: 45, height: 45) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      onlineLine: file(relativePath: { eq: "logo/online-line-logo.png" }) {
        childImageSharp {
          fixed(width: 45, height: 45) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      white: file(relativePath: { eq: "logo/logo_white.png" }) {
        childImageSharp {
          fixed(width: 45, height: 45) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return <Img className={className} fixed={data[variant || 'color']?.childImageSharp?.fixed} />;
};

LogoImage.variant = {
  COLOR: 'color',
  WHITE: 'white',
  ONLINE_LINE: 'onlineLine',
};

LogoImage.defaultProps = { variant: LogoImage.variant.COLOR, className: '' };
LogoImage.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    LogoImage.variant.COLOR,
    LogoImage.variant.WHITE,
    LogoImage.variant.ONLINE_LINE,
  ]),
};

export default LogoImage;
