import { take, put, call, fork, takeLatest, spawn, delay } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import io from 'socket.io-client';
import { getTokens } from '../services/api/tokenHandler';
import getTokenFinal from '../services/api/actions/getTokenFinal';
import refreshToken from '../services/api/actions/refreshToken';
import { isUserLogged } from '../helpers/utils';
import { SocketActionTypes, SOCKET_EVENTS } from '../constants/types';
import { LOG_OUT_USER } from '../store/constants';

const SOCKET_URL = process.env.GATSBY_API_URL;
const WRITE_SOCKET = 'WRITE_SOCKET';

function connect() {
  const socket = io(SOCKET_URL, {
    reconnection: true,
    reconnectionAttempts: 6,
    transports: ['websocket'],
    query: {
      authorization: getTokens().accessToken,
    },
  });

  socket.on('connect', () => {
    /* eslint-disable no-console */
    console.log('SOCKET_CONNECTED');
  });

  socket.on('connect_error', (err) => {
    /* eslint-disable no-console */
    console.log(`SOCKET_CONNECTED_ERROR due to ${err.message}`);
  });

  socket.on('disconnect', (reason) => {
    /* eslint-disable no-console */
    console.error('SOCKET_DISCONNECTED', reason);
  });

  return socket;
}

/* eslint-disable require-yield */
function* subscribe(socket) {
  /* eslint-disable new-cap */
  return new eventChannel((emit) => {
    const update = (data, type) => emit({ type: SocketActionTypes[type], payload: data });

    socket.on(SOCKET_EVENTS.ORDER_UPDATED, (data) => update(data, SOCKET_EVENTS.ORDER_UPDATED));

    return () => {};
  });
}

function* readWorker(socket) {
  const channel = yield call(subscribe, socket);
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

function* writeWorker(socket) {
  while (true) {
    const action = yield take(WRITE_SOCKET);
    const { event, body } = action.payload;
    socket.emit(event, body);
  }
}

function* disconnectWatcher(socket) {
  yield takeLatest([LOG_OUT_USER, refreshToken.type.error], () => socket.disconnect());
}

function* socketWatcher() {
  if (isUserLogged()) {
    yield delay();
    const socket = yield call(connect);
    yield spawn(readWorker, socket);
    yield spawn(writeWorker, socket);
    yield spawn(disconnectWatcher, socket);
  }
}

export default function* socketSaga() {
  yield fork(socketWatcher);
  yield takeLatest(getTokenFinal.type.success, socketWatcher);
}
