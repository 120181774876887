import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CookieBanner from 'react-cookie-banner';
import clsx from 'clsx';
import { createStructuredSelector } from 'reselect';
import coockieicon from '../../images/payment/cookieicon.png';
import { getCountryData } from '../../helpers/countries';
import styles from './cookie-banner.module.scss';
import { selectEntityUserDetails } from '../../store/selectors/entities';
import { pageLinks } from '../../helpers/navigation';

const customStyles = {
  banner: {
    display: 'flex',
    alignItems: 'center',
    position: 'sticky',
    bottom: 10,
    fontFamily: '112.5%/1.45em georgia, serif',
    height: 'auto',
    background: `rgba(52, 64, 81, 0.88) url(${coockieicon}) 20px 50% no-repeat`,
    backgroundSize: '30px 30px',
    padding: '9px 15px',
    backgroundColor: '',
    fontSize: '15px',
    fontWeight: 600,
    transition: 'opacity 0.3s',
  },
  button: {
    border: '1px solid white',
    borderRadius: 4,
    width: 66,
    height: 32,
    lineHeight: '32px',
    background: 'transparent',
    color: 'white',
    fontSize: '14px',
    fontWeight: 600,
    opacity: 1,
    right: 20,
    marginTop: -18,
  },
  message: {
    display: 'block',
    padding: '9px 15px',
    lineHeight: 1.3,
    textAlign: 'left',
    marginRight: 80,
    marginLeft: 60,
    color: 'white',
  },
  link: {
    textDecoration: 'none',
    fontWeight: 'bold',
  },
};

const stateSelector = createStructuredSelector({
  userDetails: selectEntityUserDetails,
});

const CookieBannerCmp = () => {
  const { userDetails } = useSelector(stateSelector);
  const [isVisible, setVisible] = useState(false);

  const message = 'This website uses cookies to ensure you get the best experience on our website.';

  function createDocLink() {
    const countryCode = userDetails && userDetails.country;
    const countryData = getCountryData(countryCode);
    const countryName = countryData ? countryData.name : null;
    if (!countryName) {
      return pageLinks.privacy;
    }
    const searchParams = new URLSearchParams(`country=${countryName}`);
    return `${pageLinks.privacy}?${searchParams.toString()}`;
  }

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <CookieBanner
      className={clsx(styles.cookieContainer, isVisible ? styles.visible : '')}
      styles={customStyles}
      message={message}
      link={<a href={`${createDocLink()}`}>Learn more</a>}
      buttonMessage="Close"
      dismissOnScroll={false}
      dismissOnClick
      cookie="accepts-cookies-shop"
    />
  );
};

export default CookieBannerCmp;
