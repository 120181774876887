import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import { Link } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { selectAddonsModalInfo } from '../../store/selectors/global';
import plansSvg from '../../images/addons/plans.svg';
import { isRetailAddonInstance } from '../../helpers/instanceHandler';
import { redirectToOtherSite, setModalInfo } from '../../store/actions';
import { createNavigateTo, pageLinks } from '../../helpers/navigation';
import { REDIRECTION_TYPES } from '../../constants/types';

import Modal from '../modal';
import Button from '../button';
import styles from './modals.module.scss';

const NEVER_LOGGED = 'neverLoggedToTravel';
const MISSING_LIVE_SUBSCRIPTION = 'doNotHaveLiveSubscription';
const RENEW_LEARN_MODAL = 'learnModal';
const LOGIN_FIRST = 'loginFirst';

const strings = {
  youCant: 'You cannot purchase this product until you have a valid active',
  subscription: 'subscription(s)',
  toPurchaseOrRenew: 'to purchase or renew your ',
};

const Modals = ({ page }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const modalInfo = useSelector(selectAddonsModalInfo);

  const gotIt = intl.formatMessage({ id: 'button.gotIt' });
  const weAreSorry = intl.formatMessage({ id: 'addons.weAreSorry' });
  const weHaveNoticed = intl.formatMessage({ id: 'addons.weHaveNoticed' });
  const visit = intl.formatMessage({ id: 'addons.visit' });
  const thePortalFirst = intl.formatMessage({ id: 'addons.thePortalFirst' });
  const youNeed = intl.formatMessage({ id: 'addons.youNeed' });

  const hideModal = () => {
    if (page === 'checkout') {
      createNavigateTo(pageLinks.shoppingCart)();
    }
    dispatch(setModalInfo({ type: null }));
  };

  const moveToShop = () => {
    dispatch(redirectToOtherSite({ type: REDIRECTION_TYPES.SHOP, authorized: true }));
  };

  return (
    <div>
      <Modal
        closeButton={false}
        onCloseClick={hideModal}
        className={clsx(styles.modal, styles.neverLogged)}
        isShown={modalInfo.type === NEVER_LOGGED}
        title={weAreSorry}
        titleCenter
      >
        <div className={clsx(styles.modalWrapper)}>
          <p className={styles.modalText}>
            {weHaveNoticed} {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href="https://start.helloquester.com" target="_blank">
              {visit}
            </a>
            {thePortalFirst}
          </p>
          <Button className={styles.modalButton} onClick={hideModal} size="large">
            {gotIt}
          </Button>
        </div>
      </Modal>

      <Modal
        closeButton={false}
        onCloseClick={hideModal}
        className={styles.modal}
        isShown={modalInfo.type === MISSING_LIVE_SUBSCRIPTION}
        title={weAreSorry}
        titleCenter
      >
        <div className={styles.modalWrapper}>
          <div className={styles.imageWrapper}>
            <img src={plansSvg} className={styles.plansImg} alt="" />
          </div>
          <p className={styles.modalText}>
            {youNeed}{' '}
            <Link onClick={moveToShop} to="/">
              Live
            </Link>{' '}
            plan to buy this {modalInfo.addonName} add-on
          </p>
          <Button className={styles.modalButton} onClick={hideModal} size="large">
            {gotIt}
          </Button>
        </div>
      </Modal>

      <Modal
        closeButton={false}
        onCloseClick={hideModal}
        className={clsx(styles.modal, styles.renewLearn)}
        isShown={modalInfo.type === LOGIN_FIRST}
        title={weAreSorry}
        titleCenter
      >
        <div className={styles.modalWrapper}>
          <p className={styles.modalText}>
            To buy an addon, you need to log-in first. <br />
            Please
            <Link to="/login/?isFormOpen=true" onClick={hideModal}>
              log in
            </Link>
            {isRetailAddonInstance && (
              <>
                <br />
                Don&apos;t have an account{' '}
                <Link to="/registration" onClick={hideModal}>
                  register now
                </Link>
              </>
            )}
          </p>

          <Button className={styles.modalButton} onClick={hideModal} size="large">
            {gotIt}
          </Button>
        </div>
      </Modal>

      <Modal
        closeButton={false}
        onCloseClick={hideModal}
        className={clsx(styles.modal, styles.renewLearn)}
        isShown={modalInfo.type === RENEW_LEARN_MODAL}
        title={weAreSorry}
        titleCenter
        modalType={RENEW_LEARN_MODAL}
      >
        <div className={styles.modalWrapper}>
          <p className={styles.modalText}>
            {`${strings.youCant} ${modalInfo.planInfo} ${strings.subscription}`}.
            <br />
            Click{' '}
            <Link onClick={moveToShop} to="/">
              here
            </Link>
            {`${strings.toPurchaseOrRenew} ${modalInfo.planInfo} ${strings.subscription}`}.
          </p>
          <Button className={styles.modalButton} onClick={hideModal} size="large">
            {gotIt}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

Modals.propTypes = {
  page: PropTypes.string.isRequired,
};

export default Modals;
