import * as Yup from 'yup';
import { useFormik } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './billing-address-form-v2.module.scss';
import { getCountryData } from '../../helpers/countries';
import Input from '../input';
import StateSelect from '../state-select';
import CountrySelect from '../country-select';
import InputPhone from '../input-phone';
import Button from '../button';

const anyLetterRegExp =
  /^[a-zA-Z\u00C0-\u00FFŸŒ¢]+([a-zA-Z\u00C0-\u00FFŸŒ¢\s'`]?)+[a-zA-Z\u00C0-\u00FFŸŒ¢]+$/;
const anyLetterErrorMessage = 'These characters are not supported';

const validationSchema = (values) =>
  Yup.object().shape({
    firstName: Yup.string()
      .required('First name is required')
      .min(2, 'First Name should contain at least two letters')
      .max(50, 'First Name should be not more than 50 characters long')
      .matches(anyLetterRegExp, anyLetterErrorMessage),
    lastName: Yup.string()
      .required('Last name is required')
      .min(2, 'Last Name should contain at least two letters')
      .max(50, 'Last Name should be not more than 50 characters long')
      .matches(anyLetterRegExp, anyLetterErrorMessage),
    country: Yup.string().required('This field is required'),
    city: Yup.string()
      .matches(/^[a-zA-Z\u00C0-\u00FF\s-ŸŒ¢]*$/, 'Should contain only letters')
      .max(50, 'City should be not more than 50 characters long')
      .required('City is required'),
    address: Yup.string()
      .matches(/^[A-z0-9\u00C0-\u00FF\s,.ŸŒ¢-]*$/, 'These characters are not supported')
      .max(50, 'Address should be not more than 50 characters long')
      .required('Address is required'),
    address2: Yup.string()
      .matches(/^[A-z0-9\u00C0-\u00FF\s,.ŸŒ¢-]*$/, 'These characters are not supported')
      .max(50, 'Address should be not more than 50 characters long'),
    zipCode: Yup.string()
      .matches(/^([a-z0-9-\s]+)$/iu, 'Must be a valid zip-code')
      .max(10, 'Postal/zip code should be not more than 10 characters long')
      .required('ZIP/Postal Code is required'),

    phone: Yup.string().when('country', (val) => {
      let code = val && getCountryData(val).phone;
      if (values.countryCode) {
        code = values.countryCode.replace('+', '');
      }

      if (val && code) {
        const minLength = 8 - code.length;
        const maxLength = 16 - code.length;
        return Yup.string()
          .required('Phone is required')
          .matches(/^[0-9]*$/, 'Must be a valid number')
          .min(minLength, `Phone number should be 8-16 digits long including country code`)
          .max(maxLength, `Phone number should be 8-16 digits long including country code`);
      }
      return Yup.string().required('Phone is required');
    }),
    state: Yup.string().when('country', {
      is: 'us',
      then: () =>
        Yup.string()
          .required('State is required')
          .matches(anyLetterRegExp, 'Should contain only letters')
          .max(50, 'State should be not more than 45 characters long'),
    }),
  });

const initialValues = {
  firstName: '',
  lastName: '',
  country: '',
  countryCode: '',
  state: '',
  city: '',
  address: '',
  zipCode: '',
  address2: '',
  phone: '',
};

const BillingAddressFormV2 = ({ onAddBillingAddress, moveStepBack }) => {
  const fk = useFormik({
    initialValues,
    validationSchema: () => Yup.lazy((formValues) => validationSchema(formValues)),
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: onAddBillingAddress,
  });

  const showErrorMessage = (id) => (fk.touched[id] ? fk.errors[id] : '');

  const isState = fk.values.countryCode === 'US';
  const isDisabled = !fk.isValid;

  return (
    <form>
      <div className={styles.row}>
        <Input
          label="First Name"
          id="firstName"
          placeholder="Enter First Name"
          onChange={fk.handleChange}
          onBlur={fk.handleBlur}
          value={fk.values.firstName}
          errorMessage={showErrorMessage('firstName')}
          required
          fullwidth
        />

        <Input
          label="Last Name"
          id="lastName"
          placeholder="Enter Last Name"
          onChange={fk.handleChange}
          onBlur={fk.handleBlur}
          value={fk.values.lastName}
          errorMessage={showErrorMessage('lastName')}
          required
          fullwidth
        />

        <CountrySelect
          id="country"
          value={fk.values.country}
          label="Country"
          placeholder="Select your country"
          onChange={(e) => {
            fk.setFieldValue('country', e.value);
            fk.setFieldValue('countryCode', '');
          }}
          required
          isSearchable
          errorMessage={showErrorMessage('country')}
        />

        {isState && (
          <StateSelect
            id="state"
            value={fk.values.state}
            label="State"
            placeholder="Select your state"
            onChange={(e) => {
              fk.setFieldValue('state', e.value);
            }}
            required
            isSearchable
            errorMessage={showErrorMessage('state')}
          />
        )}

        <Input
          label="City"
          id="city"
          placeholder="Enter your city"
          onChange={fk.handleChange}
          onBlur={fk.handleBlur}
          value={fk.values.city}
          errorMessage={showErrorMessage('city')}
          required
          fullwidth
        />
        <InputPhone
          id="phone"
          label="Phone"
          // placeholder={phoneNumber}
          onChange={fk.handleChange}
          setFieldValue={fk.setFieldValue}
          onBlur={fk.handleBlur}
          value={fk.values.phone}
          countryCode={fk.values.countryCode}
          country={fk.values.country}
          fullwidth
          type="tel"
          required
          errorMessage={showErrorMessage('phone')}
        />

        <Input
          label="Address Line"
          id="address"
          placeholder="Enter your address"
          onChange={fk.handleChange}
          onBlur={fk.handleBlur}
          value={fk.values.address}
          errorMessage={showErrorMessage('address')}
          fullwidth
          required
        />

        <Input
          label="Address Line 2"
          id="address2"
          placeholder="Enter your address"
          onChange={fk.handleChange}
          onBlur={fk.handleBlur}
          value={fk.values.address2}
          errorMessage={showErrorMessage('address2')}
          fullwidth
        />

        <Input
          label="Postal Code"
          id="zipCode"
          placeholder="Enter your postal code"
          onChange={fk.handleChange}
          onBlur={fk.handleBlur}
          value={fk.values.zipCode}
          errorMessage={showErrorMessage('zipCode')}
          fullwidth
          required
        />
      </div>

      <div className={styles.btnContainer}>
        <Button fullWidth variant="outlined" size="large" type="button" onClick={moveStepBack}>
          Back
        </Button>

        <Button
          fullWidth
          size="large"
          disabled={isDisabled}
          type="button"
          onClick={fk.handleSubmit}
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

BillingAddressFormV2.propTypes = {
  onAddBillingAddress: PropTypes.func.isRequired,
  moveStepBack: PropTypes.func.isRequired,
};

export default BillingAddressFormV2;
