import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import Img from 'gatsby-image';
import styles from './banner-btc-discount-shopping-cart.module.scss';

const strings = {
  use: 'Use E-Wallet or BTC',
  as: 'as your payment method',
  toReceive: 'to receive 5% discount on your subscriptions!',
  purchase: 'Purchase Bitcoin',
  here: 'here',
  btcLink: 'https://coinmarketcap.com/how-to-buy-bitcoin/',
};

const query = graphql`
  query {
    bitcoin: file(relativePath: { eq: "payment/bitcoin.png" }) {
      childImageSharp {
        fixed(width: 16) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const BannerBtcDiscountShoppingCart = () => {
  const data = useStaticQuery(query);

  const bitcoinIcon = data.bitcoin && data.bitcoin.childImageSharp.fixed;

  return (
    <div className={styles.container}>
      <div className={styles.bannerWrapper}>
        <div>
          <Img fixed={bitcoinIcon} />
          <span className={styles.use}>{`${strings.use} `}</span>
          <span className={styles.text}>{` ${strings.as}`}</span> <br className={styles.br} />
          <span className={styles.text}>{` ${strings.toReceive}`}</span>
          <span className={styles.text}>{` ${strings.purchase}`}</span>
          <a rel="noreferrer" target="_blank" className={styles.link} href={strings.btcLink}>
            {strings.here}
          </a>
        </div>
      </div>
      <div className={styles.triangle} />
    </div>
  );
};

export default BannerBtcDiscountShoppingCart;
