import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { createStructuredSelector } from 'reselect';
import { FramePayProvider } from '@rebilly/framepay-react';
import Modal from '../modal';
import Button from '../button';
import InputRadio from '../input-radio';
import TitleButton from '../title-button';
import BillingPaymentBlock from '../billing-payment-block';
import BillingPaymentBlockCard from '../billing-payment-block-card';
import BannerBtcDiscountCheckout from '../banner-btc-discount-checkout';
import CheckoutFormCard from '../checkout-form-card';
import { isClient, getPaymentTitle, logEvent } from '../../helpers/utils';
import { isAddonInstance } from '../../helpers/instanceHandler';
import setPaymentAsDefault from '../../services/api/actions/setPaymentAsDefault';
import addPaymentCardAndSetDefault from '../../services/api/actions/addPaymentCardAndSetDefault';
import removePaymentMethod from '../../services/api/actions/removePaymentMethod';
import addPaymentCard from '../../services/api/actions/addPaymentCard';
import { framePayLink } from '../../utils/links';
import { selectCart, showAutoRenewalMessage } from '../../store/selectors';
import { selectEntityUserDetails, selectEntityWallets } from '../../store/selectors/entities';
import {
  redirectToOtherSite,
  selectPaymentMethodFromSaved,
  createPaypalAccount,
} from '../../store/actions';
import styles from './billing-payment-modal-form.module.scss';
import rebillyConfig from '../../constants/rebillyConfig';
import addressStyles from '../billing-address-modal-form/billing-address-modal-form.module.scss';
import StaticMessages from '../../constants/staticMessages';
import { REDIRECTION_TYPES } from '../../constants/types';
import PaymentConstants from '../../constants/payment';
import RequestToPayBlock from '../request-to-pay-block';
import { selectPayForFriendMode } from '../../store/selectors/global';

const stateSelector = createStructuredSelector({
  userDetails: selectEntityUserDetails,
  wallets: selectEntityWallets,
  cartData: selectCart,
  showAutoRenewal: showAutoRenewalMessage,
  payForFriendMode: selectPayForFriendMode,
});

const defaultMethods = isAddonInstance
  ? ['paypal']
  : ['paypal', PaymentConstants.SHOP_METHODS.card];

const BillingPaymentModalForm = (props) => {
  const dispatch = useDispatch();
  const { wallets, userDetails, cartData, showAutoRenewal, payForFriendMode } =
    useSelector(stateSelector);
  const hasTopUp = cartData?.products.some((p) => p.rebillyPlanIds.includes('topup'));

  const {
    modal: modalType,
    toggleModal: showModal,
    savedMethods,
    modalError,
    isPrimaryPaymentPresent,
    availablePaymentMethods,
  } = props;
  const isAddModal = modalType === 'add';
  const [method, setPaymentMethod] = useState('');
  const intl = useIntl();

  const showAutoRenewalMsg = defaultMethods.includes(method) && showAutoRenewal;

  const isEWalletOrBitpay =
    method === 'e-wallet' || method === 'bitpay' || method === 'coinpayments';

  const showInitialBTCDiscount = false;

  const addPaymentMethod = intl.formatMessage({ id: 'purchase_checkout.addPaymentMethod' });
  const savedPaymentMethod = intl.formatMessage({ id: 'purchase_checkout.saved_methods' });
  const detailsText = intl.formatMessage({ id: 'purchase_checkout.details' });
  const addPaymentText = intl.formatMessage({ id: 'purchase_checkout.addPaymentMethod' });
  const addText = intl.formatMessage({ id: 'purchase_checkout.add' });
  const addYourCard = intl.formatMessage({ id: 'purchase_checkout.addYourCard' });
  const paymMethodText = intl.formatMessage({ id: 'purchase_checkout.payment_method' });
  const removeText = intl.formatMessage({ id: 'button.remove' });
  const setDefaultText = intl.formatMessage({ id: 'button.setDefault' });

  const modalTitle = isAddModal ? addPaymentText : paymMethodText;
  const modalText = isAddModal ? addPaymentMethod : savedPaymentMethod;

  const filteredMethods = modalError ? defaultMethods : availablePaymentMethods;

  const ewalletAmount = wallets
    ? `E-wallet: $${wallets.commissionWallet} Gifted Wallet: $${wallets.giftedWallet}`
    : '';

  const maxiCashDescription = 'Pay By Credit & Debit Card';

  // TODO: move to saga
  useEffect(() => {
    logEvent('add_payment_info');
    const isAnnual = cartData && cartData.products.some((p) => p.isAnnual);
    if (isAnnual && !isEWalletOrBitpay) {
      setPaymentMethod('coinpayments');
    }

    if (filteredMethods[0] && !method) {
      setPaymentMethod(filteredMethods[0]);
    }
  }, []);

  const redirectToPortal = () => {
    dispatch(
      redirectToOtherSite({
        type: REDIRECTION_TYPES.PORTAL,
        authorized: true,
        link: `${process.env.GATSBY_PORTAL_LINK}/app/manageSubscription`,
      })
    );
  };

  const savePayment = ({ id, isDefault, method: methodName }) => {
    const data = { paymentToken: id, isDefault };

    if (methodName === 'paypal') {
      return dispatch(
        createPaypalAccount({
          paymentMethod: methodName,
          redirectUrl: isClient && window.location.href,
          notDefault: false,
        })
      );
    }

    if (isDefault) {
      return dispatch(addPaymentCardAndSetDefault.action(data));
    }

    return dispatch(addPaymentCard.action(data));
  };

  const onSelectClick = (paymentItem) => {
    dispatch(selectPaymentMethodFromSaved({ ...paymentItem }));
    showModal();
  };

  const onRemoveCard = (paymentInstrument) => {
    dispatch(
      removePaymentMethod.action({
        paymentInstrumentId: paymentInstrument.id,
        paymentMethod: paymentInstrument.method,
      })
    );

    dispatch(selectPaymentMethodFromSaved(null));
  };

  const checkIsDisabledPaymentMethod = (paymentMethod) => {
    if (availablePaymentMethods) {
      return !availablePaymentMethods.includes(paymentMethod);
    }

    return false;
  };

  const renderAddMethodPart = () => (
    <>
      <div className={styles.modal_left_side}>
        <div className={styles.modal_left_side_default_address}>
          <div className={styles.modal_left_side_wrapper}>
            <TitleButton title={modalText} small />
            {filteredMethods?.map((item) => {
              const isDisabled = checkIsDisabledPaymentMethod(item);

              if (isDisabled && payForFriendMode) {
                return null;
              }

              const onClickHandler = () => (isDisabled ? () => {} : setPaymentMethod(item));

              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div className={styles.payment} key={item} onClick={onClickHandler}>
                  <InputRadio
                    checked={method === item}
                    className={styles.radio}
                    value="address"
                    onChange={onClickHandler}
                  />
                  <div className={styles.modal_block_wrapper}>
                    <BillingPaymentBlock method={item} isDefault={false} isDisabled={isDisabled} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.modal_right_side}>
        <div className={styles.modal_right_side_wrapper}>
          <TitleButton title={`${getPaymentTitle(method)} ${detailsText}`} small />
        </div>

        {method && method === PaymentConstants.SHOP_METHODS.card && (
          <div className={styles.modal_right_side_content}>
            {modalError && <div className={addressStyles.modalError}>{modalError}</div>}

            {showAutoRenewalMsg && (
              <span className={styles.autoRenewalMessage}>
                <span className={styles.label}>
                  {StaticMessages.autoRenewalMessage.part1}
                  <strong
                    role="button"
                    tabIndex={0}
                    onKeyDown={redirectToPortal}
                    onClick={redirectToPortal}
                  >
                    {StaticMessages.autoRenewalMessage.part2}
                  </strong>
                  {StaticMessages.autoRenewalMessage.part3}
                </span>
              </span>
            )}

            <FramePayProvider publishableKey={framePayLink} style={rebillyConfig.style}>
              <CheckoutFormCard
                onReceiveToken={savePayment}
                method={method}
                isPrimaryPaymentPresent={isPrimaryPaymentPresent}
                userDetails={userDetails}
                wrapperClassName={styles.cardWrapper}
                renderProps={(onSubmitCb) => (
                  <>
                    {showInitialBTCDiscount && <BannerBtcDiscountCheckout />}
                    <div className={styles.modal_right_side_footer_credit_card}>
                      <Button size="large" defaultWidth onClick={onSubmitCb}>
                        {addYourCard}
                      </Button>
                    </div>
                  </>
                )}
              />
            </FramePayProvider>
          </div>
        )}

        {method && ['paypal', 'ach'].includes(method) && (
          <>
            <div className={styles.modal_right_side_content}>
              {modalError && <div className={addressStyles.modalError}>{modalError}</div>}
              {showAutoRenewalMsg && (
                <span className={styles.autoRenewalMessage}>
                  <span className={styles.label}>
                    {StaticMessages.autoRenewalMessage.part1}
                    <strong
                      role="button"
                      tabIndex={0}
                      onKeyDown={redirectToPortal}
                      onClick={redirectToPortal}
                    >
                      {StaticMessages.autoRenewalMessage.part2}
                    </strong>
                    {StaticMessages.autoRenewalMessage.part3}
                  </span>
                </span>
              )}
              <BillingPaymentBlock
                method={method}
                isDefault={false}
                isDisabled={checkIsDisabledPaymentMethod(method)}
              />
              <div className={styles.ewalletBlock} />
              <>
                {showInitialBTCDiscount && <BannerBtcDiscountCheckout />}
                <div className={styles.modal_right_side_footer_credit_card}>
                  <Button
                    size="large"
                    defaultWidth
                    onClick={() => {
                      savePayment({
                        method,
                      });
                    }}
                  >
                    Select
                  </Button>
                </div>
              </>
            </div>
          </>
        )}

        {method &&
          [
            'e-wallet',
            'bitpay',
            'astroPay',
            'bank-transfer',
            'cash-deposit',
            'coinpayments',
            'paypal-card',
            'rapyd',
            'rapyd-cash',
            'AmazonPay',
            'paynote',
            'MaxiCash',
          ].includes(method) && (
            <>
              <div className={styles.modal_right_side_content}>
                {modalError && <div className={addressStyles.modalError}>{modalError}</div>}
                <BillingPaymentBlock
                  method={method}
                  isDefault={false}
                  isDisabled={checkIsDisabledPaymentMethod(method)}
                />
                {method === 'e-wallet' && <div className={styles.amount}>{ewalletAmount}</div>}
                {method === 'MaxiCash' && (
                  <div className={styles.amount}>{maxiCashDescription}</div>
                )}
                <div className={styles.ewalletBlock} />
                <>
                  <div className={styles.modal_right_side_footer_credit_card}>
                    <Button
                      size="large"
                      defaultWidth
                      onClick={() => {
                        onSelectClick({
                          method,
                          username: isClient && localStorage.getItem('UN'),
                        });
                      }}
                    >
                      Select
                    </Button>
                  </div>
                </>
              </div>
            </>
          )}

        {method === PaymentConstants.SHOP_METHODS.requestToPay && (
          <div className={styles.modal_right_side_content}>
            <RequestToPayBlock method={method} onSubmit={onSelectClick} />
          </div>
        )}

        {!method && (
          <>
            <div className={styles.modal_right_side_content} />
            {modalError && <div className={addressStyles.modalError}>{modalError}</div>}
            <>
              <div className={styles.modal_right_side_footer}>
                <Button size="large" defaultWidth disabled>
                  {addText}
                </Button>
              </div>
            </>
          </>
        )}
      </div>
    </>
  );

  const [chosenMethod, chooseMethod] = useState(null);

  const renderSavedMethodsPart = () => {
    const { paypal, cards, bankAccounts } = savedMethods || {};

    const savedMethodList = hasTopUp ? [] : ['e-wallet'];

    if (savedMethods && cards.length) {
      savedMethodList.push(PaymentConstants.SHOP_METHODS.card);
    }

    if (savedMethods && paypal.length) {
      savedMethodList.push('paypal');
    }

    if (availablePaymentMethods.includes('ach') && savedMethods && bankAccounts.length) {
      savedMethodList.push('ach');
    }

    if (availablePaymentMethods.includes('astroPay')) {
      savedMethodList.push('astroPay');
    }

    if (savedMethods && method && savedMethods[method] && !savedMethods[method].length) {
      setPaymentMethod('');
    }

    const getSavedList = (name) => {
      switch (name) {
        case PaymentConstants.SHOP_METHODS.card: {
          return cards.filter((item) => !item.brand.includes('AstroPay')) || [];
        }
        case 'astroPay': {
          return [
            {
              method: 'astroPay',
              title: 'AstroPay Card',
              username: isClient && localStorage.getItem('UN'),
            },
          ];
        }
        case 'e-wallet': {
          return [
            {
              method: 'e-wallet',
              title: 'E-Wallet',
              username: isClient && localStorage.getItem('UN'),
            },
          ];
        }
        case 'paypal': {
          return paypal || [];
        }
        case 'ach': {
          return bankAccounts || [];
        }
        default: {
          return [];
        }
      }
    };

    const address = {};

    const makeDefault = (paymentInstrument) => {
      dispatch(selectPaymentMethodFromSaved(null));
      dispatch(
        setPaymentAsDefault.action({ method: paymentInstrument.method, id: paymentInstrument.id })
      );
    };

    const onPaymentItemClick = (item) => {
      if (item === 'e-wallet') {
        setPaymentMethod(item);
        chooseMethod({ method: 'e-wallet', id: null });
        return;
      }
      setPaymentMethod(item);
      chooseMethod(null);
    };

    const renderPaymentDetails = (item) => {
      if (
        item.method === PaymentConstants.SHOP_METHODS.card ||
        item.method === 'ach' ||
        (item.brand && item.brand.includes('AstroPay'))
      ) {
        return (
          <BillingPaymentBlockCard
            brand={item.brand || item.method}
            last4={item.last4}
            isDefault={item.isDefault}
            wrapperClassName={styles.addressInList}
          />
        );
      }

      if (item.method === 'paypal') {
        return (
          <BillingPaymentBlock
            method={item.method}
            wrapperClassName={styles.addressInList}
            title={`${item.billingAddress?.firstName} ${item.billingAddress?.lastName}`}
            subtitle={
              item.billingAddress.emails.length ? item.billingAddress.emails[0]?.value : null
            }
            isDefault={item.isDefault}
          />
        );
      }

      if (
        item.method === 'e-wallet' ||
        item.method === 'astroPay' ||
        item.method === 'coinpayments' ||
        item.method === 'bank-transfer' ||
        item.method === 'cash-deposit' ||
        item.method === 'requestToPay'
      ) {
        return (
          <>
            <BillingPaymentBlock
              method={item.method}
              wrapperClassName={styles.addressInList}
              title={item.title}
              isDefault={item.isDefault}
            />
            {item.method === 'e-wallet' && <div className={styles.amount}>{ewalletAmount}</div>}
          </>
        );
      }
      return null;
    };

    return (
      <>
        <div className={styles.modal_left_side}>
          <div className={styles.modal_left_side_default_address}>
            <div className={styles.modal_left_side_wrapper}>
              <TitleButton title={modalText} small />
              {savedMethodList.map((item) => {
                const isDisabled = checkIsDisabledPaymentMethod(item);

                if (isDisabled && payForFriendMode) {
                  return null;
                }

                const onClickHandler = () => {
                  if (isDisabled) {
                    return () => {};
                  }

                  onPaymentItemClick(item);
                  chooseMethod(null);
                  return null;
                };

                const onRadioClickHandler = () => (isDisabled ? () => {} : setPaymentMethod(item));

                return (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                  <div className={styles.payment} key={item} onClick={onClickHandler}>
                    <InputRadio
                      checked={method === item}
                      className={styles.radio}
                      value="address"
                      onChange={onRadioClickHandler}
                    />
                    <div className={styles.modal_block_wrapper}>
                      <BillingPaymentBlock
                        method={item}
                        isDefault={false}
                        isDisabled={isDisabled}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles.modal_right_side}>
          <div className={styles.modal_right_side_wrapper}>
            {savedMethodList.includes(method) && (
              <TitleButton title={`${getPaymentTitle(method)} ${detailsText}`} small />
            )}
          </div>
          <div className={styles.modal_right_side_content}>
            {showAutoRenewalMsg && (
              <span className={styles.autoRenewalMessage}>
                <span className={styles.label}>
                  {StaticMessages.autoRenewalMessage.part1}
                  <strong
                    role="button"
                    tabIndex={0}
                    onKeyDown={redirectToPortal}
                    onClick={redirectToPortal}
                  >
                    {StaticMessages.autoRenewalMessage.part2}
                  </strong>
                  {StaticMessages.autoRenewalMessage.part3}
                </span>
              </span>
            )}

            {method &&
              getSavedList(method).map((item) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <div
                  className={clsx(
                    addressStyles.modal_block_wrapper,
                    styles.paymentItem,
                    chosenMethod && chosenMethod.id === item.id && styles.selected
                  )}
                  key={item.id}
                  onClick={() => chooseMethod(item)}
                >
                  {renderPaymentDetails(item)}
                  {(method === PaymentConstants.SHOP_METHODS.card ||
                    method === 'paypal' ||
                    method === 'ach') && (
                    <div className={addressStyles.row}>
                      {!item.isDefault && (
                        <button
                          type="button"
                          className={clsx(addressStyles.addressInListButton)}
                          onClick={(e) => {
                            e.stopPropagation();
                            makeDefault(item);
                          }}
                        >
                          <span>{setDefaultText}</span>
                        </button>
                      )}

                      {!item.isDefault && (
                        <button
                          type="button"
                          className={clsx(
                            addressStyles.addressInListButton,
                            addressStyles.addressInListButton_remove,

                            chosenMethod && chosenMethod.id === address.id
                              ? addressStyles.addressInListButtonSelected
                              : null
                          )}
                          onClick={(e) => {
                            e.stopPropagation();
                            onRemoveCard(item);
                          }}
                        >
                          <span>{item.method === 'paypal' ? removeText : 'disconnect'}</span>
                        </button>
                      )}
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div className={styles.modal_right_side_footer}>
            <Button
              size="large"
              defaultWidth
              disabled={!chosenMethod}
              onClick={() => onSelectClick(chosenMethod)}
            >
              Select
            </Button>
          </div>
        </div>
        <ReactTooltip className={styles.tooltip} />
      </>
    );
  };

  const ModalContent = isAddModal ? renderAddMethodPart : renderSavedMethodsPart;

  const onModalHide = () => {
    showModal();
    chooseMethod(null);
    setPaymentMethod('e-wallet');
  };

  return (
    <Modal
      isShown={modalType === 'add' || modalType === 'change'}
      title={modalTitle}
      onCloseClick={onModalHide}
    >
      <ModalContent />
    </Modal>
  );
};

BillingPaymentModalForm.defaultProps = {
  savedMethods: { cards: null, paypal: null },
  modal: '',
  modalError: null,
};

BillingPaymentModalForm.propTypes = {
  modal: PropTypes.string,
  modalError: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  availablePaymentMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleModal: PropTypes.func.isRequired,
  savedMethods: PropTypes.PropTypes.shape({}),
  isPrimaryPaymentPresent: PropTypes.bool.isRequired,
};

export default React.memo(
  BillingPaymentModalForm,
  (props, nextProps) =>
    JSON.stringify({
      ...props.savedMethods,
      ...props.availablePaymentMethods,
      modal: props.modal,
      modalError: props.modalError,
    }) ===
    JSON.stringify({
      ...nextProps.savedMethods,
      ...nextProps.availablePaymentMethods,
      modal: nextProps.modal,
      modalError: nextProps.modalError,
    })
);
