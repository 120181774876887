import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { countries } from 'countries-list';
import { Link } from 'gatsby-plugin-intl';
import { useSelector } from 'react-redux';
import { pageLinks } from '../../helpers/navigation';
import { selectUserCountry } from '../../store/selectors/global';
import styles from './region-select.module.scss';
import { selectCountriesInRegion } from '../../store/selectors/entities';

const RegionSelect = ({ invertDark }) => {
  const linkState = { modal: true };
  const userCountry = useSelector(selectUserCountry);

  const startCountry = process.env.GATSBY_INSTANCE_COUNTRY;

  const instanceCountries = useSelector(selectCountriesInRegion);

  const singleCountryInstance = instanceCountries.length === 1;

  const countryCode = singleCountryInstance
    ? instanceCountries[0].toUpperCase()
    : userCountry || startCountry;

  const countryData = countries[countryCode] || {};

  return (
    <div className={clsx(styles.inputSelectWrapper, singleCountryInstance && styles.disabled)}>
      <div className={styles.wrapper}>
        <Link to={pageLinks.regions} state={linkState} className={clsx(styles.link)}>
          {countryData?.emoji && <span className={styles.emoji}>{countryData.emoji}</span>}
          <span
            className={clsx(styles.code, { [styles.invertColor]: invertDark }, 'dont-translate')}
          >
            {countryData.name || 'Select country'}
          </span>
        </Link>
      </div>
    </div>
  );
};

RegionSelect.defaultProps = { invertDark: false };
RegionSelect.propTypes = { invertDark: PropTypes.bool };

export default RegionSelect;
