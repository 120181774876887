const sortFn = (a, b) => {
  const firstPriority = a.priority;
  const secondPriority = b.priority;
  return firstPriority - secondPriority;
};

function arrayMoveItem(arr, fromIndex, toIndex) {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
}

module.exports = function productsResolver(list, keepAllProducts = false) {
  const ibiSku = 'BPKITFEE1';
  const productsWithOutIbi = list.filter(
    (product) => !product.plans.some((p) => p.skus.includes(ibiSku))
  );
  const products = productsWithOutIbi.map((item) => {
    const minPrice = Math.min(...item.plans.map((plan) => plan.recurringPrice));
    const urlParam = item.urlParam && item.urlParam.trim();

    let productWithAnnualPlans = false;

    const commonPlanData = item.plans[0];

    const product = {
      ...item,
      urlParam,
      isComboProduct: commonPlanData.isCombo,
    };

    let plans = product.plans.sort(sortFn).map((plan) => {
      const isAnnual = plan.intervalSize > 300 && product.urlParam !== 'tickets' && !plan.isAddon;

      if (isAnnual && !productWithAnnualPlans) {
        productWithAnnualPlans = true;
      }

      return {
        ...plan,
        isAnnual,
        product: { name: product.name, url: urlParam },
        isTicket: product.urlParam === 'tickets',
        isPartOfCombo: list.reduce((acc, val) => {
          if (!product.isComboProduct && val.plans[0].isCombo) {
            return val.plans.some((p) => p.skus.includes(plan.skus[0]));
          }
          return acc;
        }, false),
      };
    });

    if (plans[plans.length - 1].name.toLowerCase().includes('prime')) {
      plans = arrayMoveItem(plans, plans.length - 1, plans.length - 2);
    }

    return {
      ...product,
      price: minPrice,
      productWithAnnualPlans,
      plans,
    };
  });

  if (keepAllProducts) {
    return products;
  }

  return products.filter((item) =>
    ['learn', 'nitro', 'elite', 'digi', 'learn-enterprise', 'tickets'].includes(item.urlParam)
  );
};
