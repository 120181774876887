import { isClient } from './utils';
import { isProduction } from './instanceHandler';

export const getSearchParam = (param, url) => new URLSearchParams(new URL(url).search).get(param);

export const checkSitePage = (expectedRoute) =>
  isClient && window.location.href.includes(expectedRoute);

export const checkRedirectionUrl = (redirectionUrl = '') => {
  if (redirectionUrl.includes('addonmkt.com')) {
    return isProduction ? 'https://star.one' : 'https://shop.stg.star.one';
  }

  return redirectionUrl;
};
