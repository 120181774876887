/* eslint-disable prefer-const */
import React from 'react';
import clsx from 'clsx';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

import styles from './about-us-cards.module.scss';

const query = graphql`
  query {
    whoWeAre: file(relativePath: { eq: "about-us/who-we-are.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 360, maxHeight: 430) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    whatWeDo: file(relativePath: { eq: "about-us/what-we-do.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 360, maxHeight: 430) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    howWeDoIt: file(relativePath: { eq: "about-us/how-we-do-it.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 360, maxHeight: 430) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const CARDS_TEXT = {
  rapidchangers: {
    whoWeAre:
      'Our collective purpose is to help transform the future of learning for the better.' +
      ' We’re all learners and instructors.' +
      ' We live out our values every day to create a culture that is diverse,' +
      ' inclusive, and committed to helping each other thrive.',
    whatWeDo:
      'We’re an online learning community with thousands of classes for curious and\n' +
      'creative people on topics spanning marketing, entrepreneurship, social media,\n' +
      'personal development, digital and more. Thousands of\n' +
      'members come together to get motivated and take the next step in their\n' +
      'creative journey.',
  },
};

const AboutUsCards = () => {
  const intl = useIntl();
  const data = useStaticQuery(query);
  let { whoWeAre, whatWeDo, howWeDoIt } = data;

  const customText = CARDS_TEXT[process.env.GATSBY_INSTANCE_NAME];

  return (
    <>
      <div className={styles.wrapper}>
        <BackgroundImage
          Tag="div"
          fluid={whoWeAre.childImageSharp.fluid}
          className={clsx(styles.imageBg, styles.who)}
          preserveStackingContext
        >
          <div className={styles.card}>
            <div className={styles.content}>
              <div>
                <div className={styles.titleWrapper}>
                  <div className={styles.title}>
                    {intl.formatMessage({ id: 'about_us_cards.who' })}
                  </div>
                  <div className={styles.title}>
                    {intl.formatMessage({ id: 'about_us_cards.we_are' })}
                  </div>
                </div>
              </div>
              <div className={styles.textWrapper}>
                <p>{intl.formatMessage({ id: 'about_us_cards.who_we_are_1' })}</p>
                <p>
                  {customText?.whoWeAre ||
                    intl.formatMessage({ id: 'about_us_cards.who_we_are_2' })}
                </p>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </div>

      <div className={styles.wrapper}>
        <BackgroundImage
          Tag="div"
          fluid={whatWeDo.childImageSharp.fluid}
          className={clsx(styles.imageBg, styles.what)}
          preserveStackingContext
        >
          <div className={styles.card}>
            <div className={styles.content}>
              <div>
                <div className={styles.titleWrapper}>
                  <div className={styles.title}>
                    {intl.formatMessage({ id: 'about_us_cards.what' })}
                  </div>
                  <div className={styles.title}>
                    {intl.formatMessage({ id: 'about_us_cards.we_do' })}
                  </div>
                </div>
              </div>
              <div className={styles.textWrapper}>
                {customText?.whatWeDo || (
                  <>
                    <p>{intl.formatMessage({ id: 'about_us_cards.what_we_do_2' })}</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </BackgroundImage>
      </div>

      <div className={styles.wrapper}>
        <BackgroundImage
          Tag="div"
          fluid={howWeDoIt.childImageSharp.fluid}
          className={clsx(styles.imageBg, styles.how)}
          preserveStackingContext
        >
          <div className={styles.card}>
            <div className={styles.content}>
              <div>
                <div className={styles.titleWrapper}>
                  <div className={styles.title}>
                    {intl.formatMessage({ id: 'about_us_cards.how' })}
                  </div>
                  <div className={styles.title}>
                    {intl.formatMessage({ id: 'about_us_cards.we_do_it' })}
                  </div>
                </div>
              </div>
              <div className={styles.textWrapper}>
                <p>{intl.formatMessage({ id: 'about_us_cards.how_we_do_it_1' })}</p>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </div>
    </>
  );
};

export default AboutUsCards;
