import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Button from '../components/button';
import OrderPlacedBanner from '../components/order-placed-banner';
import OrderSummary from '../components/order-summary';
import ArrowBack from '../components/arrowBack';
import { externalLinks, pageLinks } from '../helpers/navigation';
import {
  getInstanceName,
  isBeFreeGateways,
  isClient,
  getCompanyDescriptorName,
} from '../helpers/utils';
import {
  selectCoordinator,
  selectElectronicSignments,
  selectEntityUserDetails,
  selectMetaGateways,
  selectOrderResultData,
} from '../store/selectors/entities';
import styles from '../styles/pages/checkout-success.module.scss';
import {
  clearShoppingCart,
  redirectToOtherSite,
  setPayForFriendMode,
  toggleLiveChat,
} from '../store/actions';
import Loader from '../components/loader';
import { REDIRECTION_TYPES } from '../constants/types';
import getOrderById from '../services/api/actions/getOrderById';
import { ORDER_STATUSES } from '../constants/payment';
import { selectPayForFriendMode } from '../store/selectors/global';
import { isTrivaInstance } from '../helpers/instanceHandler';

const query = graphql`
  query {
    bgLeft: file(relativePath: { eq: "confeti-left.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 507) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bgRight: file(relativePath: { eq: "confeti-right.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 507) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const getOrderResultStatus = (status) => {
  switch (status) {
    case ORDER_STATUSES.PAID:
      return 'processed';

    case ORDER_STATUSES.PENDING:
    case ORDER_STATUSES.ON_HOLD:
    case ORDER_STATUSES.PROCESSING:
    case ORDER_STATUSES.BLOCKCHAIN_PENDING:
      return 'processing';

    case ORDER_STATUSES.CANCELED:
      return 'Declined';

    case ORDER_STATUSES.REFUNDED:
    case ORDER_STATUSES.WITH_ERROR:
    case ORDER_STATUSES.EXPIRED:
      return 'processed with error';

    default:
      return status || 'processing';
  }
};

const DELAY = 1000 * 60 * 5;

const CheckoutResult = () => {
  const intl = useIntl();
  const siteName = getInstanceName();
  const dispatch = useDispatch();
  const gateways = useSelector(selectMetaGateways);
  const userDetails = useSelector(selectEntityUserDetails);
  const electronicSignments = useSelector(selectElectronicSignments);
  const orderData = useSelector(selectOrderResultData);
  const payForFriendMode = useSelector(selectPayForFriendMode);
  const coordinator = useSelector(selectCoordinator);

  //! to test UI with different statuses you can change it below
  // const orderResultData = { ...orderData, status: ORDER_STATUSES.CANCELED };

  const orderResultData = orderData;
  const boughtProducts = isClient ? JSON.parse(localStorage.getItem('boughtProducts')) || {} : {};
  const requestToPayOrder = boughtProducts?.requestToPayOrder;
  const bankStatementDescriptor =
    orderResultData?.companyName ||
    getCompanyDescriptorName(orderResultData?.paymentMethod, gateways) ||
    'N/A';

  const [pendingMessage, togglePendingMessage] = useState(false);
  const [liveChatOpened, openLiveChat] = useState(false);

  const orderStatus = orderResultData?.status;
  const orderMessage = orderResultData?.message;
  const orderGatewayAccountId = orderResultData?.gatewayAccountId;
  const orderPaymentGetaway = orderResultData?.paymentGatewayId;

  const backToTxt = 'return to checkout';
  const myPortalTxt = intl.formatMessage({ id: 'open_my_portal' });
  const data = useStaticQuery(query);

  const orderInfo = isClient ? JSON.parse(localStorage.getItem('boughtProducts')) || {} : {};

  const userName = userDetails?.firstName;
  const requestCoordinatorFirstName = coordinator?.firstName;
  const requestCoordinatorLastName = coordinator?.lastName;

  const title = intl.formatMessage({ id: 'pageTitle.checkoutSuccess' });

  const isSuccessStatus = [ORDER_STATUSES.PAID].includes(orderStatus);

  const isPendingStatus =
    !requestToPayOrder &&
    [
      ORDER_STATUSES.PENDING,
      ORDER_STATUSES.ON_HOLD,
      ORDER_STATUSES.PROCESSING,
      ORDER_STATUSES.BLOCKCHAIN_PENDING,
    ].includes(orderStatus);

  const isFailStatus = orderStatus && !isSuccessStatus && !isPendingStatus;
  const showBeFreeDisclaimer = isBeFreeGateways(orderGatewayAccountId) || isSuccessStatus;

  const redirectToCP = (e) => {
    e.preventDefault();
    if (isClient) {
      localStorage.removeItem('audit-trial-id');
    }
    dispatch(
      redirectToOtherSite({
        type: REDIRECTION_TYPES.PORTAL,
        authorized: true,
        link: process.env.GATSBY_PORTAL_LINK,
      })
    );
  };

  const showPendingMessage = () => {
    if (isPendingStatus) {
      togglePendingMessage(true);
    }
  };

  const redirectToAlternativePm = (e) => {
    e.preventDefault();
    dispatch(
      redirectToOtherSite({
        type: REDIRECTION_TYPES.WOOCOMMERCE,
        authorized: true,
        link: `${externalLinks.alternativePaymentMethod}`,
      })
    );
  };

  useEffect(() => {
    if (isFailStatus && isClient && window.$crisp && !liveChatOpened) {
      dispatch(toggleLiveChat(true));
      openLiveChat(true);
    }

    if (isSuccessStatus || orderResultData?.status === 'Waiting for payment') {
      dispatch(clearShoppingCart());
    }

    if (payForFriendMode) {
      dispatch(setPayForFriendMode(false));
    }
  });

  useEffect(() => {
    if (document.body.classList.contains('modal-is-active')) {
      document.body.className = document.body.className.replaceAll('modal-is-active', '');
    }
  }, []);

  useEffect(() => {
    const id = boughtProducts ? `/${boughtProducts.order}` : '';

    if (id && !requestToPayOrder) {
      dispatch(getOrderById.withQuery(id).action());
    }
    setTimeout(showPendingMessage, DELAY);
  }, []);

  const renderOrderInfo = () => {
    if ((!orderStatus || isPendingStatus) && !requestToPayOrder) {
      return <Loader isLoading />;
    }

    // ! When user waits more than 5 min
    if (pendingMessage) {
      return (
        <div className={styles.info}>
          <h4>Sorry we are still waiting for your payment</h4>
          <span>
            Your order has <strong>NOT</strong> been completed yet, we are still waiting to receive
            the funds. If your subscriptions does <strong>NOT</strong> get activated within the next
            5 minutes, please retry the payment again. In the meantime you can check your{' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="" onClick={redirectToCP}>
              ORDER STATUS HERE!
            </a>
            {orderPaymentGetaway === 'coinpayments' && (
              <div className={styles.note}>
                <strong>NOTE:</strong>
                Bitcoin payment confirmation may take up to 1 hour.
              </div>
            )}
          </span>
        </div>
      );
    }

    return (
      <OrderSummary
        isOrderPlaced={isSuccessStatus}
        requestToPayOrder={requestToPayOrder}
        electronicSignments={electronicSignments}
      />
    );
  };

  return (
    <>
      <SEO title={`${title} - ${siteName}`} />
      <Layout fluid pageWrapperClassName="page_wrapper">
        <div className="container">
          {!isTrivaInstance && (
            <div>
              <ArrowBack text={backToTxt} navigationTo={pageLinks.checkout} />
            </div>
          )}

          <div className={styles.banner}>
            <OrderPlacedBanner
              userName={userName}
              payerUserName={requestCoordinatorFirstName}
              payerUserLastName={requestCoordinatorLastName}
              requestToPayOrder={!!requestToPayOrder}
              orderResult={getOrderResultStatus(orderStatus)}
              orderMessage={orderMessage}
              isInit={!!orderInfo?.products?.find((i) => i.action === 'INITIAL')}
              isSuccess={isSuccessStatus}
              showDisclaimer={showBeFreeDisclaimer}
              bankStatementDescriptor={bankStatementDescriptor}
            />
          </div>
          <>
            <div style={{ position: 'relative' }}>
              <BackgroundImage
                Tag="div"
                className={styles.summaryBg}
                fluid={[data.bgLeft.childImageSharp.fluid, data.bgRight.childImageSharp.fluid]}
                backgroundColor="transparent"
              >
                <div className={styles.summaryBgOverlay} />

                <div className={styles.summary}>{renderOrderInfo()}</div>
              </BackgroundImage>
            </div>

            {isSuccessStatus && !isTrivaInstance && (
              <div className={styles.action}>
                <Button
                  className={styles.button}
                  size="large"
                  withArrow
                  component="button"
                  onClick={redirectToCP}
                >
                  {myPortalTxt}
                </Button>
              </div>
            )}

            {process.env.GATSBY_INSTANCE_NAME === 'befreedom' && isFailStatus && (
              <div className={styles.action}>
                <Button
                  className={styles.button}
                  size="large"
                  component="button"
                  onClick={redirectToAlternativePm}
                >
                  Checkout with alternative payment method
                </Button>
              </div>
            )}
          </>
        </div>
      </Layout>
    </>
  );
};

export default CheckoutResult;
