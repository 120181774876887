// fake localStorage - i use this with edux-persist when localStorage/localForage isn't available
export default class FakeLocalStorage {
  constructor() {
    this.storage = {};
  }

  setItem(key, value) {
    this.storage[key] = value;
  }

  getItem(key) {
    return this.storage[key];
  }

  removeItem(key) {
    return delete this.storage[key];
  }

  getAllKeys(callback) {
    return new Promise((resolve) => {
      const keys = Object.keys(this.storage);
      if (callback) callback(null, keys);
      resolve(keys);
    });
  }
}
