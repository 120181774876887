import apiAction from '../apiActionCreator';

const type = 'CREATE_PAYMENT_METHOD';

const config = {
  url: '/payments/method/create',
  actionType: type,
  sendFormat: 'json',
  method: 'POST',
};

export default apiAction(config);
