import { pageLinks } from './navigation';
import DEPRECATED_PAGE_LIST from '../constants/deprecatedPages';

export const filterMemberShipPages = (link) => {
  if (process.env.GATSBY_INSTANCE_NAME !== 'rapidchangers') {
    return link.url === pageLinks.membershipLearn;
  }
  return !DEPRECATED_PAGE_LIST.some((deprecatedLink) => deprecatedLink.includes(link.url));
};

export const filterShopPages = (link) =>
  !DEPRECATED_PAGE_LIST.some((deprecatedLink) => deprecatedLink.includes(link.url));
